import React from 'react';
import { Link } from 'react-router-dom';
import env from 'conf/env.json';

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap';

import Loader from 'react-loader-spinner';
import { Editor } from '@tinymce/tinymce-react';

import Alert from 'views/components/Alert';

import NoticiasService from 'services/NoticiasService';
import LoginService from 'services/LoginService';

import TipoNoticia from 'assets/csv/tipos-noticia.json';

class Noticia extends React.Component {
  constructor(props) {
    super(props);

    this.noticiasService = new NoticiasService();
    this.loginService = new LoginService();

    this.state = {
      showAlert: false,
      erro: null,
    };
  }

  componentWillUnmount() {
    this.loginService.abortPedingRequests();
    this.noticiasService.abortPedingRequests();

    this.umounted = true;
  }

  async componentDidMount() {
    await this.loginService.verificarPapel(this.props.role);
    try {
      const noticia = await this.noticiasService
        .carregarNoticia(this.props.match.params.idNoticia);
      this.setState({ noticia });
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  conteudoPagina() {
    return (!this.state.noticia)
      ? <Card>
        <div align="center" style={{ margin: 50 }}>
          <Loader
            type="Oval"
            color="#34B5B8"
            height="50"
            width="50" />
        </div>
      </Card> : <Row>
        <Col md="12">
          <Alert
            color={this.state.alertColor}
            isOpen={this.state.showAlert}
            toggle={() => { this.setState({ showAlert: false }); }}>
            {this.state.alertMsg}
          </Alert>
          <Breadcrumb>
            <BreadcrumbItem><Link to={`${this.props.layout}/noticias`}>Notícias</Link></BreadcrumbItem>
            <BreadcrumbItem active>Notícia</BreadcrumbItem>
          </Breadcrumb>
          <Card>
            <CardBody>
              <div className={'noticias'} >
                <div className={'noticia'} >
                  <Row>
                    <Col>
                      <div className={'titulo-noticia'}>
                        <p>{this.state.noticia.titulo}</p>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="text-left">
                      {
                        this.state.noticia.tipo === TipoNoticia.PUBLICA.value
                          ? <div className="social-icons">
                            <a className="whatsapp" target="_blank" alt="Compartilhar no WhatsApp" rel="noopener noreferrer" href={`https://api.whatsapp.com/send?text=${this.state.noticia.titulo} | ${env.SYSNAME} - ${env.BASE_ADDRESS}/noticias/${this.state.noticia.cidade}/${this.state.noticia.id}`}>
                              <i className="fab fa-whatsapp fa-2x"></i>
                            </a>&nbsp;&nbsp;&nbsp;&nbsp;
                            <a className="facebook" target="_blank" rel="noopener noreferrer" href={`https://www.facebook.com/sharer/sharer.php?u=${env.BASE_ADDRESS}/noticias/${this.state.noticia.cidade}/${this.state.noticia.id}&src=sdkpreparse`}>
                              <i className="fab fa-facebook fa-2x"></i>
                            </a>&nbsp;&nbsp;&nbsp;&nbsp;
                            <a className="twitter" target="_blank" rel="noopener noreferrer" href={`https://twitter.com/share?url=${env.BASE_ADDRESS}/noticias/${this.state.noticia.cidade}/${this.state.noticia.id}`}>
                              <i className="fab fa-twitter fa-2x"></i>
                            </a>
                          </div> : <div className="social-icons">
                            <i className="fab fa-whatsapp fa-2x disabled"></i>&nbsp;&nbsp;&nbsp;&nbsp;
                          <i className="fab fa-facebook fa-2x disabled"></i>&nbsp;&nbsp;&nbsp;&nbsp;
                          <i className="fab fa-twitter fa-2x disabled"></i>
                          </div>
                      }
                    </Col>
                    <Col className="text-right">
                      <p className="data">{this.state.noticia.data}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Editor
                        apiKey='5ri9uiz44o9zmfcm5nsbg8fumf9akfxdjs25t2if5l6pasm5'
                        initialValue={this.state.noticia.conteudo}
                        init={{
                          menubar: false,
                          toolbar: false,
                          resize: false,
                          inline: true,
                          setup: (editor) => {
                            editor.setMode('readonly');
                          },
                          plugins: 'autoresize',
                        }} />
                    </Col>
                  </Row>
                </div>
              </div>
            </CardBody>
            <CardFooter>
              <Row>
                <Col>
                  <Button
                    color="primary"
                    disabled={this.state.buttonDisabled}
                    onClick={() => this.props.history.push(`${this.props.layout}/noticias`)} >
                    Voltar
                    </Button>
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Col >
      </Row >;
  }

  render() {
    return (
      <div className="content">
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
          </div>
          </Card>
        }
      </div>
    );
  }
}

export default Noticia;
