import React from 'react';
import { Link } from 'react-router-dom';

import {
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  Button,
  Table,
  FormGroup
} from 'reactstrap';

import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar';

import Loader from 'react-loader-spinner';
import uniqid from 'uniqid';

import Select from 'react-select';
import Alert from 'views/components/Alert';
import LoaderModal from 'views/components/LoaderModal';

import LoginService from 'services/LoginService';
import EntesService from 'services/EntesService';
import AnosLetivosService from 'services/AnosLetivosService';
import EscolasService from 'services/EscolasService';
import TurmasService from 'services/TurmasService';
import DiariosService from 'services/DiariosService';
import MateriasService from 'services/MateriasService';
import HabilidadesCompetenciasEspecificasService from 'services/HabilidadesCompetenciasEspecificasService';
import ProfessorAuxiliarService from 'services/ProfessorAuxiliarService';

import Turno from 'assets/csv/turnos.json';
import TipoAvaliacao from 'assets/csv/tipos-avaliacao.json';
import SituacaoHabilidadeMatricula from 'assets/csv/situacoes-habilidades-matricula';
import Papel from 'assets/csv/papeis.json';
import SituacaoMatricula from 'assets/csv/situacoes-matricula.json';

class HabilidadesAdquiridasAlunoMultiseriado extends React.Component {
  constructor(props) {
    super(props);

    this.turnos = [
      Turno.MATUTINO,
      Turno.VESPERTINO,
      Turno.NOTURNO,
      Turno.INTEGRAL,
    ];

    this.situacoes = [
      SituacaoHabilidadeMatricula.CONSTRUIDA,
      SituacaoHabilidadeMatricula.EM_CONSTRUCAO,
      SituacaoHabilidadeMatricula.NAO_CONSTRUIDA,
      SituacaoHabilidadeMatricula.NAO_INFORMADA,
    ];

    this.situacoesMatricula = [
      SituacaoMatricula.MATRICULADO,
      SituacaoMatricula.TRANSFERIDO,
      SituacaoMatricula.DESISTENTE,
      SituacaoMatricula.APROVADO,
      SituacaoMatricula.APROVADO_COM_DEPENDENCIA,
      SituacaoMatricula.REPROVADO_POR_NOTA,
      SituacaoMatricula.REPROVADO_POR_FREQUENCIA,
      SituacaoMatricula.REPROVADO_POR_FREQUENCIA_E_NOTA,
      SituacaoMatricula.NAO_MATRICULADO,
      SituacaoMatricula.FALECIDO,
    ];

    this.salvarHabilidadesAdquiridas = this.salvarHabilidadesAdquiridas.bind(this);

    this.state = {
      showAlert: false,
      showLoaderModal: false,
      buttonDisabled: false,

      error: null,
    };

    this.loginService = new LoginService();
    this.entesService = new EntesService();
    this.anosLetivosService = new AnosLetivosService();
    this.escolasService = new EscolasService();
    this.turmasService = new TurmasService();
    this.diariosService = new DiariosService();
    this.materiasService = new MateriasService();
    this.habilidadesCompetenciasEspecificasService = new
      HabilidadesCompetenciasEspecificasService();
    this.professorAuxiliarService = new ProfessorAuxiliarService();
  }

  async componentDidMount() {
    await this.loginService.verificarPapel(this.props.role);
    try {
      const dadosEnte = await this.entesService.carregarDadosEnte();

      const professoreAuxiliar = await this.professorAuxiliarService
        .carregarMeuProfessorAuxiliar();

      const anoLetivo = await this.anosLetivosService
        .carregarAnoLetivo(this.props.match.params.idAnoLetivo);

      const escola = await this.escolasService
        .carregarEscola(this.props.match.params.idEscola);

      const turma = await this.turmasService
        .carregarTurma(this.props.match.params.idTurma);

      const diario = await this.diariosService
        .carregarDiario(this.props.match.params.idDiario);

      const materias = await this.diariosService
        .carregarMateriasDoProfessor(this.props.match.params.idDiario);

      const matricula = await this.turmasService
        .carregarMatriculaPorId(this.props.match.params.idMatricula);

      const matriculas = await this.diariosService
        .carregarMatriculas(this.props.match.params.idDiario);

      const selectMatriculas = [];
      matriculas.forEach((matricula) => {
        selectMatriculas.push({
          label: `${matricula.usuario.nome} ${matricula.situacao !== SituacaoMatricula.MATRICULADO.value ? `(${this.situacoesMatricula[matricula.situacao].label})` : ''}`,
          value: matricula.id,
        });
      });

      const matriculaSelecionada = {
        label: `${matricula.usuario.nome} ${matricula.situacao !== SituacaoMatricula.MATRICULADO.value ? `(${this.situacoesMatricula[matricula.situacao].label})` : ''}`,
        value: matricula.id,
      };

      const selectMaterias = [];
      materias.forEach((materia) => {
        selectMaterias.push({
          label: materia.nome,
          value: materia.id,
        });
      });

      const materiaSelecionada = {
        label: materias[0].nome,
        value: materias[0].id,
      };

      await this.carregarSituacaoHabilidadeNaMatriculaEMateria(
        matriculaSelecionada.value, materiaSelecionada.value,
      );

      this.setState({
        anoLetivo,
        escola,
        turma,
        diario,
        selectMatriculas,
        matriculaSelecionada,
        selectMaterias,
        materiaSelecionada,
        auxiliarBloqueado: this.props.role === Papel.PROFESSOR.value && professoreAuxiliar.auxiliar && dadosEnte.bloquearAlteracaoDiarioProfessorAuxiliar,
      });
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  async carregarSituacaoHabilidadeNaMatriculaEMateria(idMatricula, idMateria) {
    this.setState({ carregandoHabilidades: true });

    const habilidades = await this.habilidadesCompetenciasEspecificasService
      .carregarHabilidadesPorMateria(idMateria);

    const fn = (habilidade) => new Promise((resolve) => resolve(
      this.habilidadesCompetenciasEspecificasService
        .carregarSituacaoHabilidadeNaMatriculaEMateria(habilidade.id,
          idMatricula, idMateria),
    ));

    const actions = habilidades.map(fn);

    const situacoesHabilidades = await Promise.all(actions);

    this.setState({
      situacoesHabilidades,
      carregandoHabilidades: false,
    });
  }

  async salvarHabilidadesAdquiridas() {
    this.setState({
      buttonDisabled: true,
      showAlert: false,
      showLoaderModal: true,
    });

    try {
      await this.habilidadesCompetenciasEspecificasService.salvarHabilidadesAdquiridas(
        this.state.matriculaSelecionada.value,
        this.state.materiaSelecionada.value,
        this.state.situacoesHabilidades,
      );

      await this.carregarSituacaoHabilidadeNaMatriculaEMateria(
        this.state.matriculaSelecionada.value, this.state.materiaSelecionada.value,
      );

      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Habilidades do aluno salvas com sucesso',
      });
    } catch (msg) {
      if (this.umounted) return;
      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao salvar habilidades do aluno',
      });
    }
  }

  voltar() {
    if (this.state.turma.tipoAvaliacao === TipoAvaliacao.NOTA.value) {
      return `${this.props.layout}`
        + `/anosletivos/${this.props.match.params.idAnoLetivo}`
        + `/escola/${this.state.escola.id}`
        + `/turma/${this.state.turma.id}`
        + `/diario/${this.state.diario.id}`;
    }
    return `${this.props.layout}`
      + `/anosletivos/${this.props.match.params.idAnoLetivo}`
      + `/escola/${this.state.escola.id}`
      + `/turma/${this.state.turma.id}`
      + `/diario/${this.state.diario.id}/alfabetizacao`;
  }

  tabelaHabilidades() {
    return this.state.carregandoHabilidades ?
      <div align="center" style={{ padding: 30 }}>
        <Loader
          type="Oval"
          color="#34B5B8"
          height="50"
          width="50" />
      </div> : 
        <PerfectScrollbar className="sisa-perfect-scrollbar">
        <Table>
        <thead className="text-primary">
          <tr>
            <th style={{ width: '80%' }}>Descrição</th>
            <th className="text-center" style={{ width: '20%' }}>Situação</th>
          </tr>
        </thead>
        <tbody>
          {
            this.state.situacoesHabilidades.map((situacaoHabilidade) => <tr
              key={uniqid()}>
              <td><div className="preserve-format">{situacaoHabilidade.habilidade.descricao}</div></td>
              <td className="text-center">
                <Button
                  color={this.situacoes[situacaoHabilidade.situacao].color}
                  onClick={() => {
                    if (this.props.role !== Papel.PROFESSOR.value) return;
                    Object.assign(situacaoHabilidade, {
                      ...situacaoHabilidade,
                      situacao: (situacaoHabilidade.situacao + 1)
                        % this.situacoes.length,
                    });
                    this.forceUpdate();
                  }}>
                  {this.situacoes[situacaoHabilidade.situacao].label}
                </Button>
              </td>
            </tr>)
          }
        </tbody>
      </Table>
      </PerfectScrollbar>
  }

  conteudoPagina() {
    return this.state.escola ? <>
      <Row>
        <Col md="12">
          <Alert
            color={this.state.alertColor}
            isOpen={this.state.showAlert}
            toggle={() => { this.setState({ showAlert: false }); }}>
            {this.state.alertMsg}
          </Alert>
          <Breadcrumb>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos`}>Anos letivos</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}`}>Ano letivo {this.state.anoLetivo.ano}</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}`}>{this.state.escola.nome}</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/${this.state.turma.id}`}>{this.state.turma.nome} - {this.turnos[this.state.turma.turno].label}</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={this.voltar()}>
              {this.state.diario.nome}</Link></BreadcrumbItem>
            <BreadcrumbItem active>Habilidades de {this.state.materiaSelecionada.label}</BreadcrumbItem>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <CardTitle tag="h4">
                Habilidades de {this.state.materiaSelecionada.label}
                &nbsp;de {this.state.matriculaSelecionada.label} no {this.state.turma.curso.nome}
              </CardTitle>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md="3">
                  <FormGroup className={'has-label has-danger'}>
                    <label>Aluno</label>
                    <Select
                      noOptionsMessage={() => 'Nenhuma entrada'}
                      className={'react-select primary'}
                      classNamePrefix="react-select"
                      value={this.state.matriculaSelecionada}
                      onChange={async (event) => {
                        this.setState({ matriculaSelecionada: event })
                        await this.carregarSituacaoHabilidadeNaMatriculaEMateria(
                          event.value, this.state.materiaSelecionada.value,
                        );
                      }}
                      options={this.state.selectMatriculas}
                    />
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup className={'has-label has-danger'}>
                    <label>Componente curricular</label>
                    <Select
                      noOptionsMessage={() => 'Nenhuma entrada'}
                      className={'react-select primary'}
                      classNamePrefix="react-select"
                      value={this.state.materiaSelecionada}
                      onChange={async (event) => {
                        this.setState({ materiaSelecionada: event })
                        await this.carregarSituacaoHabilidadeNaMatriculaEMateria(
                          this.state.matriculaSelecionada.value, event.value,
                        );
                      }}
                      options={this.state.selectMaterias}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  {
                    this.state.situacoesHabilidades.length === 0 ? <div className="text-center">
                      Nenhuma habilidade cadastrada
                    </div> : this.tabelaHabilidades()
                  }
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <Row>
                <Col className="text-left">
                  <Button
                    color="primary"
                    onClick={() => this.props.history
                      .push(this.voltar())} >
                    Voltar
                  </Button>
                </Col>
                <Col className="text-right">
                  {
                    this.props.role === Papel.PROFESSOR.value && !this.state.auxiliarBloqueado && <Button
                      color="primary"
                      disabled={this.state.buttonDisabled}
                      onClick={this.salvarHabilidadesAdquiridas} >
                      Salvar habilidades do aluno
                    </Button>
                  }
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </> : <Card>
      <div align="center" style={{ margin: 50 }}>
        <Loader
          type="Oval"
          color="#34B5B8"
          height="50"
          width="50" />
      </div>
    </Card>;
  }

  render() {
    return (
      <div className='content'>
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
            </div>
          </Card>
        }
        <LoaderModal
          isOpen={this.state.buttonDisabled}
          text="Salvando habilidades trabalhadas..." />
      </div>
    );
  }
}

export default HabilidadesAdquiridasAlunoMultiseriado;
