import React from 'react';
import Loader from 'react-loader-spinner';
import { Link } from 'react-router-dom';
// reactstrap components
import {
  Breadcrumb,
  BreadcrumbItem, Button,
  Card,
  CardBody,
  CardFooter, CardHeader,
  CardTitle,
  Col, FormGroup,
  Input,
  Row,
} from 'reactstrap';

import Select from 'react-select';

import Alert from 'views/components/Alert';
import ConfirmModal from 'views/components/ConfirmModal';

import LoaderModal from 'views/components/LoaderModal';
import ChamadosService from 'services/ChamadosService';
import LoginService from 'services/LoginService';

import TipoChamado from 'assets/csv/tipos-chamado.json';
import SituacaoChamado from 'assets/csv/situacoes-chamado.json';
import Papel from 'assets/csv/papeis.json';

class Chamado extends React.Component {
  constructor(props) {
    super(props);

    this.fileInputRef = React.createRef();

    this.tipos = [
      TipoChamado.DUVIDA,
      TipoChamado.SUGESTAO,
      TipoChamado.ERRO,
    ];

    this.situacoes = [
      SituacaoChamado.ABERTO,
      SituacaoChamado.EM_ANDAMENTO,
      SituacaoChamado.FINALIZADO,
    ];

    this.chamadosService = new ChamadosService();
    this.loginService = new LoginService();

    this.state = {
      showAlert: false,

      chamado: null,
      erro: null,

      anexos: [],
    };

    this.fileChange = this.fileChange.bind(this);
  }

  componentWillUnmount() {
    this.loginService.abortPedingRequests();
    this.chamadosService.abortPedingRequests();

    this.umounted = true;
  }

  async componentDidMount() {
    await this.loginService.verificarPapel(this.props.role);

    try {
      const chamado = await this.chamadosService
        .carregarChamado(this.props.match.params.idChamado);

      await this.carregarMensagens();

      this.setState({
        chamado,
        situacaoSelecionada: this.situacoes[chamado.situacao],
      });
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  async carregarMensagens() {
    const mensagens = await this.chamadosService
      .carregarMensagens(this.props.match.params.idChamado);

    const fn = (mensagem) => new Promise((resolve) => resolve(
      this.chamadosService
        .carregarAnexosMensagem(mensagem.id),
    ));

    const actions = mensagens.map(fn);

    const anexos = await Promise.all(actions);

    mensagens.forEach((mensagem, idx) => Object.assign(
      mensagem, { anexos: anexos[idx] },
    ));

    this.setState({
      showLoaderModal: false,
      mensagens,
    });
  }

  fileChange(e) {
    this.setState({ showAlert: false });
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];

    const re = /(?:\.([^.]+))?$/;
    const ext = re.exec(file.name)[1];

    if (!ext) {
      this.setState({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao carregar arquivo selecionado. Não é possível enviar arquivos sem extensão.',
      });
      return;
    }

    if (file) {
      reader.onloadend = async () => {
        if (file.size > 5000000) {
          this.setState({
            showAlert: true,
            alertColor: 'danger',
            alertMsg: 'Erro: Arquivo muito grande. O tamanho máximo permitido é 5MB',
          });
        } else {
          const regexp = /^data:((\w+)\/(.+));base64,(.+)$/g;
          const matches = regexp.exec(reader.result);

          if (matches.length !== 5) {
            this.setState({
              showAlert: true,
              alertColor: 'danger',
              alertMsg: 'Erro ao carregar arquivo selecionado.',
            });
          } else {
            const anexo = {
              nome: file.name,
              conteudo: matches[4],
              extensao: ext,
              tipo: matches[1],
            };

            this.setState({ anexos: this.state.anexos.concat([anexo]) });
          }
        }
      };
      reader.readAsDataURL(file);
    }
  }

  validarCampos() {
    let ret = true;
    if (!this.state.resposta) {
      this.setState({ respostaState: 'has-danger' });
      ret = false;
    }
    return ret;
  }

  async enviarResposta() {
    if (!this.validarCampos()) return;

    this.setState({
      showLoaderModal: true,
      showAlert: false,
      loaderModalText: 'Enviando resposta chamado...',
    });

    const resposta = {
      texto: this.state.resposta,
      arquivos: this.state.anexos,
    };

    try {
      await this.chamadosService.enviarResposta(
        this.props.match.params.idChamado, resposta,
      );
      this.limparFormulario();
      this.setState({
        showLoaderModal: true,
        loaderModalText: 'Carregando mensagens...',
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Resposta enviada com sucesso',
      });

      this.carregarMensagens();
    } catch (e) {
      if (this.umounted) return;
      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: e || 'Erro ao enviar resposta',
      });
    }
  }

  limparFormulario() {
    this.setState({
      resposta: '',
      anexos: [],
    });
  }

  confirmarRemocaoMensagem(mensagem) {
    this.setState({ mensagemSelecionada: mensagem, showConfirmModal: true });
  }

  async removerMensagem() {
    this.setState({ showAlert: false, showLoaderModal: true });

    try {
      await this.chamadosService
        .removerMensagem(this.state.mensagemSelecionada.id);

      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Mensagem removida com sucesso',
      });

      this.carregarMensagens();
    } catch (msg) {
      if (this.umounted) return;
      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao remover afastamento',
      });
    }
  }

  async atualizarSituacao() {
    this.setState({
      showAlert: false,
      showLoaderModal: true,
      loaderModalText: 'Atualizando situação...',
    });

    try {
      await this.chamadosService
        .atualizarSituacao(this.props.match.params.idChamado,
          this.state.situacaoSelecionada.value);

      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Situacao atualizada com sucesso.',
      });

      this.carregarMensagens();
    } catch (msg) {
      if (this.umounted) return;
      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao atualizar situação',
      });
    }
  }

  conteudoPagina() {
    return (this.props.match.params.idChamado !== undefined
      && this.state.chamado === null)
      ? <Card>
        <div align="center" style={{ margin: 50 }}>
          <Loader
            type="Oval"
            color="#34B5B8"
            height="50"
            width="50" />
        </div>
      </Card> : <>
        <Row>
          <Col md="12">
            <Alert
              color={this.state.alertColor}
              isOpen={this.state.showAlert}
              toggle={() => { this.setState({ showAlert: false }); }}>
              {this.state.alertMsg}
            </Alert>
            <Breadcrumb>
              <BreadcrumbItem><Link to={`/admin/entes/${this.props.match.params.idEnte}`}>Chamados</Link></BreadcrumbItem>
              <BreadcrumbItem active>Chamado</BreadcrumbItem>
            </Breadcrumb>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Dados do chamado</CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="6">
                    <FormGroup className={'has-label'}>
                      <label>Título</label>
                      <Input
                        disabled
                        type="text"
                        value={this.state.chamado.titulo} />
                    </FormGroup>
                  </Col>
                  <Col md="2">
                    <FormGroup className={'has-label'}>
                      <label>Tipo</label>
                      <Input
                        disabled
                        type="text"
                        value={this.tipos[this.state.chamado.tipo].label} />
                    </FormGroup>
                  </Col>
                  <Col md="2">
                    <FormGroup className={'has-label'}>
                      <label>Protocolo</label>
                      <Input
                        disabled
                        type="text"
                        value={this.state.chamado.protocolo} />
                    </FormGroup>
                  </Col>
                  <Col md="2">
                    <FormGroup className={'has-label has-danger'}>
                      <label>Situação *</label>
                      <Select
                        isDisabled={this.props.role !== Papel.ADMINISTRADOR.value}
                        noOptionsMessage={() => 'Nenhuma entrada'}
                        className={`react-select primary ${this.state.situacaoState}`}
                        classNamePrefix="react-select"
                        value={this.state.situacaoSelecionada}
                        onChange={(event) => {
                          this.setState({ situacaoState: 'primary', situacaoSelecionada: event });
                        }}
                        options={this.situacoes}
                        placeholder="Situação..."
                      />
                      {this.state.situacaoState === 'danger' ? (
                        <label className="error">
                          Informe a situação.
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <Row>
                  <Col className="text-left">
                    <Button color="primary" onClick={() => this.props.history.push(`/admin/entes/${this.props.match.params.idEnte}`)} >
                      Voltar
                    </Button>
                  </Col>
                  {
                    this.props.role === Papel.ADMINISTRADOR.value && <Col className="text-right">
                      <Button color="primary" onClick={() => this.atualizarSituacao()} >
                        Atualizar situação
                      </Button>
                    </Col>
                  }
                </Row>
              </CardFooter>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            {
              this.state.mensagens.map((mensagem, idx) => <Card key={idx}>
                <CardBody>
                  <Row>
                    <Col md="6">
                      <FormGroup className={'has-label'}>
                        <label>Autor</label>
                        <Input
                          disabled
                          value={mensagem.autor}
                          type="text" />
                      </FormGroup>
                    </Col>
                    <Col md="2">
                      <FormGroup className={'has-label'}>
                        <label>Data</label>
                        <Input
                          disabled
                          value={mensagem.data}
                          type="text" />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup className={'has-label'}>
                        <label>Texto</label>
                        <Input
                          disabled
                          value={mensagem.texto}
                          type="textarea"
                          style={{ height: '200px' }} />
                      </FormGroup>
                    </Col>
                  </Row>
                  {
                    mensagem.anexos.length > 0 && <Row>
                      <Col>
                        Anexos: <br />
                        {
                          mensagem.anexos.map((anexo, idx2) => <React.Fragment key={idx2}>
                            <Link to="#" onClick={() => window.open(anexo.url)}>{anexo.nome}</Link><br />
                          </React.Fragment>)
                        }
                      </Col>
                    </Row>
                  }
                </CardBody>
                { /*
                <CardFooter>
                  <Row>
                    <Col className="text-right">
                      <Button
                        color="danger"
                        onClick={() => this.confirmarRemocaoMensagem(mensagem)} >
                        Remover
                      </Button>
                    </Col>
                  </Row>
                </CardFooter>
                */ }
              </Card>)
            }
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardBody>
                <Row>
                  <Col>
                    <FormGroup className={`has-label ${this.state.respostaState}`}>
                      <label>Responder *</label>
                      <Input
                        value={this.state.resposta}
                        onChange={(evt) => {
                          this.setState({
                            resposta: evt.target.value,
                            respostaState: '',
                          });
                        }}
                        type="textarea"
                        style={{ height: '200px' }} />
                      {this.state.respostaState === 'has-danger' ? (
                        <label className="error">
                          Informe o texto da resposta.
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="fileinput text-center">
                      <input
                        type="file"
                        onChange={this.fileChange}
                        ref={this.fileInputRef} />
                    </div>
                    <Button color="primary"
                      onClick={() => this.fileInputRef.current.click()}>
                      Anexar arquivo
                    </Button>
                  </Col>
                </Row>
                {
                  this.state.anexos.map((anexo, idx) => <Row key={idx}>
                    <Col>{anexo.nome}</Col>
                  </Row>)
                }
              </CardBody>
              <CardFooter>
                <Row>
                  <Col className="text-right">
                    <Button
                      type="button"
                      color="primary"
                      onClick={() => this.enviarResposta()} >
                      Enviar resposta
                    </Button>
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </>;
  }

  render() {
    return (
      <div className="content">
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
          </div>
          </Card>
        }
        <ConfirmModal
          isOpen={this.state.showConfirmModal}
          callback={(confirm) => {
            this.setState({ showConfirmModal: false });
            if (confirm) {
              this.removerMensagem();
            } else {
              this.setState({ mensagemSelecionada: null });
            }
          }}
          text='Confirme a exclusão da mensagem' />
        <LoaderModal
          isOpen={this.state.showLoaderModal}
          text={this.state.loaderModalText} />
      </div>
    );
  }
}

export default Chamado;
