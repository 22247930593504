import React from 'react';
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';

import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Table,
  FormGroup,
  Input,
  Label,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap';

import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar';

import Loader from 'react-loader-spinner';

import Alert from 'views/components/Alert';

import AulasService from 'services/AulasService';
import DiretoriosService from 'services/DiretoriosService';

class ImportarAulaModal extends React.Component {
  constructor(props) {
    super(props);

    this.idPagina = `${this.props.dadosUsuario.idVinculo}-importar-aulas`;

    this.stackDir = JSON.parse(sessionStorage.getItem(`${this.idPagina}-stackDir`)) || { data: [{ id: null, nome: 'Minhas aulas' }] };
    sessionStorage.setItem(`${this.idPagina}-stackDir`, JSON.stringify(this.stackDir));

    this.checked = [];

    this.state = {
      erro: false,
      count: 0,

      showAlert: false,
      isOpen: props.isOpen,
      allChecked: false,
      buttonDisabled: false,

      diretorios: [],
      aulas: [],
    };

    this.aulasService = new AulasService();
    this.diretoriosService = new DiretoriosService();
  }

  async componentWillReceiveProps(nextProps) {
    if (this.state.isOpen !== nextProps.isOpen) {
      this.setState({
        isOpen: nextProps.isOpen,
        showAlert: false,
        buttonDisabled: false,
      });
    }
  }

  componentWillUnmount() {
    this.aulasService.abortPedingRequests();
    this.diretoriosService.abortPedingRequests();
    this.umounted = true;
  }

  async componentDidMount() {
    try {
      await this.carregarDiretorios();
      await this.carregarAulas();
    } catch (e) {
      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: e || 'Erro carregar aulas',
      });
    }
  }

  async carregarDiretorios() {
    const diretorios = await this.diretoriosService
      .carregarDiretorios(this.stackDir.data[this.stackDir.data.length - 1].id);

    this.setState({
      diretorios,
    });
  }

  async carregarAulas() {
    const aulas = await this.aulasService
      .carregarAulasDoDiretorio(this.stackDir.data[this.stackDir.data.length - 1].id);

    this.setState({
      aulas,
    });
  }

  importarAulas = async () => {
    if (this.state.count === 0) return;

    this.setState({
      buttonDisabled: true,
      showAlert: false,
      loaderModalText: 'Importando aulas...',
    });

    try {
      await this.aulasService.importarAulas(this.checked,
        this.props.idTurma, this.props.idMateria);

      this.checked = [];

      this.setState({
        count: 0,
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Aulas importadas com sucesso.',
        allChecked: false,
      });

      this.props.importCallback();
    } catch (e) {
      if (this.umounted) return;
      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: e || 'Erro ao importar aulas.',
      });
    }
  }

  async abrirDiretorio(diretorio, voltando) {
    if (!voltando) {
      this.stackDir.data.push(diretorio);
      sessionStorage.setItem(`${this.idPagina}-stackDir`, JSON.stringify(this.stackDir));
    }

    try {
      this.setState({ buttonDisabled: true });

      await this.carregarDiretorios();

      await this.carregarAulas();

      this.setState({
        buttonDisabled: false,
      });
    } catch (e) {
      if (this.umounted) return;
      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: e || 'Erro ao entrar no diretório',
      });
    }
  }

  async navegar(idx) {
    this.stackDir.data.length = idx + 1;
    sessionStorage.setItem(`${this.idPagina}-stackDir`, JSON.stringify(this.stackDir));
    await this.carregarDiretorios();
    await this.carregarAulas();
  }

  render() {
    return (
      <Modal size="xl" isOpen={this.state.isOpen}>
        <div className="modal-header justify-content-center">
          <h5 className="modal-title">Importar aulas</h5>
        </div>
        <ModalBody>
          <Alert
            color={this.state.alertColor}
            isOpen={this.state.showAlert}
            toggle={() => { this.setState({ showAlert: false }); }}>
            {this.state.alertMsg}
          </Alert>
          {
            this.state.aulas === null ? <div align="center" style={{ margin: 50 }}>
              <Loader
                type="Oval"
                color="#34B5B8"
                height="50"
                width="50" />
            </div>
              : <>
                <Breadcrumb>
                  {
                    this.stackDir.data.map((diretorio, idx) => {
                      const ativo = this.stackDir.data[
                        this.stackDir.data.length - 1].id === diretorio.id;
                      return ativo ? <BreadcrumbItem key={idx} active>
                        {diretorio.nome}
                      </BreadcrumbItem> : <BreadcrumbItem key={idx}>
                          <Link onClick={() => this.navegar(idx)} to="#">{diretorio.nome}</Link>
                        </BreadcrumbItem>;
                    })
                  }
                </Breadcrumb>
                <PerfectScrollbar className="sisa-perfect-scrollbar">
                <Table className="aulas">
                  <thead className="text-primary">
                    <tr>
                      <th style={{ width: '5%' }}></th>
                      <th style={{ width: '70%' }}>Aula</th>
                      <th>Código</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      this.stackDir.data[this.stackDir.data.length - 1].id && <tr>
                        <td className="td-dir"><i className="fas fa-folder"></i></td>
                        <td
                          className="td-name"
                          onClick={() => {
                            this.stackDir.data.pop();
                            sessionStorage.setItem(`${this.idPagina}-stackDir`, JSON.stringify(this.stackDir));
                            this.abrirDiretorio(this.stackDir
                              .data[this.stackDir.data.length - 1], true);
                          }}>..</td>
                        <td></td>
                      </tr>
                    }
                    {
                      this.state.diretorios.map((diretorio, idx) => <tr key={idx}>
                        <td className="td-dir"><i className="fas fa-folder"></i></td>
                        <td
                          className="td-name"
                          onClick={() => this.abrirDiretorio(diretorio)}>{diretorio.nome}</td>
                        <td></td>
                      </tr>)
                    }
                    {
                      this.state.aulas.map((aula, idx) => <tr key={idx}>
                        <td>
                          <FormGroup check>
                            <Label check>
                              <Input
                                type="checkbox"
                                checked={this.checked.some((a) => a.id === aula.id)}
                                onChange={(evt) => {
                                  if (evt.target.checked) {
                                    this.checked.push(this.state.aulas[idx]);
                                  } else {
                                    this.checked = this.checked
                                      .filter((a) => a.id !== this.state.aulas[idx].id);
                                  }
                                  const count = evt.target.checked ? this.state.count + 1
                                    : this.state.count - 1;
                                  this.setState({ count });
                                }} />{' '}
                              <span className="form-check-sign">
                                <span className="check"></span>
                              </span>
                            </Label>
                          </FormGroup>
                        </td>
                        <td>{aula.nome}</td>
                        <td>{aula.codigo}</td>
                      </tr>)
                    }
                  </tbody>
                </Table>
                </PerfectScrollbar>
              </>
          }
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => this.props.closeCallback()}>
            Fechar
          </Button>
          <Button
            disabled={this.state.buttonDisabled
              || this.state.count === 0}
            style={{ width: 120 }}
            color="primary"
            onClick={() => this.importarAulas()}>
            {
              this.state.buttonDisabled ? <Loader
                type="Oval"
                color="#000000"
                height="15"
                width="15" /> : 'Importar'
            }
          </Button>
          &nbsp;&nbsp;
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({ dadosUsuario: state.dadosUsuario });

export default connect(mapStateToProps)(ImportarAulaModal);
