import React from 'react';
import { Link } from 'react-router-dom';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Input,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap';

import Loader from 'react-loader-spinner';

import TipoAfastamento from 'assets/csv/tipos-afastamento.json';

import AfastamentosService from 'services/AfastamentosService';
import LoginService from 'services/LoginService';

class Afastamento extends React.Component {
  constructor(props) {
    super(props);

    this.tipos = [
      TipoAfastamento.LICENCA,
      TipoAfastamento.FERIAS,
    ];

    this.afastamentosService = new AfastamentosService();
    this.loginService = new LoginService();

    this.state = {
      afastamento: null,
      erro: null,
    };
  }

  componentWillUnmount() {
    this.loginService.abortPedingRequests();
    this.afastamentosService.abortPedingRequests();

    this.umounted = true;
  }

  async componentDidMount() {
    await this.loginService.verificarPapel(this.props.role);

    try {
      const afastamento = await this.afastamentosService
        .carregarAfastamento(this.props.match.params.idAfastamento);

      this.setState({
        afastamento,
      });
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  conteudoPagina() {
    return (this.props.match.params.idAfastamento !== undefined
      && this.state.afastamento === null)
      ? <Card>
        <div align="center" style={{ margin: 50 }}>
          <Loader
            type="Oval"
            color="#34B5B8"
            height="50"
            width="50" />
        </div>
      </Card> : <Row>
        <Col md="12">
          <Breadcrumb>
            <BreadcrumbItem><Link to="/gestor/afastamentos">Afastamentos</Link></BreadcrumbItem>
            <BreadcrumbItem active>Afastamento</BreadcrumbItem>
          </Breadcrumb>
          <Card>
            <CardHeader>
              <CardTitle tag="h4">Dados do afastamento</CardTitle>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md="2">
                  <FormGroup className={'has-label'}>
                    <label>Data de início</label>
                    <Input
                      disabled
                      type="text"
                      value={this.state.afastamento.dataInicio} />
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup className={'has-label'}>
                    <label>Data de fim</label>
                    <Input
                      disabled
                      type="text"
                      value={this.state.afastamento.dataFim} />
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup className={'has-label'}>
                    <label>Tipo</label>
                    <Input
                      disabled
                      type="text"
                      value={this.tipos[this.state.afastamento.tipo].label} />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup className={'has-label'}>
                    <label>Servidor</label>
                    <Input
                      disabled
                      type="text"
                      value={`${this.state.afastamento.servidor.nome} (Mãe: ${this.state.afastamento.servidor.nomeMae
                        ? this.state.afastamento.servidor.nomeMae : 'Não informado'})`} />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup className={'has-label'}>
                    <label>Observações</label>
                    <Input
                      disabled
                      value={this.state.afastamento.observacoes}
                      type="textarea"
                      style={{ height: '200px' }} />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <Row>
                <Col>
                  <Button color="primary" onClick={() => this.props.history.push('/gestor/afastamentos')} >
                    Voltar
                  </Button>
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Col>
      </Row>;
  }

  render() {
    return (
      <div className="content">
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
          </div>
          </Card>
        }
      </div>
    );
  }
}

export default Afastamento;
