/* eslint-disable max-len */
import React from 'react';
import { Link } from 'react-router-dom';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  Collapse,
  UncontrolledTooltip,
  Label,
} from 'reactstrap';

import Select from 'react-select';
import Loader from 'react-loader-spinner';
import NumberFormat from 'react-number-format';

import Alert from 'views/components/Alert';
import LoaderModal from 'views/components/LoaderModal';

import SituacaoTurma from 'assets/csv/situacoes-turma.json';
import Turno from 'assets/csv/turnos.json';
import CicloEnsino from 'assets/csv/ciclos-ensino.json';
import TipoAvaliacao from 'assets/csv/tipos-avaliacao.json';
import Papel from 'assets/csv/papeis.json';
import Serie from 'assets/csv/series2.json';

import AnosLetivosService from 'services/AnosLetivosService';
import EntesService from 'services/EntesService';
import CursosService from 'services/CursosService';
import TurmasService from 'services/TurmasService';
import EscolasService from 'services/EscolasService';
import LoginService from 'services/LoginService';
import EstruturasCurricularesService from 'services/EstruturasCurricularesService';

class Turmas extends React.Component {
  constructor(props) {
    super(props);

    this.situacoes = [
      SituacaoTurma.ABERTA,
      SituacaoTurma.CONSOLIDADA,
    ];

    this.turnos = [
      Turno.MATUTINO,
      Turno.VESPERTINO,
      Turno.NOTURNO,
      Turno.INTEGRAL,
    ];

    this.tiposAvaliacao = [
      TipoAvaliacao.NOTA,
      TipoAvaliacao.RELATORIO,
    ];

    this.tiposDiarios = [
      { value: true, label: 'Diário único' },
      { value: false, label: 'Diário por componente curricular' },
    ];

    this.numerosAvaliacoes = [
      { value: 2, label: '2 avaliações' },
      { value: 4, label: '4 avaliações' },
    ];

    this.estruturasCurricularesService = new EstruturasCurricularesService();
    this.entesService = new EntesService();
    this.anosLetivosService = new AnosLetivosService();
    this.cursosService = new CursosService();
    this.turmasService = new TurmasService();
    this.escolasService = new EscolasService();
    this.loginService = new LoginService();

    this.state = {
      showAlert: false,

      nome: '',
      nomeState: '',
      situacaoState: 'primary',
      turnoState: 'primary',
      tipoAvaliacaoState: 'primary',
      cursoState: 'primary',
      estruturaCurricularState: 'primary',
      mediaAprovacaoState: 'primary',
      mediaReprovacaoAntesRecuperacaoState: 'primary',
      pesoRecuperacaoState: 'primary',
      mediaAprovacaoAposRecuperacaoState: 'primary',
      frequenciaMinimaState: '',

      turnoSelecionado: '',
      tipoAvaliacaoSelecionada: '',
      cursoSelecionado: '',
      estruturaCurricularSelecionada: '',
      mediaAprovacao: '6,0',
      mediaReprovacaoAntesRecuperacao: '2,5',
      temRecuperacao: true,
      pesoRecuperacao: '1,0',
      pesoMediaAvaliacoes: '2,0',
      mediaAprovacaoAposRecuperacao: '5,0',
      frequenciaMinima: '75,0',

      cursos: [],
      estruturasCurriculares: [],
      descricoesAvaliacoes: [],
      descricoesConteudos: [],
      descricoesRelatorios: [],

      buttonDisabled: false,

      erro: null,
      anoLetivo: null,
      turmaSelecionada: null,
      frequenciaRestrita: false,

      loaderModalText: '',

      collapsedAvaliacoes: false,
      collapsedConteudos: false,
      collapsedRelatorios: false,
      collapsedFrequencia: false,

      dadosTabela: {
        header: [['Nome', '80%']],
        columnAlign: ['text-left'],
        keys: ['nome'],
        rows: [],
        total: undefined,
      },
    };

    this.cadastrarTurma = this.cadastrarTurma.bind(this);
    this.atualizarTurma = this.atualizarTurma.bind(this);
    this.validarCampos = this.validarCampos.bind(this);
    this.limparFormulario = this.limparFormulario.bind(this);
    this.removerDescricaoAvaliacao = this.removerDescricaoAvaliacao.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  async componentDidMount() {
    await this.loginService.verificarPapel(this.props.role);

    try {
      const dadosEnte = await this.entesService
        .carregarDadosEnte();
      
      const anoLetivo = await this.anosLetivosService
        .carregarAnoLetivo(this.props.match.params.idAnoLetivo);

      const escola = await this.escolasService
        .carregarEscola(this.props.match.params.idEscola);

      const data = await this.cursosService
        .carregarCursos(1, 99999, '');

      const selectCursos = [];
      data.cursos.forEach((curso) => {
        selectCursos.push({
          label: curso.nome,
          value: curso.id,
          cicloEnsino: curso.cicloEnsino,
          serie: curso.serie,
        });
      });

      this.setState({
        dadosEnte,
        cursos: selectCursos,
        escola,
        anoLetivo,
        uf: anoLetivo.ente.estado.uf,
        ente: anoLetivo.ente.nome,
      });

      if(this.state.uf === 'PB') {
        this.setState({
          mediaAprovacao: '7,0',
          mediaReprovacaoAntesRecuperacao: '2,5',
          pesoRecuperacao: '4,0',
          pesoMediaAvaliacoes: '6,0',
          mediaAprovacaoAposRecuperacao: '5,0',
        });
      }

      if (this.props.match.params.idTurma !== undefined) {
        const turma = await this.turmasService
          .carregarTurma(this.props.match.params.idTurma);

        await this.carregarEstruturasCurriculares(turma.curso.id);

        await this.carregarConfiguracoesDaTurma(turma.id);

        this.setState({ turmaSelecionada: turma });

        this.contentLoad();
      }
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  contentLoad() {
    this.setState({
      nome: this.state.turmaSelecionada.nome,
      vagas: this.state.turmaSelecionada.vagas || '',
      cursoSelecionado: {
        label: this.state.turmaSelecionada.curso.nome,
        value: this.state.turmaSelecionada.curso.id,
      },
      estruturaCurricularSelecionada: {
        label: this.state.turmaSelecionada.estruturaCurricular.nome,
        value: this.state.turmaSelecionada.estruturaCurricular.id,
      },
      turnoSelecionado: this.turnos[this.state.turmaSelecionada.turno],
      tipoAvaliacaoSelecionada: this.tiposAvaliacao[this.state.turmaSelecionada.tipoAvaliacao],
      frequenciaRestrita: this.state.turmaSelecionada.frequenciaRestrita,
      mediaAprovacao: this.state.turmaSelecionada.mediaAprovacao
        ? this.converterParaString(this.state.turmaSelecionada.mediaAprovacao) : undefined,
      temRecuperacao: this.state.turmaSelecionada.temRecuperacao,
      mediaReprovacaoAntesRecuperacao: this.state.turmaSelecionada.mediaReprovacaoAntesRecuperacao
        ? this.converterParaString(this.state.turmaSelecionada.mediaReprovacaoAntesRecuperacao)
        : undefined,
      pesoRecuperacao: this.state.turmaSelecionada.pesoRecuperacao
        ? this.converterParaString(this.state.turmaSelecionada.pesoRecuperacao) : undefined,
      pesoMediaAvaliacoes: this.state.turmaSelecionada.pesoMediaAvaliacoes
        ? this.converterParaString(this.state.turmaSelecionada.pesoMediaAvaliacoes) : undefined,
      mediaAprovacaoAposRecuperacao: this.state.turmaSelecionada.mediaAprovacaoAposRecuperacao
        ? this.converterParaString(this.state.turmaSelecionada.mediaAprovacaoAposRecuperacao)
        : undefined,
      frequenciaMinima: this.state.turmaSelecionada.frequenciaMinima !== undefined
        ? this.converterParaString(this.state.turmaSelecionada.frequenciaMinima)
        : undefined,
    });
  }

  async carregarEstruturasCurriculares(idCurso) {
    this.setState({
      buttonDisabled: true,
      showAlert: false,
      loaderModalText: 'Carregando estruturas curriculares do curso...',
    });

    try {
      const data = await this.estruturasCurricularesService
        .carregarEstruturasCurriculares(idCurso, 1, 99999, '');

      const selectEstruturasCurriculares = [];
      data.estruturasCurriculares.forEach((estruturaCurricular) => {
        selectEstruturasCurriculares.push({
          label: estruturaCurricular.nome,
          value: estruturaCurricular.id,
          materias: estruturaCurricular.materias,
        });
      });

      this.setState({
        buttonDisabled: false,
        estruturaCurricularSelecionada: '',
        estruturasCurriculares: selectEstruturasCurriculares,
      });
    } catch (e) {
      if (this.umounted) return;
      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao buscar estruturas curriculares do curso',
      });
    }
  }

  async carregarConfiguracoesDaTurma(idTurma) {
    this.setState({
      buttonDisabled: true,
      showAlert: false,
      loaderModalText: 'Carregando configurações da turma...',
    });

    try {
      const descricoesAvaliacoes = await this.turmasService
        .carregarDescricoesAvaliacoes(idTurma);

      const descricoesConteudos = await this.turmasService
        .carregarDescricoesConteudos(idTurma);

      const descricoesRelatorios = await this.turmasService
        .carregarDescricoesRelatorios(idTurma);

      this.setState({
        buttonDisabled: false,
        descricoesAvaliacoes: descricoesAvaliacoes.map((desc) => {
          const a = {
            ...desc,
            peso: desc.peso ? this.converterParaString(desc.peso) : undefined,
          };
          return a;
        }),
        descricoesConteudos,
        descricoesRelatorios,
      });
    } catch (e) {
      if (this.umounted) return;
      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao buscar configurações da turma',
      });
    }
  }

  validarCampos() {
    let ret = true;
    if (!this.state.nome) {
      this.setState({ nomeState: 'has-danger' });
      ret = false;
    }
    if (!this.state.turnoSelecionado) {
      this.setState({ turnoState: 'danger' });
      ret = false;
    }
    if (!this.state.tipoAvaliacaoSelecionada && this.state.cursoSelecionado) {
      this.setState({ tipoAvaliacaoState: 'danger' });
      ret = false;
    }
    if (!this.state.cursoSelecionado) {
      this.setState({ cursoState: 'danger' });
      ret = false;
    }
    if (!this.state.estruturaCurricularSelecionada || !this.state.estruturasCurriculares.length) {
      this.setState({ estruturaCurricularState: 'danger' });
      ret = false;
    }
    this.state.descricoesAvaliacoes.forEach((desc) => {
      if (!desc.nome) {
        Object.assign(desc, { nomeState: 'has-danger' });
        ret = false;
      }
      if (this.state.tipoAvaliacaoSelecionada.value === TipoAvaliacao.NOTA.value && !desc.peso) {
        Object.assign(desc, { pesoState: 'has-danger' });
        ret = false;
      }
    });

    this.state.descricoesConteudos.forEach((desc) => {
      if (!desc.nome) {
        Object.assign(desc, { nomeState: 'has-danger' });
        ret = false;
      }
    });

    this.state.descricoesRelatorios.forEach((desc) => {
      if (!desc.nome) {
        Object.assign(desc, { nomeState: 'has-danger' });
        ret = false;
      }
    });

    if (this.state.tipoAvaliacaoSelecionada.value === TipoAvaliacao.NOTA.value) {
      if (!this.state.mediaAprovacao) {
        this.setState({ mediaAprovacaoState: 'has-danger' });
        ret = false;
      }
      if (this.state.temRecuperacao) {
        if (!this.state.mediaReprovacaoAntesRecuperacao) {
          this.setState({ mediaReprovacaoAntesRecuperacaoState: 'has-danger' });
          ret = false;
        }
        if (!this.state.pesoRecuperacao) {
          this.setState({ pesoRecuperacaoState: 'has-danger' });
          ret = false;
        }
        if (!this.state.pesoMediaAvaliacoes) {
          this.setState({ pesoMediaAvaliacoesState: 'has-danger' });
          ret = false;
        }
        if (!this.state.mediaAprovacaoAposRecuperacao) {
          this.setState({ mediaAprovacaoAposRecuperacaoState: 'has-danger' });
          ret = false;
        }
      }

      if (!this.state.frequenciaMinima) {
        this.setState({ frequenciaMinimaState: 'has-danger' });
        ret = false;
      }
    }

    if ((this.state.cursoSelecionado.cicloEnsino === CicloEnsino.EJA.value
      || this.state.cursoSelecionado.cicloEnsino === CicloEnsino.CORRECAO_FLUXO.value)
      && this.state.tipoAvaliacaoSelecionada.value === TipoAvaliacao.NOTA.value) {
      if (!this.state.tipoDiarioSelecionado) {
        this.setState({ tipoDiarioState: 'danger' });
        ret = false;
      }
      if (!this.state.numeroAvaliacoesSelecionado) {
        this.setState({ numeroAvaliacoesState: 'danger' });
        ret = false;
      }
    }

    if (!ret) this.forceUpdate();

    return ret;
  }

  async cadastrarTurma() {
    if (!this.validarCampos()) return;

    this.setState({
      buttonDisabled: true,
      showAlert: false,
      loaderModalText: 'Cadastrando turma...',
    });

    const descricoesAvaliacoes = this.state.descricoesAvaliacoes
      .map((desc) => {
        const a = {
          ...desc,
          peso: desc.peso ? this.converterParaNumero(desc.peso) : undefined,
        };
        return a;
      });

    if (this.state.temRecuperacao) {
      descricoesAvaliacoes.push({
        nome: 'Prova Final',
        peso: this.converterParaNumero(this.state.pesoRecuperacao),
        recuperacao: true,
      });
    }

    const turma = {
      nome: this.state.nome,
      vagas: this.state.vagas || undefined,
      turno: this.state.turnoSelecionado.value,
      tipoAvaliacao: this.state.tipoAvaliacaoSelecionada.value,
      frequenciaRestrita: this.state.frequenciaRestrita,
      curso: { id: this.state.cursoSelecionado.value },
      estruturaCurricular: { id: this.state.estruturaCurricularSelecionada.value },
      escola: { id: this.state.escola.id },
      anoLetivo: { id: this.state.anoLetivo.id },
      descricoesAvaliacoes,
      descricoesConteudos: this.state.descricoesConteudos,
      descricoesRelatorios: this.state.descricoesRelatorios,
      mediaAprovacao: this.state.tipoAvaliacaoSelecionada.value
        === TipoAvaliacao.NOTA.value
        ? this.converterParaNumero(this.state.mediaAprovacao) : undefined,
      temRecuperacao: this.state.tipoAvaliacaoSelecionada.value
        === TipoAvaliacao.NOTA.value ? this.state.temRecuperacao : undefined,
      mediaReprovacaoAntesRecuperacao: this.state.tipoAvaliacaoSelecionada.value
        === TipoAvaliacao.NOTA.value && this.state.temRecuperacao
        ? this.converterParaNumero(this.state.mediaReprovacaoAntesRecuperacao) : undefined,
      pesoRecuperacao: this.state.tipoAvaliacaoSelecionada.value
        === TipoAvaliacao.NOTA.value && this.state.temRecuperacao
        ? this.converterParaNumero(this.state.pesoRecuperacao) : undefined,
      pesoMediaAvaliacoes: this.state.tipoAvaliacaoSelecionada.value
        === TipoAvaliacao.NOTA.value && this.state.temRecuperacao
        ? this.converterParaNumero(this.state.pesoMediaAvaliacoes) : undefined,
      mediaAprovacaoAposRecuperacao: this.state.tipoAvaliacaoSelecionada.value
        === TipoAvaliacao.NOTA.value && this.state.temRecuperacao
        ? this.converterParaNumero(this.state.mediaAprovacaoAposRecuperacao) : undefined,
      frequenciaMinima: this.converterParaNumero(this.state.frequenciaMinima),
      diarioUnico: this.state.tipoDiarioSelecionado
        ? this.state.tipoDiarioSelecionado.value : false,
    };

    try {
      await this.turmasService.cadastrarTurma(turma);
      this.limparFormulario();
      this.setState({
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Turma cadastrado com sucesso',
      });
    } catch (e) {
      if (this.umounted) return;
      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: e || 'Erro ao cadastrar turma',
      });
    }
  }

  async atualizarTurma() {
    if (!this.validarCampos()) return;

    this.setState({
      buttonDisabled: true,
      showAlert: false,
      loaderModalText: 'Atualizando turma...',
    });

    const turma = {
      id: this.state.turmaSelecionada.id,
      nome: this.state.nome,
      vagas: this.state.vagas || undefined,
      turno: this.state.turnoSelecionado.value,
      tipoAvaliacao: this.state.tipoAvaliacaoSelecionada.value,
      frequenciaRestrita: this.state.frequenciaRestrita,
      curso: { id: this.state.cursoSelecionado.value },
      estruturaCurricular: { id: this.state.estruturaCurricularSelecionada.value },
      escola: { id: this.state.escola.id },
      situacao: this.state.turmaSelecionada.situacao,
      anoLetivo: { id: this.state.anoLetivo.id },
      frequenciaMinima: this.converterParaNumero(this.state.frequenciaMinima),
    };

    try {
      await this.turmasService.atualizarTurma(turma);

      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Turma atualizada com sucesso',
      });
    } catch (e) {
      if (this.umounted) return;
      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: e || 'Erro ao atualizar turma',
      });
    }
  }

  limparFormulario() {
    this.setState({
      nome: '',
      vagas: '',

      nomeState: '',
      situacaoState: '',
      turnoState: '',
      tipoAvaliacaoState: '',
      cursoState: '',
      estruturaCurricularState: '',

      frequenciaRestrita: false,
      turnoSelecionado: '',
      tipoAvaliacaoSelecionada: '',
      cursoSelecionado: '',
      estruturaCurricularSelecionada: '',

      estruturasCurriculares: [],

      buttonDisabled: false,
    });
  }

  onSubmit(e) {
    e.preventDefault();

    if (this.state.buttonDisabled) return;

    if (!this.state.turmaSelecionada) {
      this.cadastrarTurma();
    } else {
      this.atualizarTurma();
    }
  }

  removerDescricaoAvaliacao(descricaoAvaliacao) {
    if (this.state.descricoesAvaliacoes.length === 1) return;
    const descricoesAvaliacoes = this.state.descricoesAvaliacoes.filter(
      (descricaoAvaliacao2) => descricaoAvaliacao !== descricaoAvaliacao2,
    );
    this.setState({ descricoesAvaliacoes });
  }

  removerDescricaoConteudo(descricaoConteudo) {
    if (this.state.descricoesConteudos.length === 1) return;
    const descricoesConteudos = this.state.descricoesConteudos.filter(
      (descricaoConteudo2) => descricaoConteudo !== descricaoConteudo2,
    );
    this.setState({ descricoesConteudos });
  }

  removerDescricaoRelatorio(descricaoRelatorio) {
    if (this.state.descricoesRelatorios.length === 1) return;
    const descricoesRelatorios = this.state.descricoesRelatorios.filter(
      (descricaoRelatorio2) => descricaoRelatorio !== descricaoRelatorio2,
    );
    this.setState({ descricoesRelatorios });
  }

  formatarNumero = (val) => {
    if (val.length === 1) {
      return val;
    }
    if (val.length === 2) {
      return `${val[0]},${val[1]}`;
    }
    if (val.length === 3) {
      if (val === '100') {
        return '10,0';
      }
      return `${val[0]},${val[1]}`;
    }
    return '10,0';
  }

  formatarNumero2 = (val) => {
    if (val.length === 1) {
      return `${val}`;
    }
    if (val.length === 2) {
      return `${val[0]},${val[1]}`;
    }
    if (val.length === 3) {
      return `${val[0]}${val[1]},${val[2]}`;
    }
    if (val.length === 4) {
      if (val === '1000') {
        return '100,0';
      }
      return `${val[0]}${val[1]},${val[2]}`;
    }
    return '100,0';
  }

  converterParaNumero = (valor) => parseFloat(valor.replace(',', '.'));

  converterParaString = (valor) => valor.toFixed(1).toString().replace('.', ',')

  conteudoPagina() {
    return this.state.anoLetivo === null || (this.props.match.params.idTurma !== undefined
      && this.state.turmaSelecionada === null)
      ? <Card>
        <div align="center" style={{ margin: 50 }}>
          <Loader
            type="Oval"
            color="#34B5B8"
            height="50"
            width="50" />
        </div>
      </Card> : <Row>
        <Col md="12">
          <Alert
            color={this.state.alertColor}
            isOpen={this.state.showAlert}
            toggle={() => { this.setState({ showAlert: false }); }}>
            {this.state.alertMsg}
          </Alert>
          <Breadcrumb>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos`}>Anos Letivos</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.state.anoLetivo.id}`}>Ano letivo {this.state.anoLetivo.ano}</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.state.anoLetivo.id}/escola/${this.state.escola.id}`}>{this.state.escola.nome}</Link></BreadcrumbItem>
            <BreadcrumbItem active>{this.state.turmaSelecionada ? 'Edição turma' : 'Cadastro turma'}</BreadcrumbItem>
          </Breadcrumb>
          <Form onSubmit={this.onSubmit}>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Dados da turma</CardTitle>
              </CardHeader>
              <CardBody>
                {this.state.turmaSelecionada
                  && <Row>
                    <Col md="2">
                      <FormGroup className="has-label">
                        <label>Situação</label>
                        <Input
                          disabled
                          type="text"
                          value={this.situacoes[this.state.turmaSelecionada.situacao].label}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                }
                <Row>
                  <Col md="4">
                    <FormGroup className="has-label">
                      <label>Escola</label>
                      <Input
                        disabled
                        type="text"
                        value={this.state.escola.nome}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup className={'has-label has-danger'}>
                      <label>Curso *</label>
                      <Select
                        noOptionsMessage={() => 'Nenhuma entrada'}
                        className={`react-select primary ${this.state.cursoState}`}
                        classNamePrefix="react-select"
                        value={this.state.cursoSelecionado}
                        onChange={(event) => {
                          const descricoesAvaliacoes = [];
                          const descricoesConteudos = [];
                          const descricoesRelatorios = [];

                          let tipoAvaliacaoSelecionada = TipoAvaliacao.NOTA;
                          if (this.state.uf === 'RN') {
                            if (event.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value || (event.cicloEnsino === CicloEnsino.ENSINO_FUNDAMENTAL_1.value && event.serie <= Serie.ENSINO_FUNDAMENTAL_3.value)) {
                              tipoAvaliacaoSelecionada = TipoAvaliacao.RELATORIO;
                            }
                          } else { //this.state.uf === 'PB'
                            if (this.state.ente === 'Patos') {
                              if (event.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value) {
                                tipoAvaliacaoSelecionada = TipoAvaliacao.RELATORIO;
                              } 
                            } else if (this.state.ente === 'Belém do Brejo do Cruz') {
                              if (event.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value || (event.cicloEnsino === CicloEnsino.ENSINO_FUNDAMENTAL_1.value && event.serie <= Serie.ENSINO_FUNDAMENTAL_3.value)) {
                                tipoAvaliacaoSelecionada = TipoAvaliacao.RELATORIO;
                              } 
                            } else {
                              if (event.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value || (event.cicloEnsino === CicloEnsino.ENSINO_FUNDAMENTAL_1.value && event.serie <= Serie.ENSINO_FUNDAMENTAL_2.value)) {
                                tipoAvaliacaoSelecionada = TipoAvaliacao.RELATORIO;
                              }   
                            }
                          }

                          if (tipoAvaliacaoSelecionada.value === TipoAvaliacao.RELATORIO.value) {
                            if(this.state.uf !== 'PB') {
                              descricoesAvaliacoes.push({ nome: '1º semestre' });
                              descricoesAvaliacoes.push({ nome: '2º semestre' });
                            } else {
                              descricoesAvaliacoes.push({ nome: '1º bimestre' });
                              descricoesAvaliacoes.push({ nome: '2º bimestre' });
                              descricoesAvaliacoes.push({ nome: '3º bimestre' });
                              descricoesAvaliacoes.push({ nome: '4º bimestre' });
                            }

                            descricoesConteudos.push({ nome: '1º bimestre' });
                            descricoesConteudos.push({ nome: '2º bimestre' });
                            descricoesConteudos.push({ nome: '3º bimestre' });
                            descricoesConteudos.push({ nome: '4º bimestre' });

                            if(this.state.uf !== 'PB') {
                              descricoesRelatorios.push({ nome: 'Proposta de trabalho anual' });
                            } else {
                              descricoesRelatorios.push({ nome: 'Plano de curso anual' });
                            }
                            descricoesRelatorios.push({ nome: 'Diagnóstico inicial da turma' });
                            descricoesRelatorios.push({ nome: 'Relatório final da turma' });
                            descricoesRelatorios.push({ nome: 'Observações' });
                          } else if (event.cicloEnsino !== CicloEnsino.EJA.value
                              || event.cicloEnsino !== CicloEnsino.CORRECAO_FLUXO.value) {
                            descricoesAvaliacoes.push({ nome: '1º bimestre', peso: '1,0', recuperacao: false });
                            descricoesAvaliacoes.push({ nome: '2º bimestre', peso: '1,0', recuperacao: false });
                            descricoesAvaliacoes.push({ nome: '3º bimestre', peso: '1,0', recuperacao: false });
                            descricoesAvaliacoes.push({ nome: '4º bimestre', peso: '1,0', recuperacao: false });

                            descricoesConteudos.push({ nome: '1º bimestre' });
                            descricoesConteudos.push({ nome: '2º bimestre' });
                            descricoesConteudos.push({ nome: '3º bimestre' });
                            descricoesConteudos.push({ nome: '4º bimestre' });

                            /**/
                            if(this.state.uf !== 'PB') {
                              descricoesRelatorios.push({ nome: 'Proposta de trabalho anual' });
                            } else {
                              descricoesRelatorios.push({ nome: 'Plano de curso anual' });
                            }
                            descricoesRelatorios.push({ nome: 'Diagnóstico inicial da turma' });
                            descricoesRelatorios.push({ nome: 'Relatório final da turma' });
                            descricoesRelatorios.push({ nome: 'Observações' });
                            /**/
                          } else { // EJA
                            descricoesConteudos.push({ nome: '1º bimestre' });
                            descricoesConteudos.push({ nome: '2º bimestre' });
                            descricoesConteudos.push({ nome: '3º bimestre' });
                            descricoesConteudos.push({ nome: '4º bimestre' });

                            /**/
                            if(this.state.uf !== 'PB') {
                              descricoesRelatorios.push({ nome: 'Proposta de trabalho anual' });
                            } else {
                              descricoesRelatorios.push({ nome: 'Plano de curso anual' });
                            }
                            descricoesRelatorios.push({ nome: 'Diagnóstico inicial da turma' });
                            descricoesRelatorios.push({ nome: 'Relatório final da turma' });
                            descricoesRelatorios.push({ nome: 'Observações' });
                            /**/
                            this.setState({
                              numeroAvaliacoesSelecionado: '',
                              tipoDiarioSelecionado: '',
                            });
                          }

                          this.carregarEstruturasCurriculares(event.value);

                          this.setState({
                            cursoState: 'primary',
                            cursoSelecionado: event,
                            tipoAvaliacaoSelecionada,
                            descricoesAvaliacoes,
                            descricoesConteudos,
                            descricoesRelatorios,
                            collapsedAvaliacoes: false,
                            collapsedConteudos: false,
                            collapsedRelatorios: false,
                            collapsedFrequencia: false,
                            temRecuperacao: tipoAvaliacaoSelecionada.value === TipoAvaliacao.NOTA.value,
                            frequenciaMinima: event.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value ? '60,0' : '75,0',
                          });
                        }}
                        isDisabled={this.props.match.params.idTurma !== undefined}
                        options={this.state.cursos}
                        placeholder="Curso..."
                      />
                      {this.state.cursoState === 'danger' ? (
                        <label className="error">
                          Informe o curso da turma.
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup className={'has-label has-danger'}>
                      <label>Estrutura curricular *</label>
                      <Select
                        noOptionsMessage={() => 'Nenhuma entrada'}
                        className={`react-select primary ${this.state.estruturaCurricularState}`}
                        classNamePrefix="react-select"
                        isDisabled={this.state.estruturasCurriculares.length === 0
                          || this.props.match.params.idTurma !== undefined}
                        value={this.state.estruturaCurricularSelecionada}
                        onChange={(event) => {
                          this.setState({
                            estruturaCurricularState: '',
                            estruturaCurricularSelecionada: event,
                          });
                        }}
                        options={this.state.estruturasCurriculares}
                        placeholder="Estrutura curricular..."
                      />
                      {this.state.estruturaCurricularState === 'danger' ? (
                        <label className="error">
                          Informe a estrutura curricular da turma.
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                  {
                    this.props.role === Papel.GESTOR.value && <Col md="1">
                      <FormGroup className={'has-label'}>
                        <>
                          <Button
                            disabled={!this.state.cursoSelecionado}
                            style={{ marginTop: 25 }}
                            id="adicionarECBtn"
                            className="btn-icon"
                            color="success"
                            onClick={() => this.props.history.push('/gestor/cursos/'
                              + `${this.state.cursoSelecionado.value}/estruturascurriculares/cadastro`)}>
                            <i className="fa fa-plus-circle" />
                          </Button>
                          <UncontrolledTooltip placement="bottom" target="adicionarECBtn" delay={0}>
                            Adicionar estrutura curricular no curso
                          </UncontrolledTooltip>
                        </>
                      </FormGroup>
                    </Col>
                  }
                </Row>
                <Row>
                  <Col md="4">
                    <FormGroup className={`has-label ${this.state.nomeState}`}>
                      <label>Nome *</label>
                      <Input
                        value={this.state.nome}
                        type="text"
                        maxLength="200"
                        onChange={(event) => this.setState({ nome: event.target.value, nomeState: 'has-success' })}
                      />
                      {this.state.nomeState === 'has-danger' ? (
                        <label className="error">
                          Informe o nome da turma.
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md="2">
                    <FormGroup className={'has-label has-danger'}>
                      <label>Turno *</label>
                      <Select
                        noOptionsMessage={() => 'Nenhuma entrada'}
                        className={`react-select primary ${this.state.turnoState}`}
                        classNamePrefix="react-select"
                        value={this.state.turnoSelecionado}
                        onChange={(event) => {
                          this.setState({ turnoState: '', turnoSelecionado: event });
                        }}
                        options={this.turnos}
                        placeholder="Turno..."
                      />
                      {this.state.turnoState === 'danger' ? (
                        <label className="error">
                          Informe o turno da turma.
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md="2">
                    <FormGroup className={`has-label ${this.state.vagasState}`}>
                      <label>Número de vagas</label>
                      <Input
                        value={this.state.vagas}
                        decimalSeparator={false}
                        allowNegative={false}
                        onValueChange={(val) => {
                          this.setState({ vagas: val.formattedValue, vagasState: '' });
                        }}
                        tag={NumberFormat} />
                      {this.state.vagasState === 'has-danger' ? (
                        <label className="error">
                          Informe o turno da turma.
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                  {
                    (this.state.cursoSelecionado.cicloEnsino === CicloEnsino.EJA.value
                      || this.state.cursoSelecionado.cicloEnsino === CicloEnsino.CORRECAO_FLUXO.value) 
                      && this.state.tipoAvaliacaoSelecionada.value === TipoAvaliacao.NOTA.value && <Col md="2">
                      <FormGroup className={'has-label has-danger'}>
                        <label>Tipo do diário *</label>
                        <Select
                          noOptionsMessage={() => 'Nenhuma entrada'}
                          className={`react-select primary ${this.state.tipoDiarioState}`}
                          classNamePrefix="react-select"
                          value={this.state.tipoDiarioSelecionado}
                          onChange={(event) => {
                            this.setState({
                              tipoDiarioSelecionado: event,
                              tipoDiarioState: '',
                            });
                          }}
                          options={this.tiposDiarios}
                          placeholder="Tipo do diário..."
                        />
                        {this.state.tipoDiarioState === 'danger' ? (
                          <label className="error">
                            Informe o tipo do diário
                          </label>
                        ) : null}
                      </FormGroup>
                    </Col>
                  }
                  {
                    (this.state.cursoSelecionado.cicloEnsino === CicloEnsino.EJA.value
                      || this.state.cursoSelecionado.cicloEnsino === CicloEnsino.CORRECAO_FLUXO.value) 
                      && this.state.tipoAvaliacaoSelecionada.value === TipoAvaliacao.NOTA.value && <Col md="2">
                      <FormGroup className={'has-label has-danger'}>
                        <label>Número de avaliações *</label>
                        <Select
                          noOptionsMessage={() => 'Nenhuma entrada'}
                          className={`react-select primary ${this.state.numeroAvaliacoesState}`}
                          classNamePrefix="react-select"
                          value={this.state.numeroAvaliacoesSelecionado}
                          onChange={(event) => {
                            const descricoesAvaliacoes = [];
                            const descricoesConteudos = [];
                            if (event.value === 2) {
                              descricoesAvaliacoes.push({ nome: '1º bimestre', peso: '1,0', recuperacao: false });
                              descricoesAvaliacoes.push({ nome: '2º bimestre', peso: '1,0', recuperacao: false });

                              descricoesConteudos.push({ nome: '1º bimestre' });
                              descricoesConteudos.push({ nome: '2º bimestre' });
                            } else {
                              descricoesAvaliacoes.push({ nome: '1º bimestre', peso: '1,0', recuperacao: false });
                              descricoesAvaliacoes.push({ nome: '2º bimestre', peso: '1,0', recuperacao: false });
                              descricoesAvaliacoes.push({ nome: '3º bimestre', peso: '1,0', recuperacao: false });
                              descricoesAvaliacoes.push({ nome: '4º bimestre', peso: '1,0', recuperacao: false });

                              descricoesConteudos.push({ nome: '1º bimestre' });
                              descricoesConteudos.push({ nome: '2º bimestre' });
                              descricoesConteudos.push({ nome: '3º bimestre' });
                              descricoesConteudos.push({ nome: '4º bimestre' });
                            }
                            this.setState({
                              descricoesAvaliacoes,
                              descricoesConteudos,
                              numeroAvaliacoesSelecionado: event,
                              numeroAvaliacoesState: '',
                            });
                          }}
                          options={this.numerosAvaliacoes}
                          placeholder="Número de avaliações..."
                        />
                        {this.state.numeroAvaliacoesState === 'danger' ? (
                          <label className="error">
                            Informe o número de avaliações.
                          </label>
                        ) : null}
                      </FormGroup>
                    </Col>
                  }
                </Row>
                <div className="bloco-tipo-avaliacao">
                  <Row>
                    <Col md="2">
                      <FormGroup className={'has-label has-danger'}>
                        <label>Tipo da avaliação *</label>
                        <Select
                          noOptionsMessage={() => 'Nenhuma entrada'}
                          className={`react-select primary ${this.state.tipoAvaliacaoState}`}
                          classNamePrefix="react-select"
                          value={this.state.tipoAvaliacaoSelecionada}
                          onChange={(event) => {
                            const descricoesAvaliacoes = [];
                            const descricoesConteudos = [];
                            const descricoesRelatorios = [];

                            if (event.value === TipoAvaliacao.RELATORIO.value) {
                              if(this.state.uf !== 'PB') {
                                descricoesAvaliacoes.push({ nome: '1º semestre' });
                                descricoesAvaliacoes.push({ nome: '2º semestre' });
                              } else {
                                descricoesAvaliacoes.push({ nome: '1º bimestre' });
                                descricoesAvaliacoes.push({ nome: '2º bimestre' });
                                descricoesAvaliacoes.push({ nome: '3º bimestre' });
                                descricoesAvaliacoes.push({ nome: '4º bimestre' });
                              }

                              descricoesConteudos.push({ nome: '1º bimestre' });
                              descricoesConteudos.push({ nome: '2º bimestre' });
                              descricoesConteudos.push({ nome: '3º bimestre' });
                              descricoesConteudos.push({ nome: '4º bimestre' });

                              if(this.state.uf !== 'PB') {
                                descricoesRelatorios.push({ nome: 'Proposta de trabalho anual' });
                              } else {
                                descricoesRelatorios.push({ nome: 'Plano de curso anual' });
                              }
                              descricoesRelatorios.push({ nome: 'Diagnóstico inicial da turma' });
                              descricoesRelatorios.push({ nome: 'Relatório final da turma' });
                              descricoesRelatorios.push({ nome: 'Observações' });

                              if (this.state.cursoSelecionado.serie === Serie.EJA_ENSINO_FUNDAMENTAL.value
                                || this.state.cursoSelecionado.serie === Serie.CORRECAO_FLUXO_ENSINO_FUNDAMENTAL.value) {
                                  this.setState({
                                    diarioUnico: true,
                                  });
                              }

                            } else if (event.cicloEnsino !== CicloEnsino.EJA.value
                              || event.cicloEnsino !== CicloEnsino.CORRECAO_FLUXO.value) {
                              descricoesAvaliacoes.push({ nome: '1º bimestre', peso: '1,0', recuperacao: false });
                              descricoesAvaliacoes.push({ nome: '2º bimestre', peso: '1,0', recuperacao: false });
                              descricoesAvaliacoes.push({ nome: '3º bimestre', peso: '1,0', recuperacao: false });
                              descricoesAvaliacoes.push({ nome: '4º bimestre', peso: '1,0', recuperacao: false });

                              descricoesConteudos.push({ nome: '1º bimestre' });
                              descricoesConteudos.push({ nome: '2º bimestre' });
                              descricoesConteudos.push({ nome: '3º bimestre' });
                              descricoesConteudos.push({ nome: '4º bimestre' });
                              /**/
                              if(this.state.uf !== 'PB') {
                                descricoesRelatorios.push({ nome: 'Proposta de trabalho anual' });
                              } else {
                                descricoesRelatorios.push({ nome: 'Plano de curso anual' });
                              }
                              descricoesRelatorios.push({ nome: 'Diagnóstico inicial da turma' });
                              descricoesRelatorios.push({ nome: 'Relatório final da turma' });
                              descricoesRelatorios.push({ nome: 'Observações' });
                              /**/
                            } else { // EJA ou CORREÇÃO DE FLUXO
                              descricoesConteudos.push({ nome: '1º bimestre' });
                              descricoesConteudos.push({ nome: '2º bimestre' });
                              descricoesConteudos.push({ nome: '3º bimestre' });
                              descricoesConteudos.push({ nome: '4º bimestre' });

                              /**/
                              if(this.state.uf !== 'PB') {
                                descricoesRelatorios.push({ nome: 'Proposta de trabalho anual' });
                              } else {
                                descricoesRelatorios.push({ nome: 'Plano de curso anual' });
                              }
                              descricoesRelatorios.push({ nome: 'Diagnóstico inicial da turma' });
                              descricoesRelatorios.push({ nome: 'Relatório final da turma' });
                              descricoesRelatorios.push({ nome: 'Observações' });
                              /**/
                            }

                            this.setState({
                              tipoAvaliacaoState: '',
                              tipoAvaliacaoSelecionada: event,
                              descricoesAvaliacoes,
                              descricoesConteudos,
                              descricoesRelatorios,
                              collapsedAvaliacoes: false,
                              collapsedConteudos: false,
                              collapsedRelatorios: false,
                              collapsedFrequencia: false,
                              temRecuperacao: event.value === TipoAvaliacao.NOTA.value,
                            });
                          }}
                          isDisabled={!this.state.cursoSelecionado
                            || this.props.match.params.idTurma !== undefined}
                          options={this.tiposAvaliacao}
                          placeholder="Tipo da avaliação..."
                        />
                        {this.state.tipoAvaliacaoState === 'danger' ? (
                          <label className="error">
                            Informe o tipo da avaliação
                          </label>
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>
                  {
                    this.state.tipoAvaliacaoSelecionada && <div
                      aria-multiselectable={true}
                      className="card-collapse"
                      id="accordion"
                      role="tablist"
                    >
                      <Card className="card-plain">
                        <CardHeader role="tab">
                          <h5
                            aria-expanded={this.state.collapsedAvaliacoes}
                            data-parent="#accordion"
                            data-toggle="collapse"
                            onClick={(e) => {
                              e.preventDefault();
                              this.setState({
                                collapsedAvaliacoes: !this.state.collapsedAvaliacoes,
                              });
                            }}
                          >
                            Configuração de {this.state.tipoAvaliacaoSelecionada.value
                              === TipoAvaliacao.RELATORIO.value ? 'relatórios dos alunos' : 'provas'}
                            <i className="nc-icon nc-minimal-down" />
                          </h5>
                        </CardHeader>
                        <Collapse
                          role="tabpanel"
                          isOpen={this.state.collapsedAvaliacoes}
                        >
                          <CardBody>
                            {
                              this.state.descricoesAvaliacoes.map(
                                (descricaoAvaliacao, idx) => <Row key={idx}>
                                  <Col md="3">
                                    <FormGroup className={`has-label ${descricaoAvaliacao.nomeState}`}>
                                      <label>Nome * </label>
                                      <Input
                                        type="text"
                                        value={descricaoAvaliacao.nome}
                                        disabled={this.props.match.params.idTurma !== undefined}
                                        onChange={(event) => {
                                          Object.assign(descricaoAvaliacao,
                                            {
                                              nome: event.target.value,
                                              nomeState: 'primary',
                                            });
                                          this.forceUpdate();
                                        }}
                                      />
                                      {descricaoAvaliacao.nomeState === 'has-danger' ? (
                                        <label className="error">
                                          Informe o nome da avaliação.
                                        </label>
                                      ) : null}
                                    </FormGroup>
                                  </Col>
                                  {
                                    this.state.tipoAvaliacaoSelecionada.value
                                    === TipoAvaliacao.NOTA.value && <Col md="1">
                                      <FormGroup className={`has-label ${descricaoAvaliacao.pesoState}`}>
                                        <label>Peso *</label>
                                        <Input
                                          value={descricaoAvaliacao.peso}
                                          disabled={this.props.match.params.idTurma !== undefined}
                                          style={{ textAlign: 'right' }}
                                          decimalSeparator=","
                                          decimalScale={1}
                                          format={this.formatarNumero}
                                          onValueChange={(val) => {
                                            Object.assign(descricaoAvaliacao,
                                              {
                                                peso: val.formattedValue,
                                                pesoState: 'primary',
                                              });
                                            this.forceUpdate();
                                          }}
                                          onBlur={() => {
                                            if (descricaoAvaliacao.peso
                                              && descricaoAvaliacao.peso.length === 1) {
                                              Object.assign(descricaoAvaliacao,
                                                {
                                                  peso: `${descricaoAvaliacao.peso},0`,
                                                  pesoState: 'primary',
                                                });
                                              this.forceUpdate();
                                            }
                                          }}
                                          tag={NumberFormat}
                                        />
                                        {descricaoAvaliacao.pesoState === 'has-danger' ? (
                                          <label className="error">
                                            Informe o peso da avaliação.
                                          </label>
                                        ) : null}
                                      </FormGroup>
                                    </Col>
                                  }
                                  {this.props.match.params.idTurma === undefined
                                    && <Col md="1">
                                      <br />
                                      <>
                                        <Button
                                          id={`btnRemoverAvaliacao${idx}`}
                                          className="btn-icon"
                                          color="danger"
                                          size="sm"
                                          onClick={() => {
                                            this.removerDescricaoAvaliacao(descricaoAvaliacao);
                                          }}
                                          type="button">
                                          <i className="fa fa-times" />
                                        </Button>
                                        <UncontrolledTooltip placement="bottom" target={`btnRemoverAvaliacao${idx}`} delay={0}>
                                          Remover
                                        </UncontrolledTooltip>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        {idx === this.state.descricoesAvaliacoes.length - 1
                                          && <>
                                            <Button
                                              id="adicionarAvaliacaoBtn"
                                              className="btn-icon"
                                              color="success"
                                              size="sm"
                                              onClick={() => {
                                                this.state.descricoesAvaliacoes.push({
                                                  nome: '',
                                                  peso: this.state.tipoAvaliacaoSelecionada.value
                                                    === TipoAvaliacao.RELATORIO.value ? undefined : '',
                                                  recuperacao: this.state
                                                    .tipoAvaliacaoSelecionada.value
                                                    === TipoAvaliacao.RELATORIO.value
                                                    ? undefined : false,
                                                });
                                                this.forceUpdate();
                                              }}>
                                              <i className="fa fa-plus-circle" />
                                            </Button>
                                            <UncontrolledTooltip placement="bottom" target="adicionarAvaliacaoBtn" delay={0}>
                                              Adicionar {this.state.tipoAvaliacaoSelecionada.value
                                                === TipoAvaliacao.RELATORIO.value ? 'relatório' : 'prova'}
                                            </UncontrolledTooltip>
                                          </>
                                        }
                                      </>
                                    </Col>
                                  }
                                </Row>,
                              )
                            }
                            <Row>
                            </Row>
                            {this.state.tipoAvaliacaoSelecionada.value === TipoAvaliacao.NOTA.value
                              && <Row>
                                <Col md="3">
                                  <FormGroup className={`has-label ${this.state.mediaAprovacaoState}`}>
                                    <label>Média de aprovação * </label>
                                    <Input
                                      value={this.state.mediaAprovacao}
                                      disabled={this.props.match.params.idTurma !== undefined}
                                      style={{ textAlign: 'right' }}
                                      decimalSeparator=","
                                      decimalScale={1}
                                      format={this.formatarNumero}
                                      onValueChange={(val) => {
                                        this.setState({
                                          mediaAprovacao: val.formattedValue,
                                          mediaAprovacaoState: 'primary',
                                        });
                                      }}
                                      onBlur={() => {
                                        if (this.state.mediaAprovacao
                                          && this.state.mediaAprovacao.length === 1) {
                                          this.setState({
                                            mediaAprovacao: `${this.state.mediaAprovacao},0`,
                                          });
                                        }
                                      }}
                                      tag={NumberFormat}
                                    />
                                    {this.state.mediaAprovacaoState === 'has-danger' ? (
                                      <label className="error">
                                        Informe a média de aprovação.
                                      </label>
                                    ) : null}
                                  </FormGroup>
                                </Col>
                              </Row>
                            }
                            {this.state.tipoAvaliacaoSelecionada.value === TipoAvaliacao.NOTA.value
                              && <>
                                < Row >
                                  <Col md="4">
                                    <FormGroup check>
                                      <Label check>
                                        <Input type="checkbox"
                                          disabled={this.props.match.params.idTurma !== undefined}
                                          defaultChecked={this.state.temRecuperacao}
                                          onClick={(evt) => this.setState({ temRecuperacao: evt.target.checked })} />{' '}
                                        Tem prova final
                                        <span className="form-check-sign">
                                          <span className="check"></span>
                                        </span>
                                      </Label>
                                    </FormGroup>
                                  </Col>
                                </Row>
                                {this.state.temRecuperacao
                                  && <>
                                    <Row>
                                      <Col md="3">
                                        <FormGroup className={`has-label ${this.state.mediaReprovacaoAntesRecuperacaoState}`}>
                                          <label>Média de reprovação antes da
                                            prova final * </label>
                                          <Input
                                            value={this.state.mediaReprovacaoAntesRecuperacao}
                                            disabled={this.props.match.params.idTurma !== undefined}
                                            style={{ textAlign: 'right' }}
                                            decimalSeparator=","
                                            decimalScale={1}
                                            format={this.formatarNumero}
                                            onValueChange={(val) => {
                                              this.setState({
                                                mediaReprovacaoAntesRecuperacao: val.formattedValue,
                                                mediaReprovacaoAntesRecuperacaoState: 'primary',
                                              });
                                            }}
                                            onBlur={() => {
                                              if (this.state.mediaReprovacaoAntesRecuperacao
                                                && this.state.mediaReprovacaoAntesRecuperacao.length
                                                === 1) {
                                                this.setState({
                                                  mediaReprovacaoAntesRecuperacao: `${this.state.mediaReprovacaoAntesRecuperacao},0`,
                                                });
                                              }
                                            }}
                                            tag={NumberFormat}
                                          />
                                          {this.state.mediaReprovacaoAntesRecuperacaoState === 'has-danger' ? (
                                            <label className="error">
                                              Informe a média de reprovação antes
                                              da prova final.
                                            </label>
                                          ) : null}
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col md="3">
                                        <FormGroup className={`has-label ${this.state.pesoRecuperacaoState}`}>
                                          <label>Peso da prova final *</label>
                                          <Input
                                            value={this.state.pesoRecuperacao}
                                            disabled={this.props.match.params.idTurma !== undefined}
                                            style={{ textAlign: 'right' }}
                                            decimalSeparator=","
                                            decimalScale={1}
                                            format={this.formatarNumero}
                                            onValueChange={(val) => {
                                              this.setState({
                                                pesoRecuperacao: val.formattedValue,
                                                pesoRecuperacaoState: 'primary',
                                              });
                                            }}
                                            onBlur={() => {
                                              if (this.state.pesoRecuperacao
                                                && this.state.pesoRecuperacao.length
                                                === 1) {
                                                this.setState({
                                                  pesoRecuperacao: `${this.state.pesoRecuperacao},0`,
                                                });
                                              }
                                            }}
                                            tag={NumberFormat}
                                          />
                                          {this.state.pesoRecuperacaoState === 'has-danger' ? (
                                            <label className="error">
                                              Informe o peso da prova final.
                                            </label>
                                          ) : null}
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col md="3">
                                        <FormGroup className={`has-label ${this.state.pesoMediaAvaliacoesState}`}>
                                          <label>Peso da média das avaliações
                                            anteriores à prova final *</label>
                                          <Input
                                            value={this.state.pesoMediaAvaliacoes}
                                            disabled={this.props.match.params.idTurma !== undefined}
                                            style={{ textAlign: 'right' }}
                                            decimalSeparator=","
                                            decimalScale={1}
                                            format={this.formatarNumero}
                                            onValueChange={(val) => {
                                              this.setState({
                                                pesoMediaAvaliacoes: val.formattedValue,
                                                pesoMediaAvaliacoesState: 'primary',
                                              });
                                            }}
                                            onBlur={() => {
                                              if (this.state.pesoMediaAvaliacoes
                                                && this.state.pesoMediaAvaliacoes.length
                                                === 1) {
                                                this.setState({
                                                  pesoMediaAvaliacoes: `${this.state.pesoMediaAvaliacoes},0`,
                                                });
                                              }
                                            }}
                                            tag={NumberFormat}
                                          />
                                          {this.state.pesoMediaAvaliacoesState === 'has-danger' ? (
                                            <label className="error">
                                              Informe o peso da média das avaliações
                                              anteriores à prova final.
                                            </label>
                                          ) : null}
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col md="3">
                                        <FormGroup className={`has-label ${this.state.mediaAprovacaoAposRecuperacaoState}`}>
                                          <label>Média de aprovação após prova final * </label>
                                          <Input
                                            value={this.state.mediaAprovacaoAposRecuperacao}
                                            disabled={this.props.match.params.idTurma !== undefined}
                                            style={{ textAlign: 'right' }}
                                            decimalSeparator=","
                                            decimalScale={1}
                                            format={this.formatarNumero}
                                            onValueChange={(val) => {
                                              this.setState({
                                                mediaAprovacaoAposRecuperacao: val.formattedValue,
                                                mediaAprovacaoAposRecuperacaoState: 'primary',
                                              });
                                            }}
                                            onBlur={() => {
                                              if (this.state.mediaAprovacaoAposRecuperacao
                                                && this.state.mediaAprovacaoAposRecuperacao.length
                                                === 1) {
                                                this.setState({
                                                  mediaAprovacaoAposRecuperacao: `${this.state.mediaAprovacaoAposRecuperacao},0`,
                                                });
                                              }
                                            }}
                                            tag={NumberFormat}
                                          />
                                          {this.state.mediaAprovacaoAposRecuperacaoState === 'has-danger' ? (
                                            <label className="error">
                                              Informe a média de aprovação após a prova final.
                                            </label>
                                          ) : null}
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                  </>
                                }
                              </>
                            }
                          </CardBody>
                        </Collapse>
                      </Card>
                    </div>
                  }
                  {
                    !this.state.dadosEnte.registroAulas && this.state.tipoAvaliacaoSelecionada && <div
                      aria-multiselectable={true}
                      className="card-collapse"
                      id="accordion"
                      role="tablist"
                    >
                      <Card className="card-plain">
                        <CardHeader role="tab">
                          <h5
                            aria-expanded={this.state.collapsedConteudos}
                            data-parent="#accordion"
                            data-toggle="collapse"
                            onClick={(e) => {
                              e.preventDefault();
                              this.setState({ collapsedConteudos: !this.state.collapsedConteudos });
                            }}
                          >
                            Configuração de conteúdos lecionados
                            <i className="nc-icon nc-minimal-down" />
                          </h5>
                        </CardHeader>
                        <Collapse
                          role="tabpanel"
                          isOpen={this.state.collapsedConteudos}
                        >
                          <CardBody>
                            {
                              this.state.descricoesConteudos.map(
                                (descricaoConteudo, idx) => <Row key={idx}>
                                  <Col md="3">
                                    <FormGroup className={`has-label ${descricaoConteudo.nomeState}`}>
                                      <label>Período * </label>
                                      <Input
                                        type="text"
                                        value={descricaoConteudo.nome}
                                        disabled={this.props.match.params.idTurma !== undefined}
                                        onChange={(event) => {
                                          Object.assign(descricaoConteudo,
                                            {
                                              nome: event.target.value,
                                              nomeState: 'primary',
                                            });
                                          this.forceUpdate();
                                        }}
                                      />
                                      {descricaoConteudo.nomeState === 'has-danger' ? (
                                        <label className="error">
                                          Informe o período do conteúdo lecionado.
                                        </label>
                                      ) : null}
                                    </FormGroup>
                                  </Col>
                                  {this.props.match.params.idTurma === undefined
                                    && <Col md="1">
                                      <br />
                                      <>
                                        <Button
                                          id={`btnRemoverConteudo${idx}`}
                                          className="btn-icon"
                                          color="danger"
                                          size="sm"
                                          onClick={() => {
                                            this.removerDescricaoConteudo(descricaoConteudo);
                                          }}
                                          type="button">
                                          <i className="fa fa-times" />
                                        </Button>
                                        <UncontrolledTooltip placement="bottom" target={`btnRemoverConteudo${idx}`} delay={0}>
                                          Remover
                                        </UncontrolledTooltip>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        {
                                          idx === this.state.descricoesConteudos.length - 1
                                          && <>
                                            <Button
                                              id="adicionarConteudoBtn"
                                              className="btn-icon"
                                              color="success"
                                              size="sm"
                                              onClick={() => {
                                                this.state.descricoesConteudos.push({
                                                  nome: '',
                                                });
                                                this.forceUpdate();
                                              }}>
                                              <i className="fa fa-plus-circle" />
                                            </Button>
                                            <UncontrolledTooltip placement="bottom" target="adicionarConteudoBtn" delay={0}>
                                              Adicionar conteúdo lecionado
                                            </UncontrolledTooltip>
                                          </>
                                        }
                                      </>
                                    </Col>
                                  }
                                </Row>,
                              )
                            }
                            <Row>
                            </Row>
                          </CardBody>
                        </Collapse>
                      </Card>
                    </div>
                  }
                  {
                    this.state.tipoAvaliacaoSelecionada && <div
                      aria-multiselectable={true}
                      className="card-collapse"
                      id="accordion"
                      role="tablist"
                    >
                      <Card className="card-plain">
                        <CardHeader role="tab">
                          <h5
                            aria-expanded={this.state.collapsedRelatorios}
                            data-parent="#accordion"
                            data-toggle="collapse"
                            onClick={(e) => {
                              e.preventDefault();
                              this.setState({
                                collapsedRelatorios: !this.state.collapsedRelatorios,
                              });
                            }}
                          >
                            Configuração de relatórios da turma
                            <i className="nc-icon nc-minimal-down" />
                          </h5>
                        </CardHeader>
                        <Collapse
                          role="tabpanel"
                          isOpen={this.state.collapsedRelatorios}
                        >
                          <CardBody>
                            {
                              this.state.descricoesRelatorios.map(
                                (descricaoRelatorio, idx) => <Row key={idx}>
                                  <Col md="3">
                                    <FormGroup className={`has-label ${descricaoRelatorio.nomeState}`}>
                                      <label>Nome * </label>
                                      <Input
                                        type="text"
                                        value={descricaoRelatorio.nome}
                                        disabled={this.props.match.params.idTurma !== undefined}
                                        onChange={(event) => {
                                          Object.assign(descricaoRelatorio,
                                            {
                                              nome: event.target.value,
                                              nomeState: 'primary',
                                            });
                                          this.forceUpdate();
                                        }}
                                      />
                                      {descricaoRelatorio.nomeState === 'has-danger' ? (
                                        <label className="error">
                                          Informe o nome do relatório.
                                        </label>
                                      ) : null}
                                    </FormGroup>
                                  </Col>
                                  {this.props.match.params.idTurma === undefined
                                    && <Col md="1">
                                      <br />
                                      <>
                                        <Button
                                          id={`btnRemoverRelatorio${idx}`}
                                          className="btn-icon"
                                          color="danger"
                                          size="sm"
                                          onClick={() => {
                                            this.removerDescricaoRelatorio(descricaoRelatorio);
                                          }}
                                          type="button">
                                          <i className="fa fa-times" />
                                        </Button>
                                        <UncontrolledTooltip placement="bottom" target={`btnRemoverRelatorio${idx}`} delay={0}>
                                          Remover
                                        </UncontrolledTooltip>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        {
                                          idx === this.state.descricoesRelatorios.length - 1
                                          && <>
                                            <Button
                                              id="adicionarRelatorioBtn"
                                              className="btn-icon"
                                              color="success"
                                              size="sm"
                                              onClick={() => {
                                                this.state.descricoesRelatorios.push({
                                                  nome: '',
                                                });
                                                this.forceUpdate();
                                              }}>
                                              <i className="fa fa-plus-circle" />
                                            </Button>
                                            <UncontrolledTooltip placement="bottom" target="adicionarRelatorioBtn" delay={0}>
                                              Adicionar relatório
                                            </UncontrolledTooltip>
                                          </>
                                        }
                                      </>
                                    </Col>
                                  }
                                </Row>,
                              )
                            }
                            <Row>
                            </Row>
                          </CardBody>
                        </Collapse>
                      </Card>
                    </div>
                  }
                  {
                    this.state.tipoAvaliacaoSelecionada && <div
                      aria-multiselectable={true}
                      className="card-collapse"
                      id="accordion"
                      role="tablist"
                    >
                      <Card className="card-plain">
                        <CardHeader role="tab">
                          <h5
                            aria-expanded={this.state.collapsedFrequencia}
                            data-parent="#accordion"
                            data-toggle="collapse"
                            onClick={(e) => {
                              e.preventDefault();
                              this.setState({
                                collapsedFrequencia: !this.state.collapsedFrequencia,
                              });
                            }}
                          >
                            Configuração de frequência
                            <i className="nc-icon nc-minimal-down" />
                          </h5>
                        </CardHeader>
                        <Collapse
                          role="tabpanel"
                          isOpen={this.state.collapsedFrequencia}
                        >
                          <CardBody>
                            <Row>
                              <Col md="3">
                                <FormGroup className={`has-label ${this.state.frequenciaMinimaState}`}>
                                  <label>Frequência mínima para aprovação (%) * </label>
                                  <Input
                                    value={this.state.frequenciaMinima}
                                    style={{ textAlign: 'right' }}
                                    decimalSeparator=","
                                    decimalScale={1}
                                    format={this.formatarNumero2}
                                    onValueChange={(val) => {
                                      this.setState({
                                        frequenciaMinima: val.formattedValue,
                                        frequenciaMinimaState: 'primary',
                                      });
                                    }}
                                    onBlur={() => {
                                      if (this.state.frequenciaMinima
                                        && this.state.frequenciaMinima.length === 1) {
                                        this.setState({
                                          frequenciaMinima: `${this.state.frequenciaMinima},0`,
                                        });
                                      }
                                    }}
                                    tag={NumberFormat}
                                  />
                                  {this.state.frequenciaMinimaState === 'has-danger' ? (
                                    <label className="error">
                                      Informe o percentual de frequência mínima para aprovação.
                                    </label>
                                  ) : null}
                                </FormGroup>
                              </Col>
                            </Row>
                          </CardBody>
                        </Collapse>
                      </Card>
                    </div>
                  }
                </div>
                <div className="category form-category">
                  * Campos obrigatórios
                </div>
              </CardBody>
              <CardFooter>
                <Row>
                  <Col md="6" className="text-left">
                    <Button
                      color="primary" disabled={this.state.buttonDisabled}
                      onClick={() => this.props.history.push(`${this.props.layout}/anosletivos/${this.state.anoLetivo.id}/escola/${this.state.escola.id}`)} >
                      Voltar
                    </Button>
                  </Col>
                  <Col md="6" className="text-right">
                    {
                      !this.state.turmaSelecionada
                        ? <Button type="submit" color="primary" disabled={this.state.buttonDisabled}>
                          Cadastrar
                        </Button>
                        : <Button type="submit" style={{ marginRight: 5 }} color="primary" disabled={this.state.buttonDisabled}>
                          Atualizar
                        </Button>
                    }
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </Form>
        </Col>
      </Row >;
  }

  render() {
    return (
      <div className="content">
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações do turma
            </div>
          </Card>
        }
        <LoaderModal
          isOpen={this.state.buttonDisabled}
          text={this.state.loaderModalText} />
      </div>
    );
  }
}

export default Turmas;
