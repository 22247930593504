import React from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap';

import moment from 'moment';

import Select from 'react-select';
import Loader from 'react-loader-spinner';

import Alert from 'views/components/Alert';
import DatePicker from 'views/components/DatePicker';
import LoaderModal from 'views/components/LoaderModal';

import TipoAfastamento from 'assets/csv/tipos-afastamento.json';

import AfastamentosService from 'services/AfastamentosService';
import UsuarioService from 'services/UsuarioService';
import LoginService from 'services/LoginService';

class CadastroAfastamento extends React.Component {
  constructor(props) {
    super(props);

    this.tipos = [
      TipoAfastamento.LICENCA,
      TipoAfastamento.FERIAS,
    ];

    this.afastamentosService = new AfastamentosService();
    this.usuarioService = new UsuarioService();
    this.loginService = new LoginService();

    this.state = {
      showAlert: false,
      buttonDisabled: false,

      dataInicio: '',
      dataFim: '',

      servidorSelecionado: '',
      tipoSelecionado: '',

      loaderModalText: '',

      afastamentoSelecionado: null,
      erro: null,

      servidores: [],

      viewDate: new Date(new Date().getFullYear(), 0, 1),
    };

    this.cadastrarAfastamento = this.cadastrarAfastamento.bind(this);
    this.atualizarAfastamento = this.atualizarAfastamento.bind(this);
    this.validarCampos = this.validarCampos.bind(this);
    this.limparFormulario = this.limparFormulario.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentWillUnmount() {
    this.loginService.abortPedingRequests();
    this.afastamentosService.abortPedingRequests();
    this.usuarioService.abortPedingRequests();

    this.umounted = true;
  }

  async componentDidMount() {
    await this.loginService.verificarPapel(this.props.role);

    this.debouncedFunction = _.debounce((text) => {
      if (text === '') {
        this.setState({ carregandoServidores: false, servidores: [] });
      } else {
        this.carregarServidores(text);
      }
    }, 1500);

    try {
      if (this.props.match.params.idAfastamento !== undefined) {
        const afastamento = await this.afastamentosService
          .carregarAfastamento(this.props.match.params.idAfastamento);

        this.setState({
          afastamentoSelecionado: afastamento,
        });

        this.contentLoad();
      }
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  async carregarServidores(like) {
    try {
      const servidores = await this.usuarioService
        .carregarServidores(like);

      const selectServidores = [];
      servidores.forEach((servidor) => {
        selectServidores.push({ value: servidor.id, label: `${servidor.nome} (Mãe: ${servidor.nomeMae ? servidor.nomeMae : 'Não informado'})` });
      });

      this.setState({
        carregandoServidores: false,
        servidores: selectServidores,
      });
    } catch (e) {
      if (this.umounted) return;
      this.setState({
        carregandoServidores: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao buscar servidores cadastrados',
      });
    }
  }

  contentLoad() {
    this.setState({
      servidorSelecionado: {
        value: this.state.afastamentoSelecionado.servidor.id,
        label: `${this.state.afastamentoSelecionado.servidor.nome} (Mãe: ${this.state.afastamentoSelecionado.servidor.nomeMae
          ? this.state.afastamentoSelecionado.servidor.nomeMae : 'Não informado'})`,
      },
      tipoSelecionado: this.tipos[this.state.afastamentoSelecionado.tipo],
      dataInicio: this.state.afastamentoSelecionado.dataInicio,
      dataFim: this.state.afastamentoSelecionado.dataFim,
      observacoes: this.state.afastamentoSelecionado.observacoes,
    });
  }

  validarCampos() {
    let ret = true;
    if (!this.state.servidorSelecionado) {
      this.setState({ servidorState: 'danger' });
      ret = false;
    }
    if (!this.state.tipoSelecionado) {
      this.setState({ tipoState: 'danger' });
      ret = false;
    }
    if (!this.state.dataInicio) {
      this.setState({
        dataInicioState: 'has-danger',
        dataInicioErro: 'Selecione uma data',
      });
      ret = false;
    }
    if (!this.state.dataFim) {
      this.setState({
        dataFimState: 'has-danger',
        dataFimErro: 'Selecione uma data',
      });
      ret = false;
    }
    if (this.state.dataInicio && this.state.dataFim) {
      if (!moment(this.state.dataFim, 'DD/MM/YYYY').isAfter(moment(this.state.dataInicio, 'DD/MM/YYYY'))) {
        this.setState({
          dataInicioState: 'has-danger',
          dataFimState: 'has-danger',
          dataInicioErro: 'A data de fim deve ser posterior a de início',
          dataFimErro: 'A data de fim deve ser posterior a de início',
        });
        ret = false;
      }
    }
    return ret;
  }

  async cadastrarAfastamento() {
    if (!this.validarCampos()) return;

    this.setState({
      buttonDisabled: true,
      showAlert: false,
      loaderModalText: 'Cadastrando afastamento...',
    });

    const afastamento = {
      servidor: { id: this.state.servidorSelecionado.value },
      tipo: this.state.tipoSelecionado.value,
      dataInicio: this.state.dataInicio,
      dataFim: this.state.dataFim,
      observacoes: this.state.observacoes,
    };
    try {
      await this.afastamentosService.cadastrarAfastamento(afastamento);
      this.limparFormulario();
      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Afastamento cadastrado com sucesso',
      });
    } catch (e) {
      if (this.umounted) return;
      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: e || 'Erro ao cadastrar afastamento',
      });
    }
  }

  async atualizarAfastamento() {
    if (!this.validarCampos()) return;

    this.setState({
      buttonDisabled: true,
      showAlert: false,
      loaderModalText: 'Atualizando afastamento...',
    });

    const afastamento = {
      id: this.state.afastamentoSelecionado.id,
      servidor: { id: this.state.servidorSelecionado.value },
      tipo: this.state.tipoSelecionado.value,
      dataInicio: this.state.dataInicio,
      dataFim: this.state.dataFim,
      observacoes: this.state.observacoes,
    };

    try {
      await this.afastamentosService.atualizarAfastamento(afastamento);

      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Afastamento atualizado com sucesso',
      });
    } catch (e) {
      if (this.umounted) return;
      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: e || 'Erro ao atualizar afastamento',
      });
    }
  }

  limparFormulario() {
    this.setState({
      servidorSelecionado: '',
      tipoSelecionado: '',
      dataInicio: '',
      dataFim: '',
      observacoes: '',

      buttonDisabled: false,
    });
  }

  onSubmit(e) {
    e.preventDefault();

    if (this.state.buttonDisabled) return;

    if (!this.state.afastamentoSelecionado) {
      this.cadastrarAfastamento();
    } else {
      this.atualizarAfastamento();
    }
  }

  conteudoPagina() {
    return (this.props.match.params.idAfastamento !== undefined
      && this.state.afastamentoSelecionado === null)
      ? <Card>
        <div align="center" style={{ margin: 50 }}>
          <Loader
            type="Oval"
            color="#34B5B8"
            height="50"
            width="50" />
        </div>
      </Card> : <Row>
        <Col md="12">
          <Alert
            color={this.state.alertColor}
            isOpen={this.state.showAlert}
            toggle={() => { this.setState({ showAlert: false }); }}>
            {this.state.alertMsg}
          </Alert>
          <Breadcrumb>
            <BreadcrumbItem><Link to="/gestor/afastamentos">Afastamentos</Link></BreadcrumbItem>
            <BreadcrumbItem active>{this.state.afastamentoSelecionado ? 'Atualização' : 'Cadastro'}</BreadcrumbItem>
          </Breadcrumb>
          <Form onSubmit={this.onSubmit}>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Dados do afastamento</CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="2">
                    <FormGroup className={`has-label ${this.state.dataInicioState}`}>
                      <label>Data de início *</label>
                      <DatePicker
                        viewDate={this.state.viewDate}
                        value={this.state.dataInicio}
                        onChange={(data) => {
                          const dataStr = moment(data).format('DD/MM/YYYY');
                          this.setState({
                            viewDate: data.toDate ? data.toDate() : this.state.viewDate,
                            dataInicio: dataStr,
                            dataInicioState: '',
                            dataFimState: '',
                          });
                        }}
                      />
                      {this.state.dataInicioState === 'has-danger' ? (
                        <label className="error">
                          {this.state.dataInicioErro}
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md="2">
                    <FormGroup className={`has-label ${this.state.dataFimState}`}>
                      <label>Data de fim *</label>
                      <DatePicker
                        viewDate={this.state.viewDate}
                        value={this.state.dataFim}
                        onChange={(data) => {
                          const dataStr = moment(data).format('DD/MM/YYYY');
                          this.setState({
                            viewDate: data.toDate ? data.toDate() : this.state.viewDate,
                            dataFim: dataStr,
                            dataInicioState: '',
                            dataFimState: '',
                          });
                        }}
                      />
                      {this.state.dataFimState === 'has-danger' ? (
                        <label className="error">
                          {this.state.dataFimErro}
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md="2">
                    <FormGroup className={'has-label has-danger'}>
                      <label>Tipo *</label>
                      <Select
                        noOptionsMessage={() => 'Nenhuma entrada'}
                        className={`react-select primary ${this.state.tipoState}`}
                        classNamePrefix="react-select"
                        value={this.state.tipoSelecionado}
                        onChange={(event) => {
                          this.setState({ tipoState: '', tipoSelecionado: event });
                        }}
                        options={this.tipos}
                        placeholder="Tipo..."
                      />
                      {this.state.tipoState === 'danger' ? (
                        <label className="error">
                          Informe o tipo do afastamento.
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup className={'has-label has-danger'}>
                      <label>Servidor *</label>
                      <Select
                        isDisabled={this.props.match.params.idAfastamento}
                        isLoading={this.state.carregandoServidores}
                        loadingMessage={() => 'Carregando...'}
                        noOptionsMessage={() => 'Nenhuma entrada'}
                        className={`react-select primary ${this.state.servidorState}`}
                        classNamePrefix="react-select"
                        value={this.state.servidorSelecionado}
                        onInputChange={(text) => {
                          this.setState({ carregandoServidores: text !== '', servidorState: 'primary' });
                          this.debouncedFunction(text);
                        }}
                        onChange={(event) => {
                          this.setState({ servidorState: 'primary', servidorSelecionado: event });
                        }}
                        options={this.state.servidores}
                        placeholder="Digite o nome de um servidor..."
                      />
                      {this.state.servidorState === 'danger' ? (
                        <label className="error">
                          Informe o nome de um servidor.
                        </label>
                      ) : <label></label>}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup className={'has-label'}>
                      <label>Observações</label>
                      <Input
                        value={this.state.observacoes}
                        onChange={(evt) => {
                          this.setState({ observacoes: evt.target.value });
                        }}
                        type="textarea"
                        style={{ height: '200px' }} />
                    </FormGroup>
                  </Col>
                </Row>
                <div className="category form-category">
                  * Campos obrigatórios
                </div>
              </CardBody>
              <CardFooter>
                <Row>
                  <Col md="6" className="text-left">
                    <Button color="primary" disabled={this.state.buttonDisabled} onClick={() => this.props.history.push('/gestor/afastamentos')} >
                      Voltar
                    </Button>
                  </Col>
                  <Col md="6" className="text-right">
                    <Button type="submit" color="primary" disabled={this.state.buttonDisabled} >
                      {!this.state.afastamentoSelecionado ? 'Cadastrar' : 'Atualizar'}
                    </Button>
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </Form>
        </Col>
      </Row>;
  }

  render() {
    return (
      <div className="content">
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
          </div>
          </Card>
        }
        <LoaderModal
          isOpen={this.state.buttonDisabled}
          text={this.state.loaderModalText} />
      </div>
    );
  }
}

export default CadastroAfastamento;
