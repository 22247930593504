import React from 'react';

import {
  Tabs,
  TabList,
  Tab,
  TabPanel,
} from 'react-tabs';

import { connect } from 'react-redux';
import Loader from 'react-loader-spinner';

import AdaptiveTable from 'views/components/AdaptiveTable';
import ConfirmModal from 'views/components/ConfirmModal';
import LoaderModal from 'views/components/LoaderModal';

import EscolasService from 'services/EscolasService';

import SituacaoSolicitacaoTransferencia from 'assets/csv/situacoes-solicitacao-transferencia';

class SolicitacoesTransferencia extends React.Component {
  constructor(props) {
    super(props);

    const str = this.props.entrada ? 'entrada' : 'saida'
    this.idPagina = `${this.props.dadosUsuario.idVinculo}-transferencias-${str}-escola`;

    this.paginaSelecionada = sessionStorage.getItem(`${this.idPagina}-pg`) || 1;
    this.linhasPorPagina = sessionStorage.getItem(`${this.idPagina}-ln`) || 5;
    this.like = '';

    this.situacoesSolicitacaoTransferencia = [
      SituacaoSolicitacaoTransferencia.AGUARDANDO,
      SituacaoSolicitacaoTransferencia.ACEITA,
      SituacaoSolicitacaoTransferencia.RECUSADA,
      SituacaoSolicitacaoTransferencia.CANCELADA,
    ];

    this.escolasService = new EscolasService();

    this.cancelarSolicitacaoTransferencia = this.cancelarSolicitacaoTransferencia.bind(this);
    this.confirmarCancelamentoSolicitacaoTransferencia = this.confirmarCancelamentoSolicitacaoTransferencia.bind(this);
    this.recusarSolicitacaoTransferencia = this.recusarSolicitacaoTransferencia.bind(this);
    this.confirmarRecusaSolicitacaoTransferencia = this.confirmarRecusaSolicitacaoTransferencia.bind(this);
    this.aceitarSolicitacaoTransferencia = this.aceitarSolicitacaoTransferencia.bind(this);
    this.confirmarAceiteSolicitacaoTransferencia = this.confirmarAceiteSolicitacaoTransferencia.bind(this);

    this.state = {
      dadosTabela: {
        header: [['Nome', '30%'], [this.props.entrada ? 'Escola origem' : 'Escola destino', '30%'], ['Data', '15%'], ['Situação', '15%']],
        columnAlign: ['text-left', 'text-left', 'text-center', 'text-center'],
        keys: ['nome', this.props.entrada ? 'escolaOrigem' : 'escolaDestino', 'dataCriacao', 'situacaoSolicitacao'],
        rows: [],
        total: undefined,
      },
    };

    this.customActions = this.props.entrada ? [
      {
        icon: 'fa fa-check',
        tooltip: 'Aceitar',
        color: 'success',
        callback: this.confirmarAceiteSolicitacaoTransferencia,
        disabled: this.isCustomActionDisabled,
      },
      {
        icon: 'fa fa-times',
        tooltip: 'Recusar',
        color: 'danger',
        callback: this.confirmarRecusaSolicitacaoTransferencia,
        disabled: this.isCustomActionDisabled,
      }
    ] : [
      {
        icon: 'fa fa-times',
        tooltip: 'Cancelar',
        color: 'danger',
        callback: this.confirmarCancelamentoSolicitacaoTransferencia,
        disabled: this.isCustomActionDisabled,
      }
    ]
  }

  async componentDidMount() {
    try {
      this.carregarSolicitacoesTransferencia(this.paginaSelecionada, this.linhasPorPagina, '');
    } catch (e) {
      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao buscar solicitações de transferência da escola',
      });
    }
  }

  isCustomActionDisabled(solicitacaoTransferencia) {
    return solicitacaoTransferencia.situacao !== SituacaoSolicitacaoTransferencia.AGUARDANDO.value;
  }

  async carregarSolicitacoesTransferencia(pagina, linhasPorPagina, like) {
    try {
      let data;
      if (this.props.entrada) {
        data = await this.escolasService
          .carregarSolicitacoesTransferenciaEntrada(this.props.match.params.idEscola, pagina, linhasPorPagina, like);
      } else {
        data = await this.escolasService
          .carregarSolicitacoesTransferenciaSaida(this.props.match.params.idEscola, pagina, linhasPorPagina, like);
      }

      data.solicitacoesTransferencia.forEach((st) => {
        Object.assign(st, {
          nome: st.aluno.nome,
          escolaOrigem: st.escolaOrigem.nome,
          escolaDestino: st.escolaDestino.nome,
          situacaoSolicitacao: this.situacoesSolicitacaoTransferencia[st.situacao].label,
        });
      });

      this.setState({
        loaded: true,
        dadosTabela: Object.assign(this.state.dadosTabela,
          { rows: data.solicitacoesTransferencia, total: data.total }),
      });
    } catch (e) {
      this.setState({
        dadosTabela: Object.assign(this.state.dadosTabela, { total: -1 }),
      });

      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao buscar solicitações de transferências da escola',
      });
    }
  }

  confirmarCancelamentoSolicitacaoTransferencia(solicitacaoTransferencia) {
    this.setState({
      solicitacaoTransferenciaSelecionada: solicitacaoTransferencia,
      acaoSolicitacaoTransferencia: this.cancelarSolicitacaoTransferencia,
      confirmModalText: 'Confirme o cancelamento da solicitação de transferência.',
      showConfirmModal: true,
      confirmText: 'Cancelar',
    });
  }

  async cancelarSolicitacaoTransferencia() {
    this.setState({ 
      showLoaderModal: true,
      loaderModalText: 'Cancelando solicitação de transferência...',
    });
    this.props.callbackMessage({ showAlert: false })

    try {
      await this.escolasService
        .cancelarSolicitacaoTransferencia(this.state.solicitacaoTransferenciaSelecionada.id);

      if (this.state.dadosTabela.total % this.linhasPorPagina === 1
        && this.paginaSelecionada > 1) {
        this.paginaSelecionada -= 1;
      }

      this.setState({
        showLoaderModal: false,
      });

      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Solicitação de transferência cancelada com sucesso',
      })

      this.carregarSolicitacoesTransferencia(this.paginaSelecionada, this.linhasPorPagina, this.like);
    } catch (msg) {
      if (this.umounted) return;
      this.setState({
        showLoaderModal: false,
      });
      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao cancelar solicitação de transferência',
      })
    }
  }

  confirmarRecusaSolicitacaoTransferencia(solicitacaoTransferencia) {
    this.setState({
      solicitacaoTransferenciaSelecionada: solicitacaoTransferencia,
      acaoSolicitacaoTransferencia: this.recusarSolicitacaoTransferencia,
      showConfirmModal: true,
      confirmModalText: 'Confirme a recusa da solicitação de transferência.',
      confirmText: 'Recusar',
    });
  }

  async recusarSolicitacaoTransferencia() {
    this.setState({ 
      showLoaderModal: true,
      loaderModalText: 'Recusando solicitação de transferência...',
    });
    this.props.callbackMessage({ showAlert: false })

    try {
      await this.escolasService
        .recusarSolicitacaoTransferencia(this.state.solicitacaoTransferenciaSelecionada.id);

      if (this.state.dadosTabela.total % this.linhasPorPagina === 1
        && this.paginaSelecionada > 1) {
        this.paginaSelecionada -= 1;
      }

      this.setState({
        showLoaderModal: false,
      });

      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Solicitação de transferência recusada com sucesso',
      })

      this.carregarSolicitacoesTransferencia(this.paginaSelecionada, this.linhasPorPagina, this.like);
    } catch (msg) {
      if (this.umounted) return;
      this.setState({
        showLoaderModal: false,
      });
      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao recusar solicitação de transferência',
      })
    }
  }

  confirmarAceiteSolicitacaoTransferencia(solicitacaoTransferencia) {
    this.setState({
      solicitacaoTransferenciaSelecionada: solicitacaoTransferencia,
      acaoSolicitacaoTransferencia: this.aceitarSolicitacaoTransferencia,
      showConfirmModal: true,
      confirmModalText: 'Confirme o aceite da solicitação de transferência.',
      confirmText: 'Aceitar',
    });
  }

  async aceitarSolicitacaoTransferencia() {
    this.setState({ 
      showLoaderModal: true,
      loaderModalText: 'Aceitando solicitação de transferência...',
    });
    this.props.callbackMessage({ showAlert: false })

    try {
      await this.escolasService
        .aceitarSolicitacaoTransferencia(this.state.solicitacaoTransferenciaSelecionada.id);

      if (this.state.dadosTabela.total % this.linhasPorPagina === 1
        && this.paginaSelecionada > 1) {
        this.paginaSelecionada -= 1;
      }

      this.setState({
        showLoaderModal: false,
      });

      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Solicitação de transferência aceita com sucesso. Transferência realizada.',
      })

      this.carregarSolicitacoesTransferencia(this.paginaSelecionada, this.linhasPorPagina, this.like);
    } catch (msg) {
      if (this.umounted) return;
      this.setState({
        showLoaderModal: false,
      });
      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao aceitar solicitação de transferência',
      })
    }
  }

  render() {
    return (
      this.state.loaded ? <>
        <AdaptiveTable
          selectedPage={this.paginaSelecionada}
          rowsPerPage={this.linhasPorPagina}
          disablePrintAction
          disableEditAction
          disableRemoveAction
          customActions={this.customActions}
          rowsPerPageCallback={(info) => {
            this.paginaSelecionada = 1;
            sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
            this.linhasPorPagina = info.rowsPerPage;
            sessionStorage.setItem(`${this.idPagina}-ln`, this.linhasPorPagina);
            this.carregarSolicitacoesTransferencia(this.paginaSelecionada, info.rowsPerPage, this.like);
          }}
          likeCallback={(text) => {
            this.like = text;
            this.paginaSelecionada = 1;
            sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
            this.carregarSolicitacoesTransferencia(this.paginaSelecionada, this.linhasPorPagina, text);
          }}
          paginatorCallback={(page) => {
            this.paginaSelecionada = page;
            sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
            this.carregarSolicitacoesTransferencia(this.paginaSelecionada, this.linhasPorPagina, this.like);
          }}
          data={this.state.dadosTabela} />
        <ConfirmModal
          isOpen={this.state.showConfirmModal}
          callback={(confirm) => {
            this.setState({ showConfirmModal: false });
            if (confirm) {
              this.state.acaoSolicitacaoTransferencia();
            } else {
              this.setState({ solicitacaoTransferenciaSelecionada: null });
            }
          }}
          confirmText={this.state.confirmText}
          text={this.state.confirmModalText} />
        <LoaderModal
          isOpen={this.state.showLoaderModal}
          text={this.state.loaderModalText} />
      </> : <div align="center" style={{ margin: 50 }}>
        <Loader
          type="Oval"
          color="#34B5B8"
          height="50"
          width="50" />
      </div>
    );
  }
}

class EscolaTransferencias extends React.Component {
  render() {
    return (
      <Tabs>
        <TabList>
          <Tab>Entrada</Tab>
          <Tab>Saída</Tab>
        </TabList>
        <TabPanel>
          <SolicitacoesTransferencia
            {...this.props}
            entrada />
        </TabPanel>
        <TabPanel>
          <SolicitacoesTransferencia
            {...this.props} />
        </TabPanel>
      </Tabs>
    );
  }
}

const mapStateToProps = (state) => ({ dadosUsuario: state.dadosUsuario });

export default connect(mapStateToProps)(EscolaTransferencias);