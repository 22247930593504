/* eslint-disable max-len */
import React from 'react';
import { Link } from 'react-router-dom';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  Collapse,
  UncontrolledTooltip,
  Label,
} from 'reactstrap';

import {
  Tabs,
  TabList,
  Tab,
  TabPanel,
} from 'react-tabs';

import Select from 'react-select';
import Loader from 'react-loader-spinner';
import NumberFormat from 'react-number-format';

import Alert from 'views/components/Alert';
import LoaderModal from 'views/components/LoaderModal';

import SituacaoTurma from 'assets/csv/situacoes-turma.json';
import Turno from 'assets/csv/turnos.json';
import CicloEnsino from 'assets/csv/ciclos-ensino.json';
import TipoAvaliacao from 'assets/csv/tipos-avaliacao.json';
import Serie from 'assets/csv/series2.json';

import AnosLetivosService from 'services/AnosLetivosService';
import CursosService from 'services/CursosService';
import TurmasService from 'services/TurmasService';
import EscolasService from 'services/EscolasService';
import LoginService from 'services/LoginService';
import EstruturasCurricularesService from 'services/EstruturasCurricularesService';

class Turmas extends React.Component {
  constructor(props) {
    super(props);

    this.situacoes = [
      SituacaoTurma.ABERTA,
      SituacaoTurma.CONSOLIDADA,
    ];

    this.turnos = [
      Turno.MATUTINO,
      Turno.VESPERTINO,
      Turno.NOTURNO,
      Turno.INTEGRAL,
    ];

    this.tiposAvaliacao = [
      TipoAvaliacao.NOTA,
      TipoAvaliacao.RELATORIO,
    ];

    this.estruturasCurricularesService = new EstruturasCurricularesService();
    this.anosLetivosService = new AnosLetivosService();
    this.cursosService = new CursosService();
    this.turmasService = new TurmasService();
    this.escolasService = new EscolasService();
    this.loginService = new LoginService();

    const descricoesConteudos = [];
    const descricoesRelatorios = [];

    descricoesConteudos.push({ nome: '1º bimestre' });
    descricoesConteudos.push({ nome: '2º bimestre' });
    descricoesConteudos.push({ nome: '3º bimestre' });
    descricoesConteudos.push({ nome: '4º bimestre' });

    descricoesRelatorios.push({ nome: 'Proposta de trabalho anual' });
    descricoesRelatorios.push({ nome: 'Diagnóstico inicial da turma' });
    descricoesRelatorios.push({ nome: 'Relatório final da turma' });
    descricoesRelatorios.push({ nome: 'Observações' });

    this.state = {
      showAlert: false,

      nomeState: '',
      turnoState: 'primary',

      nome: '',
      vagas: '',
      turnoSelecionado: '',

      series: [],

      descricoesConteudos,
      descricoesRelatorios,

      collapsedConteudos: false,
      collapsedRelatorios: false,
      collapsedFrequencia: false,

      tabIndex: 0,

      mediaAprovacao: '6,0',
      mediaReprovacaoAntesRecuperacao: '2,5',
      temRecuperacao: true,
      pesoRecuperacao: '1,0',
      pesoMediaAvaliacoes: '2,0',
      mediaAprovacaoAposRecuperacao: '5,0',
      frequenciaMinima: '75,0',

      erro: null,
    };

    this.cadastrarTurma = this.cadastrarTurma.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  async componentDidMount() {
    await this.loginService.verificarPapel(this.props.role);

    try {
      const anoLetivo = await this.anosLetivosService
        .carregarAnoLetivo(this.props.match.params.idAnoLetivo);

      const escola = await this.escolasService
        .carregarEscola(this.props.match.params.idEscola);

      const data = await this.cursosService
        .carregarCursos(1, 99999, '');

      const selectCursos = [];
      data.cursos.forEach((curso) => {
        if (curso.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value
          || curso.cicloEnsino === CicloEnsino.ENSINO_FUNDAMENTAL_1.value
          || curso.serie === Serie.EJA_ENSINO_FUNDAMENTAL.value) {
          selectCursos.push({
            label: curso.nome,
            value: curso.id,
            cicloEnsino: curso.cicloEnsino,
            serie: curso.serie,
          });
        }
      });

      this.setState({
        cursos: selectCursos,
        escola,
        anoLetivo,
        uf: anoLetivo.ente.estado.uf,
        ente: anoLetivo.ente.nome,
      });

      if(this.state.uf === 'PB') {
        const descricoesRelatorios = [];

        descricoesRelatorios.push({ nome: 'Plano de curso anual' });
        descricoesRelatorios.push({ nome: 'Diagnóstico inicial da turma' });
        descricoesRelatorios.push({ nome: 'Relatório final da turma' });
        descricoesRelatorios.push({ nome: 'Observações' });

        this.setState({
          mediaAprovacao: '7,0',
          mediaReprovacaoAntesRecuperacao: '2,5',
          temRecuperacao: true,
          pesoRecuperacao: '4,0',
          pesoMediaAvaliacoes: '6,0',
          mediaAprovacaoAposRecuperacao: '5,0',
          descricoesRelatorios,
        });
      }

      if (this.props.match.params.idTurma !== undefined) {
        const turma = await this.turmasService
          .carregarTurmaMultiseriado(this.props.match.params.idTurma);

        this.setState({ turmaSelecionada: turma });

        this.contentLoad();
      }
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  async contentLoad() {
    const series = this.state.turmaSelecionada.turmas;

    const fn = (turma) => new Promise((resolve) => resolve(
      this.carregarEstruturasCurriculares(turma, turma.curso.id),
    ));
    const actions = series.map(fn);
    await Promise.all(actions);

    const fn2 = (turma) => new Promise((resolve) => resolve(
      this.carregarConfiguracoesDaTurma(turma),
    ));
    const actions2 = series.map(fn2);
    await Promise.all(actions2);

    series.forEach((serie) => {
      Object.assign(serie, {
        cursoSelecionado: {
          label: serie.curso.nome,
          value: serie.curso.id,
        },
        estruturaCurricularSelecionada: {
          label: serie.estruturaCurricular.nome,
          value: serie.estruturaCurricular.id,
        },
        tipoAvaliacaoSelecionada: {
          label: this.tiposAvaliacao[serie.tipoAvaliacao].label,
          value: this.tiposAvaliacao[serie.tipoAvaliacao].value,
        },
      });
    });

    this.setState({
      nome: this.state.turmaSelecionada.nome,
      vagas: this.state.turmaSelecionada.vagas || '',
      turnoSelecionado: this.turnos[this.state.turmaSelecionada.turno],
      series,
      frequenciaRestrita: this.state.turmaSelecionada.frequenciaRestrita,
    });
  }

  async carregarConfiguracoesDaTurma(turma) {
    let descricoesAvaliacoes = await this.turmasService
      .carregarDescricoesAvaliacoes(turma.id);

    descricoesAvaliacoes = descricoesAvaliacoes.map((desc) => ({
      ...desc,
      peso: desc.peso ? this.converterParaString(desc.peso) : undefined,
    }));

    const descricoesConteudos = await this.turmasService
      .carregarDescricoesConteudos(turma.id);

    const descricoesRelatorios = await this.turmasService
      .carregarDescricoesRelatorios(turma.id);

    Object.assign(turma, {
      descricoesAvaliacoes,
      descricoesConteudos,
      descricoesRelatorios,
      mediaAprovacao: turma.tipoAvaliacao
        === TipoAvaliacao.NOTA.value
        ? this.converterParaString(turma.mediaAprovacao) : undefined,
      temRecuperacao: turma.tipoAvaliacao
        === TipoAvaliacao.NOTA.value ? turma.temRecuperacao : undefined,
      mediaReprovacaoAntesRecuperacao: turma.tipoAvaliacao
        === TipoAvaliacao.NOTA.value && turma.temRecuperacao
        ? this.converterParaString(turma.mediaReprovacaoAntesRecuperacao) : undefined,
      pesoRecuperacao: turma.tipoAvaliacao
        === TipoAvaliacao.NOTA.value && turma.temRecuperacao
        ? this.converterParaString(turma.pesoRecuperacao) : undefined,
      pesoMediaAvaliacoes: turma.tipoAvaliacao
        === TipoAvaliacao.NOTA.value && turma.temRecuperacao
        ? this.converterParaString(turma.pesoMediaAvaliacoes) : undefined,
      mediaAprovacaoAposRecuperacao: turma.tipoAvaliacao
        === TipoAvaliacao.NOTA.value && turma.temRecuperacao
        ? this.converterParaString(turma.mediaAprovacaoAposRecuperacao) : undefined,
      frequenciaMinima: this.converterParaString(turma.frequenciaMinima),
    });
  }

  adicionarSerie() {
    this.state.series.push({
      tipoAvaliacaoState: 'primary',
      cursoState: 'primary',
      estruturaCurricularState: 'primary',
      mediaAprovacaoState: 'primary',
      mediaReprovacaoAntesRecuperacaoState: 'primary',
      pesoRecuperacaoState: 'primary',
      mediaAprovacaoAposRecuperacaoState: 'primary',

      cursoSelecionado: '',
      tipoAvaliacaoSelecionada: '',
      estruturaCurricularSelecionada: '',
      mediaAprovacao: this.state.mediaAprovacao,
      mediaReprovacaoAntesRecuperacao: this.state.mediaReprovacaoAntesRecuperacao,
      temRecuperacao: this.state.temRecuperacao,
      pesoRecuperacao: this.state.pesoRecuperacao,
      pesoMediaAvaliacoes: this.state.pesoMediaAvaliacoes,
      mediaAprovacaoAposRecuperacao: this.state.mediaAprovacaoAposRecuperacao,

      estruturasCurriculares: [],
      descricoesAvaliacoes: [],

      collapsedAvaliacoes: false,
    });

    this.forceUpdate();
  }

  async carregarEstruturasCurriculares(serie, idCurso) {
    this.setState({
      buttonDisabled: true,
      showAlert: false,
      loaderModalText: 'Carregando estruturas curriculares do curso...',
    });

    try {
      const data = await this.estruturasCurricularesService
        .carregarEstruturasCurriculares(idCurso, 1, 99999, '');

      const selectEstruturasCurriculares = [];
      data.estruturasCurriculares.forEach((estruturaCurricular) => {
        selectEstruturasCurriculares.push({
          label: estruturaCurricular.nome,
          value: estruturaCurricular.id,
          materias: estruturaCurricular.materias,
        });
      });

      this.setState({ buttonDisabled: false });
      Object.assign(serie, {
        estruturasCurriculares: selectEstruturasCurriculares,
      });
    } catch (e) {
      if (this.umounted) return;
      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao buscar estruturas curriculares do curso',
      });
    }
  }

  onSubmit(e) {
    e.preventDefault();

    if (this.state.buttonDisabled) return;

    if (!this.state.turmaSelecionada) {
      this.cadastrarTurma();
    } else {
      this.atualizarTurma();
    }
  }

  validarCampos() {
    let ret = true;
    if (!this.state.nome) {
      this.setState({ nomeState: 'has-danger' });
      ret = false;
    }
    if (!this.state.turnoSelecionado) {
      this.setState({ turnoState: 'danger' });
      ret = false;
    }

    this.state.series.forEach((serie) => {
      
      if (!serie.tipoAvaliacaoSelecionada && serie.cursoSelecionado) {
        Object.assign(serie, { tipoAvaliacaoState: 'danger' });
        ret = false;
      }
      
      if (!serie.cursoSelecionado) {
        Object.assign(serie, {
          cursoState: 'danger',
          cursoErroMsg: 'Informe o curso da turma',
        });
        ret = false;
      }
      if (!serie.estruturaCurricularSelecionada || !serie.estruturasCurriculares.length) {
        Object.assign(serie, { estruturaCurricularState: 'danger' });
        ret = false;
      }
      serie.descricoesAvaliacoes.forEach((desc) => {
        if (!desc.nome) {
          Object.assign(desc, { nomeState: 'has-danger' });
          ret = false;
        }
        if (serie.tipoAvaliacaoSelecionada.value === TipoAvaliacao.NOTA.value && !desc.peso) {
          Object.assign(desc, { pesoState: 'has-danger' });
          ret = false;
        }
      });
      
      if (serie.tipoAvaliacaoSelecionada.value === TipoAvaliacao.NOTA.value) {
        if (!serie.mediaAprovacao) {
          Object.assign(serie, { mediaAprovacaoState: 'has-danger' });
          ret = false;
        }
        if (serie.temRecuperacao) {
          if (!serie.mediaReprovacaoAntesRecuperacao) {
            Object.assign(serie, { mediaReprovacaoAntesRecuperacaoState: 'has-danger' });
            ret = false;
          }
          
          if (!serie.pesoRecuperacao) {
            Object.assign(serie, { pesoRecuperacaoState: 'has-danger' });
            ret = false;
          }
          
          if (!serie.pesoMediaAvaliacoes) {
            Object.assign(serie, { pesoMediaAvaliacoesState: 'has-danger' });
            ret = false;
          }
          
          if (!serie.mediaAprovacaoAposRecuperacao) {
            Object.assign(serie, { mediaAprovacaoAposRecuperacaoState: 'has-danger' });
            ret = false;
          }
        }
      }

      if (!serie.frequenciaMinima) {
        Object.assign(serie, {
          frequenciaMinimaState: 'has-danger',
        });
        ret = false;
      }
    });

    this.state.descricoesConteudos.forEach((desc) => {
      if (!desc.nome) {
        this.setState({ nomeState: 'has-danger' });
        ret = false;
      }
    });

    this.state.descricoesRelatorios.forEach((desc) => {
      if (!desc.nome) {
        this.setState({ nomeState: 'has-danger' });
        ret = false;
      }
    });

    for (let i = 0; i < this.state.series.length; i += 1) {
      for (let j = i + 1; j < this.state.series.length; j += 1) {
        if (this.state.series[i].cursoSelecionado.value === this.state.series[j].cursoSelecionado.value) {
          Object.assign(this.state.series[i], {
            cursoState: 'danger',
            cursoErroMsg: 'Não pode haver curso repetido',
          });
          Object.assign(this.state.series[j], {
            cursoState: 'danger',
            cursoErroMsg: 'Não pode haver curso repetido',
          });
          ret = false;
          break;
        }
      }
    }

    if (!ret) this.forceUpdate();

    return ret;
  }

  async cadastrarTurma() {
    if (!this.validarCampos()) return;
    this.setState({
      buttonDisabled: true,
      showAlert: false,
      loaderModalText: 'Cadastrando turma...',
    });

    this.state.series.forEach((serie) => {
      const descricoesAvaliacoes = serie.descricoesAvaliacoes
        .map((desc) => {
          const a = {
            ...desc,
            peso: desc.peso ? this.converterParaNumero(desc.peso) : undefined,
          };
          return a;
        });

      if (serie.temRecuperacao) {
        descricoesAvaliacoes.push({
          nome: 'Prova Final',
          peso: this.converterParaNumero(serie.pesoRecuperacao),
          recuperacao: true,
        });
      }

      Object.assign(serie, {
        descricoesAvaliacoes,
        mediaAprovacao: serie.tipoAvaliacaoSelecionada.value
          === TipoAvaliacao.NOTA.value
          ? this.converterParaNumero(serie.mediaAprovacao) : undefined,
        temRecuperacao: serie.tipoAvaliacaoSelecionada.value
          === TipoAvaliacao.NOTA.value ? serie.temRecuperacao : undefined,
        mediaReprovacaoAntesRecuperacao: serie.tipoAvaliacaoSelecionada.value
          === TipoAvaliacao.NOTA.value && serie.temRecuperacao
          ? this.converterParaNumero(serie.mediaReprovacaoAntesRecuperacao) : undefined,
        pesoRecuperacao: serie.tipoAvaliacaoSelecionada.value
          === TipoAvaliacao.NOTA.value && serie.temRecuperacao
          ? this.converterParaNumero(serie.pesoRecuperacao) : undefined,
        pesoMediaAvaliacoes: serie.tipoAvaliacaoSelecionada.value
          === TipoAvaliacao.NOTA.value && serie.temRecuperacao
          ? this.converterParaNumero(serie.pesoMediaAvaliacoes) : undefined,
        mediaAprovacaoAposRecuperacao: serie.tipoAvaliacaoSelecionada.value
          === TipoAvaliacao.NOTA.value && serie.temRecuperacao
          ? this.converterParaNumero(serie.mediaAprovacaoAposRecuperacao) : undefined,
        frequenciaMinima: this.converterParaNumero(serie.frequenciaMinima),
        curso: { id: serie.cursoSelecionado.value },
        estruturaCurricular: { id: serie.estruturaCurricularSelecionada.value },
        tipoAvaliacao: serie.tipoAvaliacaoSelecionada.value,

        descricoesConteudos: this.state.descricoesConteudos,
        descricoesRelatorios: this.state.descricoesRelatorios,

        diarioUnico: true,
      });
    });

    const turmaMultiseriado = {
      nome: this.state.nome,
      vagas: this.state.vagas || undefined,
      turno: this.state.turnoSelecionado.value,
      escola: { id: this.state.escola.id },
      anoLetivo: { id: this.state.anoLetivo.id },
      turmas: this.state.series,
    };

    try {
      await this.turmasService.cadastrarTurmaMultiseriado(turmaMultiseriado);
      this.limparFormulario();
      this.setState({
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Turma cadastrada com sucesso',
      });
    } catch (e) {
      if (this.umounted) return;

      this.setState({
        series: [],
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: e || 'Erro ao cadastrar turma',
      });
    }
  }

  async atualizarTurma() {
    if (!this.validarCampos()) return;

    this.setState({
      buttonDisabled: true,
      showAlert: false,
      loaderModalText: 'Atualizando turma...',
    });

    const turma = {
      id: this.state.turmaSelecionada.id,
      nome: this.state.nome,
      vagas: this.state.vagas || undefined,
      turno: this.state.turnoSelecionado.value,
      situacao: this.state.turmaSelecionada.situacao,
      turmas: this.state.turmaSelecionada.turmas,
    };

    try {
      await this.turmasService.atualizarTurmaMultiseriado(turma);

      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Turma atualizada com sucesso',
      });
    } catch (e) {
      if (this.umounted) return;
      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: e || 'Erro ao atualizar turma',
      });
    }
  }

  limparFormulario() {
    this.setState({
      nome: '',
      turnoSelecionado: '',
      vagas: '',

      nomeState: '',
      turnoState: '',

      series: [],

      buttonDisabled: false,
    });
  }

  removerDescricaoAvaliacao(serie, descricaoAvaliacao) {
    if (serie.descricoesAvaliacoes.length === 1) return;
    const descricoesAvaliacoes = serie.descricoesAvaliacoes.filter(
      (descricaoAvaliacao2) => descricaoAvaliacao !== descricaoAvaliacao2,
    );
    Object.assign(serie, { descricoesAvaliacoes });
    this.forceUpdate();
  }

  removerDescricaoConteudo(descricaoConteudo) {
    if (this.state.descricoesConteudos.length === 1) return;
    const descricoesConteudos = this.state.descricoesConteudos.filter(
      (descricaoConteudo2) => descricaoConteudo !== descricaoConteudo2,
    );
    this.setState({
      descricoesConteudos,
    });
  }

  removerDescricaoRelatorio(descricaoRelatorio) {
    if (this.state.descricoesRelatorios.length === 1) return;
    const descricoesRelatorios = this.state.descricoesRelatorios.filter(
      (descricaoRelatorio2) => descricaoRelatorio !== descricaoRelatorio2,
    );
    this.setState({
      descricoesRelatorios,
    });
  }

  formatarNumero = (val) => {
    if (val.length === 1) {
      return val;
    }
    if (val.length === 2) {
      return `${val[0]},${val[1]}`;
    }
    if (val.length === 3) {
      if (val === '100') {
        return '10,0';
      }
      return `${val[0]},${val[1]}`;
    }
    return '10,0';
  }

  formatarNumero2 = (val) => {
    if (val.length === 1) {
      return `${val}`;
    }
    if (val.length === 2) {
      return `${val[0]},${val[1]}`;
    }
    if (val.length === 3) {
      return `${val[0]}${val[1]},${val[2]}`;
    }
    if (val.length === 4) {
      if (val === '1000') {
        return '100,0';
      }
      return `${val[0]}${val[1]},${val[2]}`;
    }
    return '100,0';
  }

  converterParaNumero = (valor) => parseFloat(valor.toString().replace(',', '.'));

  converterParaString = (valor) => parseFloat(valor).toFixed(1).toString().replace('.', ',')

  conteudoPagina() {
    return !this.state.anoLetivo || (this.props.match.params.idTurma !== undefined
      && !this.state.turmaSelecionada)
      ? <Card>
        <div align="center" style={{ margin: 50 }}>
          <Loader
            type="Oval"
            color="#34B5B8"
            height="50"
            width="50" />
        </div>
      </Card> : <Row>
        <Col md="12">
          <Alert
            color={this.state.alertColor}
            isOpen={this.state.showAlert}
            toggle={() => { this.setState({ showAlert: false }); }}>
            {this.state.alertMsg}
          </Alert>
          <Breadcrumb>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos`}>Anos Letivos</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.state.anoLetivo.id}`}>Ano letivo {this.state.anoLetivo.ano}</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.state.anoLetivo.id}/escola/${this.state.escola.id}`}>{this.state.escola.nome}</Link></BreadcrumbItem>
            <BreadcrumbItem active>{this.state.turmaSelecionada ? 'Edição turma' : 'Cadastro turma'}</BreadcrumbItem>
          </Breadcrumb>
          <Form onSubmit={this.onSubmit}>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Dados da turma multisseriada</CardTitle>
              </CardHeader>
              <CardBody>
                {this.state.turmaSelecionada
                  && <Row>
                    <Col md="2">
                      <FormGroup className="has-label">
                        <label>Situação</label>
                        <Input
                          disabled
                          type="text"
                          value={this.situacoes[this.state.turmaSelecionada.situacao].label}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                }
                <Row>
                  <Col md="4">
                    <FormGroup className="has-label">
                      <label>Escola</label>
                      <Input
                        disabled
                        type="text"
                        value={this.state.escola.nome}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup className={`has-label ${this.state.nomeState}`}>
                      <label>Nome da turma *</label>
                      <Input
                        value={this.state.nome}
                        type="text"
                        maxLength="200"
                        onChange={(event) => this.setState({ nome: event.target.value, nomeState: 'has-success' })}
                      />
                      {this.state.nomeState === 'has-danger' ? (
                        <label className="error">
                          Informe o nome da turma.
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md="2">
                    <FormGroup className={'has-label has-danger'}>
                      <label>Turno *</label>
                      <Select
                        noOptionsMessage={() => 'Nenhuma entrada'}
                        className={`react-select primary ${this.state.turnoState}`}
                        classNamePrefix="react-select"
                        value={this.state.turnoSelecionado}
                        onChange={(event) => {
                          this.setState({ turnoState: '', turnoSelecionado: event });
                        }}
                        options={this.turnos}
                        placeholder="Turno..."
                      />
                      {this.state.turnoState === 'danger' ? (
                        <label className="error">
                          Informe o turno da turma.
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md="2">
                    <FormGroup className={`has-label ${this.state.vagasState}`}>
                      <label>Número de vagas</label>
                      <Input
                        value={this.state.vagas}
                        decimalSeparator={false}
                        allowNegative={false}
                        onValueChange={(val) => {
                          this.setState({ vagas: val.formattedValue, vagasState: '' });
                        }}
                        tag={NumberFormat} />
                      {this.state.vagasState === 'has-danger' ? (
                        <label className="error">
                          Informe o turno da turma.
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>
                <br />
                <Tabs
                  selectedIndex={this.state.tabIndex}
                  onSelect={(tabIndex) => { this.setState({ tabIndex }); }}>
                  <TabList>
                    <Tab>Séries</Tab>
                    <Tab>Configurações gerais</Tab>
                  </TabList>
                  <TabPanel>
                    {
                      this.props.match.params.idTurma === undefined && <Row>
                        <Col>
                          <Button
                            onClick={() => this.adicionarSerie()}
                            color="primary">
                            Adicionar série
                          </Button>
                        </Col>
                      </Row>
                    }
                    {
                      this.state.series.map((serie, idx) => <div key={idx} className="bloco-tipo-avaliacao">
                        <Row>
                          <Col md="4">
                            <FormGroup className={'has-label has-danger'}>
                              <label>Curso *</label>
                              <Select
                                noOptionsMessage={() => 'Nenhuma entrada'}
                                className={`react-select primary ${serie.cursoState}`}
                                classNamePrefix="react-select"
                                value={serie.cursoSelecionado}
                                onChange={async (event) => {
                                  const descricoesAvaliacoes = [];

                                  let tipoAvaliacaoSelecionada = TipoAvaliacao.NOTA;
                          if (this.state.uf === 'RN') {
                            if (event.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value || (event.cicloEnsino === CicloEnsino.ENSINO_FUNDAMENTAL_1.value && event.serie <= Serie.ENSINO_FUNDAMENTAL_3.value)) {
                              tipoAvaliacaoSelecionada = TipoAvaliacao.RELATORIO;
                            }
                          } else { //this.state.uf === 'PB'
                            if (this.state.ente === 'Patos') {
                              if (event.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value) {
                                tipoAvaliacaoSelecionada = TipoAvaliacao.RELATORIO;
                              } 
                            } else if (this.state.ente === 'Belém do Brejo do Cruz') {
                              if (event.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value || (event.cicloEnsino === CicloEnsino.ENSINO_FUNDAMENTAL_1.value && event.serie <= Serie.ENSINO_FUNDAMENTAL_3.value)) {
                                tipoAvaliacaoSelecionada = TipoAvaliacao.RELATORIO;
                              } 
                            } else {
                              if (event.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value || (event.cicloEnsino === CicloEnsino.ENSINO_FUNDAMENTAL_1.value && event.serie <= Serie.ENSINO_FUNDAMENTAL_2.value)) {
                                tipoAvaliacaoSelecionada = TipoAvaliacao.RELATORIO;
                              }
                            }
                          }

                                  if (tipoAvaliacaoSelecionada.value === TipoAvaliacao.RELATORIO.value) {
                                    if(this.state.uf !== 'PB') {
                                      descricoesAvaliacoes.push({ nome: '1º semestre' });
                                      descricoesAvaliacoes.push({ nome: '2º semestre' });
                                    } else {
                                      descricoesAvaliacoes.push({ nome: '1º bimestre' });
                                      descricoesAvaliacoes.push({ nome: '2º bimestre' });
                                      descricoesAvaliacoes.push({ nome: '3º bimestre' });
                                      descricoesAvaliacoes.push({ nome: '4º bimestre' });
                                    }
                                  } else if (event.cicloEnsino !== CicloEnsino.EJA.value
                                      || event.cicloEnsino !== CicloEnsino.CORRECAO_FLUXO.value) {
                                    descricoesAvaliacoes.push({ nome: '1º bimestre', peso: '1,0', recuperacao: false });
                                    descricoesAvaliacoes.push({ nome: '2º bimestre', peso: '1,0', recuperacao: false });
                                    descricoesAvaliacoes.push({ nome: '3º bimestre', peso: '1,0', recuperacao: false });
                                    descricoesAvaliacoes.push({ nome: '4º bimestre', peso: '1,0', recuperacao: false });
                                  } else {
                                    descricoesAvaliacoes.push({ nome: '1º bimestre', peso: '1,0', recuperacao: false });
                                    descricoesAvaliacoes.push({ nome: '2º bimestre', peso: '1,0', recuperacao: false });
                                  }

                                  await this.carregarEstruturasCurriculares(serie, event.value);
                                  Object.assign(serie, {
                                    cursoState: 'primary',
                                    cursoSelecionado: event,
                                    estruturaCurricularSelecionada: '',

                                    tipoAvaliacaoState: '',
                                    mediaAprovacaoState: 'primary',
                                    mediaReprovacaoAntesRecuperacaoState: 'primary',
                                    pesoRecuperacaoState: 'primary',
                                    mediaAprovacaoAposRecuperacaoState: 'primary',
                                    frequenciaMinimaState: 'primary',

                                    tipoAvaliacaoSelecionada,
                                    mediaAprovacao: this.state.mediaAprovacao,
                                    mediaReprovacaoAntesRecuperacao: this.state.mediaReprovacaoAntesRecuperacao,
                                    temRecuperacao: tipoAvaliacaoSelecionada.value === TipoAvaliacao.NOTA.value,
                                    pesoRecuperacao: this.state.pesoRecuperacao,
                                    pesoMediaAvaliacoes: this.state.pesoMediaAvaliacoes,
                                    mediaAprovacaoAposRecuperacao: this.state.mediaAprovacaoAposRecuperacao,
                                    frequenciaMinima: event.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value ? '60,0' : '75,0',

                                    descricoesAvaliacoes,
                                    collapsedAvaliacoes: false,
                                  });
                                  this.forceUpdate();
                                }}
                                isDisabled={this.props.match.params.idTurma !== undefined}
                                options={this.state.cursos}
                                placeholder="Curso..."
                              />
                              {serie.cursoState === 'danger' ? (
                                <label className="error">
                                  {serie.cursoErroMsg}
                                </label>
                              ) : null}
                            </FormGroup>
                          </Col>
                          <Col md="2">
                            <FormGroup className={'has-label has-danger'}>
                              <label>Tipo da avaliação *</label>
                              <Select
                                noOptionsMessage={() => 'Nenhuma entrada'}
                                className={`react-select primary ${serie.tipoAvaliacaoState}`}
                                classNamePrefix="react-select"
                                value={serie.tipoAvaliacaoSelecionada}
                                onChange={(event) => {
                                  const descricoesAvaliacoes = [];

                                  if (event.value === TipoAvaliacao.RELATORIO.value) {
                                    if(this.state.uf !== 'PB') {
                                      descricoesAvaliacoes.push({ nome: '1º semestre' });
                                      descricoesAvaliacoes.push({ nome: '2º semestre' });
                                    } else {
                                      descricoesAvaliacoes.push({ nome: '1º bimestre' });
                                      descricoesAvaliacoes.push({ nome: '2º bimestre' });
                                      descricoesAvaliacoes.push({ nome: '3º bimestre' });
                                      descricoesAvaliacoes.push({ nome: '4º bimestre' });
                                    }
                                  } else if (serie.cursoSelecionado.cicloEnsino !== CicloEnsino.EJA.value
                                      || serie.cursoSelecionado.cicloEnsino !== CicloEnsino.CORRECAO_FLUXO.value) {
                                    descricoesAvaliacoes.push({ nome: '1º bimestre', peso: '1,0', recuperacao: false });
                                    descricoesAvaliacoes.push({ nome: '2º bimestre', peso: '1,0', recuperacao: false });
                                    descricoesAvaliacoes.push({ nome: '3º bimestre', peso: '1,0', recuperacao: false });
                                    descricoesAvaliacoes.push({ nome: '4º bimestre', peso: '1,0', recuperacao: false });
                                  } else {
                                    descricoesAvaliacoes.push({ nome: '1º bimestre', peso: '1,0', recuperacao: false });
                                    descricoesAvaliacoes.push({ nome: '2º bimestre', peso: '1,0', recuperacao: false });
                                  }

                                  Object.assign(serie, {
                                    tipoAvaliacaoState: '',
                                    mediaAprovacaoState: 'primary',
                                    mediaReprovacaoAntesRecuperacaoState: 'primary',
                                    pesoRecuperacaoState: 'primary',
                                    mediaAprovacaoAposRecuperacaoState: 'primary',

                                    tipoAvaliacaoSelecionada: event,
                                    mediaAprovacao: this.state.mediaAprovacao,
                                    mediaReprovacaoAntesRecuperacao: this.state.mediaReprovacaoAntesRecuperacao,
                                    temRecuperacao: event.value === TipoAvaliacao.NOTA.value,
                                    pesoRecuperacao: this.state.pesoRecuperacao,
                                    pesoMediaAvaliacoes: this.state.pesoMediaAvaliacoes,
                                    mediaAprovacaoAposRecuperacao: this.state.mediaAprovacaoAposRecuperacao,

                                    descricoesAvaliacoes,
                                    collapsedAvaliacoes: false,
                                  });

                                  this.forceUpdate();
                                }}
                                isDisabled={!serie.cursoSelecionado
                                  || this.props.match.params.idTurma !== undefined}
                                options={this.tiposAvaliacao}
                                placeholder="Tipo da avaliação..."
                              />
                              {serie.tipoAvaliacaoState === 'danger' ? (
                                <label className="error">
                                  Informe o tipo da avaliação
                                </label>
                              ) : null}
                            </FormGroup>
                          </Col>
                          <Col md="3">
                            <FormGroup className={'has-label has-danger'}>
                              <label>Estrutura curricular *</label>
                              <Select
                                noOptionsMessage={() => 'Nenhuma entrada'}
                                className={`react-select primary ${serie.estruturaCurricularState}`}
                                classNamePrefix="react-select"
                                isDisabled={serie.estruturasCurriculares.length === 0
                                  || this.props.match.params.idTurma !== undefined}
                                value={serie.estruturaCurricularSelecionada}
                                onChange={(event) => {
                                  Object.assign(serie, {
                                    estruturaCurricularState: '',
                                    estruturaCurricularSelecionada: event,
                                  });
                                  this.forceUpdate();
                                }}
                                options={serie.estruturasCurriculares}
                                placeholder="Estrutura curricular..."
                              />
                              {serie.estruturaCurricularState === 'danger' ? (
                                <label className="error">
                                  Informe a estrutura curricular da turma.
                                </label>
                              ) : null}
                            </FormGroup>
                          </Col>
                        </Row>
                        {
                          serie.tipoAvaliacaoSelecionada && <div
                            aria-multiselectable={true}
                            className="card-collapse"
                            id="accordion"
                            role="tablist"
                          >
                            <Card className="card-plain">
                              <CardHeader role="tab">
                                <h5
                                  aria-expanded={serie.collapsedAvaliacoes}
                                  data-parent="#accordion"
                                  data-toggle="collapse"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    Object.assign(serie, {
                                      collapsedAvaliacoes: !serie.collapsedAvaliacoes,
                                    });
                                    this.forceUpdate();
                                  }}
                                >
                                  Configuração de {serie.tipoAvaliacaoSelecionada.value
                                    === TipoAvaliacao.RELATORIO.value ? 'relatórios dos alunos' : 'provas'}
                                  <i className="nc-icon nc-minimal-down" />
                                </h5>
                              </CardHeader>
                              <Collapse
                                role="tabpanel"
                                isOpen={serie.collapsedAvaliacoes}
                              >
                                <CardBody>
                                  {
                                    serie.descricoesAvaliacoes.map(
                                      (descricaoAvaliacao, idx2) => <Row key={idx2}>
                                        <Col md="3">
                                          <FormGroup className={`has-label ${descricaoAvaliacao.nomeState}`}>
                                            <label>Nome * </label>
                                            <Input
                                              type="text"
                                              value={descricaoAvaliacao.nome}
                                              disabled={this.props.match.params.idTurma !== undefined}
                                              onChange={(event) => {
                                                Object.assign(descricaoAvaliacao,
                                                  {
                                                    nome: event.target.value,
                                                    nomeState: 'primary',
                                                  });
                                                this.forceUpdate();
                                              }}
                                            />
                                            {descricaoAvaliacao.nomeState === 'has-danger' ? (
                                              <label className="error">
                                                Informe o nome da avaliação.
                                              </label>
                                            ) : null}
                                          </FormGroup>
                                        </Col>
                                        {
                                          serie.tipoAvaliacaoSelecionada.value
                                          === TipoAvaliacao.NOTA.value && <Col md="1">
                                            <FormGroup className={`has-label ${descricaoAvaliacao.pesoState}`}>
                                              <label>Peso *</label>
                                              <Input
                                                value={descricaoAvaliacao.peso}
                                                disabled={this.props.match.params.idTurma !== undefined}
                                                style={{ textAlign: 'right' }}
                                                decimalSeparator=","
                                                decimalScale={1}
                                                format={this.formatarNumero}
                                                onValueChange={(val) => {
                                                  Object.assign(descricaoAvaliacao,
                                                    {
                                                      peso: val.formattedValue,
                                                      pesoState: 'primary',
                                                    });
                                                  this.forceUpdate();
                                                }}
                                                onBlur={() => {
                                                  if (descricaoAvaliacao.peso
                                                    && descricaoAvaliacao.peso.length === 1) {
                                                    Object.assign(descricaoAvaliacao,
                                                      {
                                                        peso: `${descricaoAvaliacao.peso},0`,
                                                        pesoState: 'primary',
                                                      });
                                                    this.forceUpdate();
                                                  }
                                                }}
                                                tag={NumberFormat}
                                              />
                                              {descricaoAvaliacao.pesoState === 'has-danger' ? (
                                                <label className="error">
                                                  Informe o peso da avaliação.
                                                </label>
                                              ) : null}
                                            </FormGroup>
                                          </Col>
                                        }
                                        {this.props.match.params.idTurma === undefined
                                          && <Col md="1">
                                            <br />
                                            <>
                                              <Button
                                                id={`btnRemoverAvaliacao${idx2}`}
                                                className="btn-icon"
                                                color="danger"
                                                size="sm"
                                                onClick={() => {
                                                  this.removerDescricaoAvaliacao(serie, descricaoAvaliacao);
                                                }}
                                                type="button">
                                                <i className="fa fa-times" />
                                              </Button>
                                              <UncontrolledTooltip placement="bottom" target={`btnRemoverAvaliacao${idx2}`} delay={0}>
                                                Remover
                                              </UncontrolledTooltip>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                              {idx2 === serie.descricoesAvaliacoes.length - 1
                                                && <>
                                                  <Button
                                                    id="adicionarAvaliacaoBtn"
                                                    className="btn-icon"
                                                    color="success"
                                                    size="sm"
                                                    onClick={() => {
                                                      serie.descricoesAvaliacoes.push({
                                                        nome: '',
                                                        peso: serie.tipoAvaliacaoSelecionada.value
                                                          === TipoAvaliacao.RELATORIO.value ? undefined : '',
                                                        recuperacao: serie.tipoAvaliacaoSelecionada.value
                                                          === TipoAvaliacao.RELATORIO.value
                                                          ? undefined : false,
                                                      });
                                                      this.forceUpdate();
                                                    }}>
                                                    <i className="fa fa-plus-circle" />
                                                  </Button>
                                                  <UncontrolledTooltip placement="bottom" target="adicionarAvaliacaoBtn" delay={0}>
                                                    Adicionar {serie.tipoAvaliacaoSelecionada.value
                                                      === TipoAvaliacao.RELATORIO.value ? 'relatório' : 'prova'}
                                                  </UncontrolledTooltip>
                                                </>
                                              }
                                            </>
                                          </Col>
                                        }
                                      </Row>,
                                    )
                                  }
                                  {serie.tipoAvaliacaoSelecionada.value === TipoAvaliacao.NOTA.value
                                    && <Row>
                                      <Col md="3">
                                        <FormGroup className={`has-label ${serie.mediaAprovacaoState}`}>
                                          <label>Média de aprovação * </label>
                                          <Input
                                            value={serie.mediaAprovacao}
                                            disabled={this.props.match.params.idTurma !== undefined}
                                            style={{ textAlign: 'right' }}
                                            decimalSeparator=","
                                            decimalScale={1}
                                            format={this.formatarNumero}
                                            onValueChange={(val) => {
                                              Object.assign(serie, {
                                                mediaAprovacao: val.formattedValue,
                                                mediaAprovacaoState: 'primary',
                                              });
                                              this.forceUpdate();
                                            }}
                                            onBlur={() => {
                                              if (serie.mediaAprovacao
                                                && serie.mediaAprovacao.length === 1) {
                                                Object.assign(serie, {
                                                  mediaAprovacao: `${serie.mediaAprovacao},0`,
                                                });
                                                this.forceUpdate();
                                              }
                                            }}
                                            tag={NumberFormat}
                                          />
                                          {serie.mediaAprovacaoState === 'has-danger' ? (
                                            <label className="error">
                                              Informe a média de aprovação.
                                            </label>
                                          ) : null}
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                  }
                                  {serie.tipoAvaliacaoSelecionada.value === TipoAvaliacao.NOTA.value
                                    && <>
                                      < Row >
                                        <Col md="4">
                                          <FormGroup check>
                                            <Label check>
                                              <Input type="checkbox"
                                                disabled={this.props.match.params.idTurma !== undefined}
                                                defaultChecked={serie.temRecuperacao}
                                                onClick={(evt) => {
                                                  Object.assign(serie, {
                                                    temRecuperacao: evt.target.checked,
                                                  });
                                                  this.forceUpdate();
                                                }} />{' '}
                                              Tem prova final
                                              <span className="form-check-sign">
                                                <span className="check"></span>
                                              </span>
                                            </Label>
                                          </FormGroup>
                                        </Col>
                                      </Row>
                                      {serie.temRecuperacao
                                        && <>
                                          <Row>
                                            <Col md="3">
                                              <FormGroup className={`has-label ${serie.mediaReprovacaoAntesRecuperacaoState}`}>
                                                <label>Média de reprovação antes da prova final * </label>
                                                <Input
                                                  value={serie.mediaReprovacaoAntesRecuperacao}
                                                  disabled={this.props.match.params.idTurma !== undefined}
                                                  style={{ textAlign: 'right' }}
                                                  decimalSeparator=","
                                                  decimalScale={1}
                                                  format={this.formatarNumero}
                                                  onValueChange={(val) => {
                                                    Object.assign(serie, {
                                                      mediaReprovacaoAntesRecuperacao: val.formattedValue,
                                                      mediaReprovacaoAntesRecuperacaoState: 'primary',
                                                    });
                                                    this.forceUpdate();
                                                  }}
                                                  onBlur={() => {
                                                    if (serie.mediaReprovacaoAntesRecuperacao
                                                      && serie.mediaReprovacaoAntesRecuperacao.length === 1) {
                                                      Object.assign(serie, {
                                                        mediaReprovacaoAntesRecuperacao: `${serie.mediaReprovacaoAntesRecuperacao},0`,
                                                      });
                                                      this.forceUpdate();
                                                    }
                                                  }}
                                                  tag={NumberFormat}
                                                />
                                                {serie.mediaReprovacaoAntesRecuperacaoState === 'has-danger' ? (
                                                  <label className="error">
                                                    Informe a média de reprovação antes
                                                    da prova final.
                                                  </label>
                                                ) : null}
                                              </FormGroup>
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col md="3">
                                              <FormGroup className={`has-label ${serie.pesoRecuperacaoState}`}>
                                                <label>Peso da prova final *</label>
                                                <Input
                                                  value={serie.pesoRecuperacao}
                                                  disabled={this.props.match.params.idTurma !== undefined}
                                                  style={{ textAlign: 'right' }}
                                                  decimalSeparator=","
                                                  decimalScale={1}
                                                  format={this.formatarNumero}
                                                  onValueChange={(val) => {
                                                    Object.assign(serie, {
                                                      pesoRecuperacao: val.formattedValue,
                                                      pesoRecuperacaoState: 'primary',
                                                    });
                                                    this.forceUpdate();
                                                  }}
                                                  onBlur={() => {
                                                    if (serie.pesoRecuperacao
                                                      && serie.pesoRecuperacao.length
                                                      === 1) {
                                                      Object.assign(serie, {
                                                        pesoRecuperacao: `${serie.pesoRecuperacao},0`,
                                                      });
                                                      this.forceUpdate();
                                                    }
                                                  }}
                                                  tag={NumberFormat}
                                                />
                                                {serie.pesoRecuperacaoState === 'has-danger' ? (
                                                  <label className="error">
                                                    Informe o peso da prova final.
                                                  </label>
                                                ) : null}
                                              </FormGroup>
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col md="3">
                                              <FormGroup className={`has-label ${serie.pesoMediaAvaliacoesState}`}>
                                                <label>Peso da média das avaliações
                                                  anteriores à prova final *</label>
                                                <Input
                                                  value={serie.pesoMediaAvaliacoes}
                                                  disabled={this.props.match.params.idTurma !== undefined}
                                                  style={{ textAlign: 'right' }}
                                                  decimalSeparator=","
                                                  decimalScale={1}
                                                  format={this.formatarNumero}
                                                  onValueChange={(val) => {
                                                    Object.assign(serie, {
                                                      pesoMediaAvaliacoes: val.formattedValue,
                                                      pesoMediaAvaliacoesState: 'primary',
                                                    });
                                                    this.forceUpdate();
                                                  }}
                                                  onBlur={() => {
                                                    if (serie.pesoMediaAvaliacoes
                                                      && serie.pesoMediaAvaliacoes.length === 1) {
                                                      Object.assign(serie, {
                                                        pesoMediaAvaliacoes: `${serie.pesoMediaAvaliacoes},0`,
                                                      });
                                                      this.forceUpdate();
                                                    }
                                                  }}
                                                  tag={NumberFormat}
                                                />
                                                {serie.pesoMediaAvaliacoesState === 'has-danger' ? (
                                                  <label className="error">
                                                    Informe o peso da média das avaliações
                                                    anteriores à prova final.
                                                  </label>
                                                ) : null}
                                              </FormGroup>
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col md="3">
                                              <FormGroup className={`has-label ${serie.mediaAprovacaoAposRecuperacaoState}`}>
                                                <label>Média de aprovação após prova final * </label>
                                                <Input
                                                  value={serie.mediaAprovacaoAposRecuperacao}
                                                  disabled={this.props.match.params.idTurma !== undefined}
                                                  style={{ textAlign: 'right' }}
                                                  decimalSeparator=","
                                                  decimalScale={1}
                                                  format={this.formatarNumero}
                                                  onValueChange={(val) => {
                                                    Object.assign(serie, {
                                                      mediaAprovacaoAposRecuperacao: val.formattedValue,
                                                      mediaAprovacaoAposRecuperacaoState: 'primary',
                                                    });
                                                    this.forceUpdate();
                                                  }}
                                                  onBlur={() => {
                                                    if (serie.mediaAprovacaoAposRecuperacao
                                                      && serie.mediaAprovacaoAposRecuperacao.length
                                                      === 1) {
                                                      Object.assign(serie, {
                                                        mediaAprovacaoAposRecuperacao: `${serie.mediaAprovacaoAposRecuperacao},0`,
                                                      });
                                                      this.forceUpdate();
                                                    }
                                                  }}
                                                  tag={NumberFormat}
                                                />
                                                {serie.mediaAprovacaoAposRecuperacaoState === 'has-danger' ? (
                                                  <label className="error">
                                                    Informe a média de aprovação após a prova final.
                                                  </label>
                                                ) : null}
                                              </FormGroup>
                                            </Col>
                                          </Row>
                                        </>
                                      }
                                    </>
                                  }
                                </CardBody>
                              </Collapse>
                            </Card>
                          </div>
                        }
                        {
                          serie.tipoAvaliacaoSelecionada && <div
                            aria-multiselectable={true}
                            className="card-collapse"
                            id="accordion"
                            role="tablist"
                          >
                            <Card className="card-plain">
                              <CardHeader role="tab">
                                <h5
                                  aria-expanded={serie.collapsedFrequencia}
                                  data-parent="#accordion"
                                  data-toggle="collapse"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    Object.assign(serie, {
                                      collapsedFrequencia: !serie.collapsedFrequencia,
                                    });
                                    this.forceUpdate();
                                  }}
                                >
                                  Configuração de frequência
                                  <i className="nc-icon nc-minimal-down" />
                                </h5>
                              </CardHeader>
                              <Collapse
                                role="tabpanel"
                                isOpen={serie.collapsedFrequencia}
                              >
                                <CardBody>
                                  <Row>
                                    <Col md="3">
                                      <FormGroup className={`has-label ${serie.frequenciaMinimaState}`}>
                                        <label>Frequência mínima para aprovação (%) * </label>
                                        <Input
                                          value={serie.frequenciaMinima}
                                          style={{ textAlign: 'right' }}
                                          decimalSeparator=","
                                          decimalScale={1}
                                          format={this.formatarNumero2}
                                          onValueChange={(val) => {
                                            Object.assign(serie, {
                                              frequenciaMinima: val.formattedValue,
                                              frequenciaMinimaState: 'primary',
                                            });
                                            this.forceUpdate();
                                          }}
                                          onBlur={() => {
                                            if (serie.seriefrequenciaMinima
                                              && serie.frequenciaMinima.length === 1) {
                                              this.setState({
                                                frequenciaMinima: `${serie.frequenciaMinima},0`,
                                              });
                                            }
                                          }}
                                          tag={NumberFormat}
                                        />
                                        {serie.frequenciaMinimaState === 'has-danger' ? (
                                          <label className="error">
                                            Informe o percentual de frequência mínima para aprovação.
                                          </label>
                                        ) : null}
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                </CardBody>
                              </Collapse>
                            </Card>
                          </div>
                        }
                      </div>)
                    }
                  </TabPanel>
                  <TabPanel>
                    <div className="bloco-tipo-avaliacao">
                      <label>Configurações das séries</label>
                      <div
                        aria-multiselectable={true}
                        className="card-collapse"
                        id="accordion"
                        role="tablist"
                      >
                        <Card className="card-plain">
                          <CardHeader>
                            <h5
                              aria-expanded={this.state.collapsedConteudos}
                              data-parent="#accordion"
                              data-toggle="collapse"
                              onClick={(e) => {
                                e.preventDefault();
                                this.setState({
                                  collapsedConteudos: !this.state.collapsedConteudos,
                                });
                              }}
                            >
                              Configuração de conteúdos lecionados
                              <i className="nc-icon nc-minimal-down" />
                            </h5>
                          </CardHeader>
                          <Collapse
                            role="tabpanel"
                            isOpen={this.state.collapsedConteudos}
                          >
                            <CardBody>
                              {
                                this.state.descricoesConteudos.map(
                                  (descricaoConteudo, idx2) => <Row key={idx2}>
                                    <Col md="3">
                                      <FormGroup className={`has-label ${descricaoConteudo.nomeState}`}>
                                        <label>Período * </label>
                                        <Input
                                          type="text"
                                          value={descricaoConteudo.nome}
                                          disabled={this.props.match.params.idTurma !== undefined}
                                          onChange={(event) => {
                                            Object.assign(descricaoConteudo,
                                              {
                                                nome: event.target.value,
                                                nomeState: 'primary',
                                              });
                                            this.forceUpdate();
                                          }}
                                        />
                                        {descricaoConteudo.nomeState === 'has-danger' ? (
                                          <label className="error">
                                            Informe o período do conteúdo lecionado.
                                          </label>
                                        ) : null}
                                      </FormGroup>
                                    </Col>
                                    {this.props.match.params.idTurma === undefined
                                      && <Col md="1">
                                        <br />
                                        <>
                                          <Button
                                            id={`btnRemoverConteudo${idx2}`}
                                            className="btn-icon"
                                            color="danger"
                                            size="sm"
                                            onClick={() => {
                                              this.removerDescricaoConteudo(descricaoConteudo);
                                            }}
                                            type="button">
                                            <i className="fa fa-times" />
                                          </Button>
                                          <UncontrolledTooltip placement="bottom" target={`btnRemoverConteudo${idx2}`} delay={0}>
                                            Remover
                                          </UncontrolledTooltip>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          {
                                            idx2 === this.state.descricoesConteudos.length - 1
                                            && <>
                                              <Button
                                                id="adicionarConteudoBtn"
                                                className="btn-icon"
                                                color="success"
                                                size="sm"
                                                onClick={() => {
                                                  this.state.descricoesConteudos.push({
                                                    nome: '',
                                                  });
                                                  this.forceUpdate();
                                                }}>
                                                <i className="fa fa-plus-circle" />
                                              </Button>
                                              <UncontrolledTooltip placement="bottom" target="adicionarConteudoBtn" delay={0}>
                                                Adicionar conteúdo lecionado
                                              </UncontrolledTooltip>
                                            </>
                                          }
                                        </>
                                      </Col>
                                    }
                                  </Row>,
                                )
                              }
                            </CardBody>
                          </Collapse>
                        </Card>
                      </div>
                      <div
                        aria-multiselectable={true}
                        className="card-collapse"
                        id="accordion"
                        role="tablist"
                      >
                        <Card className="card-plain">
                          <CardHeader>
                            <h5
                              aria-expanded={this.state.collapsedRelatorios}
                              data-parent="#accordion"
                              data-toggle="collapse"
                              onClick={(e) => {
                                e.preventDefault();
                                this.setState({
                                  collapsedRelatorios: !this.state.collapsedRelatorios,
                                });
                              }}
                            >
                              Configuração de relatórios da turma
                              <i className="nc-icon nc-minimal-down" />
                            </h5>
                          </CardHeader>
                          <Collapse
                            role="tabpanel"
                            isOpen={this.state.collapsedRelatorios}
                          >
                            <CardBody>
                              {
                                this.state.descricoesRelatorios.map(
                                  (descricaoRelatorio, idx2) => <Row key={idx2}>
                                    <Col md="3">
                                      <FormGroup className={`has-label ${descricaoRelatorio.nomeState}`}>
                                        <label>Nome * </label>
                                        <Input
                                          type="text"
                                          value={descricaoRelatorio.nome}
                                          disabled={this.props.match.params.idTurma !== undefined}
                                          onChange={(event) => {
                                            Object.assign(descricaoRelatorio,
                                              {
                                                nome: event.target.value,
                                                nomeState: 'primary',
                                              });
                                            this.forceUpdate();
                                          }}
                                        />
                                        {descricaoRelatorio.nomeState === 'has-danger' ? (
                                          <label className="error">
                                            Informe o nome do relatório.
                                          </label>
                                        ) : null}
                                      </FormGroup>
                                    </Col>
                                    {this.props.match.params.idTurma === undefined
                                      && <Col md="1">
                                        <br />
                                        <>
                                          <Button
                                            id={`btnRemoverRelatorio${idx2}`}
                                            className="btn-icon"
                                            color="danger"
                                            size="sm"
                                            onClick={() => {
                                              this.removerDescricaoRelatorio(descricaoRelatorio);
                                            }}
                                            type="button">
                                            <i className="fa fa-times" />
                                          </Button>
                                          <UncontrolledTooltip placement="bottom" target={`btnRemoverRelatorio${idx2}`} delay={0}>
                                            Remover
                                          </UncontrolledTooltip>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          {
                                            idx2 === this.state.descricoesRelatorios.length - 1
                                            && <>
                                              <Button
                                                id="adicionarRelatorioBtn"
                                                className="btn-icon"
                                                color="success"
                                                size="sm"
                                                onClick={() => {
                                                  this.state.descricoesRelatorios.push({
                                                    nome: '',
                                                  });
                                                  this.forceUpdate();
                                                }}>
                                                <i className="fa fa-plus-circle" />
                                              </Button>
                                              <UncontrolledTooltip placement="bottom" target="adicionarRelatorioBtn" delay={0}>
                                                Adicionar relatório
                                              </UncontrolledTooltip>
                                            </>
                                          }
                                        </>
                                      </Col>
                                    }
                                  </Row>,
                                )
                              }
                              <Row>
                              </Row>
                            </CardBody>
                          </Collapse>
                        </Card>
                      </div>
                    </div>
                  </TabPanel>
                </Tabs>
                <div className="category form-category">
                  * Campos obrigatórios
                </div>
              </CardBody>
              <CardFooter>
                <Row>
                  <Col md="6" className="text-left">
                    <Button
                      color="primary" disabled={this.state.buttonDisabled}
                      onClick={() => this.props.history.push(`${this.props.layout}/anosletivos/${this.state.anoLetivo.id}/escola/${this.state.escola.id}`)} >
                      Voltar
                    </Button>
                  </Col>
                  <Col md="6" className="text-right">
                    {
                      !this.state.turmaSelecionada
                        ? <Button type="submit" color="primary" disabled={this.state.buttonDisabled}>
                          Cadastrar
                        </Button>
                        : <Button type="submit" style={{ marginRight: 5 }} color="primary" disabled={this.state.buttonDisabled}>
                          Atualizar
                        </Button>
                    }
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </Form>
        </Col>
      </Row >;
  }

  render() {
    return (
      <div className="content">
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações do turma
            </div>
          </Card>
        }
        <LoaderModal
          isOpen={this.state.buttonDisabled}
          text={this.state.loaderModalText} />
      </div>
    );
  }
}

export default Turmas;
