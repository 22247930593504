import React from 'react';
import { Link } from 'react-router-dom';
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Input,
  Form,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap';

import {
  Tabs,
  TabList,
  Tab,
  TabPanel,
} from 'react-tabs';

import Loader from 'react-loader-spinner';
import Select from 'react-select';

import Alert from 'views/components/Alert';
import EscolaDiretores from 'views/components/EscolaDiretores';
import EscolaSecretarios from 'views/components/EscolaSecretarios';
import EscolaCoordenadores from 'views/components/EscolaCoordenadores';
import EscolaFuncionarios from 'views/components/EscolaFuncionarios';
import EscolaAlunos from 'views/components/EscolaAlunos';
import EscolaTransferencias from 'views/components/EscolaTransferencias';
import EscolaMerenda from 'views/components/EscolaMerenda';
import EscolaEstoques from 'views/components/EscolaEstoques';
import EscolaCardapio from 'views/components/EscolaCardapio';

import SituacaoEscola from 'assets/csv/situacoes-escola.json';
import ZonaEscola from 'assets/csv/zonas-escola.json';
import CicloEnsino from 'assets/csv/ciclos-ensino.json';
import Turno from 'assets/csv/turnos.json';

import EscolasService from 'services/EscolasService';
import UsuarioService from 'services/UsuarioService';
import LoginService from 'services/LoginService';
import EntesService from 'services/EntesService';

import Papel from 'assets/csv/papeis.json';

class Escola extends React.Component {
  constructor(props) {
    super(props);

    this.situacoes = [
      SituacaoEscola.ATIVA,
      SituacaoEscola.INATIVA,
    ];

    this.zonas = [
      ZonaEscola.URBANA,
      ZonaEscola.RURAL,
    ];

    this.selectCiclosEnsinoEscola = [
      CicloEnsino.ENSINO_INFANTIL,
      CicloEnsino.ENSINO_FUNDAMENTAL_1,
      CicloEnsino.ENSINO_FUNDAMENTAL_2,
      CicloEnsino.ENSINO_MEDIO,
      CicloEnsino.ENSINO_TECNICO,
      CicloEnsino.EJA,
      CicloEnsino.OUTRO,
    ];

    this.selectTurnosEscola = [
      Turno.MATUTINO,
      Turno.VESPERTINO,
      Turno.NOTURNO,
      Turno.INTEGRAL,
    ];

    this.escolasService = new EscolasService();
    this.usuarioService = new UsuarioService();
    this.loginService = new LoginService();
    this.entesService = new EntesService();

    this.state = {
      showAlert: false,
      showLoaderModal: false,
      showConfirmModal: false,

      loaderModalText: '',

      ciclosEnsinoEscola: [],
      turnosEscola: [],

      escola: null,
      erro: null,
    };
  }

  componentWillUnmount() {
    this.loginService.abortPedingRequests();
    this.escolasService.abortPedingRequests();
    this.usuarioService.abortPedingRequests();

    this.umounted = true;
  }

  async componentDidMount() {
    await this.loginService.verificarPapel(this.props.role);

    try {
      const dadosEnte = await this.entesService.carregarDadosEnte();

      const escola = await this.escolasService
        .carregarEscola(this.props.match.params.idEscola);

      const ciclosEnsinoEscola = await this.escolasService
        .carregarCiclosEnsino(this.props.match.params.idEscola)

      const turnosEscola = await this.escolasService
        .carregarTurnos(this.props.match.params.idEscola)

      this.setState({ escola, ciclosEnsinoEscola, turnosEscola, dadosEnte });
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  imprimirHistoricoAluno() {
    this.props.history.push(`${this.props.layout}`
      + `/escolas/${this.props.match.params.idEscola}`
      + '/historicos');
  }

  imprimirDeclaracaoMatricula() {
    this.props.history.push(`${this.props.layout}`
      + `/escolas/${this.props.match.params.idEscola}`
      + '/declaracao/matricula');
  }

  imprimirDeclaracaoConclusao() {
    this.props.history.push(`${this.props.layout}`
      + `/escolas/${this.props.match.params.idEscola}`
      + '/declaracao/conclusao');
  }

  imprimirDeclaracaoFrequencia() {
    this.props.history.push(`${this.props.layout}`
      + `/escolas/${this.props.match.params.idEscola}`
      + '/declaracao/frequencia/mensal');
  }

  imprimirDeclaracaoTransferencia() {
    this.props.history.push(`${this.props.layout}`
      + `/escolas/${this.props.match.params.idEscola}`
      + '/declaracao/transferencia');
  }

  imprimirBoletimAluno() {
    this.props.history.push(`${this.props.layout}`
      + `/escolas/${this.props.match.params.idEscola}`
      + '/boletim/aluno');
  }

  imprimirFichaMatricula() {
    this.props.history.push(`${this.props.layout}`
      + `/escolas/${this.props.match.params.idEscola}`
      + '/ficha/matricula');
  }

  imprimirFichaIndividual() {
    this.props.history.push(`${this.props.layout}`
      + `/escolas/${this.props.match.params.idEscola}`
      + '/ficha/individual');
  }

  conteudoPagina() {
    return (this.props.match.params.idEscola !== undefined
      && this.state.escola === null)
      ? <Card>
        <div align="center" style={{ margin: 50 }}>
          <Loader
            type="Oval"
            color="#34B5B8"
            height="50"
            width="50" />
        </div>
      </Card> : <Row>
        <Col md="12">
          <Alert
            color={this.state.alertColor}
            isOpen={this.state.showAlert}
            toggle={() => { this.setState({ showAlert: false }); }}>
            {this.state.alertMsg}
          </Alert>
          <Breadcrumb>
            <BreadcrumbItem><Link to={`${this.props.layout}/escolas`}>Escolas</Link></BreadcrumbItem>
            <BreadcrumbItem active>{this.state.escola ? this.state.escola.nome : 'Cadastro'}</BreadcrumbItem>
          </Breadcrumb>
          <Card>
            <CardBody>
              <Tabs>
                <TabList>
                  <Tab>Dados</Tab>
                  {this.props.role === Papel.GESTOR.value && <Tab>Diretores</Tab>}
                  <Tab>Secretários</Tab>
                  <Tab>Coordenadores</Tab>
                  <Tab>Funcionários</Tab>
                  {this.state.dadosEnte.alunosPorEscola && <Tab>Alunos</Tab>}
                  {this.state.dadosEnte.alunosPorEscola && <Tab>Transferências</Tab>}
                  {!this.state.dadosEnte.controleEstoque && <Tab>Merenda</Tab>}
                  {this.state.dadosEnte.controleEstoque && <Tab>Estoques</Tab>}
                  <Tab>Cardápio</Tab>
                </TabList>
                <TabPanel>
                  <br />
                  <Form>
                    <Row>
                      <Col md="5">
                        <FormGroup className={'has-label'}>
                          <label>Nome</label>
                          <Input
                            disabled
                            value={this.state.escola.nome}
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col md="2">
                        <FormGroup className={'has-label'}>
                          <label>Código INEP</label>
                          <Input
                            disabled
                            value={this.state.escola.codigoINEP}
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col md="3">
                        <FormGroup className={'has-label'}>
                          <label>CNPJ</label>
                          <Input
                            disabled
                            value={this.state.escola.cnpj}
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col md="2">
                        <FormGroup className={'has-label'}>
                          <label>Situação</label>
                          <Input
                            disabled
                            value={this.situacoes[this.state.escola.situacao].label}
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <FormGroup className={'has-label'}>
                          <label>Endereço</label>
                          <Input
                            disabled
                            value={this.state.escola.endereco}
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col md="3">
                        <FormGroup className={'has-label'}>
                          <label>Email</label>
                          <Input
                            disabled
                            value={this.state.escola.email}
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col md="3">
                        <FormGroup className={'has-label'}>
                          <label>Zona</label>
                          <Input
                            disabled
                            value={this.zonas[this.state.escola.zona].label}
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="3">
                        <FormGroup className={'has-label'}>
                          <label>Ato de criação</label>
                          <Input
                            disabled
                            value={this.state.escola.atoCriacao}
                            type="text"
                            maxLength="100"
                          />
                        </FormGroup>
                      </Col>
                      <Col md="3">
                        <FormGroup className={'has-label'}>
                          <label>Data ato de criação</label>
                          <Input
                            disabled
                            value={this.state.escola.dataAtoCriacao}
                            type="text"
                            maxLength="100"
                          />
                        </FormGroup>
                      </Col>
                      <Col md="3">
                        <FormGroup className={'has-label'}>
                          <label>Ato de autorização</label>
                          <Input
                            disabled
                            value={this.state.escola.atoAutorizacao}
                            type="text"
                            maxLength="100"
                          />
                        </FormGroup>
                      </Col>
                      <Col md="3">
                        <FormGroup className={'has-label'}>
                          <label>Data ato de autorização</label>
                          <Input
                            disabled
                            value={this.state.escola.dataAtoAutorizacao}
                            type="text"
                            maxLength="100"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <FormGroup className={'has-label'}>
                          <label>Etapas de funcionamento</label>
                          <Select
                            noOptionsMessage={() => 'Nenhuma entrada'}
                            className={'react-select primary'}
                            classNamePrefix="react-select"
                            placeholder="Etapas de funcionamento..."
                            closeMenuOnSelect={false}
                            isMulti
                            isDisabled={true}
                            value={this.state.ciclosEnsinoEscola}
                            options={this.selectCiclosEnsinoEscola}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup className={'has-label'}>
                          <label>Turnos de funcionamento</label>
                          <Select
                            noOptionsMessage={() => 'Nenhuma entrada'}
                            className={'react-select primary'}
                            classNamePrefix="react-select"
                            placeholder="Turnos de funcionamento..."
                            closeMenuOnSelect={false}
                            isMulti
                            isDisabled={true}
                            value={this.state.turnosEscola}
                            options={this.selectTurnosEscola}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                  <Row>
                    <Col md="6" className="text-left">
                      <Button color="primary" disabled={this.state.showLoaderModal} onClick={() => this.props.history.goBack()} >
                        Voltar
                      </Button>
                    </Col>
                  </Row>
                </TabPanel>
                {
                  this.props.role === Papel.GESTOR.value
                  && <TabPanel>
                    <br />
                    <EscolaDiretores
                      {...this.props}
                      callbackMessage={(msg) => this.setState(msg)}
                    />
                    <Row>
                      <Col md="6" className="text-left">
                        <Button
                          color="primary"
                          disabled={this.state.showLoaderModal}
                          onClick={() => this.props.history.goBack()} >
                          Voltar
                        </Button>
                      </Col>
                      <Col md="6" className="text-right">
                        <Button color="primary" disabled={this.state.showLoaderModal}
                          onClick={() => {
                            this.props.history.push(`/gestor/escolas/${this.props.match.params.idEscola}/cadastro/diretor`);
                          }} >
                          Adicionar diretor
                        </Button>
                      </Col>
                    </Row>
                  </TabPanel>
                }
                <TabPanel>
                  <br />
                  <EscolaSecretarios
                    disableActions={this.props.role !== Papel.GESTOR.value}
                    {...this.props}
                    callbackMessage={(msg) => this.setState(msg)}
                  />
                  <Row>
                    <Col md="6" className="text-left">
                      <Button
                        color="primary"
                        disabled={this.state.showLoaderModal}
                        onClick={() => this.props.history.goBack()} >
                        Voltar
                      </Button>
                    </Col>
                    {
                      this.props.role === Papel.GESTOR.value && <Col md="6" className="text-right">
                        <Button color="primary" disabled={this.state.showLoaderModal}
                          onClick={() => {
                            this.props.history.push(`/gestor/escolas/${this.props.match.params.idEscola}/cadastro/secretario`);
                          }} >
                          Adicionar secretário
                        </Button>
                      </Col>
                    }
                  </Row>
                </TabPanel>
                <TabPanel>
                  <br />
                  <EscolaCoordenadores
                    disableActions={this.props.role !== Papel.GESTOR.value}
                    {...this.props}
                    callbackMessage={(msg) => this.setState(msg)}
                  />
                  <Row>
                    <Col md="6" className="text-left">
                      <Button
                        color="primary"
                        disabled={this.state.showLoaderModal}
                        onClick={() => this.props.history.goBack()} >
                        Voltar
                      </Button>
                    </Col>
                    {
                      this.props.role === Papel.GESTOR.value && <Col md="6" className="text-right">
                        <Button color="primary" disabled={this.state.showLoaderModal}
                          onClick={() => {
                            this.props.history.push(`/gestor/escolas/${this.props.match.params.idEscola}/cadastro/coordenador`);
                          }} >
                          Adicionar coordenador
                        </Button>
                      </Col>
                    }
                  </Row>
                </TabPanel>
                <TabPanel>
                  <br />
                  <EscolaFuncionarios
                    {...this.props}
                    callbackMessage={(msg) => this.setState(msg)}
                  />
                  <Row>
                    <Col md="6" className="text-left">
                      <Button
                        color="primary"
                        disabled={this.state.showLoaderModal}
                        onClick={() => this.props.history.push(`${this.props.layout}/escolas`)} >
                        Voltar
                      </Button>
                    </Col>
                    <Col md="6" className="text-right">
                      <Button color="primary" disabled={this.state.showLoaderModal}
                        onClick={() => {
                          this.props.history.push(`${this.props.layout}/escolas/${this.props.match.params.idEscola}/funcionario/cadastro`);
                        }} >
                        Cadastrar funcionário
                      </Button>
                    </Col>
                  </Row>
                </TabPanel>
                {
                  this.state.dadosEnte.alunosPorEscola && <TabPanel>
                    <EscolaAlunos
                      {...this.props}
                      callbackMessage={(msg) => this.setState(msg)} />
                    <Row>
                      <Col md="6" className="text-left">
                        <Button
                          color="primary"
                          disabled={this.state.showLoaderModal}
                          onClick={() => this.props.history.goBack()} >
                          Voltar
                        </Button>
                      </Col>
                    </Row>
                  </TabPanel>
                }
                {
                  this.state.dadosEnte.alunosPorEscola && <TabPanel>
                    <EscolaTransferencias
                      {...this.props}
                      callbackMessage={(msg) => this.setState(msg)} />
                    <Row>
                      <Col md="6" className="text-left">
                        <Button
                          color="primary"
                          disabled={this.state.showLoaderModal}
                          onClick={() => this.props.history.goBack()} >
                          Voltar
                        </Button>
                      </Col>
                      <Col md="6" className="text-right">
                        <Button
                          color="primary"
                          disabled={this.state.showLoaderModal}
                          onClick={() => this.props.history.push(`${this.props.layout}/escolas/${this.props.match.params.idEscola}/solicitar/transferencia`)} >
                          Solicitar transferência
                        </Button>
                      </Col>
                    </Row>
                  </TabPanel>
                }
                {
                  !this.state.dadosEnte.controleEstoque && <TabPanel>
                    <br />
                    <EscolaMerenda
                      {...this.props}
                      callbackMessage={(msg) => this.setState(msg)}
                    />
                    <Row>
                      <Col md="6" className="text-left">
                        <Button
                          color="primary"
                          disabled={this.state.showLoaderModal}
                          onClick={() => this.props.history.goBack()} >
                          Voltar
                        </Button>
                      </Col>
                      <Col md="6" className="text-right">
                        <Button color="primary" disabled={this.state.showLoaderModal}
                          onClick={() => {
                            this.props.history.push(`${this.props.layout}/escolas/${this.props.match.params.idEscola}/itemmerenda/cadastro`);
                          }} >
                          Cadastrar item
                        </Button>
                      </Col>
                    </Row>
                  </TabPanel>
                }
                {
                  this.state.dadosEnte.controleEstoque && <TabPanel>
                    <br />
                    <EscolaEstoques
                      {...this.props}
                      callbackMessage={(msg) => this.setState(msg)}
                    />
                    <Row>
                      <Col md="6" className="text-left">
                        <Button
                          color="primary"
                          disabled={this.state.showLoaderModal}
                          onClick={() => this.props.history.goBack()} >
                          Voltar
                        </Button>
                      </Col>
                    </Row>
                  </TabPanel>
                }
                <TabPanel>
                  <br />
                  <EscolaCardapio
                    {...this.props}
                    callbackMessage={(msg) => this.setState(msg)}
                  />
                </TabPanel>
              </Tabs>
            </CardBody>
          </Card>
        </Col>
      </Row>;
  }

  render() {
    return (
      <div className="content">
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
            </div>
          </Card>
        }
      </div>
    );
  }
}

export default Escola;
