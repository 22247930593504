import React from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';

import {
  Row,
  Col,
  Button,
  Form,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  FormGroup,
  Input,
  CardFooter,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap';

import Loader from 'react-loader-spinner';
import Select from 'react-select';
import moment from 'moment';
import isDateFormat from 'is-date-format';

import DatePicker from 'views/components/DatePicker';
import Alert from 'views/components/Alert';
import LoaderModal from 'views/components/LoaderModal';

import LoginService from 'services/LoginService';
import AnosLetivosService from 'services/AnosLetivosService';
import EscolasService from 'services/EscolasService';
import OcorrenciasService from 'services/OcorrenciasService';
import UsuarioService from 'services/UsuarioService';

class CadastroOcorrencia extends React.Component {
  constructor(props) {
    super(props);

    this.loginService = new LoginService();
    this.anosLetivosService = new AnosLetivosService();
    this.escolasService = new EscolasService();
    this.ocorrenciasService = new OcorrenciasService();
    this.usuarioService = new UsuarioService();

    this.state = {
      showAlert: false,
      error: null,

      carregandoUsuarios: false,
      usuarioSelecionado: null,

      dataOcorrencia: '',
      descricao: '',
      viewDate: new Date(new Date().getFullYear(), 0, 1),

      usuarios: [],
    };

    this.cadastrarOcorrencia = this.cadastrarOcorrencia.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentWillUnmount() {
    this.loginService.abortPedingRequests();
    this.anosLetivosService.abortPedingRequests();
    this.escolasService.abortPedingRequests();
    this.ocorrenciasService.abortPedingRequests();
    this.usuarioService.abortPedingRequests();
    this.umounted = true;
  }

  async componentDidMount() {
    this.debouncedFunction = _.debounce((text) => {
      if (text === '') {
        this.setState({ carregandoUsuarios: false, usuarios: [] });
      } else {
        this.carregarUsuarios(text);
      }
    }, 1500);

    try {
      const anoLetivo = await this.anosLetivosService
        .carregarAnoLetivo(this.props.match.params.idAnoLetivo);

      const escola = await this.escolasService
        .carregarEscola(this.props.match.params.idEscola);

      this.setState({
        anoLetivo,
        escola,
      });

      if (this.props.match.params.idOcorrencia) {
        const ocorrencia = await this.ocorrenciasService
          .carregarOcorrencia(this.props.match.params.idOcorrencia);

        this.setState({ ocorrenciaSelecionada: ocorrencia });

        this.contentLoad();
      }
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  contentLoad() {
    this.setState({
      dataOcorrencia: this.state.ocorrenciaSelecionada.data,
      descricao: this.state.ocorrenciaSelecionada.descricao,
      usuarioSelecionado: {
        label: `${this.state.ocorrenciaSelecionada.usuario.nome} (Mãe: ${this.state.ocorrenciaSelecionada.usuario.nomeMae ? this.state.ocorrenciaSelecionada.usuario.nomeMae : 'Não informada'})`,
        value: this.state.ocorrenciaSelecionada.usuario.id,
      },
      viewDate: moment(this.state.ocorrenciaSelecionada.data, 'DD/MM/YYYY'),
    });
  }

  validarCampos() {
    let ret = true;

    if (this.state.dataOcorrencia === '') {
      this.setState({
        dataOcorrenciaState: 'has-danger',
        dataOcorrenciaErro: 'Informe a data da ocorrência',
      });
      ret = false;
    } else if (!isDateFormat(this.state.dataOcorrencia, 'dd/mm/yyyy')) {
      this.setState({
        dataOcorrenciaState: 'has-danger',
        dataOcorrenciaErro: 'Data inválida',
      });
      ret = false;
    } 

    if (!this.state.usuarioSelecionado) {
      this.setState({ usuarioState: 'danger' });
      ret = false;
    }

    if (this.state.descricao === '') {
      this.setState({ descricaoState: 'has-danger' });
      ret = false;
    }

    return ret;
  }

  async cadastrarOcorrencia() {
    if (this.state.buttonDisabled) return;
    if (!this.validarCampos()) return;

    this.setState({
      buttonDisabled: true,
      showAlert: false,
    });

    try {
      const ocorrencia = {
        data: this.state.dataOcorrencia,
        descricao: this.state.descricao,
        usuario: { id: this.state.usuarioSelecionado.value },
        anoLetivo: { id: this.state.anoLetivo.id },
        escola: { id: this.state.escola.id },
      };

      await this.ocorrenciasService
        .cadastrarOcorrencia(ocorrencia);

      this.limparFormulario();

      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Ocorrência cadastrada com sucesso',
      });
    } catch (msg) {
      if (this.umounted) return;
      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao cadastrar ocorrência',
      });
    }
  }

  async atualizarOcorrencia() {
    if (this.state.buttonDisabled) return;
    if (!this.validarCampos()) return;

    this.setState({
      buttonDisabled: true,
      showAlert: false,
    });

    try {
      const ocorrencia = {
        id: this.state.ocorrenciaSelecionada.id,
        data: this.state.dataOcorrencia,
        descricao: this.state.descricao,
        usuario: { id: this.state.usuarioSelecionado.value },
        anoLetivo: { id: this.state.anoLetivo.id },
        escola: { id: this.state.escola.id },
      };

      await this.ocorrenciasService
        .atualizarOcorrencia(ocorrencia);

      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Ocorrência atualizada com sucesso',
      });
    } catch (msg) {
      if (this.umounted) return;
      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao atualizar ocorrência',
      });
    }
  }

  limparFormulario() {
    this.setState({
      dataState: '',
      usuarioState: '',
      descricaoState: '',

      dataOcorrencia: '',
      usuarioSelecionado: null,
      descricao: '',

      usuarios: [],
      carregandoUsuarios: false,
    });
  }

  async carregarUsuarios(like) {
    try {
      const data = await this.usuarioService
        .carregarUsuariosDoEnte(1, 99999, like);

      const selectUsuarios = [];
      data.usuarios.forEach((usuario) => {
        selectUsuarios.push({
          value: usuario.id,
          label: `${usuario.nome} (Mãe: ${usuario.nomeMae
            ? usuario.nomeMae : 'Não informada'})`,
        });
      });

      this.setState({
        carregandoUsuarios: false,
        usuarios: selectUsuarios,
      });
    } catch (e) {
      if (this.umounted) return;
      this.setState({
        carregandoUsuarios: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao buscar usuários cadastrados',
      });
    }
  }

  onSubmit(e) {
    e.preventDefault();

    if (this.state.buttonDisabled) return;

    if (!this.state.ocorrenciaSelecionada) {
      this.cadastrarOcorrencia();
    } else {
      this.atualizarOcorrencia();
    }
  }

  conteudoPagina() {
    return this.state.escola ? <>
      <Row>
        <Col md="12">
          <Alert
            color={this.state.alertColor}
            isOpen={this.state.showAlert}
            toggle={() => { this.setState({ showAlert: false }); }}>
            {this.state.alertMsg}
          </Alert>
          <Breadcrumb>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos`}>Anos letivos</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}`}>Ano letivo {this.state.anoLetivo.ano}</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.state.anoLetivo.id}/escola/${this.state.escola.id}`}>{this.state.escola.nome}</Link></BreadcrumbItem>
            <BreadcrumbItem active>{this.state.turmaSelecionada ? 'Edição ocorrência' : 'Cadastro ocorrência'}</BreadcrumbItem>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Form onSubmit={this.onSubmit}>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Dados da ocorrência</CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="2">
                    <FormGroup className={`has-label ${this.state.dataOcorrenciaState}`}>
                      <label>Data da ocorrência *</label>
                      <DatePicker
                        viewDate={this.state.viewDate}
                        value={this.state.dataOcorrencia}
                        onChange={(data) => {
                          const dataStr = moment(data).format('DD/MM/YYYY');
                          this.setState({
                            viewDate: data.toDate ? data.toDate() : this.state.viewDate,
                            dataOcorrencia: dataStr,
                            dataOcorrenciaState: '',
                          });
                        }}
                      />
                      {this.state.dataOcorrenciaState === 'has-danger' ? (
                        <label className="error">
                          {this.state.dataOcorrenciaErro}
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup className={'has-label has-danger'}>
                      <label>Usuario *</label>
                      <Select
                        isDisabled={this.props.match.params.idOcorrencia}
                        isLoading={this.state.carregandoUsuarios}
                        loadingMessage={() => 'Carregando...'}
                        noOptionsMessage={() => 'Nenhuma entrada'}
                        className={`react-select primary ${this.state.usuarioState}`}
                        classNamePrefix="react-select"
                        value={this.state.usuarioSelecionado}
                        onInputChange={(text) => {
                          this.setState({ carregandoUsuarios: text !== '', usuarioState: 'primary' });
                          this.debouncedFunction(text);
                        }}
                        onChange={(event) => {
                          this.setState({ usuarioState: 'primary', usuarioSelecionado: event });
                        }}
                        options={this.state.usuarios}
                        placeholder="Digite o nome de um usuário cadastro no sistema..."
                      />
                      {this.state.usuarioState === 'danger' ? (
                        <label className="error">
                          Informe o nome de um usuário cadastrado.
                        </label>
                      ) : <label></label>}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup className={`has-label ${this.state.descricaoState}`}>
                      <label>Descrição *</label>
                      <Input
                        value={this.state.descricao}
                        onChange={(evt) => this.setState({
                          descricao: evt.target.value,
                          descricaoState: '',
                        })}
                        type="textarea"
                        style={{ height: '300px' }} />
                      {this.state.descricaoState === 'danger' ? (
                        <label className="error">
                          Informe a descrição da ocorrência.
                        </label>
                      ) : <label></label>}
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <Row>
                  <Col className="text-left">
                    <Button
                      color="primary"
                      onClick={() => this.props.history
                        .push(`${this.props.layout}/anosletivos/${this.state.anoLetivo.id}/escola/${this.state.escola.id}`)} >
                      Voltar
                  </Button>
                  </Col>
                  <Col className="text-right">
                    <Button
                      type="submit"
                      disabled={this.state.buttonDisabled}
                      color="primary">
                      { !this.state.ocorrenciaSelecionada ? 'Cadastrar' : 'Atualizar' }
                    </Button>
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </Form>
        </Col >
      </Row >
    </>
      : <Card>
        <div align="center" style={{ margin: 50 }}>
          <Loader
            type="Oval"
            color="#34B5B8"
            height="50"
            width="50" />
        </div>
      </Card>;
  }

  render() {
    return (
      <div className='content'>
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
            </div>
          </Card>
        }
        <LoaderModal
          isOpen={this.state.buttonDisabled}
          text="Cadastrando ocorrência..." />
      </div>
    );
  }
}

export default CadastroOcorrencia;
