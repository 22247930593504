import React from 'react';
import { Link } from 'react-router-dom';

import {
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Table,
} from 'reactstrap';

import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar';

import Loader from 'react-loader-spinner';

import LoginService from 'services/LoginService';
import AnosLetivosService from 'services/AnosLetivosService';
import EscolasService from 'services/EscolasService';
import TurmasService from 'services/TurmasService';
import DiariosService from 'services/DiariosService';
import FrequenciaService from 'services/FrequenciaService';
import AbonosService from 'services/AbonosService';

import Turno from 'assets/csv/turnos.json';

class FrequenciaDiario extends React.Component {
  constructor(props) {
    super(props);

    this.turnos = [
      Turno.MATUTINO,
      Turno.VESPERTINO,
      Turno.NOTURNO,
      Turno.INTEGRAL,
    ];

    this.state = {
      error: null,
    };

    this.loginService = new LoginService();
    this.anosLetivosService = new AnosLetivosService();
    this.escolasService = new EscolasService();
    this.turmasService = new TurmasService();
    this.diariosService = new DiariosService();
    this.frequenciaService = new FrequenciaService();
    this.abonosService = new AbonosService();
  }

  componentWillUnmount() {
    this.loginService.abortPedingRequests();
    this.anosLetivosService.abortPedingRequests();
    this.escolasService.abortPedingRequests();
    this.turmasService.abortPedingRequests();
    this.diariosService.abortPedingRequests();
    this.umounted = true;
  }

  async componentDidMount() {
    await this.loginService.verificarPapel(this.props.role);
    try {
      const anoLetivo = await this.anosLetivosService
        .carregarAnoLetivo(this.props.match.params.idAnoLetivo);

      const escola = await this.escolasService
        .carregarEscola(this.props.match.params.idEscola);

      const turma = await this.turmasService
        .carregarTurma(this.props.match.params.idTurma);

      const diario = await this.diariosService
        .carregarDiario(this.props.match.params.idDiario);

      const matriculas = await this.diariosService
        .carregarMatriculas(this.props.match.params.idDiario);

      const faltas = await this.frequenciaService
        .carregarFaltasDoDiarioPorMes(this.props.match.params.idDiario);

      const abonos = await this.abonosService
        .carregarAbonos(this.props.match.params.idDiario);

      const firstKey = Object.keys(faltas)[0];
      const cols = firstKey ? Object.keys(faltas[firstKey]) : undefined;

      this.setState({
        anoLetivo,
        escola,
        turma,
        diario,
        matriculas,
        faltas,
        abonos,
        cols,
      });
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  conteudoPagina() {
    return this.state.escola ? <>
      <Row>
        <Col md="12">
          <Breadcrumb>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos`}>Anos letivos</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}`}>Ano letivo {this.state.anoLetivo.ano}</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}`}>{this.state.escola.nome}</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/${this.state.turma.id}`}>{this.state.turma.nome} - {this.turnos[this.state.turma.turno].label}</Link></BreadcrumbItem>
            <BreadcrumbItem active>Faltas registradas - {this.state.diario.nome}</BreadcrumbItem>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <CardTitle tag="h4">
                Faltas registradas - {this.state.diario.nome}
              </CardTitle>
            </CardHeader>
            <CardBody>
              {
                this.state.cols ? <PerfectScrollbar className="sisa-perfect-scrollbar">
                  <Table>
                  <thead className="text-primary">
                    <tr>
                      <th style={{ width: '30%' }}>Aluno</th>
                      {
                        this.state.cols.map((col, idx) => <th className="text-center" key={idx}>{col}</th>)
                      }
                    </tr>
                  </thead>
                  <tbody>
                    {
                      Object.keys(this.state.faltas).map((nome, idx) => <tr key={idx}>
                        <td>{nome}</td>
                        {
                          Object.values(this.state.faltas[nome])
                            .map((valor, idx2) => <td className="text-center" key={idx2}>{valor}</td>)
                        }
                      </tr>)
                    }
                  </tbody>
                </Table>
                </PerfectScrollbar>
                  : <div align="center" style={{ margin: 50 }}>
                    Ainda não há alunos matriculados nesta turma
                  </div>
              }
            </CardBody>
            <CardFooter>
              <Row>
                <Col md="2" className="text-left">
                  <Button
                    color="primary"
                    onClick={() => this.props.history
                      .push(`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/${this.state.turma.id}`)} >
                    Voltar
                  </Button>
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </> : <Card>
        <div align="center" style={{ margin: 50 }}>
          <Loader
            type="Oval"
            color="#34B5B8"
            height="50"
            width="50" />
        </div>
      </Card>;
  }

  render() {
    return (
      <div className='content'>
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
            </div>
          </Card>
        }
      </div>
    );
  }
}

export default FrequenciaDiario;
