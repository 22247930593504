import React from 'react';
import { Link } from 'react-router-dom';

import {
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Breadcrumb,
  BreadcrumbItem,
  Button,
} from 'reactstrap';

import 'react-tabs/style/react-tabs.scss';

import {
  Tabs,
  TabList,
  Tab,
  TabPanel,
} from 'react-tabs';

import Loader from 'react-loader-spinner';

import Alert from 'views/components/Alert';
import AtaFinalNotas from 'views/components/AtaFinalNotas';
import AtaFinalResultado from 'views/components/AtaFinalResultado';

import LoginService from 'services/LoginService';
import AnosLetivosService from 'services/AnosLetivosService';
import EscolasService from 'services/EscolasService';
import TurmasService from 'services/TurmasService';
import DiariosService from 'services/DiariosService';
import AvaliacoesService from 'services/AvaliacoesService';
import FrequenciaService from 'services/FrequenciaService';
import RegistroSituacaoMatriculaService from 'services/RegistroSituacaoMatriculaService';
import AbonosService from 'services/AbonosService';

import Turno from 'assets/csv/turnos.json';

class AtaFinal extends React.Component {
  constructor(props) {
    super(props);

    this.turnos = [
      Turno.MATUTINO,
      Turno.VESPERTINO,
      Turno.NOTURNO,
      Turno.INTEGRAL,
    ];

    this.state = {
      showAlert: false,
      showLoaderModal: false,

      error: null,
    };

    this.loginService = new LoginService();
    this.anosLetivosService = new AnosLetivosService();
    this.escolasService = new EscolasService();
    this.turmasService = new TurmasService();
    this.diariosService = new DiariosService();
    this.avaliacoesService = new AvaliacoesService();
    this.frequenciaService = new FrequenciaService();
    this.registroSituacaoMatriculaService = new RegistroSituacaoMatriculaService();
    this.abonosService = new AbonosService();
  }

  componentWillUnmount() {
    this.loginService.abortPedingRequests();
    this.anosLetivosService.abortPedingRequests();
    this.escolasService.abortPedingRequests();
    this.turmasService.abortPedingRequests();
    this.diariosService.abortPedingRequests();
    this.avaliacoesService.abortPedingRequests();
    this.frequenciaService.abortPedingRequests();
    this.registroSituacaoMatriculaService.abortPedingRequests();
    this.abonosService.abortPedingRequests();
    this.umounted = true;
  }

  async componentDidMount() {
    await this.loginService.verificarPapel(this.props.role);
    try {
      const anoLetivo = await this.anosLetivosService
        .carregarAnoLetivo(this.props.match.params.idAnoLetivo);

      const escola = await this.escolasService
        .carregarEscola(this.props.match.params.idEscola);

      const turma = await this.turmasService
        .carregarTurma(this.props.match.params.idTurma);

      const descricoesAvaliacoes = await this.turmasService
        .carregarDescricoesAvaliacoes(this.props.match.params.idTurma);

      const diario = await this.diariosService
        .carregarDiario(this.props.match.params.idDiario);

      const materias = await this.diariosService
        .carregarMaterias(this.props.match.params.idDiario);

      const matriculas = await this.diariosService
        .carregarMatriculas(this.props.match.params.idDiario);

      const fn = (matricula) => new Promise((resolve) => resolve(
        this.registroSituacaoMatriculaService
          .carregarUltimoRegistroSituacaoMatricula(matricula.id),
      ));

      const actions = matriculas.map(fn);

      const registros = await Promise.all(actions);

      matriculas.forEach((matricula, idx) => Object.assign(
        matricula, { situacao: registros[idx].situacao },
      ));

      const faltas = await this.frequenciaService
        .carregarFaltasDoDiario(this.props.match.params.idDiario);

      const abonos = await this.abonosService
        .carregarAbonos(this.props.match.params.idDiario);

      const avaliacoes = await this.avaliacoesService
        .carregarAvaliacoesDoDiario(diario.id);

      this.setState({
        anoLetivo,
        escola,
        turma,
        diario,
        materias,
        matriculas,
        faltas,
        abonos,
        descricoesAvaliacoes,
        avaliacoes,
      });
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  conteudoPagina() {
    return this.state.escola ? <>
      <Row>
        <Col md="12">
          <Alert
            color={this.state.alertColor}
            isOpen={this.state.showAlert}
            toggle={() => { this.setState({ showAlert: false }); }}>
            {this.state.alertMsg}
          </Alert>
          <Breadcrumb>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos`}>Anos letivos</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}`}>Ano letivo {this.state.anoLetivo.ano}</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}`}>{this.state.escola.nome}</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/${this.state.turma.id}`}>{this.state.turma.nome} - {this.turnos[this.state.turma.turno].label}</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/${this.state.turma.id}/diario/${this.state.diario.id}`}>{this.state.diario.nome}</Link></BreadcrumbItem>
            <BreadcrumbItem active>Ata Final</BreadcrumbItem>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <CardTitle tag="h4">Ata Final</CardTitle>
            </CardHeader>
            <CardBody>
              <Row>
                <Col>
                  <Tabs>
                    <TabList>
                      {
                        this.state.descricoesAvaliacoes
                          .map((desc, idx) => <Tab key={idx}>{desc.nome}</Tab>)
                      }
                      <Tab>Rendimento Final</Tab>
                    </TabList>
                    {
                      this.state.descricoesAvaliacoes
                        .map((desc, idx) => <TabPanel key={idx}>
                          <AtaFinalNotas
                            idDescricaoAvaliacao={desc.id}
                            materias={this.state.materias}
                            matriculas={this.state.matriculas}
                            avaliacoes={this.state.avaliacoes} />
                        </TabPanel>)
                    }
                    <TabPanel>
                      <AtaFinalResultado
                        descricoesAvaliacoes={this.state.descricoesAvaliacoes}
                        turma={this.state.turma}
                        materias={this.state.materias}
                        matriculas={this.state.matriculas}
                        avaliacoes={this.state.avaliacoes}
                        faltas={this.state.faltas}
                        abonos={this.state.abonos}
                        totalAulas={this.state.diario.aulasALancar}
                        curso={this.state.turma.curso} />
                    </TabPanel>
                  </Tabs>
                </Col>
              </Row>
              <Row>
                <Col md="6" className="text-left">
                  <Button
                    color="primary"
                    onClick={() => this.props.history
                      .push(`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/${this.state.turma.id}/diario/${this.state.diario.id}`)} >
                    Voltar
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </> : <Card>
        <div align="center" style={{ margin: 50 }}>
          <Loader
            type="Oval"
            color="#34B5B8"
            height="50"
            width="50" />
        </div>
      </Card>;
  }

  render() {
    return (
      <div className='content'>
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
            </div>
          </Card>
        }
      </div>
    );
  }
}

export default AtaFinal;
