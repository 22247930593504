import React from 'react';
import { Link } from 'react-router-dom';

import {
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  Button,
  Form,
  FormGroup,
  Input,
} from 'reactstrap';

import moment from 'moment';
import Loader from 'react-loader-spinner';
import Select from 'react-select';
import NumberFormat from 'react-number-format';

import Alert from 'views/components/Alert';
import LoaderModal from 'views/components/LoaderModal';
import MetodologiaModal from 'views/components/MetodologiaModal';

import EntesService from 'services/EntesService';
import LoginService from 'services/LoginService';
import AnosLetivosService from 'services/AnosLetivosService';
import EscolasService from 'services/EscolasService';
import TurmasService from 'services/TurmasService';
import DiariosService from 'services/DiariosService';
import FeriadosService from 'services/FeriadosService';
import DiasCanceladosService from 'services/DiasCanceladosService';
import DiasExtrasService from 'services/DiasExtrasService';
import RegistroAulasService from 'services/RegistroAulasService';
import ObjetivosAprendizagemCampoExperienciaService from 'services/ObjetivosAprendizagemCampoExperienciaService';
import HabilidadesCompetenciasEspecificasService from 'services/HabilidadesCompetenciasEspecificasService';

import Turno from 'assets/csv/turnos.json';
import TipoAvaliacao from 'assets/csv/tipos-avaliacao.json';
import Papel from 'assets/csv/papeis.json';
import CicloEnsino from 'assets/csv/ciclos-ensino.json';

class RegistroAulas extends React.Component {
  constructor(props) {
    super(props);

    this.turnos = [
      Turno.MATUTINO,
      Turno.VESPERTINO,
      Turno.NOTURNO,
      Turno.INTEGRAL,
    ];

    this.state = {
      showAlert: false,
      showLoaderModal: false,
      showMetodologiaModal: false,

      objetoConhecimento: '',
      metodologia: '',
      observacoes: '',
      direitosAprendizagem: '',
      numeroAulas: '',

      materiasSelecionadas: [],
      materias: [],

      objetivosAprendizagem: [],
      objetivosAprendizagemSelecionados: [],
      habilidades: [],
      habilidadesSelecionadas: [],

      error: null,
    };

    this.feriadoMap = new Map();
    this.diaCanceladoMap = new Map();
    this.diaExtraMap = new Map();
    this.diasAulaMap = new Map();

    this.entesService = new EntesService();
    this.loginService = new LoginService();
    this.anosLetivosService = new AnosLetivosService();
    this.escolasService = new EscolasService();
    this.turmasService = new TurmasService();
    this.diariosService = new DiariosService();
    this.feriadosService = new FeriadosService();
    this.diasCanceladosService = new DiasCanceladosService();
    this.diasExtrasService = new DiasExtrasService();
    this.registroAulasService = new RegistroAulasService();
    this.objetivosAprendizagemCampoExperienciaService = new ObjetivosAprendizagemCampoExperienciaService();
    this.habilidadesCompetenciasEspecificasService = new HabilidadesCompetenciasEspecificasService();

    this.onSubmit = this.onSubmit.bind(this);
    this.cadastrarRegistroAula = this.cadastrarRegistroAula.bind(this);
    this.atualizarRegistroAula = this.atualizarRegistroAula.bind(this);
    this.validarCampos = this.validarCampos.bind(this);
    this.limparFormulario = this.limparFormulario.bind(this);
  }

  async componentDidMount() {
    await this.loginService.verificarPapel(this.props.role);
    try {
      const dadosEnte = await this.entesService.carregarDadosEnte();

      const anoLetivo = await this.anosLetivosService
        .carregarAnoLetivo(this.props.match.params.idAnoLetivo);

      const escola = await this.escolasService
        .carregarEscola(this.props.match.params.idEscola);

      const turma = await this.turmasService
        .carregarTurma(this.props.match.params.idTurma);

      const diario = await this.diariosService
        .carregarDiario(this.props.match.params.idDiario);

      this.setState({
        dadosEnte,
        anoLetivo,
        escola,
        turma,
        diario,
        alfabetizacao: turma.tipoAvaliacao === TipoAvaliacao.RELATORIO.value ? '/alfabetizacao' : '',
      });

      const feriados = await this.feriadosService
        .carregarFeriadosPorAnoLetivo(anoLetivo.id);

      const diasCancelados = await this.diasCanceladosService
        .carregarDiasCanceladosPorAnoLetivo(anoLetivo.id);

      const diasExtras = await this.diasExtrasService
        .carregarDiasExtrasPorAnoLetivo(anoLetivo.id);

      const dias = await this.diariosService
        .carregarDiasDeAulaDoProfessor(this.props.match.params.idDiario);

      feriados.forEach((feriado) => this.feriadoMap.set(feriado.data.replace(/\//gi, '-'), true));

      diasCancelados.forEach((dia) => this.diaCanceladoMap.set(dia.data.replace(/\//gi, '-'), true));

      diasExtras.forEach((dia) => this.diaExtraMap.set(dia.data.replace(/\//gi, '-'), true));

      dias.forEach((dia) => this.diasAulaMap.set(dia, true));

      const selectDatas = [];

      let inicio = moment(anoLetivo.dataInicioBimestre1, 'DD-MM-YYYY');
      const fim = moment(anoLetivo.dataFimBimestre4, 'DD-MM-YYYY');

      while (!inicio.isAfter(fim)) {
        if (this.diaUtil(inicio.toDate())) {
          selectDatas.push({
            value: inicio.format("DD/MM/YYYY"),
            label: inicio.format("DD/MM/YYYY - ddd").toUpperCase(),
          });
        }
        inicio.add(1, 'days');
      }

      if (this.props.match.params.idRegistroAula !== undefined) {
        const registroAula = await this.registroAulasService
          .carregarRegistroAula(this.props.match.params.idRegistroAula);

        const materias = await this.registroAulasService.carregarMateriasRegistroAula(registroAula.id);
        const materiasSelecionadas = materias.map((materiaRegistroAula) => materiaRegistroAula.materia);
        materiasSelecionadas.forEach((materia) => {
          Object.assign(materia, { value: materia.id, label: materia.nome });
        });

        const habilidadesPromises = materiasSelecionadas.map((materiaSelecionada) => new Promise((resolve) => {
          const habilidades = this.habilidadesCompetenciasEspecificasService
            .carregarHabilidadesPorMateria(materiaSelecionada.id);
          resolve(habilidades);
        }));

        const habilidadesResults = await Promise.all(habilidadesPromises);
        const habilidades = [];

        habilidadesResults.forEach((habilidadesResult) => {
          habilidadesResult.forEach((habilidade) => {
            Object.assign(habilidade, { value: habilidade.id, label: habilidade.descricao });
            habilidades.push(habilidade);
          });
        });

        const objetivosAprendizagemPromises = materiasSelecionadas.map((materiaSelecionada) => new Promise((resolve) => {
          const objetivosAprendizagem = this.objetivosAprendizagemCampoExperienciaService
            .carregarObjetivosAprendizagemPorMateria(materiaSelecionada.id);
          resolve(objetivosAprendizagem);
        }));

        const objetivosAprendizagemResults = await Promise.all(objetivosAprendizagemPromises);
        const objetivosAprendizagem = [];

        objetivosAprendizagemResults.forEach((objetivosAprendizagemResult) => {
          objetivosAprendizagemResult.forEach((objetivoAprendizagem) => {
            Object.assign(objetivoAprendizagem, { value: objetivoAprendizagem.id, label: objetivoAprendizagem.descricao });
            habilidades.push(objetivoAprendizagem);
          });
        });

        const raoas = await this.registroAulasService
          .carregarObjetivosAprendizagem(this.props.match.params.idRegistroAula);

        const rahs = await this.registroAulasService
          .carregarHabilidades(this.props.match.params.idRegistroAula);
        
        await this.carregarMateriasDoProfessorNoDia({ label: registroAula.data });

        this.setState({
          registroAulaSelecionado: registroAula,
          materiasSelecionadas,
          objetivosAprendizagem,
          objetivosAprendizagemSelecionados: raoas.map((raoa) => ({ value: raoa.objetivoAprendizagem.id, label: raoa.objetivoAprendizagem.descricao })),
          habilidades,
          habilidadesSelecionadas: rahs.map((rah) => ({ value: rah.habilidade.id, label: rah.habilidade.descricao })),
        });

        await this.carregarObjetivosAprendizagemHabilidades(materiasSelecionadas);

        this.contentLoad();
      } else {
        if (dadosEnte.registroAulasPeriodo && this.state.turma.curso.cicloEnsino !== CicloEnsino.ENSINO_FUNDAMENTAL_2.value) {
          const materias = await this.diariosService
            .carregarMateriasDoProfessor(this.props.match.params.idDiario);

          const selectMaterias = [];
          materias.forEach((materia) => {
            selectMaterias.push({
              id: materia.id,
              value: materia.id,
              label: materia.nome,
            });
          });

          const materiasSelecionadas = turma.curso.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value ?  Array.from(selectMaterias) : [];

          this.setState({
            selectMaterias,
            materiasSelecionadas,
          });

          await this.carregarObjetivosAprendizagemHabilidades(materiasSelecionadas);
        }
      }

      this.setState({
        selectDatas,
      });
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  contentLoad() {
    this.setState({
      dataSelecionada: {
        value: this.state.registroAulaSelecionado.data,
        label: this.state.registroAulaSelecionado.data,
      },
      dataFimSelecionada: {
        value: this.state.registroAulaSelecionado.dataFim,
        label: this.state.registroAulaSelecionado.dataFim,
      },
      numeroAulas: this.state.registroAulaSelecionado.numeroAulas,
      objetoConhecimento: this.state.registroAulaSelecionado.objetoConhecimento,
      metodologia: this.state.registroAulaSelecionado.metodologia,
      observacoes: this.state.registroAulaSelecionado.observacoes,
      direitosAprendizagem: this.state.registroAulaSelecionado.direitosAprendizagem,
    });
  }

  diaUtil(date) {
    const domingo = date.getDay() === 0;

    const sabado = date.getDay() === 6 && !this.state.anoLetivo.sabadoLetivo;

    const foraDoAno = this.foraDoAno(date);

    let recesso = false;
    if (this.state.anoLetivo.dataInicioRecesso && this.state.anoLetivo.dataFimRecesso) {
      const inicioRecesso = moment(this.state.anoLetivo.dataInicioRecesso, 'DD-MM-YYYY').toDate();
      const fimRecesso = moment(this.state.anoLetivo.dataFimRecesso, 'DD-MM-YYYY').toDate();
      recesso = date >= inicioRecesso && date <= fimRecesso;
    }

    const feriado = this.feriadoMap.get(moment(date).format('DD-MM-YYYY'));

    const diaCancelado = this.diaCanceladoMap.get(moment(date).format('DD-MM-YYYY'));

    const diaExtra = this.diaExtraMap.get(moment(date).format('DD-MM-YYYY'));

    const diaAula = this.diaDeAula(date) || diaExtra;

    return diaAula && (diaExtra
      || (!sabado && !domingo && !recesso && !diaCancelado && !feriado && !foraDoAno));
  }

  foraDoAno = (date) => {
    let foraDoAno = false;
    if (this.state.anoLetivo.dataInicioBimestre1 && this.state.anoLetivo.dataFimBimestre4) {
      const inicio = moment(this.state.anoLetivo.dataInicioBimestre1, 'DD-MM-YYYY').toDate();
      const fim = moment(this.state.anoLetivo.dataFimBimestre4, 'DD-MM-YYYY').toDate();
      foraDoAno = date < inicio || date > fim;
    }
    return foraDoAno;
  }

  async carregarMateriasDoProfessorNoDia(data) {
    this.setState({
      showLoaderModal: true,
      showAlert: false,
      loaderModalText: 'Carregando matérias do professor na data selecionada...',
    });

    try {

      const mData = moment(data.label, 'DD/MM/YYYY');
      const dia = mData.toDate().getDay() - 1;
      const fimDeSemana = mData.day() === 6 || mData.day() === 0;

      let materias;
      const selectMaterias = [];

      if (!fimDeSemana && !(this.state.dadosEnte.registroAulasPeriodo && this.state.turma.curso.cicloEnsino !== CicloEnsino.ENSINO_FUNDAMENTAL_2.value)) {
        materias = await this.diariosService
          .carregarMateriasDoProfessorNoDia(this.props.match.params.idDiario, dia);

        const fn = (materia) => new Promise((resolve) => resolve(
          this.diariosService.carregarQuantidadeDeAulasDoProfessorMateria(
            this.props.match.params.idDiario, materia.id, dia,
          ),
        ));

        const actions = materias.map(fn);
        const quantidadesAulas = await Promise.all(actions);

        materias.forEach((materia, idx) => {
          selectMaterias.push({
            id: materia.id,
            value: materia.id,
            label: materia.nome,
            numeroAulas: quantidadesAulas[idx],
          });
        });

      } else {
        materias = await this.diariosService
          .carregarMateriasDoProfessor(this.props.match.params.idDiario);

        materias.forEach((materia) => {
          selectMaterias.push({
            id: materia.id,
            value: materia.id,
            label: materia.nome,
          });
        });
      }

      this.setState({
        showLoaderModal: false,
        selectMaterias,
      });
    } catch (e) {
      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: e || 'Erro ao carregar matérias do professor na data selecionada',
      });
    }
  }

  diaDeAula = (date) => !this.state.turma.frequenciaRestrita
    || this.diasAulaMap.get(date.getDay() - 1);

  validarCampos() {
    let ret = true;
    
    if (!this.state.dataSelecionada) {
      this.setState({ dataState: 'danger' });
      ret = false;
    }

    if (this.state.dadosEnte.registroAulasPeriodo && this.state.turma.curso.cicloEnsino !== CicloEnsino.ENSINO_FUNDAMENTAL_2.value) {
      if (!this.state.dataFimSelecionada) {
        this.setState({ dataFimState: 'danger', dataFimErro: 'Informe a data de fim' });
        ret = false;
      } else if (moment(this.state.dataFimSelecionada.value, 'DD/MM/YYYY').isBefore(moment(this.state.dataSelecionada.value, 'DD/MM/YYYY'))) {
        this.setState({ dataFimState: 'danger', dataFimErro: 'Informe uma data de fim igual ou posterior a data de início' });
        ret = false;
      }
    }

    if (!this.state.materiasSelecionadas || this.state.materiasSelecionadas.length === 0) {
      this.setState({ materiasState: 'danger' });
      ret = false;
    }

    if (((!this.state.dadosEnte.registroAulasPeriodo && this.state.dadosEnte?.habilidadesRegistroAula && !(this.state.turma.curso.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value)) || !this.state.dadosEnte?.habilidadesRegistroAula) && !this.state.objetoConhecimento) {
      this.setState({ objetoConhecimentoState: 'has-danger' });
      ret = false;
    }
    
    if (!this.state.metodologia) {
      this.setState({ metodologiaState: 'has-danger' });
      ret = false;
    }
    
    if (this.state.dadosEnte.habilidadesRegistroAula && this.state.turma.curso.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value && !this.state.direitosAprendizagem && !this.state.dadosEnte.registroAulasPeriodo) {
      this.setState({ direitosAprendizagemState: 'has-danger' });
      ret = false;
    }
    
    if (this.state.dadosEnte.habilidadesRegistroAula && !this.state.objetivosAprendizagemSelecionados.length && !this.state.habilidadesSelecionadas.length) {
      this.setState({
        objetivosAprendizagemSelecionadosState: 'danger',
        habilidadesSelecionadasState: 'danger',
      });
      ret = false;
    }
    
    if (!(this.state.dadosEnte.registroAulasPeriodo && this.state.turma.curso.cicloEnsino !== CicloEnsino.ENSINO_FUNDAMENTAL_2.value)) {
      if (!this.state.numeroAulas) {
        this.setState({
          numeroAulasState: 'has-danger',
          numeroAulasErro: 'Informe uma quantidade válida para o campo número de aulas',
        });
        ret = false;
      } else if (this.state.numeroAulas > this.state.materiasSelecionadas.numeroAulas && !this.state.fimDeSemana) {
        this.setState({
          numeroAulasState: 'has-danger',
          numeroAulasErro: 'Número excessivo de aulas para o dia selecionado',
        });
        ret = false;
      }
    }
    
    return ret;
  }

  async cadastrarRegistroAula() {
    if (!this.validarCampos()) return;

    this.setState({
      showLoaderModal: true,
      showAlert: false,
      loaderModalText: 'Cadastrando registro de aula...',
    });

    const registroAula = {
      diario: {
        id: this.props.match.params.idDiario,
      },
      data: this.state.dataSelecionada.value,
      dataFim: this.state.dataFimSelecionada?.value,
      materias: this.state.materiasSelecionadas.map((materiaSelecionada) => ({ materia: { id: materiaSelecionada.value } })),
      numeroAulas: this.state.numeroAulas || 0,
      objetoConhecimento: this.state.objetoConhecimento,
      metodologia: this.state.metodologia,
      observacoes: this.state.observacoes,
      direitosAprendizagem: this.state.direitosAprendizagem,
      objetivosAprendizagem: this.state.objetivosAprendizagemSelecionados.map((objetivoAprendizagem) => ({ objetivoAprendizagem: { id: objetivoAprendizagem.value } })),
      habilidades: this.state.habilidadesSelecionadas.map((habilidade) => ({ habilidade: { id: habilidade.value } })),
    };

    try {
      await this.registroAulasService.cadastrarRegistroAula(registroAula);
      this.limparFormulario();
      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Registro de aula cadastrado com sucesso',
      });
    } catch (e) {
      if (this.umounted) return;
      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: e || 'Erro ao cadastrar registro de aula',
      });
    }
  }

  async atualizarRegistroAula() {
    if (!this.validarCampos()) return;

    this.setState({
      showLoaderModal: true,
      showAlert: false,
      loaderModalText: 'Atualizando registro de aula...',
    });

    const registroAula = {
      id: this.props.match.params.idRegistroAula,
      diario: {
        id: this.props.match.params.idDiario,
      },
      data: this.state.dataSelecionada.value,
      dataFim: this.state.dataFimSelecionada?.value,
      materias: this.state.materiasSelecionadas.map((materiaSelecionada) => ({ materia: { id: materiaSelecionada.value } })),
      numeroAulas: this.state.numeroAulas || 0,
      objetoConhecimento: this.state.objetoConhecimento,
      metodologia: this.state.metodologia,
      observacoes: this.state.observacoes,
      direitosAprendizagem: this.state.direitosAprendizagem,
      objetivosAprendizagem: this.state.objetivosAprendizagemSelecionados.map((objetivoAprendizagem) => ({ objetivoAprendizagem: { id: objetivoAprendizagem.value } })),
      habilidades: this.state.habilidadesSelecionadas.map((habilidade) => ({ habilidade: { id: habilidade.value } })),
    };

    try {
      await this.registroAulasService.atualizarRegistroAula(registroAula);
      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Registro de aula atualizado com sucesso',
      });
    } catch (e) {
      if (this.umounted) return;
      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: e || 'Erro ao atualizar registro de aula',
      });
    }
  }

  limparFormulario() {
    this.setState({
      showLoaderModal: false,

      dataSelecionada: '',
      dataFimSelecionada: '',
      materiasSelecionadas: '',
      objetoConhecimento: '',
      metodologia: '',
      observacoes: '',
      direitosAprendizagem: '',
      numeroAulas: '',

      habilidadesSelecionadas: [],
      objetivosAprendizagemSelecionados: [],
    });
  }

  onSubmit(e) {
    e.preventDefault();

    if (this.state.showLoaderModal) return;

    if (!this.state.registroAulaSelecionado) {
      this.cadastrarRegistroAula();
    } else {
      this.atualizarRegistroAula();
    }
  }

  voltar() {
    if (this.state.turma.tipoAvaliacao === TipoAvaliacao.NOTA.value) {
      return `${this.props.layout}`
        + `/anosletivos/${this.props.match.params.idAnoLetivo}`
        + `/escola/${this.state.escola.id}`
        + `/turma/${this.state.turma.id}`
        + `/diario/${this.state.diario.id}`;
    }
    return `${this.props.layout}`
      + `/anosletivos/${this.props.match.params.idAnoLetivo}`
      + `/escola/${this.state.escola.id}`
      + `/turma/${this.state.turma.id}`
      + `/diario/${this.state.diario.id}/alfabetizacao`;
  }

  handleClick = () => {
    if (!this.lastClickTime) {
      this.lastClickTime = new Date();
      this.countClick = 1;
    } else {
      let newClickTime = new Date();
      if (newClickTime - this.lastClickTime < 300) {
        this.lastClickTime = newClickTime;
        this.countClick += 1;
        if (this.countClick === 4) {
          this.setState({ showMetodologiaModal: true });
          this.lastClickTime = undefined;
          this.countClick = 0;
        }
      } else {
        this.lastClickTime = new Date();
        this.countClick = 1;
      }
    }
  };

  async carregarObjetivosAprendizagemHabilidades(materiasSelecionadas) {
    try {
      this.setState({
        showLoaderModal: true,
        loaderModalText: 'Carregando objetivos de aprendizagem/habilidades...'
      });

      if (this.state.turma.curso.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value && !this.state.dadosEnte.bnccNacional) {
        const objetivosAprendizagemPromises = materiasSelecionadas.map(async (materia) => (new Promise(async (resolve) => {
          const objetivosAprendizagem = this.objetivosAprendizagemCampoExperienciaService
            .carregarObjetivosAprendizagemPorMateria(materia.value);
          resolve(objetivosAprendizagem);
        })));

        const objetivosAprendizagemResults = await Promise.all(objetivosAprendizagemPromises);
        const objetivosAprendizagem = [];

        objetivosAprendizagemResults.forEach((objetivosAprendizagemResult) => {
          objetivosAprendizagemResult.forEach((objetivoAprendizagem) => {
            Object.assign(objetivoAprendizagem, { value: objetivoAprendizagem.id, label: objetivoAprendizagem.descricao });
            objetivosAprendizagem.push(objetivoAprendizagem);
          });
        });

        this.setState({ objetivosAprendizagem });
      } else {
        const habilidadesPromises = materiasSelecionadas.map((materia) => (new Promise(async (resolve) => {
          const habilidades = this.habilidadesCompetenciasEspecificasService
            .carregarHabilidadesPorMateria(materia.value);
          resolve(habilidades);
        })));

        const habilidadesResults = await Promise.all(habilidadesPromises);
        const habilidades = [];

        habilidadesResults.forEach((habilidadesResult) => {
          habilidadesResult.forEach((habilidade) => {
            Object.assign(habilidade, { value: habilidade.id, label: habilidade.descricao });
            habilidades.push(habilidade);
          });
        });

        this.setState({ habilidades });
      }
    } catch (e) {
      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: e || 'Erro ao carregar objetivos de aprendizagem/habilidades',
      });
    }

    this.setState({
      showLoaderModal: false,
    });

  }

  conteudoPagina() {
    return this.state.selectDatas ? <>
      <Row>
        <Col md="12">
          <Alert
            color={this.state.alertColor}
            isOpen={this.state.showAlert}
            toggle={() => { this.setState({ showAlert: false }); }}>
            {this.state.alertMsg}
          </Alert>
          <Breadcrumb>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos`}>Anos letivos</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}`}>Ano letivo {this.state.anoLetivo.ano}</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}`}>{this.state.escola.nome}</Link></BreadcrumbItem>
            {
              !this.state.turma.multiSeriado
                ? <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/${this.state.turma.id}`}>{this.state.turma.nome} - {this.turnos[this.state.turma.turno].label}</Link></BreadcrumbItem>
                : <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/multiseriado/${this.state.turma.turmaMultiseriado.id}`}>{this.state.turma.turmaMultiseriado.nome} - {this.turnos[this.state.turma.turno].label}</Link></BreadcrumbItem>
            }
            {
              !this.state.turma.multiSeriado
                ? <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/${this.state.turma.id}/diario/${this.state.diario.id}${this.state.alfabetizacao}`}>{this.state.diario.nome}</Link></BreadcrumbItem>
                : <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/multiseriado/${this.state.turma.turmaMultiseriado.id}/diario`}>Diário único</Link></BreadcrumbItem>
            }
            {
              !this.state.turma.multiSeriado
                ? <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/${this.state.turma.id}/diario/${this.state.diario.id}/registroaulas`}>Registro de Aulas</Link></BreadcrumbItem>
                : <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/multiseriado/${this.state.turma.turmaMultiseriado.id}/diario/registroaulas`}>Registro de Aulas</Link></BreadcrumbItem>
            }
            <BreadcrumbItem active>{this.state.registroAulaSelecionado ? 'Edição' : 'Cadastro'}</BreadcrumbItem>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Form onSubmit={this.onSubmit}>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">
                  Cadastro de Registro de Aula
                </CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="2">
                    <FormGroup className={'has-label has-danger'}>
                      <label>Data {this.state.dadosEnte.registroAulasPeriodo && this.state.turma.curso.cicloEnsino !== CicloEnsino.ENSINO_FUNDAMENTAL_2.value ? 'início' : ''} *</label>
                      <Select
                        isDisabled={this.props.match.params.idRegistroAula}
                        noOptionsMessage={() => 'Nenhuma entrada'}
                        className={`react-select primary ${this.state.dataState}`}
                        classNamePrefix="react-select"
                        value={this.state.dataSelecionada}
                        onChange={(event) => {
                          const mData = moment(event.label, 'DD/MM/YYYY');
                          const fimDeSemana = mData.day() === 6 || mData.day() === 0;
                          this.setState({
                            dataSelecionada: event,
                            fimDeSemana,
                            dataFimSelecionada: '',
                            materiasSelecionadas: this.state.dadosEnte.registroAulasPeriodo ? this.state.materiasSelecionadas : '',
                            numeroAulas: '',
                            dataState: 'primary',
                            materiasState: '',
                            objetivosAprendizagemSelecionados: [],
                            habilidadesSelecionadas: [],
                          });
                          this.carregarMateriasDoProfessorNoDia(event);
                        }}
                        options={this.state.selectDatas}
                        placeholder="Data..."
                      />
                      {this.state.dataState === 'danger' ? (
                        <label className="error">
                          { this.state.dadosEnte.registroAulasPeriodo ? 'Informe a data de início' : 'Informe a data da aula.'}
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                  {
                    this.state.dadosEnte.registroAulasPeriodo && this.state.turma.curso.cicloEnsino !== CicloEnsino.ENSINO_FUNDAMENTAL_2.value && <Col md="2">
                      <FormGroup className={'has-label has-danger'}>
                        <label>Data fim *</label>
                        <Select
                          isDisabled={this.props.match.params.idRegistroAula}
                          noOptionsMessage={() => 'Nenhuma entrada'}
                          className={`react-select primary ${this.state.dataFimState}`}
                          classNamePrefix="react-select"
                          value={this.state.dataFimSelecionada}
                          onChange={(event) => {
                            this.setState({
                              dataFimSelecionada: event,
                              dataFimState: 'primary',
                            });
                          }}
                          options={this.state.selectDatas}
                          placeholder="Data..."
                        />
                        {this.state.dataFimState === 'danger' ? (
                          <label className="error">
                            {this.state.dataFimErro}
                          </label>
                        ) : null}
                      </FormGroup>
                    </Col>
                  }
                  <Col md="8">
                    <FormGroup className={'has-label has-danger'}>
                      <label>{this.state.turma.curso.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value
                        ? 'Campo de experiências' : 'Componente curricular'} *</label>
                      <Select
                        isDisabled={this.props.match.params.idRegistroAula}
                        noOptionsMessage={() => 'Nenhuma entrada'}
                        className={`react-select primary ${this.state.materiasState}`}
                        classNamePrefix="react-select"
                        closeMenuOnSelect={!this.state.dadosEnte.registroAulasPeriodo}
                        isMulti
                        value={this.state.materiasSelecionadas}
                        onChange={async (event) => {
                          let materiasSelecionadas;
                          if (this.state.materiasSelecionadas.length === 0 || !event || event.length === 0 || (this.state.dadosEnte.registroAulasPeriodo && this.state.turma.curso.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value)){
                            materiasSelecionadas = event || [];
                          } else {
                            materiasSelecionadas = this.state.materiasSelecionadas;
                          } 
                          this.setState({
                            materiasState: 'primary',
                            materiasSelecionadas,
                            numeroAulas: event && event[0] ? event[0]?.numeroAulas : '',
                            objetivosAprendizagemSelecionados: [],
                            habilidadesSelecionadas: [],
                          });
                          await this.carregarObjetivosAprendizagemHabilidades(event || []);
                        }}
                        options={this.state.selectMaterias}
                        placeholder={this.state.turma.curso.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value
                          ? 'Campo de experiências' : 'Componente curricular'}
                      />
                      {this.state.materiasState === 'danger' ? (
                        <label className="error">
                          Informe o {this.state.turma.curso.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value
                            ? 'campo de experiências' : 'componente curricular'} da aula.
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                  {
                    !(this.state.dadosEnte.registroAulasPeriodo && this.state.turma.curso.cicloEnsino !== CicloEnsino.ENSINO_FUNDAMENTAL_2.value) && <Col md="2">
                      <FormGroup className={`has-label ${this.state.numeroAulasState}`}>
                        <label>Nº de aulas</label>
                        <Input
                          value={this.state.numeroAulas}
                          decimalSeparator={false}
                          allowNegative={false}
                          style={{ textAlign: 'right' }}
                          onValueChange={(val) => {
                            this.setState({
                              numeroAulasState: '',
                              numeroAulas: parseInt(val.formattedValue),
                            });
                          }}
                          tag={NumberFormat} />
                        {this.state.numeroAulasState === 'has-danger' ? (
                          <label className="error">
                            {this.state.numeroAulasErro}
                          </label>
                        ) : null}
                      </FormGroup>
                    </Col>
                  }
                </Row>
                {
                  this.state.dadosEnte?.habilidadesRegistroAula && <Row>
                    <Col>
                      {
                        (this.state.turma.curso.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value && !this.state.dadosEnte.bnccNacional) ? <FormGroup className={'has-label has-danger'}>
                          <label>Objetivos de aprendizagem e desenvolvimento*</label>
                          <Select
                            noOptionsMessage={() => 'Nenhuma entrada'}
                            className={`react-select primary ${this.state.objetivosAprendizagemSelecionadosState}`}
                            classNamePrefix="react-select"
                            placeholder="Objetivos de aprendizagem e desenvolvimento..."
                            closeMenuOnSelect={false}
                            isMulti
                            value={this.state.objetivosAprendizagemSelecionados}
                            onChange={(event) => {
                              this.setState({
                                objetivosAprendizagemSelecionados: event || [],
                                objetivosAprendizagemSelecionadosState: '',
                              });
                            }}
                            options={this.state.objetivosAprendizagem}
                          />
                          {this.state.objetivosAprendizagemSelecionadosState === 'danger' ? (
                            <label className="error">
                              Informe ao menos um objetivo de aprendizagem.
                            </label>
                          ) : null}
                        </FormGroup> : <FormGroup className={'has-label has-danger'}>
                          <label>{this.state.turma.curso.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value ? 'Objetivos de aprendizagem e desenvolvimento*' : 'Habilidades *'}</label>
                          <Select
                            noOptionsMessage={() => 'Nenhuma entrada'}
                            className={`react-select primary ${this.state.habilidadesSelecionadasState}`}
                            classNamePrefix="react-select"
                            placeholder={this.state.turma.curso.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value ? 'Objetivos de aprendizagem e desenvolvimento...' : 'Habilidades...'}
                            closeMenuOnSelect={false}
                            isMulti
                            value={this.state.habilidadesSelecionadas}
                            onChange={(event) => {
                              this.setState({
                                habilidadesSelecionadas: event || [],
                                habilidadesSelecionadasState: '',
                              });
                            }}
                            options={this.state.habilidades}
                          />
                          {this.state.habilidadesSelecionadasState === 'danger' ? (
                            <label className="error">
                              Informe ao menos uma habilidade.
                            </label>
                          ) : null}
                        </FormGroup>
                      }
                    </Col>
                  </Row>
                }
                {
                  ((!this.state.dadosEnte?.registroAulasPeriodo && this.state.dadosEnte?.habilidadesRegistroAula && !(this.state.turma.curso.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value)) || !this.state.dadosEnte?.habilidadesRegistroAula) && <Row>
                    <Col>
                      <FormGroup className={`has-label ${this.state.objetoConhecimentoState}`}>
                        <label>{this.state.turma.curso.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value
                          ? 'Objetivo de aprendizagem e desenvolvimento' : 'Objetos de conhecimento'} *</label>
                        <Input
                          value={this.state.objetoConhecimento}
                          onChange={(evt) => {
                            this.setState({
                              objetoConhecimento: evt.target.value,
                              objetoConhecimentoState: '',
                            });
                          }}
                          maxLength="500"
                          type="textarea"
                          style={{ height: '300px' }} />
                        {this.state.objetoConhecimentoState === 'has-danger' ? (
                          <label className="error">
                            Informe o {this.state.turma.curso.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value
                              ? 'objetivo de aprendizagem e desenvolvimento' : 'o objeto de conhecimento'} da aula.
                          </label>
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>
                }
                <Row>
                  <Col>
                    <FormGroup className={`has-label ${this.state.metodologiaState}`}>
                      <label>Metodologia *</label>
                      <Input
                        onClick={this.handleClick}
                        value={this.state.metodologia}
                        onChange={(evt) => {
                          this.setState({
                            metodologia: evt.target.value,
                            metodologiaState: ''
                          });
                        }}
                        type="textarea"
                        style={{ height: '300px' }} />
                      {this.state.metodologiaState === 'has-danger' ? (
                        <label className="error">
                          Informe a metodologia
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>
                {
                  this.state.dadosEnte.registroAulasPeriodo && <Row>
                    <Col>
                      <FormGroup className={`has-label ${this.state.observacoesState}`}>
                        <label>Observações</label>
                        <Input
                          onClick={this.handleClick}
                          value={this.state.observacoes}
                          onChange={(evt) => {
                            this.setState({
                              observacoes: evt.target.value,
                              observacoesState: ''
                            });
                          }}
                          type="textarea"
                          style={{ height: '300px' }} />
                        {this.state.observacoesState === 'has-danger' ? (
                          <label className="error">
                            Informe as observações
                          </label>
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>
                }
                {
                  this.state.dadosEnte.habilidadesRegistroAula && !this.state.dadosEnte.registroAulasPeriodo && this.state.turma.curso.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value && <Row>
                    <Col>
                      <FormGroup className={`has-label ${this.state.direitosAprendizagemState}`}>
                        <label>Direitos de aprendizagem *</label>
                        <Input
                          onClick={this.handleClick}
                          value={this.state.direitosAprendizagem}
                          onChange={(evt) => {
                            this.setState({
                              direitosAprendizagem: evt.target.value,
                              direitosAprendizagemState: ''
                            });
                          }}
                          type="textarea"
                          style={{ height: '300px' }} />
                        {this.state.direitosAprendizagemState === 'has-danger' ? (
                          <label className="error">
                            Informe os direitos de aprendizagem
                          </label>
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>
                }
              </CardBody>
              <CardFooter>
                <Row>
                  <Col className="text-left">
                    <Button
                      color="primary"
                      onClick={() => this.props.history.goBack()} >
                      Voltar
                    </Button>
                  </Col>
                  <Col className="text-right">
                    {
                      this.props.role === Papel.PROFESSOR.value && <Button type="submit" color="primary" disabled={this.state.showLoaderModal} >
                        {!this.state.registroAulaSelecionado ? 'Cadastrar' : 'Atualizar'}
                      </Button>
                    }
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </Form>
        </Col>
      </Row>
    </> : <Card>
      <div align="center" style={{ margin: 50 }}>
        <Loader
          type="Oval"
          color="#34B5B8"
          height="50"
          width="50" />
      </div>
    </Card>;
  }

  render() {
    return (
      <div className='content'>
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
            </div>
          </Card>
        }
        <LoaderModal
          isOpen={this.state.showLoaderModal}
          text={this.state.loaderModalText} />
        <MetodologiaModal
          isOpen={this.state.showMetodologiaModal}
          closeCallback={(metodologia) => {
            if (metodologia) {
              this.setState({
                metodologia: metodologia.label,
                metodologiaState: '',
              });
            }
            this.setState({ showMetodologiaModal: false });
          }} />
      </div>
    );
  }
}

export default RegistroAulas;
