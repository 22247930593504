import React from 'react';

import {
  Modal,
  Row,
  Col,
  FormGroup,
  Input,
  Button,
} from 'reactstrap';

import Loader from 'react-loader-spinner';

import TransportesService from 'services/TransportesService';

class TransporteModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: props.isOpen,
      idTransporte: props.idTransporte,
      idAnoLetivo: props.idAnoLetivo,

      loading: true,
      erro: null,

    };

    this.transportesService = new TransportesService();
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.isOpen !== nextProps.isOpen) {
      this.setState({ isOpen: nextProps.isOpen });
    }
    if (this.state.idTransporte !== nextProps.idTransporte) {
      this.setState({ idTransporte: nextProps.idTransporte });
      this.carregarDados(nextProps.idTransporte);
    }
  }

  async carregarDados(idTransporte) {
    this.setState({ erro: false, loading: true });
    try {
      const transporte = await this.transportesService
        .carregarTransporte(idTransporte);

      this.setState({
        transporte,
        loading: false,
      });
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  conteudoPagina() {
    return (
      this.state.loading ? <>
        <div align="center" style={{ margin: 50 }}>
          <Loader
            type="Oval"
            color="#34B5B8"
            height="50"
            width="50" />
        </div>
      </> : <>
        <Row>
          <Col md="6">
            <FormGroup>
              <label>Modelo</label>
              <Input
                disabled
                value={this.state.transporte.modelo}
                type="text"
              />
            </FormGroup>
          </Col>
          <Col md="2">
            <FormGroup>
              <label>Ano de fabricação</label>
              <Input
                disabled
                value={this.state.transporte.ano}
                type="text"
              />
            </FormGroup>
          </Col>
          <Col md="2">
            <FormGroup>
              <label>Placa</label>
              <Input
                disabled
                value={this.state.transporte.placa}
                type="text"
              />
            </FormGroup>
          </Col>
          <Col md="2">
            <FormGroup>
              <label>CPF/CNPJ do proprietário</label>
              <Input
                disabled
                value={this.state.transporte.cpfProprietario}
                type="text"
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <FormGroup>
              <label>Nome/Razão Social do proprietário</label>
              <Input
                disabled
                value={this.state.transporte.proprietario}
                type="text"
              />
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <label>Itinerário</label>
              <Input
                disabled
                value={this.state.transporte.itinerario}
                type="text"
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col className="text-center">
            <Button onClick={() => this.props.closeCallback()}>Fechar</Button>
          </Col>
        </Row>
      </>
    );
  }

  render() {
    return (
      <Modal
        size="xl"
        style={{ maxWidth: '1600px', width: '80%' }}
        isOpen={this.state.isOpen}>
        <div className="modal-body">
          {
            !this.state.erro
              ? this.conteudoPagina()
              : <div align="center" style={{ margin: 50 }}>
                Erro ao buscar informações do aluno<br/><br/>
                <Button onClick={() => this.props.closeCallback()}>Fechar</Button>
              </div>
          }
        </div>
      </Modal>
    );
  }
}

export default TransporteModal;
