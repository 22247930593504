import React from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';

import {
  Row,
  Col,
  Button,
  Form,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  FormGroup,
  CardFooter,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap';

import Loader from 'react-loader-spinner';
import Select from 'react-select';

import Utils from 'utils/Utils';

import Alert from 'views/components/Alert';
import LoaderModal from 'views/components/LoaderModal';

import EntesService from 'services/EntesService';
import AnosLetivosService from 'services/AnosLetivosService';
import EscolasService from 'services/EscolasService';
import UsuarioService from 'services/UsuarioService';
import TurmasService from 'services/TurmasService';
import RelatoriosService from 'services/RelatoriosEstatisticosService';

import Papel from 'assets/csv/papeis.json';
import TipoAssinatura from 'assets/csv/tipos-assinatura.json';

class ImprimirRelatorioFrequenciaAlunosPorMes extends React.Component {
  constructor(props) {
    super(props);

    this.tiposAssinatura = [
      TipoAssinatura.MANUAL,
      TipoAssinatura.AUTOMATICA,
    ];

    this.entesService = new EntesService();
    this.anosLetivosService = new AnosLetivosService();
    this.escolasService = new EscolasService();
    this.usuarioService = new UsuarioService();
    this.turmasService = new TurmasService();
    this.relatoriosService = new RelatoriosService();

    this.state = {
      showAlert: false,
      error: null,

      carregandoAlunos: false,

      alunoSelecionado: null,
      turmaSelecionada: null,

      turmas: [],
      alunos: [],
    };

    this.imprimirRelatorioFrequenciaAlunoPorMes = this.imprimirRelatorioFrequenciaAlunoPorMes.bind(this);
  }

  async componentDidMount() {
    this.debouncedFunction = _.debounce((text) => {
      if (text === '') {
        this.setState({ carregandoAlunos: false, alunos: [] });
      } else {
        this.carregarAlunos(text);
      }
    }, 1500);

    try {
      const dadosEnte = await this.entesService.carregarDadosEnte();

      let data = await this.anosLetivosService.carregarAnosLetivos(1, 99999, '');
      const { anosLetivos } = data;
      anosLetivos.forEach((anoLetivo) => Object.assign(
        anoLetivo, { value: anoLetivo.id, label: anoLetivo.ano },
      ));

      data = await this.escolasService.carregarEscolas(1, 99999, '');
      let { escolas } = data;
      escolas.forEach((escola) => Object.assign(
        escola, { value: escola.id, label: escola.nome },
      ));
      /*if (this.props.role === Papel.GESTOR.value) {
        escolas = [{ value: null, label: 'Todas as escolas' }].concat(escolas);
      }*/

      this.setState({
        anosLetivos,
        escolas,
        dadosEnte,
      });
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  validarCampos() {
    let ret = true;

    if (!this.state.anoLetivoSelecionado) {
      this.setState({ anoLetivoState: 'danger' });
      ret = false;
    }

    if (!this.state.alunoSelecionado) {
      this.setState({ alunoState: 'danger' });
      ret = false;
    }

    if (!this.state.turmaSelecionada) {
      this.setState({ turmaState: 'danger' });
      ret = false;
    }

    return ret;
  }

  limparFormulario() {
    this.setState({
      alunoState: '',
      turmaState: '',

      alunoSelecionado: null,
      turmaSelecionada: null,

      alunos: [],
      turmas: [],

      carregandoAlunos: false,
    });
  }

  async carregarAlunos(like) {
    try {
      let data;
      if(this.state.dadosEnte.alunosPorEscola) {
        data = await this.usuarioService
          .carregarUsuariosVinculadosEscola(this.state.escolaSelecionada.id, Papel.ALUNO.value, 1, 99999, like);
      } else {
        data = await this.usuarioService
          .carregarUsuarios(Papel.ALUNO.value, 1, 99999, like);
      }

      const selectAlunos = [];
      data.usuarios.forEach((aluno) => {
        selectAlunos.push({ value: aluno.id, label: `${aluno.nome} (Mãe: ${aluno.nomeMae})` });
      });

      this.setState({
        carregandoAlunos: false,
        alunos: selectAlunos,
      });
    } catch (e) {
      if (this.umounted) return;
      this.setState({
        carregandoAlunos: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao buscar alunos cadastrados',
      });
    }
  }

  async carregarTurmas(idAluno) {
    this.setState({
      showAlert: false,
      showLoaderModal: true,
      loaderModalText: 'Carregando turmas do aluno...',
    });

    try {
      let matriculas = await this.turmasService
        .carregarTodasMatriculasDoAluno(idAluno);

      matriculas = matriculas.filter((mat) => mat.ano === this.state.anoLetivoSelecionado.ano);

      const selectTurmas = [];
      matriculas.forEach((matricula) => {
        selectTurmas.push({ value: matricula.id, label: matricula.curso });
      });

      this.setState({
        showLoaderModal: false,
        turmas: selectTurmas,
      });
    } catch (e) {
      if (this.umounted) return;
      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao buscar turmas do aluno',
      });
    }
  }

  async imprimirRelatorioFrequenciaAlunoPorMes(e) {
    e.preventDefault();

    if (this.state.showLoaderModal) return;
    if (!this.validarCampos()) return;

    this.setState({
      showAlert: false,
      showLoaderModal: true,
      loaderModalText: 'Imprimindo relatório de frequência de aluno por mês para pdf...',
    });

    try {
      const pdf = await this.relatoriosService
        .imprimirRelatorioFrequenciaAlunoPorMes(this.state.turmaSelecionada.value);

      const file = Utils.base64ToBlob(pdf);
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);

      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Relatório de frequência de aluno por mês impresso com sucesso. Caso o documento não seja visualizado, desabilite o bloqueador de pop-up para este site.',
      });
    } catch (msg) {
      if (this.umounted) return;
      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao imprimir relatório de frequência de aluno por mês',
      });
    }
  }

  conteudoPagina() {
    return this.state.anosLetivos ? <>
      <Row>
        <Col md="12">
          <Alert
            color={this.state.alertColor}
            isOpen={this.state.showAlert}
            toggle={() => { this.setState({ showAlert: false }); }}>
            {this.state.alertMsg}
          </Alert>
          <Breadcrumb>
          <BreadcrumbItem><Link to={`${this.props.layout}/relatorios`}>Relatórios</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/relatorios/alunos`}>Relatórios de alunos</Link></BreadcrumbItem>
            <BreadcrumbItem active>Relatório de frequência do aluno por mês</BreadcrumbItem>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Form onSubmit={this.imprimirRelatorioFrequenciaAlunoPorMes}>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Imprimir relatório de frequência do aluno por mês</CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                <Col md="2">
                    <FormGroup className={'has-label has-danger'}>
                      <label>Ano letivo *</label>
                      <Select
                        noOptionsMessage={() => 'Nenhuma entrada'}
                        className={`react-select primary ${this.state.anoLetivoState}`}
                        classNamePrefix="react-select"
                        placeholder="Ano letivo..."
                        value={this.state.anoLetivoSelecionado}
                        onChange={(event) => {
                          this.setState({
                            anoLetivoSelecionado: event,
                            anoLetivoState: '',
                            alunos: undefined,
                            alunoSelecionado: '',
                            turmas: undefined,
                            turmaSelecionada: '',
                          });
                        }}
                        options={this.state.anosLetivos}
                      />
                      {this.state.anoLetivoState === 'danger' ? (
                        <label className="error">
                          Informe o ano letivo.
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup className={'has-label has-danger'}>
                      <label>Escola *</label>
                      <Select
                        isDisabled={!this.state.anoLetivoSelecionado}
                        noOptionsMessage={() => 'Nenhuma entrada'}
                        className={`react-select primary ${this.state.escolaState}`}
                        classNamePrefix="react-select"
                        placeholder="Escola..."
                        value={this.state.escolaSelecionada}
                        onChange={(event) => {
                          this.setState({
                            escolaSelecionada: event,
                            escolaState: '',
                          });
                        }}
                        options={this.state.escolas}
                      />
                      {this.state.escolaState === 'danger' ? (
                        <label className="error">
                          Informe a escola.
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup className={'has-label has-danger'}>
                      <label>Aluno *</label>
                      <Select
                        isLoading={this.state.carregandoAlunos}
                        loadingMessage={() => 'Carregando...'}
                        noOptionsMessage={() => 'Nenhuma entrada'}
                        className={`react-select primary ${this.state.alunoState}`}
                        classNamePrefix="react-select"
                        value={this.state.alunoSelecionado}
                        onInputChange={(text) => {
                          this.setState({ carregandoAlunos: text !== '', alunoState: 'primary' });
                          this.debouncedFunction(text);
                        }}
                        onChange={(event) => {
                          this.setState({
                            alunoState: 'primary',
                            alunoSelecionado: event,
                            turmas: [],
                            turmaSelecionada: '',
                          });
                          this.carregarTurmas(event.value);
                        }}
                        options={this.state.alunos}
                        placeholder="Digite o nome de um aluno cadastro no sistema..."
                        isDisabled={!this.state.escolaSelecionada}
                      />
                      {this.state.alunoState === 'danger' ? (
                        <label className="error">
                          Informe o nome de um aluno cadastrado.
                        </label>
                      ) : <label></label>}
                    </FormGroup>
                  </Col>
                  <Col md="2">
                    <FormGroup className={'has-label has-danger'}>
                      <label>Turma *</label>
                      <Select
                        noOptionsMessage={() => 'Nenhuma entrada'}
                        className={`react-select primary ${this.state.turmaState}`}
                        classNamePrefix="react-select"
                        value={this.state.turmaSelecionada}
                        onChange={(event) => {
                          this.setState({ turmaState: 'primary', turmaSelecionada: event });
                        }}
                        options={this.state.turmas}
                        placeholder="Selecione uma turma..."
                        isDisabled={!this.state.alunoSelecionado}
                      />
                      {this.state.turmaState === 'danger' ? (
                        <label className="error">
                          Informe a turma.
                        </label>
                      ) : <label></label>}
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <Row>
                  <Col className="text-left">
                    <Button
                      color="primary"
                      onClick={() => this.props.history
                        .push(`${this.props.layout}/relatorios/alunos`)} >
                      Voltar
                  </Button>
                  </Col>
                  <Col className="text-right">
                    <Button
                      type="submit"
                      disabled={this.state.showLoaderModal}
                      color="primary">
                      Imprimir
                    </Button>
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </Form>
        </Col >
      </Row >
    </>
      : <Card>
        <div align="center" style={{ margin: 50 }}>
          <Loader
            type="Oval"
            color="#34B5B8"
            height="50"
            width="50" />
        </div>
      </Card>;
  }

  render() {
    return (
      <div className='content'>
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
            </div>
          </Card>
        }
        <LoaderModal
          isOpen={this.state.showLoaderModal}
          text={this.state.loaderModalText} />
      </div>
    );
  }
}

export default ImprimirRelatorioFrequenciaAlunosPorMes;
