import React from 'react';
import { Link } from 'react-router-dom';
// reactstrap components
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Row,
} from 'reactstrap';

import Loader from 'react-loader-spinner';
import Select from 'react-select';

import Alert from 'views/components/Alert';
import LoaderModal from 'views/components/LoaderModal';

import EstoquesService from 'services/EstoquesService';
import EscolasService from 'services/EscolasService';

import UnidadeMedida from 'assets/csv/unidades-medida.json';

class CadastroEstoqueItem extends React.Component {
  constructor(props) {
    super(props);

    this.unidadesMedida = [
      UnidadeMedida.QUILOGRAMA,
      UnidadeMedida.LITRO,
      UnidadeMedida.UNIDADE,
      UnidadeMedida.METRO,
      UnidadeMedida.METRO_QUADRADO,
      UnidadeMedida.METRO_CUBICO,
      UnidadeMedida.CAIXA,
      UnidadeMedida.PACOTE,
      UnidadeMedida.GRAMA,
      UnidadeMedida.MILILITRO,
      UnidadeMedida.PAR,
      UnidadeMedida.FARDO
    ];

    this.estoquesService = new EstoquesService();
    this.escolasService = new EscolasService();

    this.state = {
      loading: true,

      showAlert: false,
      showLoaderModal: false,

      nome: '',
      nomeState: '',
      loaderModalText: '',

      estoqueItemSelecionado: null,
      unidadeMedidaSelecionada: '',

      erro: null,
    };

    this.cadastrarEstoqueItem = this.cadastrarEstoqueItem.bind(this);
    this.atualizarEstoqueItem = this.atualizarEstoqueItem.bind(this);
    this.validarCampos = this.validarCampos.bind(this);
    this.limparFormulario = this.limparFormulario.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  async componentDidMount() {
    try {
      const estoque = await this.estoquesService
        .carregarEstoque(this.props.match.params.idEstoque);

      if (this.props.match.params.idEscola !== undefined) {
        const escola = await this.escolasService.carregarEscola(this.props.match.params.idEscola);
        this.setState({ escola });
      }

      if (this.props.match.params.idEstoqueItem !== undefined) {
        const estoqueItem = await this.estoquesService
          .carregarEstoqueItem(this.props.match.params.idEstoqueItem);

        this.setState({ estoqueItemSelecionado: estoqueItem });

        this.contentLoad();
      }

      this.setState({
        estoque,
        loading: false,
      });
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  contentLoad() {
    this.setState({
      nome: this.state.estoqueItemSelecionado.nome,
      unidadeMedidaSelecionada: this.unidadesMedida[this.state.estoqueItemSelecionado.unidadeMedida],
    });
  }

  validarCampos() {
    let ret = true;
    if (this.state.nome === '') {
      this.setState({ nomeState: 'has-danger' });
      ret = false;
    }
    if (!this.state.unidadeMedidaSelecionada) {
      this.setState({ unidadeMedidaState: 'danger' });
      ret = false;
    }
    return ret;
  }

  async cadastrarEstoqueItem() {
    if (!this.validarCampos()) return;

    this.setState({
      showLoaderModal: true,
      showAlert: false,
      loaderModalText: 'Cadastrando item...',
    });

    const estoqueItem = {
      nome: this.state.nome,
      unidadeMedida: this.state.unidadeMedidaSelecionada.value,
      estoque: { id: this.props.match.params.idEstoque }
    };

    try {
      await this.estoquesService.cadastrarEstoqueItem(estoqueItem);

      this.limparFormulario();

      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Item cadastrado com sucesso',
      });
    } catch (e) {
      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: e || 'Erro ao cadastrar item',
      });
    }
  }

  async atualizarEstoqueItem() {
    if (!this.validarCampos()) return;

    this.setState({
      showLoaderModal: true,
      showAlert: false,
      loaderModalText: 'Atualizando item...',
    });

    const estoqueItem = {
      id: this.state.estoqueItemSelecionado.id,
      nome: this.state.nome,
      unidadeMedida: this.state.unidadeMedidaSelecionada.value,
      estoque: { id: this.props.match.params.idEstoque }
    };

    try {
      await this.estoquesService.atualizarEstoqueItem(estoqueItem);

      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Item atualizado com sucesso',
      });
    } catch (e) {
      if (this.umounted) return;
      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: e || 'Erro ao atualizar item',
      });
    }
  }

  limparFormulario() {
    this.setState({
      nome: '',
      unidadeMedidaSelecionada: '',

      nomeState: '',
      unidadeMedidaState: '',
    });
  }

  onSubmit(e) {
    e.preventDefault();

    if (this.state.showLoaderModal) return;

    if (!this.state.estoqueItemSelecionado) {
      this.cadastrarEstoqueItem();
    } else {
      this.atualizarEstoqueItem();
    }
  }

  voltar() {
    if (this.props.match.params.idEscola !== undefined) {
      this.props.history.push(`${this.props.layout}/escolas/${this.props.match.params.idEscola}/estoques/${this.props.match.params.idEstoque}`);
    } else {
      this.props.history.push(`${this.props.layout}/estoques/${this.props.match.params.idEstoque}`);
    }
  }

  conteudoPagina() {
    return this.state.loading ? <Card>
      <div align="center" style={{ margin: 50 }}>
        <Loader
          type="Oval"
          color="#34B5B8"
          height="50"
          width="50" />
      </div>
    </Card> : <Row>
      <Col md="12">
        <Alert
          color={this.state.alertColor}
          isOpen={this.state.showAlert}
          toggle={() => { this.setState({ showAlert: false }); }}>
          {this.state.alertMsg}
        </Alert>
        {
          this.props.match.params.idEscola === undefined ? <Breadcrumb>
            <BreadcrumbItem><Link to={`${this.props.layout}/estoques`}>Estoques</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/estoques/${this.props.match.params.idEstoque}`}>{this.state.estoque.nome}</Link></BreadcrumbItem>
            <BreadcrumbItem active>{this.state.estoqueItemSelecionado ? this.state.estoqueItemSelecionado.nome : 'Cadastro de Item'}</BreadcrumbItem>
          </Breadcrumb> : <Breadcrumb>
            <BreadcrumbItem><Link to={`${this.props.layout}/escolas`}>Escolas</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/escolas/${this.props.match.params.idEscola}`}>{this.state.escola.nome}</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/escolas/${this.props.match.params.idEscola}/estoques/${this.props.match.params.idEstoque}`}>{this.state.estoque.nome}</Link></BreadcrumbItem>
            <BreadcrumbItem active>{this.state.estoqueItemSelecionado ? this.state.estoqueItemSelecionado.nome : 'Cadastro de Item'}</BreadcrumbItem>
          </Breadcrumb>
        }
        <Form onSubmit={this.onSubmit}>
          <Card>
            <CardHeader>
              <CardTitle tag="h4">Dados do item</CardTitle>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md="5">
                  <FormGroup className={`has-label ${this.state.nomeState}`}>
                    <label>Nome *</label>
                    <Input
                      value={this.state.nome}
                      name="nomeEstoque"
                      type="text"
                      maxLength="100"
                      onChange={(e) => this.setState({ nome: e.target.value, nomeState: 'has-success' })}
                    />
                    {this.state.nomeState === 'has-danger' ? (
                      <label className="error">
                        Informe o nome do item.
                      </label>
                    ) : null}
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup className={'has-label has-danger'}>
                    <label>Unidade de medida *</label>
                    <Select
                      noOptionsMessage={() => 'Nenhuma entrada'}
                      className={`react-select primary ${this.state.unidadeMedidaState}`}
                      classNamePrefix="react-select"
                      value={this.state.unidadeMedidaSelecionada}
                      onChange={(event) => {
                        this.setState({ unidadeMedidaState: '', unidadeMedidaSelecionada: event });
                      }}
                      options={this.unidadesMedida}
                      placeholder="Unidade de medida..."
                    />
                    {this.state.unidadeMedidaState === 'danger' ? (
                      <label className="error">
                        Informe a unidade de medida.
                      </label>
                    ) : null}
                  </FormGroup>
                </Col>
              </Row>
              <div className="category form-category">
                * Campos obrigatórios
              </div>
            </CardBody>
            <CardFooter>
              <Row>
                <Col md="6" className="text-left">
                  <Button color="primary" onClick={() => this.voltar()} >
                    Voltar
                  </Button>
                </Col>
                <Col md="6" className="text-right">
                  {
                    !this.state.estoqueItemSelecionado
                      ? <Button type="submit" color="primary" >
                        Cadastrar
                      </Button>
                      : <Button type="submit" style={{ marginRight: 5 }} color="primary">
                        Atualizar
                      </Button>
                  }
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Form>
      </Col>
    </Row>;
  }

  render() {
    return (
      <div className="content">
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
            </div>
          </Card>
        }
        <LoaderModal
          isOpen={this.state.showLoaderModal}
          text={this.state.loaderModalText} />
      </div>
    );
  }
}

export default CadastroEstoqueItem;
