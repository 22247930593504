import React from 'react';
import { NavLink } from 'react-router-dom';
import env from 'conf/env.json';

import ReCAPTCHA from 'react-google-recaptcha';
import validator from 'validator';
import phone from 'phone';

import LoaderModal from 'views/components/LoaderModal';

import DepoimentosService from 'services/DepoimentosService';

import ScrollableAnchor, { configureAnchors } from 'react-scrollable-anchor';

import 'views/raiz/assets/vendor/icofont/icofont.min.css';
import 'views/raiz/assets/vendor/boxicons/css/boxicons.min.css';
import 'views/raiz/assets/vendor/remixicon/remixicon.css';
import 'views/raiz/assets/vendor/venobox/venobox.css';
import 'views/raiz/assets/vendor/owl.carousel/assets/owl.carousel.min.css';
import 'views/raiz/assets/vendor/aos/aos.css';

import 'views/raiz/assets/css/style.css';

// portfólio
//import carouselImg01 from 'views/raiz/assets/img/portfolio/01-dashboard.png';
//import carouselImg02 from 'views/raiz/assets/img/portfolio/02-diario.png';
//import carouselImg03 from 'views/raiz/assets/img/portfolio/03-notas.png';
//import carouselImg04 from 'views/raiz/assets/img/portfolio/04-calendario.png';
//import carouselImg05 from 'views/raiz/assets/img/portfolio/05-frequencia.png';
//import carouselImg06 from 'views/raiz/assets/img/portfolio/06-ata-final.png';
//import carouselImg07 from 'views/raiz/assets/img/portfolio/07-aluno.png';
//import carouselImg08 from 'views/raiz/assets/img/portfolio/08-ano-letivo.png';
//import carouselImg09 from 'views/raiz/assets/img/portfolio/09-cursos.png';
//import carouselImg10 from 'views/raiz/assets/img/portfolio/10-escola.png';
//import carouselImg11 from 'views/raiz/assets/img/portfolio/11-app1.png';
//import carouselImg12 from 'views/raiz/assets/img/portfolio/12-app2.png';

import RootFooter from './Footer';

const clients = [
  { src: 'https://sisaeduc.s3.sa-east-1.amazonaws.com/public/clients/001-japi-rn.jpg', label: 'Japi/RN' },
  { src: 'https://sisaeduc.s3.sa-east-1.amazonaws.com/public/clients/002-sao-bento-do-trairi-rn.jpg', label: 'São Bento do Trairi/RN' },
  { src: 'https://sisaeduc.s3.sa-east-1.amazonaws.com/public/clients/003-felipe-guerra-rn.jpg', label: 'Felipe Guerra/RN' },
  { src: 'https://sisaeduc.s3.sa-east-1.amazonaws.com/public/clients/004-serra-negra-do-norte-rn.jpg', label: 'Serra Negra do Norte/RN' },
  { src: 'https://sisaeduc.s3.sa-east-1.amazonaws.com/public/clients/005-apodi-rn.png', label: 'Apodi/RN' },
  { src: 'https://sisaeduc.s3.sa-east-1.amazonaws.com/public/clients/006-sao-vicente-rn.png', label: 'São Vicente/RN' },
  { src: 'https://sisaeduc.s3.sa-east-1.amazonaws.com/public/clients/007-sao-bento-do-norte-rn.png', label: 'São Bento do Norte/RN' },
  { src: 'https://sisaeduc.s3.sa-east-1.amazonaws.com/public/clients/008-jucurutu-rn.png', label: 'Jucurutu/RN' },
  { src: 'https://sisaeduc.s3.sa-east-1.amazonaws.com/public/clients/009-taipu-rn.jpg', label: 'Taipu/RN' },
  { src: 'https://sisaeduc.s3.sa-east-1.amazonaws.com/public/clients/010-joao-camara-rn.jpg', label: 'João Câmara/RN' },
  { src: 'https://sisaeduc.s3.sa-east-1.amazonaws.com/public/clients/011-patu-rn.jpg', label: 'Patu/RN' },
  { src: 'https://sisaeduc.s3.sa-east-1.amazonaws.com/public/clients/012-governador-dix-sept-rosado-rn.jpg', label: 'Governador Dix-Sept Rosado/RN' },
  { src: 'https://sisaeduc.s3.sa-east-1.amazonaws.com/public/clients/013-coronel-ezequiel-rn.jpg', label: 'Coronel Ezequiel/RN' },
  { src: 'https://sisaeduc.s3.sa-east-1.amazonaws.com/public/clients/014-caraubas-rn.jpg', label: 'Caraúbas/RN' },
  { src: 'https://sisaeduc.s3.sa-east-1.amazonaws.com/public/clients/015-tenente-laurentino-cruz-rn.jpg', label: 'Tenente Laurentino Cruz/RN' },
  { src: 'https://sisaeduc.s3.sa-east-1.amazonaws.com/public/clients/016-lajes-pintadas-rn.jpg', label: 'Lajes Pintadas/RN' },
  { src: 'https://sisaeduc.s3.sa-east-1.amazonaws.com/public/clients/017-sao-jose-de-mipibu-rn.jpg', label: 'São José de Mipibu/RN' },
  { src: 'https://sisaeduc.s3.sa-east-1.amazonaws.com/public/clients/018-patos-pb.png', label: 'Patos/PB' },
  { src: 'https://sisaeduc.s3.sa-east-1.amazonaws.com/public/clients/019-frutuoso-gomes-rn.jpg', label: 'Frutuoso Gomes/RN' },
  { src: 'https://sisaeduc.s3.sa-east-1.amazonaws.com/public/clients/020-serrinha-rn.jpg', label: 'Serrinha/RN' },
  { src: 'https://sisaeduc.s3.sa-east-1.amazonaws.com/public/clients/021-lucrecia-rn.png', label: 'Lucrécia/RN' },
  { src: 'https://sisaeduc.s3.sa-east-1.amazonaws.com/public/clients/022-rodolfo-fernandes-rn.png', label: 'Rodolfo Fernandes/RN' },
  { src: 'https://sisaeduc.s3.sa-east-1.amazonaws.com/public/clients/023-caicara-do-rio-do-vento-rn.jpg', label: 'Caiçara do Rio do Vento/RN' },
  { src: 'https://sisaeduc.s3.sa-east-1.amazonaws.com/public/clients/024-parau-rn.jpg', label: 'Paraú/RN' },
  { src: 'https://sisaeduc.s3.sa-east-1.amazonaws.com/public/clients/025-ipueira-rn.jpg', label: 'Ipueira/RN' },
  { src: 'https://sisaeduc.s3.sa-east-1.amazonaws.com/public/clients/026-coremas-pb.jpg', label: 'Coremas/PB' },
  { src: 'https://sisaeduc.s3.sa-east-1.amazonaws.com/public/clients/027-sao-jose-do-bonfim-pb.jpg', label: 'São José do Bonfim/PB' },
  { src: 'https://sisaeduc.s3.sa-east-1.amazonaws.com/public/clients/028-santa-terezinha-pb.jpg', label: 'Santa Terezinha/PB' },
  { src: 'https://sisaeduc.s3.sa-east-1.amazonaws.com/public/clients/029-borborema-pb.jpg', label: 'Borborema/PB' },
  { src: 'https://sisaeduc.s3.sa-east-1.amazonaws.com/public/clients/030-equador-rn.jpg', label: 'Equador/RN' },
  { src: 'https://sisaeduc.s3.sa-east-1.amazonaws.com/public/clients/031-martins-rn.jpg', label: 'Martins/RN' },
  { src: 'https://sisaeduc.s3.sa-east-1.amazonaws.com/public/clients/032-belem-do-brejo-do-cruz-pb.jpg', label: 'Belém do Brejo do Cruz/PB' },
];

class Inicio extends React.Component {
  constructor(props) {
    super(props);

    this.emailRef = React.createRef();
    this.nomeRef = React.createRef();
    this.telefoneRef = React.createRef();
    this.assuntoRef = React.createRef();
    this.textoRef = React.createRef();
    this.recaptchaRef = React.createRef();

    this.state = {
      buttonDisabled: false,
      showAlert: false,
      activeAnchor: '#heroAnchor',
      anchors: [
        { id: '#heroAnchor', label: 'Início' },
        // { id: '#aboutAnchor', label: 'Sobre' },
        { id: '#parceirosAnchor', label: 'Parceiros' },
        { id: '#servicesAnchor', label: 'Serviços' },
        // { id: '#depoimentosAnchor', label: 'Depoimentos' },
      ],
      depoimentos: [],
    };

    this.depoimentosService = new DepoimentosService();
  }

  componentWillMount() {
    this.unlisten = this.props.history.listen((location) => {
      this.setState({ activeAnchor: location.hash });
    });
  }

  async componentDidMount() {
    configureAnchors({ offset: -60 });

    //const depoimentos = await this.depoimentosService
    //  .carregarDepoimentosInicio();
    const depoimentos = [];

    this.setState({ depoimentos });

    this.appendScript('assets/js/main.js');
  }

  appendScript = (scriptToAppend) => {
    const script = document.createElement('script');
    script.src = scriptToAppend;
    script.async = true;
    script.onload = () => this.forceUpdate();
    document.body.appendChild(script);
  }

  componentWillUnmount() {
    this.unlisten();
  }

  validarCampos() {
    let ret = true;
    if (this.nomeRef.current.value === '') {
      this.setState({ nomeState: 'has-danger' });
      ret = false;
    }
    if (!validator.isEmail(this.emailRef.current.value)) {
      this.setState({ emailState: 'has-danger' });
      ret = false;
    }
    if (!phone(this.telefoneRef.current.value, 'BR')[0]) {
      this.setState({ telefoneState: 'has-danger' });
      ret = false;
    }
    if (this.assuntoRef.current.value === '') {
      this.setState({ assuntoState: 'has-danger' });
      ret = false;
    }
    if (this.textoRef.current.value === '') {
      this.setState({ textoState: 'has-danger' });
      ret = false;
    }

    return ret;
  }

  render = () => <div className="rootCSS">
    <header id="header" className="fixed-top">
      <div className="container d-flex align-items-center">
        <h1 className="logo mr-auto">
          <a
            href="#heroAnchor"
            onClick={() => this.setState({ activeAnchor: '#heroAnchor' })}>
            {env.SYSNAME}
          </a>
        </h1>
        <nav className="nav-menu d-none d-lg-block">
          <ul>
            {
              this.state.anchors.map((anchor, idx) => <li key={idx}
                className={anchor.id === this.state.activeAnchor ? 'active' : ''}>
                <a
                  href={anchor.id}
                  onClick={() => this.setState({ activeAnchor: anchor.id })}>
                  {anchor.label}
                </a>
              </li>)
            }
          </ul>
        </nav>
        <NavLink to="/login"
          className="get-started-btn">LOGIN</NavLink>
      </div>
    </header>
    <ScrollableAnchor id={'heroAnchor'}>
      <section id="hero" className="d-flex align-items-center">
        <div className="container position-relative" data-aos="fade-up" data-aos-delay="100">
          <div className="row justify-content-center">
            <div className="col-xl-7 col-lg-9 text-center">
              <h1>{env.SYSNAME}</h1>
              <h2>Sistema de Auxílio à Educação</h2>
            </div>
          </div>
          <div className="row icon-boxes">
            <div className="col-md-4 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in" data-aos-delay="200">
              <div className="icon-box">
                <div className="icon"><i className="ri-file-copy-2-line yellow"></i></div>
                <h4 className="title">Diários on-line</h4>
                <p className="description">
                  Diários impressos trazem muitos problemas ao processo de gestão da Educação.
                  Entre os principais problemas estão o custo de impressão anual, demora na
                  entrega dos diários aos professores, rasuras, extravios,
                  armazenamento, cálculo manual de notas e frequência, além de perda de tempo
                  na preparação e na consolidação do diário, entre outros. Resolva todos esses
                  problemas com a {env.SYSNAME}.
              </p>
              </div>
            </div>
            <div className="col-md-4 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in" data-aos-delay="300">
              <div className="icon-box">
                <div className="icon"><i className="ri-profile-line green"></i></div>
                <h4 className="title">Gestão de matrículas</h4>
                <p className="description">
                  Preencher fichas de matrícula ano a ano é um processo ineficiente.
                  Na {env.SYSNAME} você cadastra o aluno apenas uma vez e nos anos seguntes você realiza a
                  matrícula com poucos cliques. Além disso, gerir desistências e transferências
                  em diários impressos é uma tarefa maçante, sendo necessário muitas vezes
                  solicitar dezenas de diários para implementar essas mudanças. Automatize esse
                  processo com a {env.SYSNAME}.
              </p>
              </div>
            </div>
            <div className="col-md-4 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in" data-aos-delay="400">
              <div className="icon-box">
                <div className="icon"><i className="ri-cloud-line red"></i></div>
                <h4 className="title">Armazenamento em nuvem</h4>
                <p className="description">A quantidade de informações geradas anualmente na
                gestão da educação é muito grande. Armazenar esses dados fisicamente traz muitos
                riscos. Problemas como enchentes e incêndios podem causar perda irreparável
                de informações. Mantenha seguros em nossa nuvem os dados das suas
                escolas, turmas, diários, alunos, professores e muito mais.
              </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </ScrollableAnchor>
    <main id="main">
      {/* <ScrollableAnchor id={'aboutAnchor'}>
        <section id="about" className="about">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2>Sobre nós</h2>
              <p>Somos entusiastas da eficiência da gestão e da educação. </p>
            </div>
            <div className="row content">
              <div className="col-lg-12">
                <p>
                  Somos uma startup do ramo da gestão educacional, cuja missão é levar soluções
                  descomplicadas, por um preço acessível, a todos os municípios do Brasil.
              </p>
              </div>
            </div>
          </div>
        </section>
      </ScrollableAnchor>
      */}
      <section id="about-video">
        <div className="container" data-aos="fade-up">
          <div className="row">
            <div className="col-lg-6 video-box align-self-baseline" data-aos="fade-right" data-aos-delay="100">
              <img src="https://sisaeduc.s3.sa-east-1.amazonaws.com/public/about-video.png" className="img-fluid img" alt="" />
            </div>
            <div className="col-lg-6 pt-3 pt-lg-0 content" data-aos-delay="100">
              <h3>Conheça a plataforma {env.SYSNAME}</h3>
              <p className="font-italic">
                Confira alguns dos recursos oferecidos pela plataforma {env.SYSNAME}.
              </p>
              <ul>
                <li><i className="bx bx-check-double"></i> Cadastro de escolas, anos letivos, turmas.</li>
                <li><i className="bx bx-check-double"></i> Acesso para gestores, diretores, coordenadores, professores, alunos e pais.</li>
                <li><i className="bx bx-check-double"></i> Diário de classe online.</li>
                <li><i className="bx bx-check-double"></i> Lançamento de frequência.</li>
                <li><i className="bx bx-check-double"></i> Lançamento de notas.</li>
                <li><i className="bx bx-check-double"></i> Ata final automática.</li>
                <li><i className="bx bx-check-double"></i> Emissão de documentos e relatórios em pdf.</li>
                <li><i className="bx bx-check-double"></i> Gestão de transportes e passageiros.</li>
                <li><i className="bx bx-check-double"></i> Gestão de funcionários.</li>
                <li><i className="bx bx-check-double"></i> Módulo de ensino com cadastro de aulas, tarefas e porta arquivos.</li>
                <li><i className="bx bx-check-double"></i> Dashboard com estatísticas do município.</li>
                <li><i className="bx bx-check-double"></i> Aplicativo móvel Android e IOS para professores, alunos e pais.</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section id="counts" className="counts section-bg">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h5><b>NOSSOS NÚMEROS</b></h5>
            </div>
          </div>
          <div className="row justify-content-center count-boxes">
            <div className="col-lg-3 col-md-6 col-12 d-md-flex align-items-md-stretch">
              <div className="count-box">
                <div className="count-group">
                  <span>+</span>
                  <span data-toggle="counter-up">8000</span>
                </div>
                <p>Turmas</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-12 d-md-flex align-items-md-stretch">
              <div className="count-box">
                <div className="count-group">
                  <span>+</span>
                  <span data-toggle="counter-up">22000</span>
                </div>
                <p>Diários</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-12 d-md-flex align-items-md-stretch">
              <div className="count-box">
                <div className="count-group">
                  <span>+</span>
                  <span data-toggle="counter-up">67000</span>
                </div>
                <p>Alunos</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-12 d-md-flex align-items-md-stretch">
              <div className="count-box">
                <div className="count-group">
                  <span>+</span>
                  <span data-toggle="counter-up">4000</span>
                </div>
                <p>Professores</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ScrollableAnchor id={'parceirosAnchor'}>
        <section id="clients" className="clients section-bg">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <h5><b>MUNICÍPIOS PARCEIROS</b></h5>
              </div>
              {
                clients.map((client, idx) => <div key={idx} className="col-lg-2 col-md-4 col-6 icon-container" data-aos="zoom-in">
                  <div className="icon-box">
                    <img id={`client${idx}`} src={client.src} className="img-fluid" alt="" />
                    <p>{client.label}</p>
                  </div>
                </div>)
              }
            </div>
          </div>
        </section>
      </ScrollableAnchor>
      <ScrollableAnchor id={'servicesAnchor'}>
        <section id="services" className="services section-bg">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2>SERVIÇOS</h2>
              <p>Confira alguns dos serviços presentes na plataforma {env.SYSNAME}.</p>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
                <div className="icon-box iconbox-blue">
                  <div className="icon">
                    <svg width="100" height="100" viewBox="0 0 600 600" xmlns="http://www.w3.org/2000/svg">
                      <path stroke="none" strokeWidth="0" fill="#f5f5f5"
                        d="M300,521.0016835830174C376.1290562159157,517.8887921683347,466.0731472004068,529.7835943286574,510.70327084640275,468.03025145048787C554.3714126377745,407.6079735673963,508.03601936045806,328.9844924480964,491.2728898941984,256.3432110539036C474.5976632858925,184.082847569629,479.9380746630129,96.60480741107993,416.23090153303,58.64404602377083C348.86323505073057,18.502131276798302,261.93793281208167,40.57373210992963,193.5410806939664,78.93577620505333C130.42746243093433,114.334589627462,98.30271207620316,179.96522072025542,76.75703585869454,249.04625023123273C51.97151888228291,328.5150500222984,13.704378332031375,421.85034740162234,66.52175969318436,486.19268352777647C119.04800174914682,550.1803526380478,217.28368757567262,524.383925680826,300,521.0016835830174">
                      </path>
                    </svg>
                    <i className="ri-file-copy-2-line"></i>
                  </div>
                  <h4>Diários on-line</h4>
                  <p>Acabe com a papelada e os custos de tempo e dinheiro atrelados a ela.
                  Adote um processo inteligente.
                </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0" data-aos="zoom-in"
                data-aos-delay="200">
                <div className="icon-box iconbox-orange ">
                  <div className="icon">
                    <svg width="100" height="100" viewBox="0 0 600 600" xmlns="http://www.w3.org/2000/svg">
                      <path stroke="none" strokeWidth="0" fill="#f5f5f5"
                        d="M300,582.0697525312426C382.5290701553225,586.8405444964366,449.9789794690241,525.3245884688669,502.5850820975895,461.55621195738473C556.606425686781,396.0723002908107,615.8543463187945,314.28637112970534,586.6730223649479,234.56875336149918C558.9533121215079,158.8439757836574,454.9685369536778,164.00468322053177,381.49747125262974,130.76875717737553C312.15926192815925,99.40240125094834,248.97055460311594,18.661163978235184,179.8680185752513,50.54337015887873C110.5421016452524,82.52863877960104,119.82277516462835,180.83849132639028,109.12597500060166,256.43424936330496C100.08760227029461,320.3096726198365,92.17705696193138,384.0621239912766,124.79988738764834,439.7174275375508C164.83382741302287,508.01625554203684,220.96474134820875,577.5009287672846,300,582.0697525312426">
                      </path>
                    </svg>
                    <i className="ri-calculator-line"></i>
                  </div>
                  <h4>Cálculo automático de notas e frequência</h4>
                  <p>Não deixe seu professor perder tempo com tarefas que passaram a ser
                  automatizadas há décadas. Deixe a {env.SYSNAME} fazer o trabalho maçante
                  por eles.</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0" data-aos="zoom-in"
                data-aos-delay="300">
                <div className="icon-box iconbox-pink">
                  <div className="icon">
                    <svg width="100" height="100" viewBox="0 0 600 600" xmlns="http://www.w3.org/2000/svg">
                      <path stroke="none" strokeWidth="0" fill="#f5f5f5"
                        d="M300,541.5067337569781C382.14930387511276,545.0595476570109,479.8736841581634,548.3450877840088,526.4010558755058,480.5488172755941C571.5218469581645,414.80211281144784,517.5187510058486,332.0715597781072,496.52539010469104,255.14436215662573C477.37192572678356,184.95920475031193,473.57363656557914,105.61284051026155,413.0603344069578,65.22779650032875C343.27470386102294,18.654635553484475,251.2091493199835,5.337323636656869,175.0934190732945,40.62881213300186C97.87086631185822,76.43348514350839,51.98124368387456,156.15599469081315,36.44837278890362,239.84606092416172C21.716077023791087,319.22268207091537,43.775223500013084,401.1760424656574,96.891909868211,461.97329694683043C147.22146801428983,519.5804099606455,223.5754009179313,538.201503339737,300,541.5067337569781">
                      </path>
                    </svg>
                    <i className="ri-profile-line"></i>
                  </div>
                  <h4>Gestão de matrículas</h4>
                  <p>Esqueça a dor de cabeça de atualizar dezenas de diários quando um aluno
                  é transferido ou se evade. Resolva essa tarefa com poucos cliques na {env.SYSNAME}.
                </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="100">
                <div className="icon-box iconbox-yellow">
                  <div className="icon">
                    <svg width="100" height="100" viewBox="0 0 600 600" xmlns="http://www.w3.org/2000/svg">
                      <path stroke="none" strokeWidth="0" fill="#f5f5f5"
                        d="M300,503.46388370962813C374.79870501325706,506.71871716319447,464.8034551963731,527.1746412648533,510.4981551193396,467.86667711651364C555.9287308511215,408.9015244558933,512.6030010748507,327.5744911775523,490.211057578863,256.5855673507754C471.097692560561,195.9906835881958,447.69079081568157,138.11976852964426,395.19560036434837,102.3242989838813C329.3053358748298,57.3949838291264,248.02791733380457,8.279543830951368,175.87071277845988,42.242879143198664C103.41431057327972,76.34704239035025,93.79494320519305,170.9812938413882,81.28167332365135,250.07896920659033C70.17666984294237,320.27484674793965,64.84698225790005,396.69656628748305,111.28512138212992,450.4950937839243C156.20124167950087,502.5303643271138,231.32542653798444,500.4755392045468,300,503.46388370962813">
                      </path>
                    </svg>
                    <i className="ri-pie-chart-fill"></i>
                  </div>
                  <h4>Dashboard com estatísticas</h4>
                  <p>O levantamento de estatísticas sobre turmas e matrículas não será mais
                  um problema para você. O Dashboard da {env.SYSNAME} apresenta informações úteis
                  para levantamento de censos escolares e prestação de informações
                  governamentais.
                </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="200">
                <div className="icon-box iconbox-red">
                  <div className="icon">
                    <svg width="100" height="100" viewBox="0 0 600 600" xmlns="http://www.w3.org/2000/svg">
                      <path stroke="none" strokeWidth="0" fill="#f5f5f5"
                        d="M300,532.3542879108572C369.38199826031484,532.3153073249985,429.10787420159085,491.63046689027357,474.5244479745417,439.17860296908856C522.8885846962883,383.3225815378663,569.1668002868075,314.3205725914397,550.7432151929288,242.7694973846089C532.6665558377875,172.5657663291529,456.2379748765914,142.6223662098291,390.3689995646985,112.34683881706744C326.66090330228417,83.06452184765237,258.84405631176094,53.51806209861945,193.32584062364296,78.48882559362697C121.61183558270385,105.82097193414197,62.805066853699245,167.19869350419734,48.57481801355237,242.6138429142374C34.843463184063346,315.3850353017275,76.69343916112496,383.4422959591041,125.22947124332185,439.3748458443577C170.7312796277747,491.8107796887764,230.57421082200815,532.3932930995766,300,532.3542879108572">
                      </path>
                    </svg>
                    <i className="ri-mail-send-line"></i>
                  </div>
                  <h4>Envio de convites para professores e diretores</h4>
                  <p>Com poucos cliques envie convites para seus professores e diretores.
                  Após um cadastro rápido e sem burocracia, eles terão acesso à plataforma {env.SYSNAME}.
                </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay="300">
                <div className="icon-box iconbox-teal">
                  <div className="icon">
                    <svg width="100" height="100" viewBox="0 0 600 600" xmlns="http://www.w3.org/2000/svg">
                      <path stroke="none" strokeWidth="0" fill="#f5f5f5"
                        d="M300,566.797414625762C385.7384707136149,576.1784315230908,478.7894351017131,552.8928747891023,531.9192734346935,484.94944893311C584.6109503024035,417.5663521118492,582.489472248146,322.67544863468447,553.9536738515405,242.03673114598146C529.1557734026468,171.96086150256528,465.24506316201064,127.66468636344209,395.9583748389544,100.7403814666027C334.2173773831606,76.7482773500951,269.4350130405921,84.62216499799875,207.1952322260088,107.2889140133804C132.92018162631612,134.33871894543012,41.79353780512637,160.00259165414826,22.644507872594943,236.69541883565114C3.319112789854554,314.0945973066697,72.72355303640163,379.243833228382,124.04198916343866,440.3218312028393C172.9286146004772,498.5055451809895,224.45579914871206,558.5317968840102,300,566.797414625762">
                      </path>
                    </svg>
                    <i className="ri-cloud-line"></i>
                  </div>
                  <h4>Armazenamento em nuvem</h4>
                  <p>Economize espaço e não corra riscos com perda de material impresso.
                  Deixe seus dados seguros na nuvem com a {env.SYSNAME}.
                </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </ScrollableAnchor>
      { /*
      <ScrollableAnchor id={'portfolioAnchor'}>
        <section id="portfolio" className="portfolio">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2>Portfólio</h2>
              <p>O {env.SYSNAME} é fruto de pesquisas com secretários, diretores,
              coordenadores, professores, pais e alunos, utilizando as tecnologias mais modernas
                de desenvolvimento de software para web e dispositivos móveis.</p>
            </div>
            <div className="row" data-aos="fade-up" data-aos-delay="150">
              <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
                <ol className="carousel-indicators">
                  <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
                  <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                  <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                  <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
                  <li data-target="#carouselExampleIndicators" data-slide-to="4"></li>
                  <li data-target="#carouselExampleIndicators" data-slide-to="5"></li>
                  <li data-target="#carouselExampleIndicators" data-slide-to="6"></li>
                  <li data-target="#carouselExampleIndicators" data-slide-to="7"></li>
                  <li data-target="#carouselExampleIndicators" data-slide-to="8"></li>
                  <li data-target="#carouselExampleIndicators" data-slide-to="9"></li>
                  <li data-target="#carouselExampleIndicators" data-slide-to="10"></li>
                  <li data-target="#carouselExampleIndicators" data-slide-to="11"></li>
                </ol>
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <img className="d-block w-100" src={carouselImg01} alt="Dashboard" />
                    <div className="carousel-caption d-none d-md-block">
                      <h5>Dashboard</h5>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <img className="d-block w-100" src={carouselImg02} alt="Diários" />
                    <div className="carousel-caption d-none d-md-block">
                      <h5>Diário de classe</h5>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <img className="d-block w-100" src={carouselImg03} alt="Lançamento de notas" />
                    <div className="carousel-caption d-none d-md-block">
                      <h5>Lançamento de notas</h5>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <img className="d-block w-100" src={carouselImg04} alt="Calendário de frequência" />
                    <div className="carousel-caption d-none d-md-block">
                      <h5>Calendário de frequência</h5>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <img className="d-block w-100" src={carouselImg05} alt="Lançamento de frequencia" />
                    <div className="carousel-caption d-none d-md-block">
                      <h5>Lançamento de frequência</h5>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <img className="d-block w-100" src={carouselImg06} alt="Ata final" />
                    <div className="carousel-caption d-none d-md-block">
                      <h5>Ata final do diário de classe</h5>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <img className="d-block w-100" src={carouselImg07} alt="Informações do aluno" />
                    <div className="carousel-caption d-none d-md-block">
                      <h5>Informações do aluno</h5>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <img className="d-block w-100" src={carouselImg08} alt="Informações do ano letivo" />
                    <div className="carousel-caption d-none d-md-block">
                      <h5>Informações do ano letivo</h5>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <img className="d-block w-100" src={carouselImg09} alt="Cursos cadastrados" />
                    <div className="carousel-caption d-none d-md-block">
                      <h5>Cursos cadastrados</h5>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <img className="d-block w-100" src={carouselImg10} alt="Informações da escola" />
                    <div className="carousel-caption d-none d-md-block">
                      <h5>Informações da escola</h5>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <img className="d-block w-100" src={carouselImg11} alt="Diários mobile" />
                    <div className="carousel-caption d-none d-md-block">
                      <h5>Diários de classe no aplicativo mobile</h5>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <img className="d-block w-100" src={carouselImg12} alt="Frequência mobile" />
                    <div className="carousel-caption d-none d-md-block">
                      <h5>Lançamento de frequência no aplicativo mobile</h5>
                    </div>
                  </div>
                </div>
                <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                  <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span className="sr-only">Anterior</span>
                </a>
                <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                  <span className="carousel-control-next-icon carousel-arrows" aria-hidden="true"></span>
                  <span className="sr-only">Próximo</span>
                </a>
              </div>
            </div>
          </div>
        </section>
      </ScrollableAnchor>
      */ }
      { /*
        <ScrollableAnchor id={'depoimentosAnchor'}>
        <section id="testimonials" className="testimonials section-bg">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2>Depoimentos</h2>
              <p>
                Compromisso, trabalho em equipe e melhoria contínua são chaves para
                conquistar execelência em qualidade e satisfação dos clientes.
              </p>
              <p>
                Veja o que pensam alguns dos nossos usuários.
              </p>
            </div>
            <div className="owl-carousel testimonials-carousel">
              {
                this.state.depoimentos.map((depoimento, idx) => <div key={idx} className="testimonial-item">
                  <p>
                    <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                    <b>{depoimento.descricao}</b> - {depoimento.texto}
                    <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                  </p>
                  <img src={`data:image/jpeg;base64,${depoimento.foto}`} className="testimonial-img" alt="" />
                  <h3>{depoimento.usuario}</h3>
                  <h4>{depoimento.ente}</h4>
                </div>)
              }
            </div>
          </div>
        </section>
      </ScrollableAnchor>
      */ }
      { /*
      <section id="faq" className="faq section-bg">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>Perguntas frequentes</h2>
            <p>Veja algumas das perguntas frequentes de nossos clientes</p>
          </div>
          <div className="faq-list">
            <ul>
              <li data-aos="fade-up">
                <i className="bx bx-help-circle icon-help"></i>
                  <a data-toggle="collapse" className="collapse"
                  href="#faq-list-1">
                    <b>
                      A assinatura de plano anual após os 30 dias gratuitos é obrigatória?
                    </b>
                    <i className="bx bx-chevron-down icon-show"></i>
                    <i className="bx bx-chevron-up icon-close"></i>
                  </a>
                <div id="faq-list-1" className="collapse show" data-parent=".faq-list">
                  <p>
                    A assinatura de plano após os 30 dias gratuitos não é obrigatória.
                    Mesmo após os 30 dias você continua com acesso ao portal para fazer
                    apenas consultas. Para voltar a fazer cadastros será necessário assinar
                    um dos planos.
                  </p>
                </div>
              </li>
              <li data-aos="fade-up" data-aos-delay="100">
                <i className="bx bx-help-circle icon-help"></i>
                <a data-toggle="collapse" href="#faq-list-2" className="collapsed">
                  <b>Após vencimento do meu plano anual, terei o acesso ao {env.SYSNAME} bloqueado?</b>
                  <i className="bx bx-chevron-down icon-show"></i>
                  <i className="bx bx-chevron-up icon-close"></i>
                </a>
                <div id="faq-list-2" className="collapse" data-parent=".faq-list">
                  <p>
                    Não. Você continuará com acesso para realizar apenas consultas.
                    Para realizar novos cadastros (matrículas, alunos, turmas etc) será
                    necessário renovar a assinatura.
                  </p>
                </div>
              </li>
              <li data-aos="fade-up" data-aos-delay="200">
                <i className="bx bx-help-circle icon-help"></i>
                <a data-toggle="collapse" href="#faq-list-3" className="collapsed">
                  <b>Quais as formas de pagamento?</b>
                  <i className="bx bx-chevron-down icon-show"></i>
                  <i className="bx bx-chevron-up icon-close"></i>
                </a>
                <div id="faq-list-3" className="collapse" data-parent=".faq-list">
                  <p>
                    Trabalhamos com pagamento,
                    que pode ser gerado no menu Planos após realizar o cadastro e login
                    na plataforma.
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>
      */ }
    </main>
    <br/><br/><br/>
    <RootFooter />
    <ReCAPTCHA
      style={{ align: 'left' }}
      ref={this.recaptchaRef}
      size="invisible"
      onExpired={this.redoCaptcha}
      onChange={this.onVerifyRecaptcha}
      onError={this.onErrorRecaptcha}
      sitekey="6LfX4rkUAAAAAPrcwxxlJex3L08dtx2gkCFPQH3o"
    />
    <LoaderModal
      isOpen={this.state.buttonDisabled}
      text="Enviando mensagem..." />
    {/*
    <div className="whatsapp-icon">
      <a href="https://api.whatsapp.com/send?phone=5584986286287" className="float" target="_blank" rel="noopener noreferrer">
        <i className="fa fa-whatsapp my-float"></i>
      </a>
    </div>
    */}
  </div>;
}

export default Inicio;
