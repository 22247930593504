import React from 'react';
import { Link } from 'react-router-dom';

// reactstrap components
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  Button,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap';

import Loader from 'react-loader-spinner';
import { Editor } from '@tinymce/tinymce-react';

import Alert from 'views/components/Alert';

import LoginService from 'services/LoginService';
import TurmasService from 'services/TurmasService';
import MateriasService from 'services/MateriasService';
import AulasService from 'services/AulasService';

import Turno from 'assets/csv/turnos.json';

class TurmaVirtual extends React.Component {
  constructor(props) {
    super(props);

    this.turnos = [
      Turno.MATUTINO,
      Turno.VESPERTINO,
      Turno.NOTURNO,
      Turno.INTEGRAL,
    ];

    this.loginService = new LoginService();
    this.turmasService = new TurmasService();
    this.materiasService = new MateriasService();
    this.aulasService = new AulasService();

    this.state = {
      showAlert: false,
      erro: false,
    };
  }

  componentWillUnmount() {
    this.turmasService.abortPedingRequests();
    this.materiasService.abortPedingRequests();
    this.aulasService.abortPedingRequests();
    this.umounted = true;
  }

  async componentDidMount() {
    await this.loginService.verificarPapel(this.props.role);

    try {
      const matricula = await this.turmasService
        .carregarMatriculaPorId(this.props.match.params.idMatricula);

      const turma = await this.turmasService
        .carregarTurma(matricula.idTurma);

      const materia = await this.materiasService
        .carregarMateria(this.props.match.params.idMateria);

      const aula = await this.aulasService
        .carregarAula(this.props.match.params.idAula);

      this.setState({
        turma,
        aula,
        materia,
      });

      this.setState({ ready: true });
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  conteudoPagina() {
    return this.state.ready ? <>
      <Row>
        <Col md="12">
          <Alert
            color={this.state.alertColor}
            isOpen={this.state.showAlert}
            toggle={() => { this.setState({ showAlert: false }); }}>
            {this.state.alertMsg}
          </Alert>
          <Breadcrumb>
            <BreadcrumbItem><Link to={`${this.props.layout}/turmas`}>Turmas</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/turmas/${this.props.match.params.idMatricula}`}>{this.state.turma.curso.nome}</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/turmas/${this.props.match.params.idMatricula}/turma/${this.props.match.params.idTurma}/materia/${this.props.match.params.idMateria}/turmavirtual`}>Turma virtual - {this.state.materia.nome}</Link></BreadcrumbItem>
            <BreadcrumbItem active>Aula {this.state.aula.nome}</BreadcrumbItem>
          </Breadcrumb>
          <Card>
            <CardHeader>
              <CardTitle tag="h4">Aula {this.state.aula.nome}</CardTitle>
            </CardHeader>
            <CardBody>
              <div className="aula">
                <Editor
                  apiKey='5ri9uiz44o9zmfcm5nsbg8fumf9akfxdjs25t2if5l6pasm5'
                  initialValue={this.state.aula.conteudo}
                  init={{
                    height: 1000,
                    menubar: false,
                    toolbar: false,
                    inline: true,
                    setup: (editor) => {
                      editor.setMode('readonly');
                    },
                  }} />
              </div>
            </CardBody>
            <CardFooter>
              <Row>
                <Col md="6" className="text-left">
                  <Button color="primary"
                    onClick={() => this.props.history.goBack()}>
                    Voltar
                  </Button>
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </> : <Card>
        <div align="center" style={{ margin: 50 }}>
          <Loader
            type="Oval"
            color="#34B5B8"
            height="50"
            width="50" />
        </div>
      </Card>;
  }

  render() {
    return (
      <div className='content'>
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
            </div>
          </Card>
        }
      </div>
    );
  }
}

export default TurmaVirtual;
