/* eslint-disable max-len */
import React from 'react';
import { Link } from 'react-router-dom';

import {
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  Button,
  Table,
  FormGroup,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from 'reactstrap';

import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar';

import Loader from 'react-loader-spinner';
import Select from 'react-select';
import Alert from 'views/components/Alert';
import LoaderModal from 'views/components/LoaderModal';

import LoginService from 'services/LoginService';
import EntesService from 'services/EntesService';
import AnosLetivosService from 'services/AnosLetivosService';
import EscolasService from 'services/EscolasService';
import TurmasService from 'services/TurmasService';
import DiariosService from 'services/DiariosService';
import MateriasService from 'services/MateriasService';
import ObjetivosAprendizagemCampoExperienciaService from 'services/ObjetivosAprendizagemCampoExperienciaService';
import ProfessorAuxiliarService from 'services/ProfessorAuxiliarService';

import Turno from 'assets/csv/turnos.json';
import TipoAvaliacao from 'assets/csv/tipos-avaliacao.json';
import SituacaoObjetivo from 'assets/csv/situacoes-objetivo';
import Papel from 'assets/csv/papeis.json';

class ObjetivosAprendizagemTrabalhados extends React.Component {
  constructor(props) {
    super(props);

    this.turnos = [
      Turno.MATUTINO,
      Turno.VESPERTINO,
      Turno.NOTURNO,
      Turno.INTEGRAL,
    ];

    this.situacoes = [
      SituacaoObjetivo.TRABALHADO,
      SituacaoObjetivo.TRABALHADO_PARCIALMENTE,
      SituacaoObjetivo.NAO_TRABALHADO,
      SituacaoObjetivo.NAO_INFORMADO,
    ];

    this.salvarObjetivosTrabalhados = this.salvarObjetivosTrabalhados.bind(this);

    this.state = {
      showAlert: false,
      showLoaderModal: false,
      buttonDisabled: false,

      error: null,
    };

    this.loginService = new LoginService();
    this.entesService = new EntesService();
    this.anosLetivosService = new AnosLetivosService();
    this.escolasService = new EscolasService();
    this.turmasService = new TurmasService();
    this.diariosService = new DiariosService();
    this.materiasService = new MateriasService();
    this.objetivosAprendizagemCampoExperienciaService = new
      ObjetivosAprendizagemCampoExperienciaService();
    this.professorAuxiliarService = new ProfessorAuxiliarService();
  }

  async componentDidMount() {
    await this.loginService.verificarPapel(this.props.role);
    try {
      const dadosEnte = await this.entesService.carregarDadosEnte();
      
      const professoreAuxiliar = await this.professorAuxiliarService
        .carregarMeuProfessorAuxiliar();

      const anoLetivo = await this.anosLetivosService
        .carregarAnoLetivo(this.props.match.params.idAnoLetivo);

      const escola = await this.escolasService
        .carregarEscola(this.props.match.params.idEscola);

      const turma = await this.turmasService
        .carregarTurma(this.props.match.params.idTurma);

      const diario = await this.diariosService
        .carregarDiario(this.props.match.params.idDiario);

      const materias = await this.diariosService
        .carregarMateriasDoProfessor(this.props.match.params.idDiario);

      const selectMaterias = [];
      materias.forEach((materia) => {
        selectMaterias.push({
          label: materia.nome,
          value: materia.id,
        });
      });

      const materiaSelecionada = {
        label: materias[0].nome,
        value: materias[0].id,
      };

      await this.carregarSituacaoObjetivoNoDiarioEMateria(
        materiaSelecionada.value,
      );

      let anos1;
      let anos2;
      if (turma.curso.serie === 0 || turma.curso.serie === 1) {
        anos1 = '0 anos';
        anos2 = '1 anos';
      } else if (turma.curso.serie === 2 || turma.curso.serie === 3) {
        anos1 = '2 anos';
        anos2 = '3 anos';
      } else {
        anos1 = '4 anos';
        anos2 = '5 anos';
      }

      this.setState({
        anoLetivo,
        escola,
        turma,
        diario,
        anos1,
        anos2,
        selectMaterias,
        materiaSelecionada,
        auxiliarBloqueado: this.props.role === Papel.PROFESSOR.value && professoreAuxiliar.auxiliar && dadosEnte.bloquearAlteracaoDiarioProfessorAuxiliar,
      });
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  async carregarSituacaoObjetivoNoDiarioEMateria(idMateria) {
    this.setState({ carregandoObjetivos: true });

    const objetivosAprendizagem = await this.objetivosAprendizagemCampoExperienciaService
      .carregarObjetivosAprendizagemPorMateria(idMateria);

    const fn = (objetivo) => new Promise((resolve) => resolve(
      this.objetivosAprendizagemCampoExperienciaService
        .carregarSituacaoObjetivoAprendizagemDiarioMateria(objetivo.id,
          this.props.match.params.idDiario, idMateria),
    ));

    // eslint-disable-next-line no-restricted-syntax
    for (const objetivoAprendizagem of objetivosAprendizagem) {
      const actions = objetivoAprendizagem.objetivosEspecificos.map(fn);
      // eslint-disable-next-line no-await-in-loop
      const situacoesObjetivos = await Promise.all(actions);
      objetivoAprendizagem.objetivosEspecificos.forEach((objetivoEspecifico, idx) => {
        Object.assign(
          objetivoEspecifico, { situacao: situacoesObjetivos[idx] },
        );
      });
    }
    this.setState({
      objetivosAprendizagem,
      carregandoObjetivos: false,
    });
  }

  async salvarObjetivosTrabalhados() {
    this.setState({
      buttonDisabled: true,
      showAlert: false,
      showLoaderModal: true,
    });

    try {
      await this.objetivosAprendizagemCampoExperienciaService.salvarObjetivosAprendizagemTrabalhados(
        this.props.match.params.idDiario,
        this.state.materiaSelecionada.value,
        this.state.objetivosAprendizagem,
      );

      await this.carregarSituacaoObjetivoNoDiarioEMateria(
        this.state.materiaSelecionada.value,
      );

      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Objetivos de aprendizagem salvos com sucesso',
      });
    } catch (msg) {
      if (this.umounted) return;
      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao salvar objetivos de aprendizagem',
      });
    }
  }

  voltar() {
    if (this.state.turma.tipoAvaliacao === TipoAvaliacao.NOTA.value) {
      return `${this.props.layout}`
        + `/anosletivos/${this.props.match.params.idAnoLetivo}`
        + `/escola/${this.state.escola.id}`
        + `/turma/${this.state.turma.id}`
        + `/diario/${this.state.diario.id}`;
    }
    return `${this.props.layout}`
      + `/anosletivos/${this.props.match.params.idAnoLetivo}`
      + `/escola/${this.state.escola.id}`
      + `/turma/${this.state.turma.id}`
      + `/diario/${this.state.diario.id}/alfabetizacao`;
  }

  tabelaObjetivos() {
    return this.state.carregandoObjetivos
      ? <div align="center" style={{ padding: 30 }}>
        <Loader
          type="Oval"
          color="#34B5B8"
          height="50"
          width="50" />
      </div>
      : <PerfectScrollbar className="sisa-perfect-scrollbar">
        <Table>
        <thead className="text-primary">
          <tr>
            <th rowSpan="2" className="text-center" style={{ width: '20%' }}>Objetivo de aprendizagem e desenvolvimento</th>
            <th colSpan="2" className="text-center" style={{ width: '60%' }}>Objetivo de específico</th>
            <th rowSpan="2" className="text-center" style={{ width: '20%' }}>Situação</th>
          </tr>
          <tr>
            <th className="text-center">{this.state.anos1}</th>
            <th className="text-center">{this.state.anos2}</th>
          </tr>
        </thead>
        <tbody>
          {
            this.state.objetivosAprendizagem.map((objetivoAprendizagem, index1) => <React.Fragment key={index1}>
              {
                objetivoAprendizagem.objetivosEspecificos.map((objetivoEspecifico, index2) => <tr key={index2}>
                  {
                    index2 === 0 && <td style={{ width: '20%' }} rowSpan={objetivoAprendizagem.objetivosEspecificos.length}>
                      <div className="preserve-format">{objetivoAprendizagem.descricao}</div>
                    </td>
                  }
                  <td style={{ width: '30%' }}>
                    <div className="preserve-format">{objetivoEspecifico.descricao}</div>
                  </td>
                  <td style={{ width: '30%' }}>
                    <div className="preserve-format">{objetivoEspecifico.descricao2}</div>
                  </td>
                  <td style={{ width: '20%' }} className="text-center">
                    <UncontrolledDropdown>
                      <DropdownToggle
                        aria-haspopup={true}
                        color={this.situacoes[objetivoEspecifico.situacao.situacao].color}
                        data-toggle="collapse"
                        id="acoesHabilidadesDropdownMenuLink"
                        onClick={(evt) => evt.stopPropagation()}
                        nav>
                        <Button color={this.situacoes[objetivoEspecifico.situacao.situacao].color}>
                          {this.situacoes[objetivoEspecifico.situacao.situacao].label} <b className="caret" />
                        </Button>
                      </DropdownToggle>
                      <DropdownMenu aria-labelledby="acoesHabilidadesDropdownMenuLink" right>
                        <DropdownItem
                          onClick={() => {
                            if (this.props.role !== Papel.PROFESSOR.value) return;
                            Object.assign(objetivoEspecifico.situacao, {
                              situacao: SituacaoObjetivo.NAO_INFORMADO.value,
                            });
                            this.forceUpdate();
                          }}>
                          {SituacaoObjetivo.NAO_INFORMADO.label}
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            if (this.props.role !== Papel.PROFESSOR.value) return;
                            Object.assign(objetivoEspecifico.situacao, {
                              situacao: SituacaoObjetivo.TRABALHADO.value,
                            });
                            this.forceUpdate();
                          }}>
                          {SituacaoObjetivo.TRABALHADO.label}
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            if (this.props.role !== Papel.PROFESSOR.value) return;
                            Object.assign(objetivoEspecifico.situacao, {
                              situacao: SituacaoObjetivo.TRABALHADO_PARCIALMENTE.value,
                            });
                            this.forceUpdate();
                          }}>
                          {SituacaoObjetivo.TRABALHADO_PARCIALMENTE.label}
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            if (this.props.role !== Papel.PROFESSOR.value) return;
                            Object.assign(objetivoEspecifico.situacao, {
                              situacao: SituacaoObjetivo.NAO_TRABALHADO.value,
                            });
                            this.forceUpdate();
                          }}>
                          {SituacaoObjetivo.NAO_TRABALHADO.label}
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </td>
                </tr>)
              }
            </React.Fragment>)
          }
        </tbody>
      </Table>
      </PerfectScrollbar>;
  }

  conteudoPagina() {
    return this.state.escola ? <>
      <Row>
        <Col md="12">
          <Alert
            color={this.state.alertColor}
            isOpen={this.state.showAlert}
            toggle={() => { this.setState({ showAlert: false }); }}>
            {this.state.alertMsg}
          </Alert>
          <Breadcrumb>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos`}>Anos letivos</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}`}>Ano letivo {this.state.anoLetivo.ano}</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}`}>{this.state.escola.nome}</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/${this.state.turma.id}`}>{this.state.turma.nome} - {this.turnos[this.state.turma.turno].label}</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={this.voltar()}>
              {this.state.diario.nome}</Link></BreadcrumbItem>
            <BreadcrumbItem active>Objetivos de aprendizagem em {this.state.materiaSelecionada.label}</BreadcrumbItem>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <CardTitle tag="h4">
                Objetivos de aprendizagem em {this.state.materiaSelecionada.label}
              </CardTitle>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md="4">
                  <FormGroup className={'has-label has-danger'}>
                    <label>Campos de experiências</label>
                    <Select
                      noOptionsMessage={() => 'Nenhuma entrada'}
                      className={'react-select primary'}
                      classNamePrefix="react-select"
                      value={this.state.materiaSelecionada}
                      onChange={async (event) => {
                        this.setState({ materiaSelecionada: event })
                        await this.carregarSituacaoObjetivoNoDiarioEMateria(
                          event.value,
                        );
                      }}
                      options={this.state.selectMaterias}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  {
                    this.state.objetivosAprendizagem.length === 0 ? <div className="text-center">
                      Nenhum objetivo de aprendizagem cadastrado
                    </div> : this.tabelaObjetivos()
                  }
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <Row>
                <Col className="text-left">
                  <Button
                    color="primary"
                    onClick={() => this.props.history
                      .push(this.voltar())} >
                    Voltar
                  </Button>
                </Col>
                <Col className="text-right">
                  {
                    this.props.role === Papel.PROFESSOR.value && !this.state.auxiliarBloqueado && <Button
                      color="primary"
                      disabled={this.state.buttonDisabled}
                      onClick={this.salvarObjetivosTrabalhados} >
                      Salvar habilidades trabalhadas
                    </Button>
                  }
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </> : <Card>
      <div align="center" style={{ margin: 50 }}>
        <Loader
          type="Oval"
          color="#34B5B8"
          height="50"
          width="50" />
      </div>
    </Card>;
  }

  render() {
    return (
      <div className='content'>
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
            </div>
          </Card>
        }
        <LoaderModal
          isOpen={this.state.buttonDisabled}
          text="Salvando habilidades trabalhadas..." />
      </div>
    );
  }
}

export default ObjetivosAprendizagemTrabalhados;
