import BaseService from './BaseService';

class RelatoriosEstatisticosService extends BaseService {
  imprimirRelatorioAlunosPorEscola(idAnoLetivo, idEscola, informacoesRelatorioAlunos) {
    let url = `/relatorioestatistico/alunosporescola/anoletivo/${idAnoLetivo}`;
    if (idEscola) {
      url += `/escola/${idEscola}`;
    }

    const requestInfo = {
      method: 'post',
      url,
      data: JSON.stringify(informacoesRelatorioAlunos),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  imprimirRelatorioAlunosBolsaFamiliaPorEscola(idAnoLetivo, idEscola, informacoesRelatorioAlunos) {
    let url = `/relatorioestatistico/alunosbolsafamiliaporescola/anoletivo/${idAnoLetivo}`;
    if (idEscola) {
      url += `/escola/${idEscola}`;
    }

    const requestInfo = {
      method: 'post',
      url,
      data: JSON.stringify(informacoesRelatorioAlunos),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  imprimirRelatorioAlunosPorTurma(idAnoLetivo, idEscola, idTurma, informacoesRelatorioAlunos) {
    let url = `/relatorioestatistico/alunosporturma/anoletivo/${idAnoLetivo}`;
    if (idEscola) {
      url += `/escola/${idEscola}`;
    }
    if (idTurma) {
      url += `/turma/${idTurma}`;
    }

    const requestInfo = {
      method: 'post',
      url,
      data: JSON.stringify(informacoesRelatorioAlunos),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  imprimirRelatorioNotasPorTurma(idAnoLetivo, idEscola, idTurma) {
    let url = `/relatorioestatistico/notasporturma/anoletivo/${idAnoLetivo}`;
    if (idEscola) {
      url += `/escola/${idEscola}`;
    }
    if (idTurma) {
      url += `/turma/${idTurma}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  imprimirRelatorioNotasFinaisPorTurma(idAnoLetivo, idEscola, idTurma) {
    let url = `/relatorioestatistico/notasfinaisporturma/anoletivo/${idAnoLetivo}`;
    if (idEscola) {
      url += `/escola/${idEscola}`;
    }
    if (idTurma) {
      url += `/turma/${idTurma}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  imprimirRelatorioFichasIndividuaisPorTurma(idAnoLetivo, idEscola, idTurma) {
    console.log('aaaa')
    let url = `/relatorioestatistico/fichasindividuaisporturma/anoletivo/${idAnoLetivo}`;
    if (idEscola) {
      url += `/escola/${idEscola}`;
    }
    if (idTurma) {
      url += `/turma/${idTurma}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  imprimirRelatorioAlunosPnePorEscola(idAnoLetivo, idEscola) {
    let url = `/relatorioestatistico/alunospneporescola/anoletivo/${idAnoLetivo}`;
    if (idEscola) {
      url += `/escola/${idEscola}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  imprimirRelatorioAlunosPnePorTurma(idAnoLetivo, idEscola, idTurma) {
    let url = `/relatorioestatistico/alunospneporturma/anoletivo/${idAnoLetivo}`;
    if (idEscola) {
      url += `/escola/${idEscola}`;
    }
    if (idTurma) {
      url += `/turma/${idTurma}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  imprimirRelatorioAlunosPorIdade(idAnoLetivo, idEscola) {
    let url = `/relatorioestatistico/alunosporidade/anoletivo/${idAnoLetivo}`;
    if (idEscola) {
      url += `/escola/${idEscola}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  imprimirRelatorioTurmasPorEscola(idAnoLetivo, idEscola) {
    let url = `/relatorioestatistico/turmasporescola/anoletivo/${idAnoLetivo}`;
    if (idEscola) {
      url += `/escola/${idEscola}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  imprimirListagemRelatoriosTurmasNaoInformadosPorEscola(idAnoLetivo, idEscola) {
    let url = `/relatorioestatistico/listagemrelatoriosturmasnaoinformadosporescola/anoletivo/${idAnoLetivo}`;
    if (idEscola) {
      url += `/escola/${idEscola}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  imprimirRelatorioTurmasPorSerie(idAnoLetivo, idEscola) {
    let url = `/relatorioestatistico/turmasporserie/anoletivo/${idAnoLetivo}`;
    if (idEscola) {
      url += `/escola/${idEscola}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  imprimirRelatorioDiariosPorEscola(idAnoLetivo, idEscola) {
    let url = `/relatorioestatistico/diariosporescola/anoletivo/${idAnoLetivo}`;
    if (idEscola) {
      url += `/escola/${idEscola}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  imprimirRelatorioDistorcaoIdadeSeriePorEscola(idAnoLetivo, idEscola) {
    let url = `/relatorioestatistico/distorcaoidadeserie/anoletivo/${idAnoLetivo}`;
    if (idEscola) {
      url += `/escola/${idEscola}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  imprimirRelatorioDistorcaoIdadeSerieNoMunicipio(idAnoLetivo) {
    const url = `/relatorioestatistico/distorcaoidadeseriemunicipio/anoletivo/${idAnoLetivo}`;

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  imprimirRelatorioAlunosForaDeFaixaPorEscola(idAnoLetivo, idEscola) {
    let url = `/relatorioestatistico/alunosforadefaixaporescola/anoletivo/${idAnoLetivo}`;
    if (idEscola) {
      url += `/escola/${idEscola}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  imprimirRelatorioLivroDeMatriculasPorEscola(idAnoLetivo, idEscola) {
    let url = `/relatorioestatistico/livromatriculasporescola/anoletivo/${idAnoLetivo}`;
    if (idEscola) {
      url += `/escola/${idEscola}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  imprimirRelatorioLivroDeMatriculasPorTurma(idAnoLetivo, idEscola, idTurma) {
    let url = `/relatorioestatistico/livromatriculasporturma/anoletivo/${idAnoLetivo}`;
    if (idEscola) {
      url += `/escola/${idEscola}`;
    }
    if (idTurma) {
      url += `/turma/${idTurma}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  imprimirRelatorioProfessoresPorEscola(idAnoLetivo, idEscola) {
    let url = `/relatorioestatistico/professoresporescola/anoletivo/${idAnoLetivo}`;
    if (idEscola) {
      url += `/escola/${idEscola}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  imprimirRelatorioProfessoresPorTurma(idAnoLetivo, idEscola, idTurma) {
    let url = `/relatorioestatistico/professoresporturma/anoletivo/${idAnoLetivo}`;
    if (idEscola) {
      url += `/escola/${idEscola}`;
    }
    if (idTurma) {
      url += `/turma/${idTurma}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  imprimirRelatorioPassageirosPorTransporte(idAnoLetivo, idTransporte) {
    let url = `/relatorioestatistico/passageirosportransporte/anoletivo/${idAnoLetivo}`;
    if (idTransporte) {
      url += `/transporte/${idTransporte}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  imprimirRelatorioPassageirosPorTurma(idAnoLetivo, idEscola, idTurma) {
    let url = `/relatorioestatistico/passageirosporturma/anoletivo/${idAnoLetivo}`;
    if (idEscola) {
      url += `/escola/${idEscola}`;
    }
    if (idTurma) {
      url += `/turma/${idTurma}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  imprimirRelatorioAlunosAbaixoDaMediaPorTurma(idAnoLetivo, idEscola, idTurma) {
    let url = `/relatorioestatistico/alunosabaixodamediaporturma/anoletivo/${idAnoLetivo}`;
    if (idEscola) {
      url += `/escola/${idEscola}`;
    }
    if (idTurma) {
      url += `/turma/${idTurma}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  imprimirRelatorioAlunosBaixaFrequenciaPorTurma(idAnoLetivo, idEscola, idTurma) {
    let url = `/relatorioestatistico/alunosbaixafrequenciaporturma/anoletivo/${idAnoLetivo}`;
    if (idEscola) {
      url += `/escola/${idEscola}`;
    }
    if (idTurma) {
      url += `/turma/${idTurma}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  imprimirRelatorioDatasAnoLetivo(idAnoLetivo) {
    const url = `/relatorioestatistico/datasanoletivo/anoletivo/${idAnoLetivo}`;

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  imprimirRelatorioMatriculasNaoRenovadas(idAnoLetivo) {
    console.log('aaaa');
    const url = `/relatorioestatistico/matriculasnaorenovadas/anoletivo/${idAnoLetivo}`;

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  imprimirRelatorioProfessores(campos) {
    const url = '/relatorioestatistico/professores';

    const requestInfo = {
      method: 'post',
      url,
      data: JSON.stringify(campos),
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  imprimirRelatorioHabilidadesAdquiridas(idAnoLetivo, idEscola, idTurma) {
    let url = `/relatorioestatistico/habilidadesadquiridas/anoletivo/${idAnoLetivo}`;
    if (idEscola) {
      url += `/escola/${idEscola}`;
    }
    if (idTurma) {
      url += `/turma/${idTurma}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  imprimirRelatorioHabilidadesAdquiridasBimestres(idAnoLetivo, idEscola, idTurma) {
    let url = `/relatorioestatistico/habilidadesadquiridas/anoletivo/${idAnoLetivo}`;
    if (idEscola) {
      url += `/escola/${idEscola}`;
    }
    if (idTurma) {
      url += `/turma/${idTurma}`;
    }
    url += '/bimestres';
    
    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  imprimirRelatorioFuncionariosPorEscola(idEscola, funcao) {
    let url = `/relatorioestatistico/funcionarios/escola/${idEscola}`;
    if (funcao !== null) {
      url += `/tipofuncionario/${funcao}`;
    }
    
    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  imprimirRelatorioOcorrenciasPorEscola(idAnoLetivo, idEscola) {
    let url = `/relatorioestatistico/ocorrenciasporescola/anoletivo/${idAnoLetivo}`;
    if (idEscola) {
      url += `/escola/${idEscola}`;
    }
    
    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  imprimirRelatorioJustificativasFaltasPorTurma(idAnoLetivo, idEscola, idTurma) {
    let url = `/relatorioestatistico/faltasjustificadasporturma/anoletivo/${idAnoLetivo}`;
    if (idEscola) {
      url += `/escola/${idEscola}`;
    }
    if (idTurma) {
      url += `/turma/${idTurma}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  async imprimirRelatorioHabilidadesAdquiridasAluno(idEscola, idMatricula) {
    const requestInfo = {
      method: 'get',
      url: `/relatorioestatistico/habilidadesadquiridasaluno/escola/${idEscola}/matricula/${idMatricula}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  async imprimirRelatorioHabilidadesAdquiridasAlunoBimestres(idEscola, idMatricula) {
    const requestInfo = {
      method: 'get',
      url: `/relatorioestatistico/habilidadesadquiridasaluno/escola/${idEscola}/matricula/${idMatricula}/bimestres`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  imprimirRelatorioRegistrosAulaPorEscola(idAnoLetivo, idEscola) {
    let url = `/relatorioestatistico/registrosaulaporescola/anoletivo/${idAnoLetivo}`;
    if (idEscola) {
      url += `/escola/${idEscola}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  imprimirRelatorioRegistrosAulaPorTurma(idAnoLetivo, idEscola, idTurma) {
    let url = `/relatorioestatistico/registrosaulaporturma/anoletivo/${idAnoLetivo}`;
    if (idEscola) {
      url += `/escola/${idEscola}`;
    }
    if (idTurma) {
      url += `/turma/${idTurma}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  imprimirRelatorioRegistrosAulaPorProfessor(idAnoLetivo, idEscola, idProfessor) {
    let url = `/relatorioestatistico/registrosaulaporprofessor/anoletivo/${idAnoLetivo}`;
    if (idEscola) {
      url += `/escola/${idEscola}`;
    }
    url += `/professor/${idProfessor}`;

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  imprimirRelatorioQuantidadeRegistrosAulaPorEscola(idAnoLetivo, idEscola) {
    let url = `/relatorioestatistico/quantidaderegistrosaulaporescola/anoletivo/${idAnoLetivo}`;
    if (idEscola) {
      url += `/escola/${idEscola}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  imprimirRelatorioQuantidadeRegistrosAulaPorTurma(idAnoLetivo, idEscola, idTurma) {
    let url = `/relatorioestatistico/quantidaderegistrosaulaporturma/anoletivo/${idAnoLetivo}`;
    if (idEscola) {
      url += `/escola/${idEscola}`;
    }
    if (idTurma) {
      url += `/turma/${idTurma}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  imprimirRelatorioQuantidadeRegistrosAulaPorProfessor(idAnoLetivo, idEscola, idProfessor) {
    let url = `/relatorioestatistico/quantidaderegistrosaulaporprofessor/anoletivo/${idAnoLetivo}`;
    if (idEscola) {
      url += `/escola/${idEscola}`;
    }
    url += `/professor/${idProfessor}`;

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  imprimirRelatorioMediasAlunosPorEscola(idAnoLetivo, idEscola) {
    let url = `/relatorioestatistico/mediasalunosporescola/anoletivo/${idAnoLetivo}`;
    if (idEscola) {
      url += `/escola/${idEscola}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  imprimirRelatorioNotasAlunosPorMateria(idAnoLetivo, idEscola, materia) {
    let url = `/relatorioestatistico/notasalunospormateria/anoletivo/${idAnoLetivo}`;
    if (idEscola) {
      url += `/escola/${idEscola}`;
    }
    if (materia) {
      url += `/materia/${materia}`;
    }
    
    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  imprimirRelatorioNotasAlunosPorProfessor(idAnoLetivo, idEscola, idProfessor) {
    let url = `/relatorioestatistico/notasalunosporprofessor/anoletivo/${idAnoLetivo}`;
    if (idEscola) {
      url += `/escola/${idEscola}`;
    }
    if (idProfessor) {
      url += `/professor/${idProfessor}`;
    }
    
    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  imprimirRelatorioNotasAlunosPorBimestre(idAnoLetivo, idEscola, bimestre) {
    let url = `/relatorioestatistico/notasalunosporbimestre/anoletivo/${idAnoLetivo}`;
    if (idEscola) {
      url += `/escola/${idEscola}`;
    }
    if (bimestre) {
      url += `/bimestre/${bimestre}`;
    }
    
    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  imprimirRelatorioFrequenciaAlunoPorMes(idMatricula) {
    const url = `/relatorioestatistico/frequenciaalunopormes/matricula/${idMatricula}`;
    
    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  imprimirRelatorioFrequenciaAlunoPorBimestre(idMatricula) {
    const url = `/relatorioestatistico/frequenciaalunoporbimestre/matricula/${idMatricula}`;
    
    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  imprimirRelatorioFrequenciaAlunoPorMateria(idMatricula) {
    const url = `/relatorioestatistico/frequenciaalunopormateria/matricula/${idMatricula}`;
    
    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  imprimirRelatorioContagemAlunosPorEscola(idAnoLetivo, idEscola) {
    let url = `/relatorioestatistico/contagemalunosporescola/anoletivo/${idAnoLetivo}`;
    if (idEscola) {
      url += `/escola/${idEscola}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  imprimirRelatorioContagemAlunosPorEscolaEtapa(idAnoLetivo, idEscola) {
    let url = `/relatorioestatistico/contagemalunosporescolaetapa/anoletivo/${idAnoLetivo}`;
    if (idEscola) {
      url += `/escola/${idEscola}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  imprimirRelatorioContagemAlunosAprovadosReprovadosPorEscola(idAnoLetivo, idEscola) {
    let url = `/relatorioestatistico/contagemalunosaprovadosreprovadosporescola/anoletivo/${idAnoLetivo}`;
    if (idEscola) {
      url += `/escola/${idEscola}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  imprimirRelatorioFrequenciasMensaisPorTurma(idAnoLetivo, idEscola, idTurma) {
    let url = `/relatorioestatistico/frequenciasmensaisporturma/anoletivo/${idAnoLetivo}`;
    if (idEscola) {
      url += `/escola/${idEscola}`;
    }
    if (idTurma) {
      url += `/turma/${idTurma}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  async imprimirRelatorioQuantidadeFrequenciaDia(idAnoLetivo, idEscola, idDiario) {
    let url = `/relatorioestatistico/quantidadefrequenciadia/anoletivo/${idAnoLetivo}`;
    if (idEscola) {
      url += `/escola/${idEscola}`;
    }
    if (idDiario) {
      url += `/diario/${idDiario}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  async imprimirRelatorioFaltasAluno(idEscola, idMatricula) {
    const requestInfo = {
      method: 'get',
      url: `/relatorioestatistico/faltas/escola/${idEscola}/matricula/${idMatricula}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  async imprimirRelatorioPeiAluno(idEscola, idMatricula) {
    const requestInfo = {
      method: 'get',
      url: `/relatorioestatistico/pei/escola/${idEscola}/matricula/${idMatricula}`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  imprimirRelatorioAlunosPorTurnoPorEscola(idAnoLetivo, idEscola) {
    let url = `/relatorioestatistico/alunosporturnoporescola/anoletivo/${idAnoLetivo}`;
    if (idEscola) {
      url += `/escola/${idEscola}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  imprimirRelatorioAlunosMatriculadosDependenciaPorTurma(idAnoLetivo, idEscola, idTurma) {
    let url = `/relatorioestatistico/alunosmatriculadosdependenciaporturma/anoletivo/${idAnoLetivo}`;
    if (idEscola) {
      url += `/escola/${idEscola}`;
    }
    if (idTurma) {
      url += `/turma/${idTurma}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  imprimirRelatorioAlunosAprovadosDependenciaPorTurma(idAnoLetivo, idEscola, idTurma) {
    let url = `/relatorioestatistico/alunosaprovadosdependenciaporturma/anoletivo/${idAnoLetivo}`;
    if (idEscola) {
      url += `/escola/${idEscola}`;
    }
    if (idTurma) {
      url += `/turma/${idTurma}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }
  
  async imprimirRelatorioAlunosSemMatriculas() {
    const requestInfo = {
      method: 'get',
      url: `/relatorioestatistico/alunos/sem/matricula`,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }

  imprimirRelatorioDiariosRegistroAulasEmAtrasoPorEscola(idAnoLetivo, idEscola) {
    let url = `/relatorioestatistico/diariosregistrosaulasematrasoporescola/anoletivo/${idAnoLetivo}`;
    if (idEscola) {
      url += `/escola/${idEscola}`;
    }

    const requestInfo = {
      method: 'get',
      url,
      headers: {
        'Content-type': 'text/html',
        Authorization: `Bearer ${sessionStorage.getItem('auth-token')}`,
      },
    };

    return this.doRequest(
      requestInfo,
    );
  }
}

export default RelatoriosEstatisticosService;
