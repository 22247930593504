import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Loader from 'react-loader-spinner';

import {
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  Breadcrumb,
  BreadcrumbItem,
  FormGroup,
  Input,
  Button,
} from 'reactstrap';

import Alert from 'views/components/Alert';
import LoaderModal from 'views/components/LoaderModal';
import ConfirmModal from 'views/components/ConfirmModal';
import AdaptiveTable from 'views/components/AdaptiveTable';

import LoginService from 'services/LoginService';
import AnosLetivosService from 'services/AnosLetivosService';
import TransportesService from 'services/TransportesService';

class Transporte extends React.Component {
  constructor(props) {
    super(props);

    this.idPagina = `${this.props.dadosUsuario.idVinculo}-transporte-${this.props.match.params.idAnoLetivo}-${this.props.match.params.idTransporte}`;
    this.paginaSelecionada = sessionStorage.getItem(`${this.idPagina}-pg`) || 1;
    this.linhasPorPagina = sessionStorage.getItem(`${this.idPagina}-ln`) || 5;
    this.like = '';

    this.state = {
      showAlert: false,

      erro: null,

      dadosTabela: {
        header: [['Nome', '90%']],
        columnAlign: ['text-left'],
        keys: ['nome'],
        rows: [],
        total: undefined,
      },
    };

    this.loginService = new LoginService();
    this.anosLetivosService = new AnosLetivosService();
    this.transportesService = new TransportesService();
  }

  componentWillUnmount() {
    this.loginService.abortPedingRequests();
    this.anosLetivosService.abortPedingRequests();
    this.transportesService.abortPedingRequests();
    this.umounted = true;
  }

  async componentDidMount() {
    await this.loginService.verificarPapel(this.props.role);
    try {
      const anoLetivo = await this.anosLetivosService
        .carregarAnoLetivo(this.props.match.params.idAnoLetivo);

      const transporte = await this.transportesService
        .carregarTransporte(this.props.match.params.idTransporte);

      await this.carregarPassageiros(this.paginaSelecionada,
        this.linhasPorPagina, this.like);

      this.setState({
        anoLetivo,
        transporte,
      });
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  async carregarPassageiros(pagina, linhasPorPagina, like) {
    try {
      const data = await this.transportesService
        .carregarPassageiros(this.props.match.params.idTransporte,
          this.props.match.params.idAnoLetivo, pagina, linhasPorPagina, like);

      this.setState({
        dadosTabela: Object.assign(this.state.dadosTabela,
          { rows: data.passageiros, total: data.total }),
      });
    } catch (e) {
      if (this.umounted) return;
      this.setState({
        dadosTabela: Object.assign(this.state.dadosTabela, { total: -1 }),
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao buscar passageiros',
      });
    }
  }

  confirmarRemocaoPassageiro(passageiro) {
    this.setState({ passageiroSelecionado: passageiro, showConfirmModal: true });
  }

  async removerPassageiro() {
    this.setState({ showAlert: false, showLoaderModal: true });

    try {
      await this.transportesService
        .removerPassageiro(this.props.match.params.idTransporte,
          this.state.passageiroSelecionado.id, this.props.match.params.idAnoLetivo);

      if (this.state.dadosTabela.total % this.linhasPorPagina === 1
        && this.paginaSelecionada > 1) {
        this.paginaSelecionada -= 1;
      }

      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Passageiro removido com sucesso',
      });

      this.carregarPassageiros(this.paginaSelecionada, this.linhasPorPagina, this.like);
    } catch (msg) {
      if (this.umounted) return;
      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao remover passageiro',
      });
    }
  }

  conteudoPagina() {
    return this.state.transporte ? <>
      <Row>
        <Col md="12">
          <Alert
            color={this.state.alertColor}
            isOpen={this.state.showAlert}
            toggle={() => { this.setState({ showAlert: false }); }}>
            {this.state.alertMsg}
          </Alert>
          <Breadcrumb>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos`}>Anos letivos</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}`}>Ano letivo {this.state.anoLetivo.ano}</Link></BreadcrumbItem>
            <BreadcrumbItem active>Placa {this.state.transporte.placa}</BreadcrumbItem>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <CardTitle tag="h4">Rota</CardTitle>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md="6">
                  <FormGroup>
                    <label>Modelo do veículo</label>
                    <Input
                      disabled
                      value={this.state.transporte.modelo}
                      type="text"
                    />
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup>
                    <label>Ano de fabricação</label>
                    <Input
                      disabled
                      value={this.state.transporte.ano}
                      type="text"
                    />
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup>
                    <label>Placa</label>
                    <Input
                      disabled
                      value={this.state.transporte.placa}
                      type="text"
                    />
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup>
                    <label>CPF/CNPJ do proprietário do veículo</label>
                    <Input
                      disabled
                      value={this.state.transporte.cpfProprietario}
                      type="text"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <FormGroup>
                    <label>Nome/Razão Social do proprietário</label>
                    <Input
                      disabled
                      value={this.state.transporte.proprietario}
                      type="text"
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <label>Motorista da rota</label>
                    <Input
                      disabled
                      value={this.state.transporte.motorista}
                      type="text"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <label>Itinerário da rota</label>
                    <Input
                      disabled
                      value={this.state.transporte.itinerario}
                      type="textarea"
                      row={4}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h4>Passageiros</h4>
                </Col>
              </Row>
              <Row>
                <Col>
                  <AdaptiveTable
                    selectedPage={this.paginaSelecionada}
                    rowsPerPage={this.linhasPorPagina}
                    disablePrintAction
                    disableEditAction
                    removeButtonCallback={(passageiro) => this
                      .confirmarRemocaoPassageiro(passageiro)}
                    rowsPerPageCallback={(info) => {
                      this.paginaSelecionada = 1;
                      sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
                      this.linhasPorPagina = info.rowsPerPage;
                      sessionStorage.setItem(`${this.idPagina}-ln`, this.linhasPorPagina);
                      this.carregarPassageiros(this.paginaSelecionada, info.rowsPerPage, this.like);
                    }}
                    likeCallback={(text) => {
                      this.like = text;
                      this.paginaSelecionada = 1;
                      sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
                      this.carregarPassageiros(this.paginaSelecionada, this.linhasPorPagina, text);
                    }}
                    paginatorCallback={(page) => {
                      this.paginaSelecionada = page;
                      sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
                      this.carregarPassageiros(this.paginaSelecionada,
                        this.linhasPorPagina, this.like);
                    }}
                    data={this.state.dadosTabela} />
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <Row>
                <Col md="6" className="text-left">
                  <Button
                    color="primary" disabled={this.state.buttonDisabled}
                    onClick={() => this.props.history.push(`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}`)} >
                    Voltar
                  </Button>
                </Col>
                <Col md="6" className="text-right">
                  <Button color="primary"
                    onClick={() => {
                      this.props.history.push({ pathname: `${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/transporte/${this.props.match.params.idTransporte}/adicionarpassageiro` });
                    }}>
                    Adicionar passageiro
                  </Button>
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </> : <Card>
      <div align="center" style={{ margin: 50 }}>
        <Loader
          type="Oval"
          color="#34B5B8"
          height="50"
          width="50" />
      </div>
    </Card>;
  }

  render() {
    return (
      <div className='content'>
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
            </div>
          </Card>
        }
        <ConfirmModal
          isOpen={this.state.showConfirmModal}
          callback={(confirm) => {
            this.setState({ showConfirmModal: false });
            if (confirm) {
              this.removerPassageiro();
            } else {
              this.setState({ passageiroSelecionado: null });
            }
          }}
          text='Confirme a exclusão do passageiro' />
        <LoaderModal
          isOpen={this.state.showLoaderModal}
          text={`${this.state.loaderModalText}`} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ dadosUsuario: state.dadosUsuario });

export default connect(mapStateToProps)(Transporte);
