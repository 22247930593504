/* eslint-disable max-len */
import React from 'react';
import { Link } from 'react-router-dom';

import {
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  Button,
} from 'reactstrap';

import tippy, { animateFill } from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/dist/backdrop.css';
import 'tippy.js/animations/shift-away.css';

import moment from 'moment';
import Calendar from 'rc-year-calendar';
import 'rc-year-calendar/locales/rc-year-calendar.pt';
import Loader from 'react-loader-spinner';

import Alert from 'views/components/Alert';

import LoginService from 'services/LoginService';
import AnosLetivosService from 'services/AnosLetivosService';
import EscolasService from 'services/EscolasService';
import TurmasService from 'services/TurmasService';
import DiariosService from 'services/DiariosService';
import FeriadosService from 'services/FeriadosService';
import DiasCanceladosService from 'services/DiasCanceladosService';
import DiasExtrasService from 'services/DiasExtrasService';
import FrequenciaService from 'services/FrequenciaService';

import Turno from 'assets/csv/turnos.json';
import TipoFeriado from 'assets/csv/tipos-feriado.json';

class CalendarioFrequencia extends React.Component {
  constructor(props) {
    super(props);

    this.tipos_feriados = [
      TipoFeriado.FERIADO_NACIONAL,
      TipoFeriado.FERIADO_ESTADUAL,
      TipoFeriado.FERIADO_MUNICIPAL,
      TipoFeriado.PONTO_FACULTATIVO,
    ];

    this.turnos = [
      Turno.MATUTINO,
      Turno.VESPERTINO,
      Turno.NOTURNO,
      Turno.INTEGRAL,
    ];

    this.tooltip = null;
    this.feriadoMap = new Map();
    this.diaCanceladoMap = new Map();
    this.diaExtraMap = new Map();
    this.diasAulaMap = new Map();
    this.diasLancadosMap = new Map();

    this.diaSelecionado = this.diaSelecionado.bind(this);

    this.state = {
      showAlert: false,
      showLoaderModal: false,
      buttonDisabled: false,

      error: null,
    };

    this.loginService = new LoginService();
    this.anosLetivosService = new AnosLetivosService();
    this.escolasService = new EscolasService();
    this.turmasService = new TurmasService();
    this.diariosService = new DiariosService();
    this.feriadosService = new FeriadosService();
    this.diasCanceladosService = new DiasCanceladosService();
    this.diasExtrasService = new DiasExtrasService();
    this.frequenciaService = new FrequenciaService();
  }

  componentWillUnmount() {
    this.loginService.abortPedingRequests();
    this.anosLetivosService.abortPedingRequests();
    this.escolasService.abortPedingRequests();
    this.turmasService.abortPedingRequests();
    this.diariosService.abortPedingRequests();
    this.feriadosService.abortPedingRequests();
    this.diasCanceladosService.abortPedingRequests();
    this.diasExtrasService.abortPedingRequests();
    this.frequenciaService.abortPedingRequests();
    this.umounted = true;
  }

  async componentDidMount() {
    await this.loginService.verificarPapel(this.props.role);
    try {
      const anoLetivo = await this.anosLetivosService
        .carregarAnoLetivo(this.props.match.params.idAnoLetivo);

      const escola = await this.escolasService
        .carregarEscola(this.props.match.params.idEscola);

      const turmaMultiseriado = await this.turmasService
        .carregarTurmaMultiseriado(this.props.match.params.idTurma);

      const diarios = await this.diariosService
        .carregarDiariosDaTurmaMultiseriado(this.props.match.params.idTurma);

      const diario = diarios[0];

      const feriados = await this.feriadosService
        .carregarFeriadosPorAnoLetivo(anoLetivo.id);

      const diasCancelados = await this.diasCanceladosService
        .carregarDiasCanceladosPorAnoLetivo(anoLetivo.id);

      const diasExtras = await this.diasExtrasService
        .carregarDiasExtrasPorAnoLetivo(anoLetivo.id);

      feriados.forEach((feriado) => this.feriadoMap.set(feriado.data.replace(/\//gi, '-'), true));

      diasCancelados.forEach((dia) => this.diaCanceladoMap.set(dia.data.replace(/\//gi, '-'), true));

      diasExtras.forEach((dia) => this.diaExtraMap.set(dia.data.replace(/\//gi, '-'), true));

      Object.assign(anoLetivo, { feriados, diasCancelados, diasExtras });

      const dias = await this.diariosService
        .carregarDiasDeAulaDoProfessor(diario.id);

      dias.forEach((dia) => this.diasAulaMap.set(dia, true));

      const fn = (d) => new Promise((resolve) => resolve(
        this.frequenciaService.carregarDiasLancados(d.id),
      ));

      const actions = diarios.map(fn);

      const diasLancadosTemp = await Promise.all(actions);

      let diasLancados = diasLancadosTemp[0];
      for (let i = 1; i < diasLancadosTemp.length; i += 1) {
        if (diasLancadosTemp[i].length > diasLancados.length) {
          diasLancados = diasLancadosTemp[i];
        }
      }

      diasLancados.forEach((data) => this.diasLancadosMap.set(data, true));

      const aulasLancadas = await this.frequenciaService
        .carregarAulasLancadasTurmaMultiseriado(turmaMultiseriado.id);

      this.setState({
        anoLetivo,
        escola,
        turmaMultiseriado,
        turma: turmaMultiseriado.turmas[0],
        diario,
        diasLancados,
        aulasLancadas,
      });
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  getDataSource() {
    const dataSource = [];
    if (this.state.anoLetivo.dataInicioBimestre1) {
      dataSource.push({
        startDate: moment(this.state.anoLetivo.dataInicioBimestre1, 'DD-MM-YYYY').toDate(),
        endDate: moment(this.state.anoLetivo.dataInicioBimestre1, 'DD-MM-YYYY').toDate(),
        name: 'Início do 1º Bimestre',
        color: '#2572B9',
      });
    }
    if (this.state.anoLetivo.dataInicioBimestre2) {
      dataSource.push({
        startDate: moment(this.state.anoLetivo.dataInicioBimestre2, 'DD-MM-YYYY').toDate(),
        endDate: moment(this.state.anoLetivo.dataInicioBimestre2, 'DD-MM-YYYY').toDate(),
        name: 'Início do 2º Bimestre',
        color: '#2572B9',
      });
    }
    if (this.state.anoLetivo.dataInicioBimestre3) {
      dataSource.push({
        startDate: moment(this.state.anoLetivo.dataInicioBimestre3, 'DD-MM-YYYY').toDate(),
        endDate: moment(this.state.anoLetivo.dataInicioBimestre3, 'DD-MM-YYYY').toDate(),
        name: 'Início do 3º Bimestre',
        color: '#2572B9',
      });
    }
    if (this.state.anoLetivo.dataInicioBimestre4) {
      dataSource.push({
        startDate: moment(this.state.anoLetivo.dataInicioBimestre4, 'DD-MM-YYYY').toDate(),
        endDate: moment(this.state.anoLetivo.dataInicioBimestre4, 'DD-MM-YYYY').toDate(),
        name: 'Início do 4º Bimestre',
        color: '#2572B9',
      });
    }
    if (this.state.anoLetivo.dataFimBimestre1) {
      dataSource.push({
        startDate: moment(this.state.anoLetivo.dataFimBimestre1, 'DD-MM-YYYY').toDate(),
        endDate: moment(this.state.anoLetivo.dataFimBimestre1, 'DD-MM-YYYY').toDate(),
        name: 'Fim do 1º Bimestre',
        color: '#E77225',
      });
    }
    if (this.state.anoLetivo.dataFimBimestre2) {
      dataSource.push({
        startDate: moment(this.state.anoLetivo.dataFimBimestre2, 'DD-MM-YYYY').toDate(),
        endDate: moment(this.state.anoLetivo.dataFimBimestre2, 'DD-MM-YYYY').toDate(),
        name: 'Fim do 2º Bimestre',
        color: '#E77225',
      });
    }
    if (this.state.anoLetivo.dataFimBimestre3) {
      dataSource.push({
        startDate: moment(this.state.anoLetivo.dataFimBimestre3, 'DD-MM-YYYY').toDate(),
        endDate: moment(this.state.anoLetivo.dataFimBimestre3, 'DD-MM-YYYY').toDate(),
        name: 'Fim do 3º Bimestre',
        color: '#E77225',
      });
    }
    if (this.state.anoLetivo.dataFimBimestre4) {
      dataSource.push({
        startDate: moment(this.state.anoLetivo.dataFimBimestre4, 'DD-MM-YYYY').toDate(),
        endDate: moment(this.state.anoLetivo.dataFimBimestre4, 'DD-MM-YYYY').toDate(),
        name: 'Fim do 4º Bimestre',
        color: '#E77225',
      });
    }
    if (this.state.anoLetivo.feriados) {
      this.state.anoLetivo.feriados.forEach((feriado) => {
        dataSource.push({
          startDate: moment(feriado.data, 'DD-MM-YYYY').toDate(),
          endDate: moment(feriado.data, 'DD-MM-YYYY').toDate(),
          name: `${this.tipos_feriados[feriado.tipo].label}: ${feriado.nome}`,
          color: '#EE332A',
        });
      });
    }
    if (this.state.anoLetivo.diasCancelados) {
      this.state.anoLetivo.diasCancelados.forEach((dia) => {
        dataSource.push({
          startDate: moment(dia.data, 'DD-MM-YYYY').toDate(),
          endDate: moment(dia.data, 'DD-MM-YYYY').toDate(),
          name: `Dia letivo cancelado: ${dia.motivo}`,
          color: '#EE332A',
        });
      });
    }
    if (this.state.anoLetivo.diasExtras) {
      this.state.anoLetivo.diasExtras.forEach((dia) => {
        dataSource.push({
          startDate: moment(dia.data, 'DD-MM-YYYY').toDate(),
          endDate: moment(dia.data, 'DD-MM-YYYY').toDate(),
          name: `Dia letivo extra: ${dia.motivo}`,
          color: '#E54CE0',
        });
      });
    }
    if (this.state.anoLetivo.dataInicioRecesso && this.state.anoLetivo.dataFimRecesso) {
      const inicioRecesso = moment(this.state.anoLetivo.dataInicioRecesso, 'DD-MM-YYYY').toDate();
      const fimRecesso = moment(this.state.anoLetivo.dataFimRecesso, 'DD-MM-YYYY').toDate();
      for (let d = inicioRecesso; d <= fimRecesso; d.setDate(d.getDate() + 1)) {
        dataSource.push({
          startDate: new Date(d),
          endDate: new Date(d),
          name: 'Recesso escolar',
          color: '#3CC932',
        });
      }
    }

    return dataSource;
  }

  handleDayEnter(e) {
    if (e.events.length > 0) {
      let content = '';
      for (let i = 0; i < e.events.length; i += 1) {
        content += `${'<div class="event-tooltip-content">'
          + '<div class="event-name">'}${e.events[i].name}</div>`
          // + '<div class="event-details">' + e.events[i].details + '</div>'
          + '</div>';
      }
      if (this.tooltip != null) {
        this.tooltip.destroy();
        this.tooltip = null;
      }
      this.tooltip = tippy(e.element, {
        placement: 'right',
        content,
        animateFill: false,
        animation: 'shift-away',
        arrow: true,
      }, [animateFill]);
      this.tooltip.show();
    }
  }

  handleDayLeave() {
    if (this.tooltip !== null) {
      this.tooltip.destroy();
      this.tooltip = null;
    }
  }

  fimDeSemana = (date) => date.getDay() === 0 || date.getDay() === 6;

  diaDeAula = (date) => !this.state.turma.frequenciaRestrita
    || this.diasAulaMap.get(date.getDay() - 1);

  foraDoAno = (date) => {
    let foraDoAno = false;
    if (this.state.anoLetivo.dataInicioBimestre1 && this.state.anoLetivo.dataFimBimestre4) {
      const inicio = moment(this.state.anoLetivo.dataInicioBimestre1, 'DD-MM-YYYY').toDate();
      const fim = moment(this.state.anoLetivo.dataFimBimestre4, 'DD-MM-YYYY').toDate();
      foraDoAno = date < inicio || date > fim;
    }
    return foraDoAno;
  }

  diaUtil(date) {
    const domingo = date.getDay() === 0;

    const sabado = date.getDay() === 6 && !this.state.anoLetivo.sabadoLetivo;

    const foraDoAno = this.foraDoAno(date);

    let recesso = false;
    if (this.state.anoLetivo.dataInicioRecesso && this.state.anoLetivo.dataFimRecesso) {
      const inicioRecesso = moment(this.state.anoLetivo.dataInicioRecesso, 'DD-MM-YYYY').toDate();
      const fimRecesso = moment(this.state.anoLetivo.dataFimRecesso, 'DD-MM-YYYY').toDate();
      recesso = date >= inicioRecesso && date <= fimRecesso;
    }

    const feriado = this.feriadoMap.get(moment(date).format('DD-MM-YYYY'));

    const diaCancelado = this.diaCanceladoMap.get(moment(date).format('DD-MM-YYYY'));

    const diaExtra = this.diaExtraMap.get(moment(date).format('DD-MM-YYYY'));

    const diaAula = this.diaDeAula(date) || diaExtra;

    return diaAula && (diaExtra
      || (!sabado && !domingo && !recesso && !diaCancelado && !feriado && !foraDoAno));
  }

  diaSelecionado(event) {
    if (!(this.diaUtil(event.date) || this.diasLancadosMap.get(moment(event.date).format('DD-MM-YYYY')))) return;

    const data = moment(event.date).format('DD-MM-YYYY');

    this.props.history.push(`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}`
      + `/escola/${this.state.escola.id}/turma/multiseriado/${this.state.turmaMultiseriado.id}`
      + `/diario/frequencia/data/${data}`);
  }

  voltar() {
    return `${this.props.layout}`
      + `/anosletivos/${this.props.match.params.idAnoLetivo}`
      + `/escola/${this.state.escola.id}`
      + `/turma/multiseriado/${this.state.turmaMultiseriado.id}/diario`;
  }

  conteudoPagina() {
    return this.state.escola ? <>
      <Row>
        <Col md="12">
          <Alert
            color={this.state.alertColor}
            isOpen={this.state.showAlert}
            toggle={() => { this.setState({ showAlert: false }); }}>
            {this.state.alertMsg}
          </Alert>
          <Breadcrumb>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos`}>Anos letivos</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}`}>Ano letivo {this.state.anoLetivo.ano}</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}`}>{this.state.escola.nome}</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/multiseriado/${this.state.turmaMultiseriado.id}`}>{this.state.turmaMultiseriado.nome} - {this.turnos[this.state.turma.turno].label}</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={this.voltar()}>{this.state.diario.nome}</Link></BreadcrumbItem>
            <BreadcrumbItem active>Calendário Frequência</BreadcrumbItem>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <CardTitle tag="h4">
                Calendário Frequência
              </CardTitle>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md="12">
                  <h5>
                    &emsp;Aulas a lançar:&nbsp;
                    {
                      this.state.diario.aulasALancar || this.state.diario.aulasALancar === 0
                        ? `${this.state.aulasLancadas} / ${this.state.diario.aulasALancar}`
                        : 'Não informado'
                    }
                  </h5>
                </Col>
              </Row>
              <Row>
                <Col md={{ size: 8, offset: 2 }}>
                  <Calendar
                    dataSource={this.getDataSource()}
                    onDayEnter={(e) => this.handleDayEnter(e)}
                    onDayLeave={() => this.handleDayLeave()}
                    year={this.state.anoLetivo.ano}
                    customDayRenderer={(element, date) => {
                      let cor = this.fimDeSemana(date) ? '#FF0000' : '#000000';

                      if (this.diaUtil(date) || this.diasLancadosMap.get(moment(date).format('DD-MM-YYYY'))) {
                        if (this.diasLancadosMap.get(moment(date).format('DD-MM-YYYY'))) {
                          cor = '#00FF00';
                        }
                        Object.assign(element.style, { cssText: `color: ${cor}; font-weight: bold` });
                      } else {
                        Object.assign(element.style, { cssText: `color: ${cor}44; background-color: transparent; font-weight: bold`, cursor: 'default' });
                      }
                    }}
                    language="pt"
                    onDayClick={this.diaSelecionado} />
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <Row>
                <Col className="text-left">
                  <Button
                    color="primary"
                    onClick={() => this.props.history
                      .push(this.voltar())} >
                    Voltar
                  </Button>
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </> : <Card>
      <div align="center" style={{ margin: 50 }}>
        <Loader
          type="Oval"
          color="#34B5B8"
          height="50"
          width="50" />
      </div>
    </Card>;
  }

  render() {
    return (
      <div className='content'>
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
            </div>
          </Card>
        }
      </div>
    );
  }
}

export default CalendarioFrequencia;
