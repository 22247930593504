import React from 'react';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap';

import Alert from 'views/components/Alert';
import LoaderModal from 'views/components/LoaderModal';
import Loader from 'react-loader-spinner';

import LoginService from 'services/LoginService';
import ContatoService from 'services/ContatoService';
import UsuarioService from 'services/UsuarioService';

class ContatoLogado extends React.Component {
  constructor(props) {
    super(props);

    this.assuntoRef = React.createRef();
    this.textoRef = React.createRef();

    this.state = {
      showAlert: false,

      usuario: null,

      assuntoState: '',
      textoState: '',

      showLoaderModal: false,
    };

    this.enviar = this.enviar.bind(this);

    this.loginService = new LoginService();
    this.contatoService = new ContatoService();
    this.usuarioService = new UsuarioService();
  }

  componentWillUnmount() {
    this.loginService.abortPedingRequests();
    this.contatoService.abortPedingRequests();
    this.usuarioService.abortPedingRequests();
    this.umounted = true;
  }

  async componentDidMount() {
    await this.loginService.verificarPapel(this.props.role);

    try {
      const usuario = await this.usuarioService.carregarUsuario();
      this.setState({
        usuario,
      });
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  validarCampos() {
    let ret = true;
    if (this.assuntoRef.current.value === '') {
      this.setState({ assuntoState: 'has-danger' });
      ret = false;
    }
    if (this.textoRef.current.value === '') {
      this.setState({ textoState: 'has-danger' });
      ret = false;
    }

    return ret;
  }

  async enviar() {
    if (this.state.showLoaderModal) return;
    if (!this.validarCampos()) return;

    this.setState({ showLoaderModal: true, showAlert: false });

    const contato = {
      email: this.state.usuario.credenciais.email,
      assunto: this.assuntoRef.current.value,
      texto: this.textoRef.current.value,
    };

    try {
      await this.contatoService.enviarContatoLogado(contato);
      this.limparFormulario();
      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Contato enviado com sucesso. Em breve retornaremos o contato através do seu endereço de email.',
      });
    } catch (msg) {
      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Ocorreu um erro ao enviar a mensagem.',
      });
    }
  }

  limparFormulario() {
    this.assuntoRef.current.value = '';
    this.textoRef.current.value = '';

    this.setState({
      assuntoState: '',
      textoState: '',

      showLoaderModal: false,
    });
  }

  conteudoPagina() {
    return this.state.usuario === null
      ? <Card>
        <div align="center" style={{ margin: 50 }}>
          <Loader
            type="Oval"
            color="#34B5B8"
            height="50"
            width="50" />
        </div>
      </Card> : <Row>
        <Col md="12">
          <Alert
            color={this.state.alertColor}
            isOpen={this.state.showAlert}
            toggle={() => { this.setState({ showAlert: false }); }}>
            {this.state.alertMsg}
          </Alert>
          <Breadcrumb>
            <BreadcrumbItem active>Contato</BreadcrumbItem>
          </Breadcrumb>
          <Form onSubmit={(e) => {
            e.preventDefault();
            this.enviar();
          }}>
            <Card className="card-signup text-left" style={{ padding: '20px' }}>
              <CardHeader>
                <CardTitle tag="h4">Contato</CardTitle>
              </CardHeader>
              <CardBody>
                <FormGroup className={'has-label'}>
                  <label>Email para resposta *</label>
                  <Input
                    disabled
                    value={this.state.usuario.credenciais.email}
                  />
                </FormGroup>
                <FormGroup className={`has-label ${this.state.assuntoState}`}>
                  <label>Assunto *</label>
                  <Input
                    innerRef={this.assuntoRef}
                    onChange={() => this.setState({ assuntoState: '' })}
                    type="text"
                    maxLength='200'
                  />
                  {this.state.assuntoState === 'has-danger' ? (
                    <label className="error">
                      Informe o assunto do contato
                    </label>
                  ) : null}
                </FormGroup>
                <FormGroup className={`has-label ${this.state.textoState}`}>
                  <label>Texto *</label>
                  <Input
                    innerRef={this.textoRef}
                    onChange={() => this.setState({ textoState: '' })}
                    type="textarea"
                    style={{ height: '200px' }} />
                  {this.state.textoState === 'has-danger' ? (
                    <label className="error">
                      Informe o texto do contato
                    </label>
                  ) : null}
                </FormGroup>
              </CardBody>
              <CardFooter>
                <Row>
                  <Col className="text-left">
                    <Button
                      color="primary"
                      disabled={this.state.showLoaderModal}
                      onClick={() => this.props.history.goBack()}>
                      Voltar
                    </Button>
                  </Col>
                  <Col className="text-right">
                    <Button type="submit" color="primary" disabled={this.state.showLoaderModal}>
                      Enviar
                    </Button>
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </Form>
        </Col>
      </Row>;
  }

  render() {
    return (
      <div className="content">
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
          </div>
          </Card>
        }
        <LoaderModal
          isOpen={this.state.showLoaderModal}
          text="Enviando mensagem..." />
      </div>
    );
  }
}

export default ContatoLogado;
