import React from 'react';

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  FormGroup,
} from 'reactstrap';

import {
  Tabs,
  TabList,
  Tab,
  TabPanel,
} from 'react-tabs';

import Select from 'react-select';
import Loader from 'react-loader-spinner';

import Alert from 'views/components/Alert';
import Diarios from 'views/components/Diarios';
import DiariosMultiseriado from 'views/components/DiariosMultiseriado';

import AnosLetivosService from 'services/AnosLetivosService';
import LoginService from 'services/LoginService';

class _Diarios extends React.Component {
  constructor(props) {
    super(props);

    this.anosLetivosService = new AnosLetivosService();
    this.loginService = new LoginService();

    this.state = {
      showAlert: false,
    };
  }

  async componentDidMount() {
    await this.loginService.verificarPapel(this.props.role);
    try {
      const data = await this.anosLetivosService
        .carregarAnosLetivos(1, 999999, '');

      const anosLetivos = [];
      data.anosLetivos.forEach((anoLetivo) => {
        anosLetivos.push({
          label: anoLetivo.ano,
          value: anoLetivo.id,
        });
      });

      let anoLetivoSelecionado = '';

      if (anosLetivos.length > 0) {
        anoLetivoSelecionado = anosLetivos[anosLetivos.length - 1];
      }

      this.setState({
        anosLetivos,
        anoLetivoSelecionado,
      });
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  conteudoPagina() {
    return this.state.anosLetivos
      ? <Row>
        <Col md="12">
          <Alert
            color={this.state.alertColor}
            isOpen={this.state.showAlert}
            toggle={() => { this.setState({ showAlert: false }); }}>
            {this.state.alertMsg}
          </Alert>
          <Breadcrumb>
            <BreadcrumbItem active>Diários do Professor</BreadcrumbItem>
          </Breadcrumb>
          <Card>
            <CardHeader>
              <CardTitle tag="h4">Diários do Professor</CardTitle>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md="3">
                  <FormGroup className={'has-label'}>
                    <label>Anos letivos *</label>
                    <Select
                      noOptionsMessage={() => 'Nenhuma entrada'}
                      className={`react-select primary ${this.state.anosLetivos}`}
                      classNamePrefix="react-select"
                      isDisabled={this.state.anosLetivos.length === 0}
                      value={this.state.anoLetivoSelecionado}
                      onChange={(event) => {
                        this.setState({ anoLetivoSelecionado: event });
                      }}
                      options={this.state.anosLetivos}
                      placeholder="Ano letivo..."
                    />
                  </FormGroup>
                </Col>
              </Row>
              <br />
              <Row>
                <Col>
                  <Tabs>
                    <TabList>
                      <Tab>Diários</Tab>
                      <Tab>Diários multisseriado</Tab>
                    </TabList>
                    <TabPanel>
                      {
                        this.state.anoLetivoSelecionado ? <Diarios
                          {...this.props}
                          anoLetivoSelecionado={this.state.anoLetivoSelecionado}
                          callbackMessage={(msg) => this.setState(msg)}
                        /> : <div align="center" style={{ marginBottom: 50 }}>
                          Nenhum diário
                        </div>
                      }
                    </TabPanel>
                    <TabPanel>
                      {
                        this.state.anoLetivoSelecionado ? <DiariosMultiseriado
                          {...this.props}
                          anoLetivoSelecionado={this.state.anoLetivoSelecionado}
                          callbackMessage={(msg) => this.setState(msg)}
                        /> : <div align="center" style={{ marginBottom: 50 }}>
                          Nenhum diário
                        </div>
                      }
                    </TabPanel>
                  </Tabs>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row> : <Card>
        <div align="center" style={{ margin: 50 }}>
          <Loader
            type="Oval"
            color="#34B5B8"
            height="50"
            width="50" />
        </div>
      </Card>;
  }

  render() {
    return <div className='content'>
      {!this.state.erro
        ? this.conteudoPagina()
        : <Card>
          <div align="center" style={{ margin: 50 }}>
            Erro ao buscar informações da escola
          </div>
        </Card>
      }
    </div>;
  }
}

export default _Diarios;
