import React from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';

import { connect } from 'react-redux';

import {
  Row,
  Col,
  Button,
  Form,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  FormGroup,
  CardFooter,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap';

import Loader from 'react-loader-spinner';
import Select from 'react-select';

import Utils from 'utils/Utils';

import Alert from 'views/components/Alert';
import LoaderModal from 'views/components/LoaderModal';

import LoginService from 'services/LoginService';
import AnosLetivosService from 'services/AnosLetivosService';
import EscolasService from 'services/EscolasService';
import UsuarioService from 'services/UsuarioService';
import TurmasService from 'services/TurmasService';
import RelatoriosService from 'services/RelatoriosEstatisticosService';
import EntesService from 'services/EntesService';

import Papel from 'assets/csv/papeis.json';
import TipoAssinatura from 'assets/csv/tipos-assinatura.json';

class imprimirRelatorioFaltasAluno extends React.Component {
  constructor(props) {
    super(props);

    this.loginService = new LoginService();
    this.anosLetivosService = new AnosLetivosService();
    this.escolasService = new EscolasService();
    this.usuarioService = new UsuarioService();
    this.relatoriosService = new RelatoriosService();
    this.turmasService = new TurmasService();
    this.entesService = new EntesService();

    this.state = {
      showAlert: false,
      error: null,

      escolaSelecionada: null,

      carregandoAlunos: false,

      alunoSelecionado: null,
      matriculaSelecionada: null,

      tipoAssinaturaSelecionado: TipoAssinatura.MANUAL,

      matriculas: [],
      alunos: [],
    };

    this.imprimirRelatorioFaltasAluno = this.imprimirRelatorioFaltasAluno.bind(this);
  }

  async componentDidMount() {
    this.debouncedFunction = _.debounce((text) => {
      if (text === '') {
        this.setState({ carregandoAlunos: false, alunos: [] });
      } else {
        this.carregarAlunos(text);
      }
    }, 1500);

    try {
      const dadosEnte = await this.entesService.carregarDadosEnte();

      const data = await this.escolasService
        .carregarEscolas(1, 999999, '');

      const selectEscolas = [];
      data.escolas.forEach((escola) => selectEscolas.push({
        value: escola.id,
        label: escola.nome,
      }));

      this.setState({
        selectEscolas,
        dadosEnte,
      });
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  validarCampos() {
    let ret = true;

    if (!this.state.escolaSelecionada) {
      this.setState({ escolaState: 'danger' });
      ret = false;
    }

    if (!this.state.alunoSelecionado) {
      this.setState({ alunoState: 'danger' });
      ret = false;
    }

    if (!this.state.matriculaSelecionada) {
      this.setState({ matriculaState: 'danger' });
      ret = false;
    }

    return ret;
  }

  limparFormulario() {
    this.setState({
      escolaState: '',
      escolaSelecionada: null,

      alunoState: '',
      matriculaState: '',

      alunoSelecionado: null,
      matriculaSelecionada: null,

      alunos: [],
      matriculas: [],

      carregandoAlunos: false,
    });
  }

  async carregarAlunos(like) {
    try {
      let data;
      if(this.state.dadosEnte.alunosPorEscola) {
        data = await this.usuarioService
          .carregarUsuariosVinculadosEscola(this.state.escolaSelecionada.value, Papel.ALUNO.value, 1, 99999, like);
      } else {
        data = await this.usuarioService
          .carregarUsuarios(Papel.ALUNO.value, 1, 99999, like);
      }

      const selectAlunos = [];
      data.usuarios.forEach((aluno) => {
        selectAlunos.push({ value: aluno.id, label: `${aluno.nome} (Mãe: ${aluno.nomeMae})` });
      });

      this.setState({
        carregandoAlunos: false,
        alunos: selectAlunos,
      });
    } catch (e) {
      if (this.umounted) return;
      this.setState({
        carregandoAlunos: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao buscar alunos cadastrados',
      });
    }
  }

  async carregarTurmas(idAluno) {
    this.setState({
      showAlert: false,
      buttonDisabled: true,
      loaderModalText: 'Carregando matrículas do aluno...',
    });

    try {
      const matriculas = await this.turmasService
        .carregarTodasMatriculasDoAluno(idAluno);

      if (matriculas.length === 0) {
        this.setState({
          buttonDisabled: false,
          showAlert: true,
          alertColor: 'danger',
          alertMsg: 'O aluno selecionado não possui matrículas na sua escola.',
        });
      } else {
        const selectMatriculas = [];
        matriculas.forEach((matricula) => {
          if (matricula.idEscola === this.state.escolaSelecionada.value) {
            selectMatriculas.push({ value: matricula.id, label: matricula.curso });
          }
        });

        if (selectMatriculas.length === 0) {
          this.setState({
            buttonDisabled: false,
            showAlert: true,
            alertColor: 'danger',
            alertMsg: 'O aluno selecionado não possui matrículas na sua escola.',
          });
        }

        this.setState({
          buttonDisabled: false,
          matriculas: selectMatriculas,
        });
      }
    } catch (e) {
      if (this.umounted) return;
      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao buscar matrículas do aluno',
      });
    }
  }

  async imprimirRelatorioFaltasAluno(e) {
    e.preventDefault();

    if (this.state.buttonDisabled) return;
    if (!this.validarCampos()) return;

    this.setState({
      showAlert: false,
      buttonDisabled: true,
      loaderModalText: 'Imprimindo relatório de faltas do aluno para pdf...',
    });

    try {
      let pdf = await this.relatoriosService
        .imprimirRelatorioFaltasAluno(this.state.escolaSelecionada.value, this.state.matriculaSelecionada.value);

      const file = Utils.base64ToBlob(pdf);
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);

      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Relatório de faltas do aluno impresso com sucesso. Caso o documento não seja visualizado, desabilite o bloqueador de pop-up para este site',
      });
    } catch (msg) {
      console.log(msg);
      if (this.umounted) return;
      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao imprimir relatório de faltas do aluno',
      });
    }
  }

  conteudoPagina() {
    return this.state.selectEscolas ? <>
      <Row>
        <Col md="12">
          <Alert
            color={this.state.alertColor}
            isOpen={this.state.showAlert}
            toggle={() => { this.setState({ showAlert: false }); }}>
            {this.state.alertMsg}
          </Alert>
          <Breadcrumb>
            <BreadcrumbItem><Link to={`${this.props.layout}/relatorios`}>Relatórios</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/relatorios/alunos`}>Relatórios de alunos</Link></BreadcrumbItem>
            <BreadcrumbItem active>Relatório de faltas do aluno</BreadcrumbItem>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Form onSubmit={this.imprimirRelatorioFaltasAluno}>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Imprimir relatório de faltas do aluno</CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="4">
                    <FormGroup className={'has-label has-danger'}>
                      <label>Escola *</label>
                      <Select
                        noOptionsMessage={() => 'Nenhuma entrada'}
                        className={`react-select primary ${this.state.escolaState}`}
                        classNamePrefix="react-select"
                        value={this.state.escolaSelecionada}
                        onChange={(event) => {
                          this.setState({ escolaState: '', escolaSelecionada: event });
                        }}
                        options={this.state.selectEscolas}
                        placeholder="Escola..."
                      />
                      {this.state.escolaState === 'danger' ? (
                        <label className="error">
                          Informe a escola.
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup className={'has-label has-danger'}>
                      <label>Aluno *</label>
                      <Select
                        isLoading={this.state.carregandoAlunos}
                        loadingMessage={() => 'Carregando...'}
                        noOptionsMessage={() => 'Nenhuma entrada'}
                        className={`react-select primary ${this.state.alunoState}`}
                        classNamePrefix="react-select"
                        value={this.state.alunoSelecionado}
                        onInputChange={(text) => {
                          this.setState({ carregandoAlunos: text !== '', alunoState: 'primary' });
                          this.debouncedFunction(text);
                        }}
                        onChange={(event) => {
                          this.setState({
                            alunoState: 'primary',
                            alunoSelecionado: event,
                            matriculas: [],
                            matriculaSelecionada: '',
                          });
                          this.carregarTurmas(event.value);
                        }}
                        options={this.state.alunos}
                        placeholder="Digite o nome de um aluno cadastro no sistema..."
                        isDisabled={!this.state.escolaSelecionada}
                      />
                      {this.state.alunoState === 'danger' ? (
                        <label className="error">
                          Informe o nome de um aluno cadastrado.
                        </label>
                      ) : <label></label>}
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup className={'has-label has-danger'}>
                      <label>Matrícula *</label>
                      <Select
                        noOptionsMessage={() => 'Nenhuma entrada'}
                        className={`react-select primary ${this.state.matriculaState}`}
                        classNamePrefix="react-select"
                        value={this.state.matriculaSelecionada}
                        onChange={(event) => {
                          this.setState({ matriculaState: 'primary', matriculaSelecionada: event });
                        }}
                        options={this.state.matriculas}
                        placeholder="Selecione uma matrícula..."
                        isDisabled={!this.state.alunoSelecionado}
                      />
                      {this.state.matriculaState === 'danger' ? (
                        <label className="error">
                          Informe a matrícula.
                        </label>
                      ) : <label></label>}
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <Row>
                  <Col className="text-left">
                    <Button
                      color="primary"
                      onClick={() => this.props.history
                        .push(`${this.props.layout}/relatorios/alunos`)} >
                      Voltar
                    </Button>
                  </Col>
                  <Col className="text-right">
                    <Button
                      type="submit"
                      disabled={this.state.buttonDisabled}
                      color="primary">
                      Imprimir
                    </Button>
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </Form>
        </Col >
      </Row >
    </>
      : <Card>
        <div align="center" style={{ margin: 50 }}>
          <Loader
            type="Oval"
            color="#34B5B8"
            height="50"
            width="50" />
        </div>
      </Card>;
  }

  render() {
    return (
      <div className='content'>
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
            </div>
          </Card>
        }
        <LoaderModal
          isOpen={this.state.buttonDisabled}
          text={this.state.loaderModalText} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ dadosUsuario: state.dadosUsuario });

export default connect(mapStateToProps)(imprimirRelatorioFaltasAluno);
