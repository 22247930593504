/* eslint-disable max-len */
import React from 'react';
import { Link } from 'react-router-dom';

import {
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  Button,
  FormGroup
} from 'reactstrap';

import moment from 'moment';
import Loader from 'react-loader-spinner';
import TextareaAutosize from 'react-textarea-autosize';

import Alert from 'views/components/Alert';
import LoaderModal from 'views/components/LoaderModal';

import LoginService from 'services/LoginService';
import EntesService from 'services/EntesService';
import AnosLetivosService from 'services/AnosLetivosService';
import EscolasService from 'services/EscolasService';
import TurmasService from 'services/TurmasService';
import DiariosService from 'services/DiariosService';
import AvaliacaoEspecialService from 'services/AvaliacaoEspecialService';
import UsuarioService from 'services/UsuarioService';
import ProfessorAuxiliarService from 'services/ProfessorAuxiliarService';

import Turno from 'assets/csv/turnos.json';
import Papel from 'assets/csv/papeis.json';
import TipoAvaliacao from 'assets/csv/tipos-avaliacao.json';

class RelatorioAluno extends React.Component {
  constructor(props) {
    super(props);

    this.turnos = [
      Turno.MATUTINO,
      Turno.VESPERTINO,
      Turno.NOTURNO,
      Turno.INTEGRAL,
    ];

    this.atualizarAvaliacaoEspecial = this.atualizarAvaliacaoEspecial.bind(this);
    this.dataLimiteExcedida = this.dataLimiteExcedida.bind(this);

    this.state = {
      showAlert: false,
      showLoaderModal: false,
      buttonDisabled: false,

      error: null,
    };

    this.loginService = new LoginService();
    this.entesService = new EntesService();
    this.anosLetivosService = new AnosLetivosService();
    this.escolasService = new EscolasService();
    this.turmasService = new TurmasService();
    this.diariosService = new DiariosService();
    this.avaliacaoEspecialService = new AvaliacaoEspecialService();
    this.usuarioService = new UsuarioService();
    this.professorAuxiliarService = new ProfessorAuxiliarService();
  }

  async componentDidMount() {
    try {
      const dadosEnte = await this.entesService
        .carregarDadosEnte();
      
      const professoreAuxiliar = await this.professorAuxiliarService
        .carregarMeuProfessorAuxiliar();

      const anoLetivo = await this.anosLetivosService
        .carregarAnoLetivo(this.props.match.params.idAnoLetivo);

      const escola = await this.escolasService
        .carregarEscola(this.props.match.params.idEscola);

      const turma = await this.turmasService
        .carregarTurma(this.props.match.params.idTurma);

      const diario = await this.diariosService
        .carregarDiario(this.props.match.params.idDiario);

      const avaliacaoEspecial = await this.avaliacaoEspecialService
        .carregarAvaliacaoEspecialPorDiarioEMatricula(this.props.match.params.idDiario,
          this.props.match.params.idMatricula);

      const matricula = await this.turmasService
        .carregarMatricula(this.props.match.params.idTurma, this.props.match.params.idMatricula);

      this.setState({
        dadosEnte,
        anoLetivo,
        escola,
        turma,
        diario,
        avaliacaoEspecial,
        aluno: matricula.usuario,
        auxiliarBloqueado: this.props.role === Papel.PROFESSOR.value && professoreAuxiliar.auxiliar && dadosEnte.bloquearAlteracaoDiarioProfessorAuxiliar,
      });
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  async atualizarAvaliacaoEspecial() {
    this.setState({
      showAlert: false,
      showLoaderModal: true,
    });

    try {
      await this.avaliacaoEspecialService.atualizarAvaliacaoEspecial(
        this.props.match.params.idDiario, this.props.match.params.idMatricula, this.state.avaliacaoEspecial,
      );

      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Plano educacional individualizado salva com sucesso',
      });
    } catch (msg) {
      if (this.umounted) return;
      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao salvar plano educacional individualizado',
      });
    }
  }

  async salvarEmBackground() {
    await this.avaliacaoEspecialService.atualizarAvaliacaoEspecial(
      this.props.match.params.idDiario, this.props.match.params.idMatricula, this.state.avaliacaoEspecial,
    );
  }

  voltar() {
    if (this.state.turma.multiSeriado) {
      return `${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/multiseriado/${this.state.turma.turmaMultiseriado.id}/diario`;
    }
    if (this.state.turma.tipoAvaliacao === TipoAvaliacao.NOTA.value) {
      return `${this.props.layout}`
        + `/anosletivos/${this.props.match.params.idAnoLetivo}`
        + `/escola/${this.state.escola.id}`
        + `/turma/${this.state.turma.id}`
        + `/diario/${this.state.diario.id}`;
    }
    return `${this.props.layout}`
      + `/anosletivos/${this.props.match.params.idAnoLetivo}`
      + `/escola/${this.state.escola.id}`
      + `/turma/${this.state.turma.id}`
      + `/diario/${this.state.diario.id}/alfabetizacao`;
  }

  dataLimiteExcedida(avaliacao) {
    if (!this.state.dadosEnte.limitesBimestrais) return false;

    let dataLimite = null;
    if (avaliacao.descricaoAvaliacao.nome === '1º semestre' && this.state.anoLetivo.dataLimiteBimestre2) {
      dataLimite = moment(this.state.anoLetivo.dataLimiteBimestre2, 'DD/MM/YYYY');
    } else if (avaliacao.descricaoAvaliacao.nome === '2º semestre' && this.state.anoLetivo.dataLimiteBimestre4) {
      dataLimite = moment(this.state.anoLetivo.dataLimiteBimestre4, 'DD/MM/YYYY');
    }

    if (!dataLimite) return false;

    dataLimite.set('hours', 23);
    dataLimite.set('minutes', 59);
    dataLimite.set('seconds', 59);

    const today = moment();

    return today.isAfter(dataLimite)
  }

  conteudoPagina() {
    return this.state.escola ? <>
      <Row>
        <Col md="12">
          <Alert
            color={this.state.alertColor}
            isOpen={this.state.showAlert}
            toggle={() => { this.setState({ showAlert: false }); }}>
            {this.state.alertMsg}
          </Alert>
          <Breadcrumb>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos`}>Anos letivos</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}`}>Ano letivo {this.state.anoLetivo.ano}</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}`}>{this.state.escola.nome}</Link></BreadcrumbItem>
            {
              !this.state.turma.multiSeriado
                ? <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/${this.state.turma.id}`}>{this.state.turma.nome} - {this.turnos[this.state.turma.turno].label}</Link></BreadcrumbItem>
                : <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/multiseriado/${this.state.turma.turmaMultiseriado.id}`}>{this.state.turma.nome} - {this.turnos[this.state.turma.turno].label}</Link></BreadcrumbItem>
            }
            {
              !this.state.turma.multiSeriado
                ? <BreadcrumbItem><Link to={this.voltar()}>{this.state.diario.nome}</Link></BreadcrumbItem>
                : <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/multiseriado/${this.state.turma.turmaMultiseriado.id}/diario`}>Diário único</Link></BreadcrumbItem>
            }
            <BreadcrumbItem active>Plano educacional individualizado (PEI) - {this.state.aluno.nome}</BreadcrumbItem>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <CardTitle tag="h4">
                Plano educacional individualizado (PEI) - {this.state.aluno.nome}
              </CardTitle>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md="12">
                  <FormGroup className={`has-label ${this.state.historicoState}`}>
                    <label>Histórico escolar do estudante</label>
                    <TextareaAutosize
                      className={`autoheight`}
                      disabled={this.props.role !== Papel.PROFESSOR.value}
                      value={this.state.avaliacaoEspecial.historico}
                      onChange={(evt) => {
                        const diff = evt.target.value.length - this.state.avaliacaoEspecial.historico.length;
                        Object.assign(
                          this.state.avaliacaoEspecial, { historico: evt.target.value },
                        );
                        if ((evt.target.value.length > 0
                          && evt.target.value.length % 140 === 0) || diff > 140) {
                          this.salvarEmBackground();
                        }
                        this.forceUpdate();
                      }}/>
                  </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <FormGroup className={`has-label ${this.state.observacoesState}`}>
                  <label>Observações iniciais de dificuldades e potencialidades (diagnóstico inicial)</label>
                  <TextareaAutosize
                    className={`autoheight`}
                    disabled={this.props.role !== Papel.PROFESSOR.value}
                    value={this.state.avaliacaoEspecial.observacoes}
                    onChange={(evt) => {
                      const diff = evt.target.value.length - this.state.avaliacaoEspecial.observacoes.length;
                      Object.assign(
                        this.state.avaliacaoEspecial, { observacoes: evt.target.value },
                      );
                      if ((evt.target.value.length > 0
                        && evt.target.value.length % 140 === 0) || diff > 140) {
                        this.salvarEmBackground();
                      }
                      this.forceUpdate();
                    }}/>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <FormGroup className={`has-label ${this.state.metasSemestre1State}`}>
                  <label>Metas e objetivos para o estudante (1º semestre)</label>
                  <TextareaAutosize
                    className={`autoheight`}
                    disabled={this.props.role !== Papel.PROFESSOR.value}
                    value={this.state.avaliacaoEspecial.metasSemestre1}
                    onChange={(evt) => {
                      const diff = evt.target.value.length - this.state.avaliacaoEspecial.metasSemestre1.length;
                      Object.assign(
                        this.state.avaliacaoEspecial, { metasSemestre1: evt.target.value },
                      );
                      if ((evt.target.value.length > 0
                        && evt.target.value.length % 140 === 0) || diff > 140) {
                        this.salvarEmBackground();
                      }
                      this.forceUpdate();
                    }}/>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <FormGroup className={`has-label ${this.state.relatorioSemestre1State}`}>
                  <label>Relatório descritivo (1º semestre)</label>
                  <TextareaAutosize
                    className={`autoheight`}
                    disabled={this.props.role !== Papel.PROFESSOR.value}
                    value={this.state.avaliacaoEspecial.relatorioSemestre1}
                    onChange={(evt) => {
                      const diff = evt.target.value.length - this.state.avaliacaoEspecial.relatorioSemestre1.length;
                      Object.assign(
                        this.state.avaliacaoEspecial, { relatorioSemestre1: evt.target.value },
                      );
                      if ((evt.target.value.length > 0
                        && evt.target.value.length % 140 === 0) || diff > 140) {
                        this.salvarEmBackground();
                      }
                      this.forceUpdate();
                    }}/>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <FormGroup className={`has-label ${this.state.metasSemestre2State}`}>
                  <label>Metas e objetivos para o estudante (2º semestre)</label>
                  <TextareaAutosize
                    className={`autoheight`}
                    disabled={this.props.role !== Papel.PROFESSOR.value}
                    value={this.state.avaliacaoEspecial.metasSemestre2}
                    onChange={(evt) => {
                      const diff = evt.target.value.length - this.state.avaliacaoEspecial.metasSemestre2.length;
                      Object.assign(
                        this.state.avaliacaoEspecial, { metasSemestre2: evt.target.value },
                      );
                      if ((evt.target.value.length > 0
                        && evt.target.value.length % 140 === 0) || diff > 140) {
                        this.salvarEmBackground();
                      }
                      this.forceUpdate();
                    }}/>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <FormGroup className={`has-label ${this.state.relatorioSemestre2State}`}>
                  <label>Relatório descritivo (2º semestre)</label>
                  <TextareaAutosize
                    className={`autoheight`}
                    disabled={this.props.role !== Papel.PROFESSOR.value}
                    value={this.state.avaliacaoEspecial.relatorioSemestre2}
                    onChange={(evt) => {
                      const diff = evt.target.value.length - this.state.avaliacaoEspecial.relatorioSemestre2.length;
                      Object.assign(
                        this.state.avaliacaoEspecial, { relatorioSemestre2: evt.target.value },
                      );
                      if ((evt.target.value.length > 0
                        && evt.target.value.length % 140 === 0) || diff > 140) {
                        this.salvarEmBackground();
                      }
                      this.forceUpdate();
                    }}/>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <FormGroup className={`has-label ${this.state.adaptacoesState}`}>
                  <label>Adaptações e recursos didáticos necessários</label>
                  <TextareaAutosize
                    className={`autoheight`}
                    disabled={this.props.role !== Papel.PROFESSOR.value}
                    value={this.state.avaliacaoEspecial.adaptacoes}
                    onChange={(evt) => {
                      const diff = evt.target.value.length - this.state.avaliacaoEspecial.adaptacoes.length;
                      Object.assign(
                        this.state.avaliacaoEspecial, { adaptacoes: evt.target.value },
                      );
                      if ((evt.target.value.length > 0
                        && evt.target.value.length % 140 === 0) || diff > 140) {
                        this.salvarEmBackground();
                      }
                      this.forceUpdate();
                    }}/>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <FormGroup className={`has-label ${this.state.outrosState}`}>
                  <label>Outros serviços de que o estudante necessita</label>
                  <TextareaAutosize
                    className={`autoheight`}
                    disabled={this.props.role !== Papel.PROFESSOR.value}
                    value={this.state.avaliacaoEspecial.outros}
                    onChange={(evt) => {
                      const diff = evt.target.value.length - this.state.avaliacaoEspecial.outros.length;
                      Object.assign(
                        this.state.avaliacaoEspecial, { outros: evt.target.value },
                      );
                      if ((evt.target.value.length > 0
                        && evt.target.value.length % 140 === 0) || diff > 140) {
                        this.salvarEmBackground();
                      }
                      this.forceUpdate();
                    }}/>
                </FormGroup>
              </Col>
            </Row>
            <div className="category form-category">
              * Campos obrigatórios
            </div>
          </CardBody>
          <CardFooter>
            <Row>
              <Col className="text-left">
                <Button
                  color="primary"
                  onClick={() => this.props.history.push(this.voltar())} >
                  Voltar
                </Button>
              </Col>
              <Col className="text-right">
                {
                  this.props.role === Papel.PROFESSOR.value && !this.state.auxiliarBloqueado && <Button
                    color="primary"
                    disabled={this.state.showLoaderModal}
                    onClick={() => this.atualizarAvaliacaoEspecial()} >
                    Salvar PEI
                  </Button>
                }
              </Col>
            </Row>
          </CardFooter>
        </Card>
      </Col>
    </Row >
    </> : <Card>
      <div align="center" style={{ margin: 50 }}>
        <Loader
          type="Oval"
          color="#34B5B8"
          height="50"
          width="50" />
      </div>
    </Card>;
  }

  render() {
    return (
      <div className='content'>
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
            </div>
          </Card>
        }
        <LoaderModal
          isOpen={this.state.showLoaderModal}
          text="Salvando plano educacional individualizado..." />
      </div>
    );
  }
}

export default RelatorioAluno;
