import React from 'react';
import env from 'conf/env.json';
// react plugin used to create charts
import { Bar, Doughnut } from 'react-chartjs-2';

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap';

import Select from 'react-select';
import Loader from 'react-loader-spinner';

import LoaderModal from 'views/components/LoaderModal';
import Alert from 'views/components/Alert';

import LoginService from 'services/LoginService';
import DashboardService from 'services/DashboardService';

class DashboardProfessor extends React.Component {
  constructor(props) {
    super(props);

    this.loginService = new LoginService();
    this.dashboardService = new DashboardService();

    this.state = {
      showLoaderModal: false,
      showAlert: false,

      selectAnosLetivos: [],
      anoLetivoSelecionado: '',

      selectTurmas: [],
      turmaSelecionada: '',

      anoLetivo: null,

      error: null,
    };
  }

  componentWillUnmount() {
    this.loginService.abortPedingRequests();
    this.dashboardService.abortPedingRequests();
  }

  async componentDidMount() {
    await this.loginService.verificarPapel(this.props.role);

    try {
      const informacoesGerais = await this.dashboardService
        .carregarInformacoesGeraisProfessor();

      const selectAnosLetivos = [];
      informacoesGerais.anosLetivos.forEach((anoLetivo) => {
        selectAnosLetivos.push({
          value: anoLetivo.id,
          label: anoLetivo.ano,
        });
      });

      const anoLetivoSelecionado = selectAnosLetivos.length > 0
        ? selectAnosLetivos[selectAnosLetivos.length - 1] : '';

      this.setState({
        informacoesGerais,
        selectAnosLetivos,
        anoLetivoSelecionado,
      });

      if (anoLetivoSelecionado) {
        await this.carregarTurmasDoProfessor(anoLetivoSelecionado.value);
      }
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  async carregarTurmasDoProfessor(idAnoLetivo) {
    this.setState({
      showAlert: false,
    });

    try {
      const turmas = await this.dashboardService
        .carregarTurmasDoProfessor(idAnoLetivo);

      const selectTurmas = [];
      turmas.forEach((turma) => {
        selectTurmas.push({
          value: turma.id,
          label: `${turma.nome} - ${turma.escola.nome}`,
        });
      });

      const turmaSelecionada = selectTurmas.length > 0
        ? selectTurmas[0] : '';

      this.setState({
        showLoaderModal: false,
        selectTurmas,
        turmaSelecionada,
      });

      if (turmaSelecionada) {
        await this.carregarDiariosDoProfessor(turmaSelecionada.value);
      }
    } catch (e) {
      if (this.umounted) return;
      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao carregar turmas do ano letivo',
      });
    }
  }

  async carregarDiariosDoProfessor(idTurma) {
    this.setState({
      showAlert: false,
    });

    try {
      const diarios = await this.dashboardService
        .carregarDiariosDoProfessor(idTurma);

      const selectDiarios = [];
      diarios.forEach((diario) => {
        selectDiarios.push({
          value: diario.id,
          label: diario.nome,
        });
      });

      const diarioSelecionado = selectDiarios.length > 0
        ? selectDiarios[0] : '';

      this.setState({
        showLoaderModal: false,
        selectDiarios,
        diarioSelecionado,
      });

      if (diarioSelecionado) {
        await this.carregarInformacoesAnoLetivoProfessor(diarioSelecionado.value);
      }
    } catch (e) {
      if (this.umounted) return;
      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao carregar diários da turma',
      });
    }
  }

  async carregarInformacoesAnoLetivoProfessor(idDiario) {
    this.setState({
      showAlert: false,
    });

    try {
      const anoLetivo = await this.dashboardService
        .carregarInformacoesAnoLetivoProfessor(idDiario);

      const scales = {
        xAxes: [{
          gridLines: {
            color: 'rgba(0, 0, 0, 0)',
          },
          ticks: {
            fontColor: env.CHART_LABEL_COLOR, // Set x-axis label color here
          }
        }],
        yAxes: [{
          ticks: {
            beginAtZero: true,
          },
        }],
      };

      const options = {
        events: ['mousemove'],
        onHover: (event) => {
          Object.assign(event.target.style, { cursor: 'pointer' });
        },
        hover: { mode: null },
        plugins: {
          datalabels: {
            color: env.CHART_LABEL_COLOR,
          }
        }
      };

      Object.assign(anoLetivo.graficoSituacoesAlunos.options, options);
      Object.assign(anoLetivo.graficoAulasLancadas.options, options);

      anoLetivo.graficoSituacoesAlunos.options.scales = scales;

      this.setState({
        showLoaderModal: false,
        anoLetivo,
      });
    } catch (e) {
      if (this.umounted) return;
      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro carregar dados do diário na turma',
      });
    }
  }

  conteudoPagina() {
    return this.state.informacoesGerais ? <>
      <div className="content">
        <Alert
          color={this.state.alertColor}
          isOpen={this.state.showAlert}
          toggle={() => { this.setState({ showAlert: false }); }}>
          {this.state.alertMsg}
        </Alert>
        <Breadcrumb>
          <BreadcrumbItem>Dashboard</BreadcrumbItem>
        </Breadcrumb>
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col md="2" style={{ marginTop: 10 }}>
                    <Select
                      noOptionsMessage={() => 'Nenhuma entrada'}
                      className="react-select primary"
                      classNamePrefix="react-select"
                      placeholder="Ano letivo..."
                      value={this.state.anoLetivoSelecionado}
                      onChange={(event) => {
                        this.setState({
                          anoLetivoSelecionado: event,
                          anoLetivo: null,
                          turmaSelecionada: '',
                        });
                        this.carregarTurmasDoProfessor(event.value);
                      }}
                      options={this.state.selectAnosLetivos}
                    />
                  </Col>
                  {
                    this.state.anoLetivoSelecionado && <Col md="4" style={{ marginTop: 10 }}>
                      <Select
                        noOptionsMessage={() => 'Nenhuma entrada'}
                        className="react-select primary"
                        classNamePrefix="react-select"
                        placeholder="Turma..."
                        value={this.state.turmaSelecionada}
                        onChange={(event) => {
                          this.setState({
                            turmaSelecionada: event,
                            anoLetivo: null,
                            diarioSelecionado: '',
                          });
                          this.carregarDiariosDoProfessor(event.value);
                        }}
                        options={this.state.selectTurmas}
                      />
                    </Col>
                  }
                  {
                    this.state.turmaSelecionada && <Col md="3" style={{ marginTop: 10 }}>
                      <Select
                        noOptionsMessage={() => 'Nenhuma entrada'}
                        className="react-select primary"
                        classNamePrefix="react-select"
                        placeholder="Diário..."
                        value={this.state.diarioSelecionado}
                        onChange={(event) => {
                          this.setState({
                            anoLetivo: null,
                            diarioSelecionado: event,
                          });
                          this.carregarInformacoesAnoLetivoProfessor(event.value);
                        }}
                        options={this.state.selectDiarios}
                      />
                    </Col>
                  }
                </Row>
              </CardHeader>
              <CardBody>
                {
                  this.state.anoLetivo ? <>
                    <Row style={{ marginTop: 50 }}>
                      <Col md="4">
                        <p className="titulo-grafico">Informações sobre matrículas</p>
                        <hr />
                        <Bar
                          onElementsClick={() => {
                            const path = `${this.props.layout}/dashboard/relatorios/informacoesmatriculas`
                              + `/anoletivo/${this.state.anoLetivoSelecionado.value}`
                              + `/diario/${this.state.diarioSelecionado.value}`;

                            this.props.history.push(path);
                          }}
                          data={this.state.anoLetivo.graficoSituacoesAlunos}
                          options={this.state.anoLetivo.graficoSituacoesAlunos.options}
                          width={500}
                          height={300}
                        />
                        <hr />
                      </Col>
                      <Col md="4">
                        <p className="titulo-grafico">Frequência</p>
                        <hr />
                        <Doughnut
                          data={this.state.anoLetivo.graficoAulasLancadas}
                          options={this.state.anoLetivo.graficoAulasLancadas.options}
                          width={500}
                          height={300}
                        />
                        <hr />
                      </Col>
                    </Row>
                  </> : this.state.anoLetivoSelecionado
                    && <div align="center" style={{ margin: 50 }}>
                      <Loader
                        type="Oval"
                        color="#34B5B8"
                        height="50"
                        width="50" />
                    </div>
                }
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </> : <Card>
        <div align="center" style={{ margin: 50 }}>
          <Loader
            type="Oval"
            color="#34B5B8"
            height="50"
            width="50" />
        </div>
      </Card>;
  }

  render() {
    return (
      <div className='content'>
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
            </div>
          </Card>
        }
        <LoaderModal
          isOpen={this.state.showLoaderModal}
          text="Carregando..." />
      </div>
    );
  }
}

export default DashboardProfessor;
