/* eslint-disable max-len */
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Input,
} from 'reactstrap';

import {
  Tabs,
  TabList,
  Tab,
  TabPanel,
} from 'react-tabs';

import Loader from 'react-loader-spinner';

import Alert from 'views/components/Alert';
import LoaderModal from 'views/components/LoaderModal';
import ConfirmModal from 'views/components/ConfirmModal';
import AdaptiveTable from 'views/components/AdaptiveTable';

import LoginService from 'services/LoginService';
import EntesService from 'services/EntesService';
import AnosLetivosService from 'services/AnosLetivosService';
import EscolasService from 'services/EscolasService';
import TurmasService from 'services/TurmasService';
import DiariosService from 'services/DiariosService';
import RelatoriosService from 'services/RelatoriosService';
import ProfessorAuxiliarService from 'services/ProfessorAuxiliarService';

import Turno from 'assets/csv/turnos.json';
import TipoAvaliacao from 'assets/csv/tipos-avaliacao.json';
import Papel from 'assets/csv/papeis.json';

class RelatorioTurma extends React.Component {
  constructor(props) {
    super(props);

    this.idPagina = `${this.props.dadosUsuario.idVinculo}-arquivos-relatorio-diario-${this.props.match.params.idDiario}`;

    this.paginaSelecionada = sessionStorage.getItem(`${this.idPagina}-pg`) || 1;
    this.linhasPorPagina = sessionStorage.getItem(`${this.idPagina}-ln`) || 5;
    this.like = '';

    this.turnos = [
      Turno.MATUTINO,
      Turno.VESPERTINO,
      Turno.NOTURNO,
      Turno.INTEGRAL,
    ];

    this.fileInputRef = React.createRef();
    this.fileChange = this.fileChange.bind(this);

    this.salvarRelatorios = this.salvarRelatorios.bind(this);

    this.state = {
      showAlert: false,
      buttonDisabled: false,
      showLoaderModal: false,

      error: null,

      dadosTabela: {
        header: [['Nome', '90%']],
        columnAlign: ['text-left'],
        keys: ['nome'],
        rows: [],
        total: undefined,
      },
    };

    this.loginService = new LoginService();
    this.entesService = new EntesService();
    this.anosLetivosService = new AnosLetivosService();
    this.escolasService = new EscolasService();
    this.turmasService = new TurmasService();
    this.diariosService = new DiariosService();
    this.relatoriosService = new RelatoriosService();
    this.professorAuxiliarService = new ProfessorAuxiliarService();
  }

  async componentDidMount() {
    await this.loginService.verificarPapel(this.props.role);
    try {
      const dadosEnte = await this.entesService.carregarDadosEnte();

      const professoreAuxiliar = await this.professorAuxiliarService
        .carregarMeuProfessorAuxiliar();

      const anoLetivo = await this.anosLetivosService
        .carregarAnoLetivo(this.props.match.params.idAnoLetivo);

      const escola = await this.escolasService
        .carregarEscola(this.props.match.params.idEscola);

      const turma = await this.turmasService
        .carregarTurma(this.props.match.params.idTurma);

      const diario = await this.diariosService
        .carregarDiario(this.props.match.params.idDiario);

      const relatorios = await this.relatoriosService
        .carregarRelatorios(this.props.match.params.idDiario);

      await this.carregarArquivosRelatorioDiario(this.paginaSelecionada,
        this.linhasPorPagina, this.like);

      this.setState({
        anoLetivo,
        escola,
        turma,
        diario,
        relatorios,
        auxiliarBloqueado: this.props.role === Papel.PROFESSOR.value && professoreAuxiliar.auxiliar && dadosEnte.bloquearAlteracaoDiarioProfessorAuxiliar,
      });
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  async carregarArquivosRelatorioDiario(pagina, linhasPorPagina, like) {
    try {
      const data = await this.diariosService
        .carregarArquivosRelatorioDiario(this.props.match.params.idDiario,
          pagina, linhasPorPagina, like);

      this.setState({
        dadosTabela: Object.assign(this.state.dadosTabela,
          { rows: data.arquivos, total: data.total }),
      });
    } catch (e) {
      if (this.umounted) return;
      this.setState({
        dadosTabela: Object.assign(this.state.dadosTabela, { total: -1 }),
      });
    }
  }

  fileChange(e) {
    this.setState({ showAlert: false });
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];

    const re = /(?:\.([^.]+))?$/;
    const ext = re.exec(file.name)[1];

    if (!ext) {
      this.setState({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao carregar arquivo selecionado. Não é possível enviar arquivos sem extensão.',
      });
      return;
    }

    if (file) {
      reader.onloadend = async () => {
        if (file.size > 10000000) {
          this.setState({
            showAlert: true,
            alertColor: 'danger',
            alertMsg: 'Erro: Arquivo muito grande. O tamanho máximo para arquivo é 10MB',
          });
        } else {
          const regexp = /^data:((\w+)\/(.+));base64,(.+)$/g;
          const matches = regexp.exec(reader.result);

          if (matches.length !== 5) {
            this.setState({
              showAlert: true,
              alertColor: 'danger',
              alertMsg: 'Erro ao carregar arquivo selecionado.',
            });
          } else {
            try {
              this.setState({
                buttonDisabled: true,
                loaderModalText: 'Fazendo upload...',
              });

              const arquivo = {
                nome: file.name,
                conteudo: matches[4],
                extensao: ext,
                tipo: matches[1],
              };

              await this.diariosService.salvarArquivoRelatorioDiario(
                this.props.match.params.idDiario, arquivo,
              );

              await this.carregarArquivosRelatorioDiario(this.paginaSelecionada,
                this.linhasPorPagina, this.like);

              this.setState({
                buttonDisabled: false,
                showAlert: true,
                alertColor: 'success',
                alertMsg: 'Arquivo salvo com sucesso.',
              });
            } catch (msg) {
              this.setState({
                buttonDisabled: false,
                showAlert: true,
                alertColor: 'danger',
                alertMsg: msg || 'Erro ao salvar arquivo selecionado.',
              });
            }
          }
        }
        this.fileInputRef.current.value = '';
      };
      reader.readAsDataURL(file);
    }
  }

  confirmarRemocaoArquivoRelatorioDiario(arquivo) {
    this.setState({ arquivoSelecionado: arquivo, showConfirmModal: true });
  }

  async removerArquivoRelatorioDiario() {
    this.setState({ showAlert: false, buttonDisabled: true });

    try {
      await this.diariosService
        .removerArquivoRelatorioDiario(this.state.arquivoSelecionado.id);

      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Arquivo removido com sucesso',
      });

      await this.carregarArquivosRelatorioDiario(this.paginaSelecionada,
        this.linhasPorPagina, this.like);
    } catch (msg) {
      if (this.umounted) return;
      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao remover arquivo',
      });
    }
  }

  async salvarRelatorios() {
    this.setState({
      buttonDisabled: true,
      showAlert: false,
      showLoaderModal: true,
    });

    try {
      await this.relatoriosService.salvarRelatorios(
        this.state.diario.id, this.state.relatorios,
      );

      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Relatórios salvos com sucesso',
      });
    } catch (msg) {
      if (this.umounted) return;
      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao salvar relatórios',
      });
    }
  }

  async salvarEmBackground() {
    await this.relatoriosService.salvarRelatorios(
      this.state.diario.id, this.state.relatorios,
    );
  }

  voltar() {
    if (this.state.turma.tipoAvaliacao === TipoAvaliacao.NOTA.value) {
      return `${this.props.layout}`
        + `/anosletivos/${this.props.match.params.idAnoLetivo}`
        + `/escola/${this.state.escola.id}`
        + `/turma/${this.state.turma.id}`
        + `/diario/${this.state.diario.id}`;
    }
    return `${this.props.layout}`
      + `/anosletivos/${this.props.match.params.idAnoLetivo}`
      + `/escola/${this.state.escola.id}`
      + `/turma/${this.state.turma.id}`
      + `/diario/${this.state.diario.id}/alfabetizacao`;
  }

  conteudoPagina() {
    return this.state.escola ? <>
      <Row>
        <Col md="12">
          <Alert
            color={this.state.alertColor}
            isOpen={this.state.showAlert}
            toggle={() => { this.setState({ showAlert: false }); }}>
            {this.state.alertMsg}
          </Alert>
          <Breadcrumb>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos`}>Anos letivos</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}`}>Ano letivo {this.state.anoLetivo.ano}</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}`}>{this.state.escola.nome}</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/${this.state.turma.id}`}>{this.state.turma.nome} - {this.turnos[this.state.turma.turno].label}</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={this.voltar()}>
              {this.state.diario.nome}</Link></BreadcrumbItem>
            <BreadcrumbItem active>Relatórios da turma</BreadcrumbItem>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <CardTitle tag="h4">
                Relatórios da turma
              </CardTitle>
            </CardHeader>
            <CardBody>
              <div className="fileinput text-center">
                <input
                  type="file"
                  onChange={this.fileChange}
                  ref={this.fileInputRef} />
              </div>
              <Row>
                <Col md="12">
                  <Tabs>
                    <TabList>
                      {
                        this.state.relatorios
                          .map((relatorio, idx) => <Tab key={idx}>
                            {relatorio.descricaoRelatorio.nome} {relatorio.descricaoRelatorio.nome !== 'Observações' ? '*' : ''}
                          </Tab>)
                      }
                      <Tab>Arquivos</Tab>
                    </TabList>
                    {
                      this.state.relatorios
                        .map((relatorio, idx) => <TabPanel key={idx}>
                          <Row>
                            <Col>
                              <Input
                                disabled={this.props.role !== Papel.PROFESSOR.value
                                  && this.props.role !== Papel.COORDENADOR_PEDAGOGICO.value}
                                value={relatorio.conteudo}
                                onChange={(evt) => {
                                  const diff = evt.target.value.length - relatorio.conteudo.length;
                                  Object.assign(
                                    relatorio, { conteudo: evt.target.value },
                                  );
                                  if ((evt.target.value.length > 0
                                    && evt.target.value.length % 140 === 0) || diff > 140) {
                                    this.salvarEmBackground();
                                  }
                                  this.forceUpdate();
                                }}
                                type="textarea"
                                style={{ height: '500px' }} />
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <div className="category form-category">
                                * Campos obrigatórios
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="6" className="text-left">
                              <Button
                                color="primary"
                                onClick={() => this.props.history
                                  .push(this.voltar())} >
                                Voltar
                              </Button>
                            </Col>
                            <Col md="6" className="text-right">
                              {
                                ((this.props.role === Papel.PROFESSOR.value && !this.state.auxiliarBloqueado)
                                  || this.props.role === Papel.COORDENADOR_PEDAGOGICO.value) && <Button
                                    color="primary"
                                    disabled={this.state.buttonDisabled}
                                    onClick={this.salvarRelatorios} >
                                  Salvar relatórios
                                </Button>
                              }
                            </Col>
                          </Row>
                        </TabPanel>)
                    }
                    <TabPanel>
                      <Row>
                        <Col>
                          <AdaptiveTable
                            disablePrintAction
                            disableEditAction
                            disableRemoveButton={this.props.role === Papel.COORDENADOR_PEDAGOGICO.value}
                            clickRows
                            selectedPage={this.paginaSelecionada}
                            rowsPerPage={this.linhasPorPagina}
                            disableActions={this.props.role === Papel.COORDENADOR_PEDAGOGICO.value}
                            rowsPerPageCallback={(info) => {
                              this.paginaSelecionada = 1;
                              sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
                              this.linhasPorPagina = info.rowsPerPage;
                              sessionStorage.setItem(`${this.idPagina}-ln`, this.linhasPorPagina);
                              this.carregarArquivosRelatorioDiario(this.paginaSelecionada,
                                info.rowsPerPage, this.like);
                            }}
                            likeCallback={(text) => {
                              this.like = text;
                              this.paginaSelecionada = 1;
                              sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
                              this.carregarArquivosRelatorioDiario(this.paginaSelecionada,
                                this.linhasPorPagina, text);
                            }}
                            paginatorCallback={(page) => {
                              this.paginaSelecionada = page;
                              sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
                              this.carregarArquivosRelatorioDiario(this.paginaSelecionada,
                                this.linhasPorPagina, this.like);
                            }}
                            removeButtonCallback={(arquivo) => this
                              .confirmarRemocaoArquivoRelatorioDiario(arquivo)}
                            rowCallback={(arquivo) => window.open(arquivo.url)}
                            data={this.state.dadosTabela}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col className="text-left">
                          <Button
                            color="primary"
                            onClick={() => this.props.history
                              .push(this.voltar())} >
                            Voltar
                          </Button>
                        </Col>
                        <Col className="text-right">
                          {
                            ((this.props.role === Papel.PROFESSOR.value && !this.state.auxiliarBloqueado)
                              || this.props.role === Papel.COORDENADOR_PEDAGOGICO.value) && <Button
                                color="primary"
                                disabled={this.state.buttonDisabled}
                                onClick={() => this.fileInputRef.current.click()} >
                              Inserir arquivo
                            </Button>
                          }
                        </Col>
                      </Row>
                    </TabPanel>
                  </Tabs>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </> : <Card>
      <div align="center" style={{ margin: 50 }}>
        <Loader
          type="Oval"
          color="#34B5B8"
          height="50"
          width="50" />
      </div>
    </Card>;
  }

  render() {
    return (
      <div className='content'>
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
            </div>
          </Card>
        }
        <LoaderModal
          isOpen={this.state.buttonDisabled}
          text="Salvando relatórios..." />
        <ConfirmModal
          isOpen={this.state.showConfirmModal}
          callback={(confirm) => {
            this.setState({ showConfirmModal: false });
            if (confirm) {
              this.removerArquivoRelatorioDiario();
            } else {
              this.setState({ arquivoSelecionado: null });
            }
          }}
          text='Confirme a exclusão do arquivo' />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ dadosUsuario: state.dadosUsuario });

export default connect(mapStateToProps)(RelatorioTurma);
