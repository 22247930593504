import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// reactstrap components
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  FormGroup,
  Input,
  Row
} from 'reactstrap';

import {
  Tab,
  TabList,
  TabPanel,
  Tabs,
} from 'react-tabs';

import Loader from 'react-loader-spinner';

import AdaptiveTable from 'views/components/AdaptiveTable';
import Alert from 'views/components/Alert';
import ConfirmTypingModal from 'views/components/ConfirmTypingModal';
import LoaderModal from 'views/components/LoaderModal';

import EstoquesService from 'services/EstoquesService';
import EscolasService from 'services/EscolasService';

import DestinoEstoqueSaida from 'assets/csv/destinos-estoque-saida.json';
import OrigemEstoqueEntrada from 'assets/csv/origens-estoque-entrada.json';
import UnidadeMedida from 'assets/csv/unidades-medida.json';
import Papel from 'assets/csv/papeis.json';

class Itens extends React.Component {
  constructor(props) {
    super(props);

    this.unidadesMedida = [
      UnidadeMedida.QUILOGRAMA,
      UnidadeMedida.LITRO,
      UnidadeMedida.UNIDADE,
      UnidadeMedida.METRO,
      UnidadeMedida.METRO_QUADRADO,
      UnidadeMedida.METRO_CUBICO,
      UnidadeMedida.CAIXA,
      UnidadeMedida.PACOTE,
      UnidadeMedida.GRAMA,
      UnidadeMedida.MILILITRO,
      UnidadeMedida.PAR,
      UnidadeMedida.FARDO
    ];

    this.idPagina = `${props.idVinculo}-estoque-${props.idEstoque}-itens`;
    this.paginaSelecionada = sessionStorage.getItem(`${this.idPagina}-pg`) || 1;
    this.linhasPorPagina = sessionStorage.getItem(`${this.idPagina}-ln`) || 5;
    this.like = '';

    this.state = {
      dadosTabela: {
        header: [['Nome', '40%'], ['Quantidadade em estoque', '30%'], ['Unidade de media', '20%']],
        columnAlign: ['text-left', 'text-center', 'text-center'],
        keys: ['nome', 'quantidade', 'medida'],
        rows: [],
        total: undefined,
      },
    }

    this.estoquesService = new EstoquesService();

    this.carregarEstoqueItens = this.carregarEstoqueItens.bind(this);
    this.editarEstoqueItem = this.editarEstoqueItem.bind(this);
    this.removerEstoqueItem = this.removerEstoqueItem.bind(this);
  }

  async componentDidMount() {
    this.carregarEstoqueItens(this.paginaSelecionada, this.linhasPorPagina, '');
  }

  async carregarEstoqueItens(pagina, linhasPorPagina, like) {
    try {
      const data = await this.estoquesService.carregarEstoqueItens(this.props.idEstoque, pagina, linhasPorPagina, like);

      const promisesItens = data.estoqueItens.map((estoqueItem) => new Promise(async (resolve) => {
        const quantidade = await this.estoquesService.carregarQuantidadeItem(estoqueItem.id, this.props.idEscola);
        resolve(quantidade);
      }));

      const quantidadeItens = await Promise.all(promisesItens);

      data.estoqueItens.forEach((estoqueItem, idx) => Object.assign(estoqueItem, {
        medida: this.unidadesMedida[estoqueItem.unidadeMedida].label,
        quantidade: quantidadeItens[idx].quantidade,
      }));

      this.setState({
        dadosTabela: Object.assign(this.state.dadosTabela,
          { rows: data.estoqueItens, total: data.total }),
      });
    } catch (e) {
      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao buscar itens do estoque',
      });

      this.setState({ dadosTabela: Object.assign(this.state.dadosTabela, { total: -1 }) });
    }
  }

  editarEstoqueItem(estoqueItem) {
    this.props.history.push({ pathname: `/gestor/estoques/${this.props.idEstoque}/itens/edicao/${estoqueItem.id}` });
  }

  confirmarRemocaoEstoqueItem(estoqueItem) {
    this.setState({ estoqueItemSelecionado: estoqueItem, showConfirmModal: true });
  }

  async removerEstoqueItem(senha) {
    this.setState({ showAlert: false, showLoaderModal: true });

    try {
      await this.estoquesService.removerEstoqueItem(this.state.estoqueItemSelecionado.id, senha);

      if (this.state.dadosTabela.total % this.linhasPorPagina === 1
        && this.paginaSelecionada > 1) {
        this.paginaSelecionada -= 1;
      }

      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Item de estoque removido com sucesso',
      })

      this.setState({ showLoaderModal: false });

      this.carregarEstoqueItens(this.paginaSelecionada, this.linhasPorPagina, this.like);
    } catch (msg) {
      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao remover item de estoque',
      })
      this.setState({ showLoaderModal: false });
    }
  }

  cadastrarItem() {
    if (this.props.idEscola !== undefined) {
      this.props.history.push(`${this.props.layout}/escolas/${this.props.idEscola}/estoques/${this.props.idEstoque}/itens/cadastro`);
    } else {
      this.props.history.push(`${this.props.layout}/estoques/${this.props.idEstoque}/itens/cadastro`)
    }
  }

  visualizarEstoqueItem(estoqueItem) {
    if (this.props.idEscola !== undefined) {
      this.props.history.push(`${this.props.layout}/escolas/${this.props.idEscola}/estoques/${this.props.idEstoque}/itens/${estoqueItem.id}`);
    } else {
      this.props.history.push(`${this.props.layout}/estoques/${this.props.idEstoque}/itens/${estoqueItem.id}`);
    }
  }

  voltar() {
    if (this.props.idEscola !== undefined) {
      this.props.history.push(`${this.props.layout}/escolas/${this.props.idEscola}`);
    } else {
      this.props.history.push(`${this.props.layout}/estoques`);
    }
  }

  render() {
    return (
      <>
        <Row>
          <Col>
            <AdaptiveTable
              selectedPage={this.paginaSelecionada}
              rowsPerPage={this.linhasPorPagina}
              disablePrintAction
              disableActions={this.props.idEscola !== undefined}
              clickRows
              editButtonCallback={(estoqueItem) => this.editarEstoqueItem(estoqueItem)}
              removeButtonCallback={(estoqueItem) => this.confirmarRemocaoEstoqueItem(estoqueItem)}
              rowsPerPageCallback={(info) => {
                this.paginaSelecionada = 1;
                sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
                this.linhasPorPagina = info.rowsPerPage;
                sessionStorage.setItem(`${this.idPagina}-ln`, this.linhasPorPagina);
                this.carregarEstoqueItens(this.paginaSelecionada, info.rowsPerPage, this.like);
              }}
              likeCallback={(text) => {
                this.like = text;
                this.paginaSelecionada = 1;
                sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
                this.carregarEstoqueItens(this.paginaSelecionada, this.linhasPorPagina, text);
              }}
              paginatorCallback={(page) => {
                this.paginaSelecionada = page;
                sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
                this.carregarEstoqueItens(this.paginaSelecionada, this.linhasPorPagina, this.like);
              }}
              rowCallback={(estoqueItem) => this.visualizarEstoqueItem(estoqueItem)}
              data={this.state.dadosTabela} />
          </Col>
        </Row>
        <Row>
          <Col md="6" className="text-left">
            <Button color="primary" onClick={() => this.voltar()} >
              Voltar
            </Button>
          </Col>
          {
            this.props.role === Papel.GESTOR.value && <Col md="6" className="text-right">
              <Button color="primary" onClick={() => this.cadastrarItem()} >
                Cadastrar Item
              </Button>
            </Col>
          }
        </Row>
        <ConfirmTypingModal
          isOpen={this.state.showConfirmModal}
          callback={(senha) => {
            this.setState({ showConfirmModal: false });
            if (senha) {
              this.removerEstoqueItem(senha);
            } else {
              this.setState({ estoqueItemSelecionado: null });
            }
          }}
          deletionInstruction='Confirme que você quer excluir o item de estoque digitando a sua senha no campo abaixo.'
          deletionText={''}
          deletionWarning='A exclusão de um item de estoque causará a exclusão de todas as entradas e saídas associadas ao item e demais dados relacionados.' />
        <LoaderModal
          isOpen={this.state.showLoaderModal}
          text='Excluindo item de estoque...' />
      </>
    )
  }

}

class Entradas extends React.Component {
  constructor(props) {
    super(props);

    this.origensEstoqueEntrada = [
      OrigemEstoqueEntrada.ESTOQUE_CENTRAL,
      OrigemEstoqueEntrada.RECURSOS_PROPRIOS,
    ];

    this.idPagina = `${props.idVinculo}-estoque-${props.idEstoque}-entradas`;
    this.paginaSelecionada = sessionStorage.getItem(`${this.idPagina}-pg`) || 1;
    this.linhasPorPagina = sessionStorage.getItem(`${this.idPagina}-ln`) || 5;
    this.like = '';

    this.state = {
      dadosTabela: {
        header: [['Nome', '40%'], ['Quantidade', '15%'], ['Origem', '20%'], ['Data', '15%']],
        columnAlign: ['text-left', 'text-center', 'text-center', 'text-center'],
        keys: ['nome', 'quantidade', 'origem_str', 'data'],
        rows: [],
        total: undefined,
      },
    }

    this.estoquesService = new EstoquesService();

    this.carregarEstoqueEntradas = this.carregarEstoqueEntradas.bind(this);
    this.editarEstoqueEntrada = this.editarEstoqueEntrada.bind(this);
    this.confirmarRemocaoEstoqueEntrada = this.confirmarRemocaoEstoqueEntrada.bind(this);
    this.removerEstoqueEntrada = this.removerEstoqueEntrada.bind(this);

  }

  async componentDidMount() {
    this.carregarEstoqueEntradas(this.paginaSelecionada, this.linhasPorPagina, '');
  }

  async carregarEstoqueEntradas(pagina, linhasPorPagina, like) {
    try {
      const data = await this.estoquesService.carregarEstoqueEntradas(this.props.idEstoque, this.props.idEscola, pagina, linhasPorPagina, like);

      data.estoqueEntradas.forEach((estoqueEntrada) => Object.assign(estoqueEntrada, {
        origem_str: this.origensEstoqueEntrada[estoqueEntrada.origem].label,
        nome: estoqueEntrada.estoqueItem.nome,
        disabledRemove: estoqueEntrada.origem === OrigemEstoqueEntrada.ESTOQUE_CENTRAL.value,
        disabledEdit: estoqueEntrada.origem === OrigemEstoqueEntrada.ESTOQUE_CENTRAL.value,
      }));

      this.setState({
        dadosTabela: Object.assign(this.state.dadosTabela,
          { rows: data.estoqueEntradas, total: data.total }),
      });
    } catch (e) {
      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao buscar itens do estoque',
      });

      this.setState({ dadosTabela: Object.assign(this.state.dadosTabela, { total: -1 }) });
    }
  }

  editarEstoqueEntrada(estoqueEntrada) {
    if (this.props.idEscola !== undefined) {
      this.props.history.push(`${this.props.layout}/escolas/${this.props.idEscola}/estoques/${this.props.idEstoque}/entradas/edicao/${estoqueEntrada.id}`);
    } else {
      this.props.history.push(`${this.props.layout}/estoques/${this.props.idEstoque}/entradas/edicao/${estoqueEntrada.id}`)
    }
  }

  confirmarRemocaoEstoqueEntrada(estoqueEntrada) {
    this.setState({ estoqueEntradaSelecionada: estoqueEntrada, showConfirmModal: true });
  }

  async removerEstoqueEntrada(senha) {
    this.setState({ showAlert: false, showLoaderModal: true });

    try {
      await this.estoquesService.removerEstoqueEntrada(this.state.estoqueEntradaSelecionada.id, senha);

      if (this.state.dadosTabela.total % this.linhasPorPagina === 1
        && this.paginaSelecionada > 1) {
        this.paginaSelecionada -= 1;
      }

      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Entrada de estoque removido com sucesso',
      })

      this.setState({ showLoaderModal: false });

      this.carregarEstoqueEntradas(this.paginaSelecionada, this.linhasPorPagina, this.like);
    } catch (msg) {
      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao remover item de estoque',
      })
      this.setState({ showLoaderModal: false });
    }
  }

  cadastrarEntrada() {
    if (this.props.idEscola !== undefined) {
      this.props.history.push(`${this.props.layout}/escolas/${this.props.idEscola}/estoques/${this.props.idEstoque}/entradas/cadastro`);
    } else {
      this.props.history.push(`${this.props.layout}/estoques/${this.props.idEstoque}/entradas/cadastro`)
    }
  }

  visualizarEstoqueEntrada(estoqueEntrada) {
    if (this.props.idEscola !== undefined) {
      this.props.history.push(`${this.props.layout}/escolas/${this.props.idEscola}/estoques/${this.props.idEstoque}/entradas/${estoqueEntrada.id}`);
    } else {
      this.props.history.push(`${this.props.layout}/estoques/${this.props.idEstoque}/entradas/${estoqueEntrada.id}`);
    }
  }

  voltar() {
    if (this.props.idEscola !== undefined) {
      this.props.history.push(`${this.props.layout}/escolas/${this.props.idEscola}`);
    } else {
      this.props.history.push(`${this.props.layout}/estoques`);
    }
  }

  render() {
    return (
      <>
        <Row>
          <Col>
            <AdaptiveTable
              selectedPage={this.paginaSelecionada}
              rowsPerPage={this.linhasPorPagina}
              disablePrintAction
              clickRows
              editButtonCallback={(estoqueEntrada) => this.editarEstoqueEntrada(estoqueEntrada)}
              removeButtonCallback={(estoqueEntrada) => this.confirmarRemocaoEstoqueEntrada(estoqueEntrada)}
              rowsPerPageCallback={(info) => {
                this.paginaSelecionada = 1;
                sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
                this.linhasPorPagina = info.rowsPerPage;
                sessionStorage.setItem(`${this.idPagina}-ln`, this.linhasPorPagina);
                this.carregarEstoqueEntradas(this.paginaSelecionada, info.rowsPerPage, this.like);
              }}
              likeCallback={(text) => {
                this.like = text;
                this.paginaSelecionada = 1;
                sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
                this.carregarEstoqueEntradas(this.paginaSelecionada, this.linhasPorPagina, text);
              }}
              paginatorCallback={(page) => {
                this.paginaSelecionada = page;
                sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
                this.carregarEstoqueEntradas(this.paginaSelecionada, this.linhasPorPagina, this.like);
              }}
              rowCallback={(estoqueEntrada) => this.visualizarEstoqueEntrada(estoqueEntrada)}
              data={this.state.dadosTabela} />
          </Col>
        </Row>
        <Row>
          <Col md="6" className="text-left">
            <Button color="primary" onClick={() => this.voltar()} >
              Voltar
            </Button>
          </Col>
          <Col md="6" className="text-right">
            <Button color="primary" onClick={() => this.cadastrarEntrada()} >
              Cadastrar Entrada
            </Button>
          </Col>
        </Row>
        <ConfirmTypingModal
          isOpen={this.state.showConfirmModal}
          callback={(senha) => {
            this.setState({ showConfirmModal: false });
            if (senha) {
              this.removerEstoqueEntrada(senha);
            } else {
              this.setState({ estoqueEntradaSelecionada: null });
            }
          }}
          deletionInstruction='Confirme que você quer excluir a entrada de estoque digitando a sua senha no campo abaixo.'
          deletionText={''}
          deletionWarning='A exclusão de uma entrada não poderá ser despeita.' />
        <LoaderModal
          isOpen={this.state.showLoaderModal}
          text='Excluindo entrada de estoque...' />
      </>
    )
  }
}

class Saidas extends React.Component {
  constructor(props) {
    super(props);

    this.destinosEstoqueSaida = [
      DestinoEstoqueSaida.TRANSFERENCIA_ESCOLA,
      DestinoEstoqueSaida.CONSUMO_PROPRIO,
    ];

    this.idPagina = `${props.idVinculo}-estoque-${props.idEstoque}-saidas`;
    this.paginaSelecionada = sessionStorage.getItem(`${this.idPagina}-pg`) || 1;
    this.linhasPorPagina = sessionStorage.getItem(`${this.idPagina}-ln`) || 5;
    this.like = '';

    this.state = {
      dadosTabela: {
        header: [['Nome', '40%'], ['Quantidade', '15%'], ['Destino', '20%'], ['Data', '15%']],
        columnAlign: ['text-left', 'text-center', 'text-center', 'text-center'],
        keys: ['nome', 'quantidade', 'destino_str', 'data'],
        rows: [],
        total: undefined,
      },
    }

    this.estoquesService = new EstoquesService();

    this.carregarEstoqueSaidas = this.carregarEstoqueSaidas.bind(this);
    this.editarEstoqueSaida = this.editarEstoqueSaida.bind(this);
    this.confirmarRemocaoEstoqueSaida = this.confirmarRemocaoEstoqueSaida.bind(this);
    this.removerEstoqueSaida = this.removerEstoqueSaida.bind(this);

  }

  async componentDidMount() {
    this.carregarEstoqueSaidas(this.paginaSelecionada, this.linhasPorPagina, '');
  }

  async carregarEstoqueSaidas(pagina, linhasPorPagina, like) {
    try {
      const data = await this.estoquesService.carregarEstoqueSaidas(this.props.idEstoque, this.props.idEscola, pagina, linhasPorPagina, like);

      data.estoqueSaidas.forEach((estoqueSaida) => Object.assign(estoqueSaida, {
        destino_str: this.destinosEstoqueSaida[estoqueSaida.destino].label,
        nome: estoqueSaida.estoqueItem.nome,
      }));

      this.setState({
        dadosTabela: Object.assign(this.state.dadosTabela,
          { rows: data.estoqueSaidas, total: data.total }),
      });
    } catch (e) {
      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao buscar saída do estoque',
      });

      this.setState({ dadosTabela: Object.assign(this.state.dadosTabela, { total: -1 }) });
    }
  }

  editarEstoqueSaida(estoqueSaida) {
    if (this.props.idEscola !== undefined) {
      this.props.history.push(`${this.props.layout}/escolas/${this.props.idEscola}/estoques/${this.props.idEstoque}/saidas/edicao/${estoqueSaida.id}`);
    } else {
      this.props.history.push(`${this.props.layout}/estoques/${this.props.idEstoque}/saidas/edicao/${estoqueSaida.id}`)
    }
  }

  confirmarRemocaoEstoqueSaida(estoqueSaida) {
    this.setState({ estoqueSaidaSelecionada: estoqueSaida, showConfirmModal: true });
  }

  async removerEstoqueSaida(senha) {
    this.setState({ showAlert: false, showLoaderModal: true });

    try {
      await this.estoquesService.removerEstoqueSaida(this.state.estoqueSaidaSelecionada.id, senha);

      if (this.state.dadosTabela.total % this.linhasPorPagina === 1
        && this.paginaSelecionada > 1) {
        this.paginaSelecionada -= 1;
      }

      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Saída de estoque removida com sucesso',
      })

      this.setState({ showLoaderModal: false });

      this.carregarEstoqueSaidas(this.paginaSelecionada, this.linhasPorPagina, this.like);
    } catch (msg) {
      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao remover saída de estoque',
      })
      this.setState({ showLoaderModal: false });
    }
  }

  cadastrarSaida() {
    if (this.props.idEscola !== undefined) {
      this.props.history.push(`${this.props.layout}/escolas/${this.props.idEscola}/estoques/${this.props.idEstoque}/saidas/cadastro`);
    } else {
      this.props.history.push(`${this.props.layout}/estoques/${this.props.idEstoque}/saidas/cadastro`)
    }
  }

  visualizarEstoqueSaida(estoqueSaida) {
    if (this.props.idEscola !== undefined) {
      this.props.history.push(`${this.props.layout}/escolas/${this.props.idEscola}/estoques/${this.props.idEstoque}/saidas/${estoqueSaida.id}`);
    } else {
      this.props.history.push(`${this.props.layout}/estoques/${this.props.idEstoque}/saidas/${estoqueSaida.id}`);
    }
  }

  voltar() {
    if (this.props.idEscola !== undefined) {
      this.props.history.push(`${this.props.layout}/escolas/${this.props.idEscola}`);
    } else {
      this.props.history.push(`${this.props.layout}/estoques`);
    }
  }

  render() {
    return (
      <>
        <Row>
          <Col>
            <AdaptiveTable
              selectedPage={this.paginaSelecionada}
              rowsPerPage={this.linhasPorPagina}
              disablePrintAction
              clickRows
              editButtonCallback={(estoqueSaida) => this.editarEstoqueSaida(estoqueSaida)}
              removeButtonCallback={(estoqueSaida) => this.confirmarRemocaoEstoqueSaida(estoqueSaida)}
              rowsPerPageCallback={(info) => {
                this.paginaSelecionada = 1;
                sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
                this.linhasPorPagina = info.rowsPerPage;
                sessionStorage.setItem(`${this.idPagina}-ln`, this.linhasPorPagina);
                this.carregarEstoqueSaidas(this.paginaSelecionada, info.rowsPerPage, this.like);
              }}
              likeCallback={(text) => {
                this.like = text;
                this.paginaSelecionada = 1;
                sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
                this.carregarEstoqueSaidas(this.paginaSelecionada, this.linhasPorPagina, text);
              }}
              paginatorCallback={(page) => {
                this.paginaSelecionada = page;
                sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
                this.carregarEstoqueSaidas(this.paginaSelecionada, this.linhasPorPagina, this.like);
              }}
              rowCallback={(estoqueSaida) => this.visualizarEstoqueSaida(estoqueSaida)}
              data={this.state.dadosTabela} />
          </Col>
        </Row>
        <Row>
          <Col md="6" className="text-left">
            <Button color="primary" onClick={() => this.voltar()} >
              Voltar
            </Button>
          </Col>
          <Col md="6" className="text-right">
            <Button color="primary" onClick={() => this.cadastrarSaida()} >
              Cadastrar Saída
            </Button>
          </Col>
        </Row>
        <ConfirmTypingModal
          isOpen={this.state.showConfirmModal}
          callback={(senha) => {
            this.setState({ showConfirmModal: false });
            if (senha) {
              this.removerEstoqueSaida(senha);
            } else {
              this.setState({ estoqueSaidaSelecionada: null });
            }
          }}
          deletionInstruction='Confirme que você quer excluir a saída de estoque digitando a sua senha no campo abaixo.'
          deletionText={''}
          deletionWarning='A exclusão de uma saída não poderá ser despeita.' />
        <LoaderModal
          isOpen={this.state.showLoaderModal}
          text='Excluindo saída de estoque...' />
      </>
    )
  }
}

class Estoque extends React.Component {
  constructor(props) {
    super(props);

    this.estoquesService = new EstoquesService();
    this.escolasService = new EscolasService();

    this.state = {
      loading: true,
      showAlert: false,

      nome: '',

      erro: null,
    };
  }

  async componentDidMount() {
    try {

      const estoque = await this.estoquesService
        .carregarEstoque(this.props.match.params.idEstoque);

      if (this.props.match.params.idEscola) {
        const escola = await this.escolasService.carregarEscola(this.props.match.params.idEscola)
        this.setState({ escola });
      }

      this.setState({ estoque, loading: false });

    } catch (e) {
      this.setState({ erro: true });
    }
  }


  conteudoPagina() {
    return this.state.loading ? <Card>
      <div align="center" style={{ margin: 50 }}>
        <Loader
          type="Oval"
          color="#34B5B8"
          height="50"
          width="50" />
      </div>
    </Card> : <Row>
      <Col md="12">
        <Alert
          color={this.state.alertColor}
          isOpen={this.state.showAlert}
          toggle={() => { this.setState({ showAlert: false }); }}>
          {this.state.alertMsg}
        </Alert>
        {
          this.props.match.params.idEscola !== undefined ? <Breadcrumb>
            <BreadcrumbItem><Link to={`${this.props.layout}/escolas`}>Escolas</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/escolas/${this.props.match.params.idEscola}`}>{this.state.escola.nome}</Link></BreadcrumbItem>
            <BreadcrumbItem active>{this.state.estoque.nome}</BreadcrumbItem>
          </Breadcrumb> : <Breadcrumb>
            <BreadcrumbItem><Link to="/gestor/estoques">Estoques</Link></BreadcrumbItem>
            <BreadcrumbItem active>{this.state.estoque.nome}</BreadcrumbItem>
          </Breadcrumb>
        }

        <Card>
          <CardHeader>
            <CardTitle tag="h4">Dados do estoque</CardTitle>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="5">
                <FormGroup className={`has-label`}>
                  <label>Nome *</label>
                  <Input
                    disabled
                    value={this.state.estoque.nome}
                    name="nomeEstoque"
                    type="text"
                    maxLength="100"
                  />
                </FormGroup>
              </Col>
            </Row>
            <br />
            <Row>
              <Col>
                <Tabs>
                  <TabList>
                    <Tab>Itens</Tab>
                    <Tab>Entradas</Tab>
                    <Tab>Saídas</Tab>
                  </TabList>
                  <TabPanel>
                    <Itens
                      idEstoque={this.props.match.params.idEstoque}
                      idEscola={this.props.match.params.idEscola}
                      idVinculo={this.props.dadosUsuario.idVinculo}
                      history={this.props.history}
                      layout={this.props.layout}
                      role={this.props.role}
                      callbackError={() => this.setState({ erro: true })}
                      callbackMessage={(msg) => this.setState(msg)}
                    />
                  </TabPanel>
                  <TabPanel>
                    <Entradas
                      idEstoque={this.props.match.params.idEstoque}
                      idEscola={this.props.match.params.idEscola}
                      idVinculo={this.props.dadosUsuario.idVinculo}
                      history={this.props.history}
                      layout={this.props.layout}
                      callbackError={() => this.setState({ erro: true })}
                      callbackMessage={(msg) => this.setState(msg)}
                    />
                  </TabPanel>
                  <TabPanel>
                    <Saidas
                      idEstoque={this.props.match.params.idEstoque}
                      idEscola={this.props.match.params.idEscola}
                      idVinculo={this.props.dadosUsuario.idVinculo}
                      history={this.props.history}
                      layout={this.props.layout}
                      callbackError={() => this.setState({ erro: true })}
                      callbackMessage={(msg) => this.setState(msg)}
                    />
                  </TabPanel>
                </Tabs>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>;
  }

  render() {
    return (
      <div className="content">
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
            </div>
          </Card>
        }
        <LoaderModal
          isOpen={this.state.showLoaderModal}
          text={this.state.loaderModalText} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ dadosUsuario: state.dadosUsuario });

export default connect(mapStateToProps)(Estoque);