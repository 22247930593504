import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import env from 'conf/env.json';

import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
  Row,
  Button,
} from 'reactstrap';

import ReCAPTCHA from 'react-google-recaptcha';

import Alert from 'views/components/Alert';
import LoaderModal from 'views/components/LoaderModal';

import LoginService from 'services/LoginService';

import RootFooter from './Footer';

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.loginRef = React.createRef();
    this.senhaRef = React.createRef();
    this.recaptchaRef = React.createRef();

    this.enviar = this.enviar.bind(this);
    this.enviarDados = this.enviarDados.bind(this);
    this.redoCaptcha = this.redoCaptcha.bind(this);
    this.onVerifyRecaptcha = this.onVerifyRecaptcha.bind(this);
    this.onErrorRecaptcha = this.onErrorRecaptcha.bind(this);

    this.loginService = new LoginService();

    this.state = {
      showAlert: false,

      loginState: '',
      senhaState: '',

      buttonDisabled: false,

      secure: true,
    };
  }

  componentDidMount = () => {
    const params = new URLSearchParams(this.props.location.search);

    const cadastro = params.get('cadastro');
    const msg = params.get('msg');

    if (cadastro === 'ok') {
      this.setState({
        showAlert: true,
        alertColor: 'success',
        alertMsg: msg,
      });
    } else if (cadastro === 'fail') {
      this.setState({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg,
      });
    }
  }

  componentWillUnmount() {
    this.loginService.abortPedingRequests();
  }

  validarCampos() {
    let ret = true;
    if (this.loginRef.current.value === '') {
      this.setState({ loginState: 'has-danger' });
      ret = false;
    }
    if (this.senhaRef.current.value === '') {
      this.setState({ senhaState: 'has-danger' });
      ret = false;
    }

    return ret;
  }

  async enviar() {
    if (this.state.buttonDisabled) return;
    if (!this.validarCampos()) return;

    // this.enviarDados();
    this.recaptchaRef.current.execute();
  }

  async enviarDados() {
    this.setState({ buttonDisabled: true, showAlert: false });
    const login = this.loginRef.current.value;

    try {
      const usuario = await this.loginService
        .enviar(login, this.senhaRef.current.value);

      this.props.history.push({
        pathname: '/login/escolhervinculo',
        state: {
          login,
          senha: this.senhaRef.current.value,
          usuario,
        },
      });
    } catch (msg) {
      this.recaptchaRef.current.reset();
      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Ocorreu um erro ao realizar login.',
      });
    }
  }

  redoCaptcha() {
    this.recaptchaRef.current.reset();
    this.recaptchaRef.current.execute();
  }

  onVerifyRecaptcha() {
    this.enviarDados();
  }

  onErrorRecaptcha() {
    this.setState({
      buttonDisabled: false,
      showAlert: true,
      alertColor: 'danger',
      alertMsg: 'Erro: sua interação foi identificada como um robô. Por favor, recarregue a página e tente novamente.',
    });
  }

  render() {
    const {
      loginState,
      senhaState,
    } = this.state;

    return <>
      <header id="header" className="fixed-top">
        <div className="container d-flex align-items-center">
          <h1 className="logo mr-auto"><Link to="/">{env.SYSNAME}</Link></h1>
          <nav className="nav-menu d-none d-lg-block">
            <ul>
              <li><NavLink to="/">&nbsp;</NavLink></li>
            </ul>
          </nav>
        </div>
      </header>
      <main id="main">
        <section className="breadcrumbs">
          <div className="container">
            <div className="d-flex justify-content-between align-items-center">
              <h2>Login</h2>
              <ol>
                <li><NavLink to="/">Início</NavLink></li>
                <li>Login</li>
              </ol>
            </div>
          </div>
        </section>
        <div className="container">
          <Alert
            style={{ marginTop: '20px' }}
            color={this.state.alertColor}
            isOpen={this.state.showAlert}
            toggle={() => { this.setState({ showAlert: false }); }}>
            {this.state.alertMsg}
          </Alert>
        </div>
        <section className="inner-page">
          <div className="container">
            <Row>
              <Col className="ml-auto mr-auto" md="4">
                <Form id="loginForm" className="form" onSubmit={(e) => {
                  e.preventDefault();
                  this.enviar();
                }}>
                  <Card className="card-root">
                    <CardHeader>
                      <CardHeader>
                        <h3 className="header text-center">Login</h3>
                      </CardHeader>
                    </CardHeader>
                    <CardBody>
                      <InputGroup className={`has-label ${loginState}`}>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fas fa-user" style={{ marginRight: '10px' }} />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          innerRef={this.loginRef}
                          placeholder="Login..."
                          type="text"
                          maxLength="100"
                          onChange={() => { this.setState({ loginState: '' }); }} />
                        {this.state.loginState === 'has-danger' ? (
                          <label className="error">
                            Informe o login.
                          </label>
                        ) : null}
                      </InputGroup>
                      <InputGroup className={`has-label ${senhaState}`}>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fas fa-key" style={{ marginRight: '10px' }} />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          className="inputappend"
                          innerRef={this.senhaRef}
                          placeholder="Senha..."
                          type={this.state.secure ? 'password' : 'text' }
                          autoComplete="off"
                          onChange={() => { this.setState({ senhaState: '' }); }} />
                        {this.state.senhaState === 'has-danger' ? (
                          <label className="error">
                            Informe a senha.
                          </label>
                        ) : null}
                      </InputGroup>
                      <Row>
                        <Col>
                          <ReCAPTCHA
                            ref={this.recaptchaRef}
                            size="invisible"
                            onExpired={this.redoCaptcha}
                            onChange={this.onVerifyRecaptcha}
                            onError={this.onErrorRecaptcha}
                            sitekey={env.SITEKEY}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Link to="#" onClick={() => this.setState({secure: !this.state.secure})}>
                            <i className={this.state.secure ? 'fas fa-eye' : 'fas fa-eye-slash'} style={{ marginLeft: '10px' }} /> {this.state.secure ? 'Ver' : 'Ocultar'} senha
                          </Link>
                        </Col>
                        <Col className="text-right">
                          <Link to="/login/recuperacaosenha">
                            Recuperar senha
                          </Link>
                        </Col>
                      </Row>
                    </CardBody>
                    <CardFooter>
                      <Row>
                        <Col className="text-center">
                          <Button
                            disabled={this.state.buttonDisabled}
                            color="primary"
                            type="submit">
                            Entrar
                          </Button>
                        </Col>
                      </Row>
                    </CardFooter>
                  </Card>
                </Form>
              </Col>
            </Row>
          </div>
        </section>
      </main>
      <RootFooter />
      <LoaderModal
        isOpen={this.state.buttonDisabled}
        text='Realizando login...' />
    </>;
  }
}

export default Login;
