/* eslint-disable max-len */
import React from 'react';
// reactstrap components
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  Table,
} from 'reactstrap';

import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar';

import Loader from 'react-loader-spinner';

import EntesService from 'services/EntesService';

import Papel from 'assets/csv/papeis.json';

class RelatoriosEstatisticos extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      erro: undefined,
    };

    this.entesService = new EntesService();
  }

  imprimirHistoricoAluno() {
    this.props.history.push(`${this.props.layout}`
      + `/escolas/${this.props.match.params.idEscola}`
      + '/historicos');
  }

  async componentDidMount() {
    try {
      const dadosEnte = await this.entesService
        .carregarDadosEnte();

      this.setState({
        dadosEnte,
      })
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  conteudoPagina() {
    return this.state.dadosEnte ? <div className="content">
      <Row>
        <Col md="12">
          <Breadcrumb>
            <BreadcrumbItem active>Relatórios</BreadcrumbItem>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <CardTitle></CardTitle>
            </CardHeader>
            <CardBody>
              <PerfectScrollbar className="sisa-perfect-scrollbar">
              <Table hover>
                <thead className="text-primary">
                  <tr>
                    <th>Relatórios</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    className='tr-clickable'
                    onClick={() => this.props.history.push(`${this.props.layout}/relatorios/datasanoletivo`)}>
                    <td>Relação de datas do ano letivo</td>
                  </tr>
                  {
                    this.props.role === Papel.GESTOR.value && <tr
                      className='tr-clickable'
                      onClick={() => this.props.history.push(`${this.props.layout}/relatorios/matriculasnaorenovadas`)}>
                      <td>Relatório de matrículas não renovadas</td>
                    </tr>
                  }
                  {/*
                  <tr
                    className='tr-clickable'
                    onClick={() => this.props.history
                      .push(`${this.props.layout}/relatorios/alunosbaixafrequenciaporturma`)}>
                    <td>Relação de alunos com baixa frequência por turma</td>
                  </tr>
                  */}
                  <tr
                    className='tr-clickable'
                    onClick={() => this.props.history.push(`${this.props.layout}/relatorios/escolasturmas`)}>
                    <td>Relatórios de escolas e turmas <i className="fas fa-angle-double-right"></i></td>
                  </tr>
                  <tr
                    className='tr-clickable'
                    onClick={() => this.props.history.push(`${this.props.layout}/relatorios/distorcaoidadeserie`)}>
                    <td>Relatórios de distorção idade série <i className="fas fa-angle-double-right"></i></td>
                  </tr>
                  <tr
                    className='tr-clickable'
                    onClick={() => this.props.history.push(`${this.props.layout}/relatorios/professores`)}>
                    <td>Relatórios de professores <i className="fas fa-angle-double-right"></i></td>
                  </tr>
                  <tr
                    className='tr-clickable'
                    onClick={() => this.props.history.push(`${this.props.layout}/relatorios/alunos`)}>
                    <td>Relatórios de alunos <i className="fas fa-angle-double-right"></i></td>
                  </tr>
                  <tr
                    className='tr-clickable'
                    onClick={() => this.props.history.push(`${this.props.layout}/relatorios/transportes`)}>
                    <td>Relatórios transportes <i className="fas fa-angle-double-right"></i></td>
                  </tr>
                  {
                    this.state.dadosEnte.registroAulas && <tr
                      className='tr-clickable'
                      onClick={() => this.props.history.push(`${this.props.layout}/relatorios/registrosaula`)}>
                      <td>Relatórios de registros de aula <i className="fas fa-angle-double-right"></i></td>
                    </tr>
                  }
                </tbody>
              </Table>
              </PerfectScrollbar>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div> : <Card>
      <div align="center" style={{ margin: 50 }}>
        <Loader
          type="Oval"
          color="#34B5B8"
          height="50"
          width="50" />
      </div>
    </Card>
  }

  render() {
    return (
      <div className='content'>
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
            </div>
          </Card>
        }
      </div>
    );
  }
}

export default RelatoriosEstatisticos;
