import React from 'react';
import { Link } from 'react-router-dom';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap';

import InputMask from 'react-input-mask';
import Loader from 'react-loader-spinner';
import Select from 'react-select';

import Alert from 'views/components/Alert';
import LoaderModal from 'views/components/LoaderModal';

import EscolasService from 'services/EscolasService';
import UsuarioService from 'services/UsuarioService';
import LoginService from 'services/LoginService';

import TipoFuncionario from 'assets/csv/tipos-funcionario.json';
import Sexo from 'assets/csv/sexos.json';
import Etnia from 'assets/csv/etnias.json';
import Escolaridade from 'assets/csv/escolaridades.json';

class Funcionario extends React.Component {
  constructor(props) {
    super(props);

    this.tiposFuncionario = [
      TipoFuncionario.AUXILIAR_ADMINISTRATIVO,
      TipoFuncionario.SECRETARIO_ESCOLAR,
      TipoFuncionario.AUXILIAR_SERVICOS_GERAIS,
      TipoFuncionario.INSPETOR_ESCOLAR,
      TipoFuncionario.PORTEIRO,
      TipoFuncionario.MERENDEIRO,
      TipoFuncionario.SUPORTE_PEDAGOGICO,
      TipoFuncionario.AUXILIAR_MERENDEIRA,
      TipoFuncionario.CUIDADORA,
      TipoFuncionario.VICE_DIRETOR,
      TipoFuncionario.PROFESSOR_SALA_DE_LEITURA,
      TipoFuncionario.DIRETOR_ADMINISTRATIVO_FINANCEIRO,
      TipoFuncionario.DIRETOR_PEDAGOGICO,
    ];

    this.sexos = [
      Sexo.MASCULINO,
      Sexo.FEMININO,
    ];

    this.etnias = [
      Etnia.BRANCA,
      Etnia.PRETA,
      Etnia.PARDA,
      Etnia.AMARELA,
      Etnia.NAO_DECLARADA,
      Etnia.INDIGENA,
    ];

    this.pnes = [
      { label: 'Não', value: false },
      { label: 'Sim', value: true },
    ];

    this.opcoesBolsaFamilia = [
      { label: 'Não', value: false },
      { label: 'Sim', value: true },
    ];

    this.escolaridades = [
      Escolaridade.ENSINO_MEDIO_COMPLETO,
      Escolaridade.ENSINO_SUPERIOR_INCOMPLETO,
      Escolaridade.ENSINO_SUPERIOR_COMPLETO,
    ];

    this.escolasService = new EscolasService();
    this.usuarioService = new UsuarioService();
    this.loginService = new LoginService();

    this.state = {
      showAlert: false,
      buttonDisabled: false,

      loaderModalText: '',

      nome: '',
      endereco: '',
      naturalidade: '',
      cpf: '',
      rg: '',
      cartaoSUS: '',
      nis: '',
      dataNascimento: '',

      funcionario: null,
      erro: null,
    };
  }

  async componentDidMount() {
    await this.loginService.verificarPapel(this.props.role);

    try {
      const escola = await this.escolasService
        .carregarEscola(this.props.match.params.idEscola);

      this.setState({ escola });

      const funcionario = await this.escolasService
        .carregarFuncionario(this.props.match.params.idFuncionario);

      this.setState({
        nome: funcionario.nome || '',
        endereco: funcionario.endereco || '',
        naturalidade: funcionario.naturalidade || '',
        cpf: funcionario.cpf || '',
        rg: funcionario.rg || '',
        cartaoSUS: funcionario.cartaoSUS || '',
        nis: funcionario.nis || '',
        dataNascimento: funcionario.dataNascimento || '',
  
        tipoFuncionarioSelecionado: this.tiposFuncionario[funcionario.tipo],
        sexoSelecionado: this.sexos[funcionario.sexo],
        etniaSelecionada: this.etnias[funcionario.etnia],
        pneSelecionado: this.pnes[funcionario.pne ? 1 : 0],
        bolsaFamilia: this.opcoesBolsaFamilia[funcionario.bolsaFamilia ? 1 : 0],
      });

    } catch (e) {
      this.setState({ erro: true });
    }
  }

  conteudoPagina() {
    return (!this.state.escola)
      ? <Card>
        <div align="center" style={{ margin: 50 }}>
          <Loader
            type="Oval"
            color="#34B5B8"
            height="50"
            width="50" />
        </div>
      </Card> : <Row>
        <Col md="12">
          <Alert
            color={this.state.alertColor}
            isOpen={this.state.showAlert}
            toggle={() => { this.setState({ showAlert: false }); }}>
            {this.state.alertMsg}
          </Alert>
          <Breadcrumb>
            <BreadcrumbItem><Link to="/gestor/escolas">Escolas</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/escolas/${this.props.match.params.idEscola}`}>{this.state.escola.nome}</Link></BreadcrumbItem>
            <BreadcrumbItem active>Funcionário</BreadcrumbItem>
          </Breadcrumb>
          <Form onSubmit={this.onSubmit}>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Dados do funcionário</CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="4">
                    <FormGroup className={`has-label ${this.state.nomeState}`}>
                      <label>Nome *</label>
                      <Input
                        disabled
                        type="text"
                        maxLength="200"
                        value={this.state.nome}
                        onChange={(evt) => this.setState({ nome: evt.target.value, nomeState: 'has-success' })}
                      />
                      {this.state.nomeState === 'has-danger' ? (
                        <label className="error">
                          Informe o nome do funcionário.
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup className={'has-label has-danger'}>
                      <label>Função *</label>
                      <Select
                        isDisabled
                        noOptionsMessage={() => 'Nenhuma entrada'}
                        className={`react-select primary ${this.state.tipoFuncionarioState}`}
                        classNamePrefix="react-select"
                        value={this.state.tipoFuncionarioSelecionado}
                        onChange={(event) => {
                          this.setState({ tipoFuncionarioState: 'primary', tipoFuncionarioSelecionado: event });
                        }}
                        options={this.tiposFuncionario}
                        placeholder="Função..."
                      />
                      {this.state.tipoFuncionarioState === 'danger' ? (
                        <label className="error">
                          Informe a função.
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md="5">
                    <FormGroup className={`has-label ${this.state.enderecoState}`}>
                      <label>Endereço</label>
                      <Input
                        disabled
                        type="text"
                        maxLength="200"
                        value={this.state.endereco}
                        onChange={(evt) => this.setState({ endereco: evt.target.value, enderecoState: 'has-success' })}
                      />
                      {this.state.enderecoState === 'has-danger' ? (
                        <label className="error">
                          Informe o endereço do funcionário.
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="3">
                    <FormGroup className={`has-label ${this.state.naturalidadeState}`}>
                      <label>Naturalidade</label>
                      <Input
                        disabled
                        value={this.state.naturalidade}
                        type="text"
                        maxLength="100"
                        onChange={(evt) => this.setState({ naturalidade: evt.target.value, naturalidadeState: 'has-success' })}
                      />
                      {this.state.naturalidadeState === 'has-danger' ? (
                        <label className="error">
                          Informe a naturalidade.
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md="2">
                    <FormGroup className={`has-label ${this.state.cpfState}`}>
                      <label>CPF</label>
                      <Input
                        disabled
                        value={this.state.cpf}
                        type="text"
                        mask="999.999.999-99"
                        maskChar=""
                        onChange={(evt) => this.setState({ cpf: evt.target.value, cpfState: 'has-success' })}
                        tag={InputMask}
                      />
                      {this.state.cpfState === 'has-danger' ? (
                        <label className="error">
                          Informe um CPF válido.
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup className={`has-label ${this.state.rgState}`}>
                      <label>RG</label>
                      <Input
                        disabled
                        value={this.state.rg}
                        type="text"
                        maxLength="100"
                        onChange={(evt) => this.setState({ rg: evt.target.value, rgState: 'has-success' })}
                      />
                      {this.state.rgState === 'has-danger' ? (
                        <label className="error">
                          Informe os dados do RG do funcionário.
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md="2">
                    <FormGroup className={`has-label ${this.state.cartaoSUSState}`}>
                      <label>Cartão SUS</label>
                      <Input
                        disabled
                        value={this.state.cartaoSUS}
                        type="text"
                        mask="999 9999 9999 9999"
                        maskChar=""
                        onChange={(evt) => this.setState({ cartaoSUS: evt.target.value, cartaoSUSState: 'has-success' })}
                        tag={InputMask}
                      />
                      {this.state.cartaoSUSState === 'has-danger' ? (
                        <label className="error">
                          Informe um número do SUS válido.
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md="2">
                    <FormGroup className={`has-label ${this.state.nisState}`}>
                      <label>NIS</label>
                      <Input
                        disabled
                        value={this.state.nis}
                        type="text"
                        mask="99999999999"
                        maskChar=""
                        tag={InputMask}
                        onChange={(evt) => this.setState({ nis: evt.target.value, nisState: 'has-success' })}
                      />
                      {this.state.nisState === 'has-danger' ? (
                        <label className="error">
                          NIS inválido.
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="2">
                    <FormGroup className={`has-label ${this.state.dataNascimentoState}`}>
                      <label>Data de nascimento</label>
                      <Input
                        disabled
                        value={this.state.dataNascimento}
                        type="text"
                        mask="99/99/9999"
                        maskChar=""
                        tag={InputMask}
                        onChange={(evt) => this.setState({ dataNascimento: evt.target.value, dataNascimentoState: 'has-success' })}
                      />
                      {this.state.dataNascimentoState === 'has-danger' ? (
                        <label className="error">
                          Informe uma data válida.
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md="2">
                    <FormGroup className={'has-label has-danger'}>
                      <label>Sexo</label>
                      <Select
                        isDisabled
                        noOptionsMessage={() => 'Nenhuma entrada'}
                        className={`react-select primary ${this.state.sexoState}`}
                        classNamePrefix="react-select"
                        value={this.state.sexoSelecionado}
                        onChange={(event) => {
                          this.setState({ sexoState: 'primary', sexoSelecionado: event });
                        }}
                        options={this.sexos}
                        placeholder="Sexo..."
                      />
                      {this.state.sexoState === 'danger' ? (
                        <label className="error">
                          Informe o sexo.
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup className={'has-label has-danger'}>
                      <label>Etnia</label>
                      <Select
                        isDisabled
                        noOptionsMessage={() => 'Nenhuma entrada'}
                        className={`react-select primary ${this.state.etniaState}`}
                        classNamePrefix="react-select"
                        value={this.state.etniaSelecionada}
                        onChange={(event) => {
                          this.setState({ etniaState: 'primary', etniaSelecionada: event });
                        }}
                        options={this.etnias}
                        placeholder="Etnia..."
                      />
                      {this.state.etniaState === 'danger' ? (
                        <label className="error">
                          Informe a etnia.
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup className={'has-label has-danger'}>
                      <label>PCD</label>
                      <Select
                        isDisabled
                        noOptionsMessage={() => 'Nenhuma entrada'}
                        className={`react-select primary ${this.state.pneState}`}
                        classNamePrefix="react-select"
                        value={this.state.pneSelecionado}
                        onChange={(event) => {
                          this.setState({ pneState: 'primary', pneSelecionado: event });
                        }}
                        options={this.pnes}
                        placeholder="Pessoa com deficiência..."
                      />
                      {this.state.pneState === 'danger' ? (
                        <label className="error">
                          Informe se possui deficiência.
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md="2">
                    <FormGroup className={'has-label has-danger'}>
                      <label>Bolsa família</label>
                      <Select
                        isDisabled
                        noOptionsMessage={() => 'Nenhuma entrada'}
                        className={`react-select primary ${this.state.bolsaFamiliaState}`}
                        classNamePrefix="react-select"
                        value={this.state.bolsaFamilia}
                        onChange={(event) => {
                          this.setState({ bolsaFamiliaState: 'primary', bolsaFamilia: event });
                        }}
                        options={this.opcoesBolsaFamilia}
                        placeholder="Recebe bolsa família..."
                      />
                      {this.state.bolsaFamiliaState === 'danger' ? (
                        <label className="error">
                          Informe se recebe bolsa família.
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>
                <div className="category form-category">
                  * Campos obrigatórios
                </div>
              </CardBody>
              <CardFooter>
                <Row>
                  <Col md="6" className="text-left">
                    <Button
                      color="primary"
                      disabled={this.state.buttonDisabled}
                      onClick={() => this.props.history.push(`${this.props.layout}/escolas/${this.props.match.params.idEscola}`)} >
                      Voltar
                    </Button>
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </Form>
        </Col>
      </Row>;
  }

  render() {
    return (
      <div className="content">
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
            </div>
          </Card>
        }
        <LoaderModal
          isOpen={this.state.buttonDisabled}
          text={this.state.loaderModalText} />
      </div>
    );
  }
}

export default Funcionario;
