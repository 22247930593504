/* eslint-disable max-len */
import React from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';

import {
  Row,
  Col,
  Button,
  Form,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Input,
  FormGroup,
  CardFooter,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap';

import Loader from 'react-loader-spinner';
import Select from 'react-select';

import Alert from 'views/components/Alert';
import LoaderModal from 'views/components/LoaderModal';
import ConfirmTypingModal from 'views/components/ConfirmTypingModal';

import LoginService from 'services/LoginService';
import EscolasService from 'services/EscolasService';
import UsuarioService from 'services/UsuarioService';

import Papel from 'assets/csv/papeis.json';

class TransferirAluno extends React.Component {
  constructor(props) {
    super(props);

    this.loginService = new LoginService();
    this.escolasService = new EscolasService();
    this.usuarioService = new UsuarioService();

    this.state = {
      showAlert: false,
      error: null,

      escolaSelecionada: null,

      alunos: [],
      carregandoAlunos: false,
      alunoSelecionado: null,
    };

    this.cadastrarSolicitacaoTransferencia = this.cadastrarSolicitacaoTransferencia.bind(this);
  }

  componentWillUnmount() {
    this.loginService.abortPedingRequests();
    this.escolasService.abortPedingRequests();
    this.umounted = true;
  }

  async componentDidMount() {
    this.debouncedFunction = _.debounce((text) => {
      if (text === '') {
        this.setState({ carregandoAlunos: false, alunos: [] });
      } else {
        this.carregarAlunos(text);
      }
    }, 1500);

    try {
      const escola = await this.escolasService
        .carregarEscola(this.props.match.params.idEscola);

      const escolas = await this.escolasService
        .carregarTodasAsEscolas();

      const aluno = await this.usuarioService
        .carregarUsuario(this.props.match.params.idAluno);

      const selectEscolas = [];
      escolas.forEach((esc) => {
        if (esc.id !== escola.id) {
          selectEscolas.push({
            value: esc.id,
            label: esc.nome,
          })
        }
      });

      this.setState({
        escola,
        selectEscolas,
        aluno,
      });
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  async carregarAlunos(like) {
    try {
      const data = await this.usuarioService
        .carregarUsuariosVinculadosEscola(this.props.match.params.idEscola, Papel.ALUNO.value, 1, 99999, like);

      const selectAlunos = [];
      data.usuarios.forEach((aluno) => {
        selectAlunos.push({ value: aluno.id, label: `${aluno.nome} (Mãe: ${aluno.nomeMae})` });
      });

      this.setState({
        carregandoAlunos: false,
        alunos: selectAlunos,
      });
    } catch (e) {
      if (this.umounted) return;
      this.setState({
        carregandoAlunos: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao buscar alunos cadastrados',
      });
    }
  }

  validarCampos() {
    let ret = true;

    if (!this.state.escolaSelecionada) {
      this.setState({ escolaState: 'danger' });
      ret = false;
    }

    if (!this.state.alunoSelecionado) {
      this.setState({ alunoState: 'danger' });
      ret = false;
    }

    return ret;
  }

  limparFormulario() {
    this.setState({
      escolaState: '',
      escolaSelecionada: null,

      alunoState: '',
      alunoSelecionado: null,
    });
  }

  confirmarSolicitacaoTransferenciaAluno() {
    if (!this.validarCampos()) return;

    this.setState({
      showConfirmModal: true,
    });
  }

  async cadastrarSolicitacaoTransferencia(senha) {
    if (!this.validarCampos()) return;

    this.setState({
      showAlert: false,
      showLoaderModal: true,
      loaderModalText: 'Cadastrando solicitação de transferência...',
    });

    try {
      await this.escolasService
        .cadastrarSolicitacaoTransferencia(this.props.match.params.idEscola,
          this.state.alunoSelecionado.value,
          this.state.escolaSelecionada.value,
          senha);

      this.limparFormulario();

      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Solicitação de transferência cadastrada com sucesso.',
      });
    } catch (msg) {
      if (this.umounted) return;
      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao cadastrar solicitação de transferência',
      });
    }
  }

  conteudoPagina() {
    return this.state.selectEscolas ? <>
      <Row>
        <Col md="12">
          <Alert
            color={this.state.alertColor}
            isOpen={this.state.showAlert}
            toggle={() => { this.setState({ showAlert: false }); }}>
            {this.state.alertMsg}
          </Alert>
          <Breadcrumb>
            <BreadcrumbItem><Link to={`${this.props.layout}/escolas`}>Escolas</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/escolas/${this.props.match.params.idEscola}`}>{this.state.escola.nome}</Link></BreadcrumbItem>
            <BreadcrumbItem active>Solicitar transferência de aluno</BreadcrumbItem>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Form>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Solicitar transferência de aluno</CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="4">
                    <FormGroup className={`has-label`}>
                      <label>Escola de origem</label>
                      <Input
                        disabled
                        type="text"
                        value={this.state.escola.nome}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup className={'has-label has-danger'}>
                      <label>Escola de destino *</label>
                      <Select
                        noOptionsMessage={() => 'Nenhuma entrada'}
                        className={`react-select primary ${this.state.escolaState}`}
                        classNamePrefix="react-select"
                        value={this.state.escolaSelecionada}
                        onChange={(event) => {
                          this.setState({ escolaState: '', escolaSelecionada: event });
                        }}
                        options={this.state.selectEscolas}
                        placeholder="Escola..."
                      />
                      {this.state.escolaState === 'danger' ? (
                        <label className="error">
                          Informe a escola para a qual o aluno deve ser transferido.
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup className={'has-label has-danger'}>
                      <label>Aluno *</label>
                      <Select
                        isLoading={this.state.carregandoAlunos}
                        loadingMessage={() => 'Carregando...'}
                        noOptionsMessage={() => 'Nenhuma entrada'}
                        className={`react-select primary ${this.state.alunoState}`}
                        classNamePrefix="react-select"
                        value={this.state.alunoSelecionado}
                        onInputChange={(text) => {
                          this.setState({ carregandoAlunos: text !== '', alunoState: 'primary' });
                          this.debouncedFunction(text);
                        }}
                        onChange={(event) => {
                          this.setState({
                            alunoState: 'primary',
                            alunoSelecionado: event,
                          });
                        }}
                        options={this.state.alunos}
                        placeholder="Digite o nome de um aluno cadastro no sistema..."
                      />
                      {this.state.alunoState === 'danger' ? (
                        <label className="error">
                          Informe o nome de um aluno cadastrado.
                        </label>
                      ) : <label></label>}
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <Row>
                  <Col className="text-left">
                    <Button
                      color="primary"
                      onClick={() => this.props.history.goBack()} >
                      Voltar
                    </Button>
                  </Col>
                  <Col className="text-right">
                    <Button
                      type="button"
                      color="primary"
                      onClick={() => this.confirmarSolicitacaoTransferenciaAluno()}>
                      Solicitar transferência
                    </Button>
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </Form>
        </Col >
      </Row >
    </>
      : <Card>
        <div align="center" style={{ margin: 50 }}>
          <Loader
            type="Oval"
            color="#34B5B8"
            height="50"
            width="50" />
        </div>
      </Card>;
  }

  render() {
    return (
      <div className='content'>
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
            </div>
          </Card>
        }
        <LoaderModal
          isOpen={this.state.showLoaderModal}
          text={this.state.loaderModalText} />
        <ConfirmTypingModal
          isOpen={this.state.showConfirmModal}
          callback={(senha) => {
            this.setState({ showConfirmModal: false });
            if (senha) {
              this.cadastrarSolicitacaoTransferencia(senha);
            } else {
              this.setState({ escolaSelecionada: undefined });
            }
          }}
          deletionInstruction='Confirme que você quer cadastrar uma solicitação de transferência digitando a sua senha no campo abaixo'
          deletionText={''}
          deletionWarning='A transferência será realizada somente após a confirmação pelo diretor da escola de destino.'
          buttonText={'Solicitar transferência'} />
      </div>
    );
  }
}

export default TransferirAluno;
