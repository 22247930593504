import React from 'react';
import { Link } from 'react-router-dom';

import {
  Row,
  Col,
  Card,
  CardBody,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap';

import {
  Tabs,
  TabList,
  Tab,
  TabPanel,
} from 'react-tabs';

import { connect } from 'react-redux';

import Loader from 'react-loader-spinner';

import Alert from 'views/components/Alert';

import LoginService from 'services/LoginService';
import EntesService from 'services/EntesService';
import AnosLetivosService from 'services/AnosLetivosService';
import EscolasService from 'services/EscolasService';
import TurmasService from 'services/TurmasService';

import Turno from 'assets/csv/turnos.json';
import InformacoesTurmaMultiseriado from 'views/components/InformacoesTurmaMultiseriado';
import DiariosTurmaMultiseriado from 'views/components/DiariosTurmaMultiseriado';
import ProfessoresTurmaMultiseriado from 'views/components/ProfessoresTurmaMultiseriado';
import HorariosTurmaMultiseriado from 'views/components/HorariosTurmaMultiseriado';
import MatriculasTurmaMultiseriado from 'views/components/MatriculasTurmaMultiseriado';

class TurmaMultiseriado extends React.Component {
  constructor(props) {
    super(props);

    this.turnos = [
      Turno.MATUTINO,
      Turno.VESPERTINO,
      Turno.NOTURNO,
      Turno.INTEGRAL,
    ];

    this.state = {
      showAlert: false,

      error: null,
    };

    this.loginService = new LoginService();
    this.entesService = new EntesService();
    this.anosLetivosService = new AnosLetivosService();
    this.escolasService = new EscolasService();
    this.turmasService = new TurmasService();
  }

  async componentDidMount() {
    await this.loginService.verificarPapel(this.props.role);
    try {
      const dadosEnte = await this.entesService
        .carregarDadosEnte();

      const anoLetivo = await this.anosLetivosService
        .carregarAnoLetivo(this.props.match.params.idAnoLetivo);

      const escola = await this.escolasService
        .carregarEscola(this.props.match.params.idEscola);

      const turma = await this.turmasService
        .carregarTurmaMultiseriado(this.props.match.params.idTurma);

      turma.turmas.sort((t1, t2) => t1.curso.serie !== t2.curso.serie 
        ? t1.curso.serie - t2.curso.serie : t1.curso.nome.localeCompare(t2.curso.nome));

      this.setState({
        dadosEnte,
        anoLetivo,
        escola,
        turma,
      });
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  conteudoPagina() {
    return this.state.escola ? <>
      <Row>
        <Col md="12">
          <Alert
            color={this.state.alertColor}
            isOpen={this.state.showAlert}
            toggle={() => { this.setState({ showAlert: false }); }}>
            {this.state.alertMsg}
          </Alert>
          <Breadcrumb>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos`}>Anos Letivos</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}`}>Ano letivo {this.state.anoLetivo.ano}</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}`}>{this.state.escola.nome}</Link></BreadcrumbItem>
            <BreadcrumbItem active>{this.state.turma.nome} - {this.turnos[this.state.turma.turno].label}</BreadcrumbItem>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Card>
            <CardBody>
              <Tabs>
                <TabList>
                  <Tab>Informações</Tab>
                  <Tab>Diários</Tab>
                  <Tab>Professores</Tab>
                  { this.state.dadosEnte.registroAulas && <Tab>Horários</Tab> }
                  <Tab>Alunos matriculados</Tab>
                </TabList>
                <TabPanel>
                  <InformacoesTurmaMultiseriado
                    {...this.props}
                    turma={this.state.turma}
                    anoLetivo={this.state.anoLetivo}
                    callbackMessage={(msg) => this.setState(msg)}
                  />
                </TabPanel>
                <TabPanel>
                  <DiariosTurmaMultiseriado
                    {...this.props}
                    turma={this.state.turma}
                    callbackMessage={(msg) => this.setState(msg)} />
                </TabPanel>
                <TabPanel>
                  <ProfessoresTurmaMultiseriado
                    {...this.props}
                    turma={this.state.turma}
                    callbackMessage={(msg) => this.setState(msg)}
                  />
                </TabPanel>
                { 
                  this.state.dadosEnte.registroAulas && <TabPanel>
                    <HorariosTurmaMultiseriado
                      {...this.props}
                      turma={this.state.turma}
                      anoLetivo={this.state.anoLetivo}
                      escola={this.state.escola}
                      callbackMessage={(msg) => this.setState(msg)}
                    />
                  </TabPanel>
                }
                <TabPanel>
                  <MatriculasTurmaMultiseriado
                    {...this.props}
                    turma={this.state.turma}
                    anoLetivo={this.state.anoLetivo}
                    callbackMessage={(msg) => this.setState(msg)}
                  />
                </TabPanel>
              </Tabs>
            </CardBody>
          </Card>
        </Col >
      </Row >
    </>
      : <Card>
        <div align="center" style={{ margin: 50 }}>
          <Loader
            type="Oval"
            color="#34B5B8"
            height="50"
            width="50" />
        </div>
      </Card>;
  }

  render() {
    return (
      <div className='content'>
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
            </div>
          </Card>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ dadosUsuario: state.dadosUsuario });

export default connect(mapStateToProps)(TurmaMultiseriado);
